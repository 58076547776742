import {
  POST_ERROR,
  POST_NOTICE,
  CONSUME_ERRORS,
  CONSUME_NOTICES,
} from './const';

export function postError(message) {
  return { type: POST_ERROR, message };
}
export function postNotice(message) {
  return { type: POST_NOTICE, message };
}
export function consumeErrors() {
  return { type: CONSUME_ERRORS };
}
export function consumeNotices() {
  return { type: CONSUME_NOTICES };
}
