import React from "react";
import { useSelector } from "react-redux";

import PatientDashboard from "./../patient/pages/PatientDashboard";
import DoctorDashboard from "./../doctor/pages/DoctorDashboard";

const Dashboard = (props, context) => {
  const { doctorId, patientId } = useSelector(state => state.user.current);

  return !!doctorId ? (
    <DoctorDashboard />
  ) : !!patientId ? (
    <PatientDashboard />
  ) : null;
};

export default Dashboard;
