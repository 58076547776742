import { cloneDeep } from "lodash";

import {
  DOCUMENT_TYPES_INDEX,
  DOCUMENT_TYPES_INDEX_SUCCESS,
  DOCUMENT_TYPES_INDEX_ERROR
} from "../actions/const";

const initialState = {
  loading: false,
  list: []
};

function reducer(state = initialState, action) {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case DOCUMENT_TYPES_INDEX: {
      nextState.loading = true;
      return nextState;
    }
    case DOCUMENT_TYPES_INDEX_SUCCESS: {
      nextState.loading = false;
      nextState.list = action.documentTypes;
      return nextState;
    }
    case DOCUMENT_TYPES_INDEX_ERROR: {
      nextState.loading = false;
      nextState.list = [];
      return nextState;
    }
    default: {
      return state;
    }
  }
}

export default reducer;
