import { cloneDeep } from "lodash";

import {
  GET_DOCTOR_PATIENT,
  GET_DOCTOR_PATIENT_SUCCESS,
  GET_DOCTOR_PATIENT_ERROR
} from "../actions/const";
import {
  EDIT_DOCTOR_PATIENT,
  EDIT_DOCTOR_PATIENT_SUCCESS,
  EDIT_DOCTOR_PATIENT_ERROR
} from "../actions/const";

const initialState = {
  loading: false,
  current: {},
  errors: []
};

const reducer = (state = initialState, action) => {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case GET_DOCTOR_PATIENT: {
      nextState.loading = true;
      return nextState;
    }
    case GET_DOCTOR_PATIENT_SUCCESS: {
      const { doctorPatient } = action;
      nextState.loading = false;
      nextState.current = doctorPatient;
      nextState.loading = false;
      return nextState;
    }
    case GET_DOCTOR_PATIENT_ERROR: {
      nextState.current = {};
      nextState.loading = false;
      return nextState;
    }
    case EDIT_DOCTOR_PATIENT: {
      return nextState;
    }
    case EDIT_DOCTOR_PATIENT_SUCCESS: {
      nextState.errors = [];
      return nextState;
    }
    case EDIT_DOCTOR_PATIENT_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    default: {
      return nextState;
    }
  }
};

export default reducer;
