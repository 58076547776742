import {
  LOGIN,
  LOGOUT_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_ERROR,
  LOGIN_SUCCESS
} from "../actions/const";

export const localStorageMiddleware = () => {
  return next => action => {
    const result = next(action);
    if (
      [LOGIN, LOGOUT_SUCCESS, LOGIN_ERROR, LOGOUT_ERROR].includes(result.type)
    ) {
      localStorage.removeItem("user_token");
      localStorage.removeItem("patient_profile_id");
      localStorage.removeItem("doctor_profile_id");
    }

    if (result.type === LOGIN_SUCCESS) {
      localStorage.setItem("user_token", result.token);
      if (!!result.data.patientId)
        localStorage.setItem("patient_profile_id", result.data.patientId);
      if (!!result.data.doctorId)
        localStorage.setItem("doctor_profile_id", result.data.doctorId);
    }

    return result;
  };
};
