import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Dialog } from "@material-ui/core";

import AddScheduledFileDialogBody from "./AddScheduledFileDialogBody";
import { resetScheduledFileErrors } from "../../../../actions";

const AddScheduledFileHome = (props, context) => {
  const actionsDispatch = useDispatch();
  const closeModal = () => {
    actionsDispatch(resetScheduledFileErrors());
    actionsDispatch(push("/"));
  };
  return (
    <Dialog open={true} onClose={closeModal}>
      <AddScheduledFileDialogBody closeModal={closeModal} />
    </Dialog>
  );
};

export default AddScheduledFileHome;
