export const specializations = [
  "general_surgery",
  "pediatric_surgery",
  "plastic_surgey",
  "reconstructive_surgey",
  "cosmetic_surgey",
  "gynecology",
  "obstetrics",
  "neurosurgery",
  "orthopedics",
  "traumatology",
  "urology",
  "maxillo_facial_surgery",
  "ophthalmology",
  "otolaryngology",
  "cardiac_surgery",
  "thoracic_surgery",
  "vascular_surgery"
];

export const documentCodes = {
  medicalRecord: "med_rec"
};
