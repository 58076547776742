import React, { useEffect, useReducer, useState } from "react";
import { useInterval } from "./../../../hooks/useInterval";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty, filter, reject } from "lodash";
import ApiJsonNavigator from "../../../sources/libs/ApiJsonNavigator";
import {
  Avatar,
  Box,
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Tabs,
  Tab,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from "@material-ui/core";
import {
  Check,
  Close,
  Reply,
  Payment,
  Undo,
  Phone,
  Email,
  PhoneAndroid,
  LocalHospital,
  HeadsetMic,
  CreditCard
} from "@material-ui/icons";
import {
  addExaminationDocument,
  cancelExamination,
  deleteExaminationDocument,
  examinationDocumentIndex,
  getExamination
} from "../../../actions";
import FileUploader from "../../shared/FileUploader";
import FileList from "../../shared/FileList";
import ConfirmDialog from "./../../shared/ConfirmDialog";
import MessageList from "../../shared/MessageList";
import store from "./../../../stores";
import config from "./../../../config";
import axios from "./../../../sources/AxiosConfig";

/*import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { loadScript } from "@paypal/paypal-js";
import { FUNDING } from "@paypal/react-paypal-js";*/
import { PayPalButton } from "react-paypal-button-v2";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { PayPalButtons } from "@paypal/react-paypal-js";
const stripePromise = loadStripe("pk_test_51IMYTXIHWTkudsw55ft14bEgmSantG7zDah64vlCy8qZPoFhSRIMzdsrIhhimmm8UeVoiyELNhDnlwjFxQKY6GTj00fshrK5qC");

const useStyles = makeStyles(theme => ({
  green: { backgroundColor: "green", width: 96, height: 96 },
  orange: { backgroundColor: "orange", width: 96, height: 96 },
  red: { backgroundColor: "red", width: 96, height: 96 },
  icon: { fontSize: "4rem" },
  textkarla: { fontFamily: "karla"}
}));

const useEnhancer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [fileTabValue, setFileTabValue] = useState(0);

  useEffect(() => {
    dispatch(dispatch(getExamination(id)));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(examinationDocumentIndex(id));
  }, [dispatch, id]);
  const examination = useSelector(state => {
    return state.examination.current instanceof ApiJsonNavigator
      ? state.examination.current.data()
      : state.examination.current;
  });

  useInterval(async () => {
    dispatch(getExamination(id));
    dispatch(examinationDocumentIndex(id));
  }, 3000);
  
  return {
    id,
    loading: useSelector(
      state => state.examination.loading || state.document.loading
    ),
    examination: examination,
    documents: useSelector(state =>
      state.document.list.map(({ id, url, file_name, mime_type }) => ({
        id,
        url,
        filename: file_name,
        mime_type
      }))
    ),
    onCancel: () => {
      setConfirmDialog(true);
    },
    onCancelConfirmed: () => {
      dispatch(cancelExamination(id, () => dispatch(getExamination(id))));
    },
    onFileUpload: ({ file }) => {
      dispatch(
        addExaminationDocument(
          id,
          null,
          examination.patient_profile().id,
          examination.medical_service().title,
          null,
          file,
          () => dispatch(examinationDocumentIndex(id))
        )
      );
    },
    onFileDelete: ({ fileId }) => {
      dispatch(
        deleteExaminationDocument(id, fileId, () =>
          dispatch(examinationDocumentIndex(id))
        )
      );
    },
    openExaminationZoomMeeting: () => {
      const token = store.getState().user.token;
      document.cookie = `token=${token};domain=${config.cookieDomain};path=/`;
      window.open(
        `${config.backofficeUrl}/examinations/${id}/zoom_meeting`,
        "_blank"
      );
    },
    confirmDialog,
    setConfirmDialog,
    fileTabValue,
    setFileTabValue
  };
};


//FUNCTION COMPONENT START//
const Examination = (props, { t }) => {
  const {
    id,
    loading,
    examination,
    documents,
    onCancel,
    onCancelConfirmed,
    onFileUpload,
    onFileDelete,
    openExaminationZoomMeeting,
    confirmDialog,
    setConfirmDialog,
    fileTabValue,
    setFileTabValue
  } = useEnhancer();
  const classes = useStyles();

  const [stripeAmount, setStripeAmount] = useState('');
  const [stripePreCheck, setStripePreCheck] = useState('');
  const [payPalDetails, setPayPalDetails] = useState('');
  const [payPalData, setPayPalData] = useState('');
  const [clientMessage, setClientMessage] = useState('');
  const [zoomHealthJoinUrl, setZoomHealthJoinUrl] = useState(0);

  let icon = (
    <Avatar className={classes.red}>
      <Close />
    </Avatar>
  );

  useEffect(() => {
    console.log("THis is ytesy ");
    window
      .fetch(`${config.apiBaseUrl}/payment_intent_before.json`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ examinationId: id })
      }).then(res => {
        // console.log(res.json());
        return res.json();
      })
      .then(data => {
        //console.log(data);
        setStripePreCheck(data.status);
      });
  }, [id]);

  useEffect(() => {
    if((payPalData != '') && (payPalDetails != '')){
      //alert(JSON.stringify(payPalDetails.purchase_units[0].payments.captures[0].amount.value));
      //alert(payPalData);
      window
      .fetch(`${config.apiBaseUrl}/payment_intent_paypal_after.json`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          payPalPayloadDetails: payPalDetails, 
          payPalPayloadData: payPalData,
          examinationId: id
        })
      }).then(res => {
        return res.json();
      })
      .then(data => {
        console.log(data)
        setClientMessage(data.status);
        if(data.status === "succeeded"){
          setStripePreCheck('succeeded');
        }
      });
    } else { 

    }
  }, [id, payPalData, payPalDetails]);

  useEffect(() => {
    if(zoomHealthJoinUrl) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/zoom_health_join_url.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ examinationId: id })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if((data.status == 'NoUserCreated') || (data.status == 'NoMeetingCreated')){
            setZoomHealthJoinUrl(0)
          }else{
            window.open(`${data.meetingJoinUrl}`, '_blank')
            setZoomHealthJoinUrl(0)
          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [zoomHealthJoinUrl]);

  const [postProcessDoctorTeleconsultDoctorEmail, setPostProcessDoctorTeleconsultDoctorEmail] = useState(0);
  const [postProcessDoctorTeleconsultPatientEmail, setPostProcessDoctorTeleconsultPatientEmail] = useState(0);

  useEffect(() => {
    console.log("THis is ytesy ");
    window
      .fetch(`${config.apiBaseUrl}/post_process_doctor_teleconsult.json`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ examinationId: id})
      }).then(res => {
        // console.log(res.json());
        return res.json();
      })
      .then(data => {
        if(data.status3 && data.status4){
          setPostProcessDoctorTeleconsultDoctorEmail(data.status3)
          setPostProcessDoctorTeleconsultPatientEmail(data.status4)
        } else{
          setPostProcessDoctorTeleconsultDoctorEmail(data.status)
        }
        //console.log(data.zoomHealthConMsg);
      });
  }, [id]);

  switch (examination.aasm_state) {
    case "requested":
      icon = (
        <Avatar className={classes.orange}>
          <Reply className={classes.icon} />
        </Avatar>
      );
      break;
    case "accepted":
      icon = (
        postProcessDoctorTeleconsultDoctorEmail
        ?
          <Avatar className={classes.green}>
            <Check className={classes.icon} />
          </Avatar>
        :
          <Avatar className={stripePreCheck == "succeeded" ? (classes.green) : classes.orange}>
            {
              stripePreCheck == "succeeded"
              ? <Check className={classes.icon} />
              : <Payment className={classes.icon} />
            }
          </Avatar>
      );
      break;
    default:
      icon = (
        <Avatar className={classes.red}>
          <Close className={classes.icon} />
        </Avatar>
      );
      break;
  }

  if (isEmpty(examination)) return <LinearProgress />;

  return (
    <>
      <Grid container spacing={4}>

        <Grid item sm={12} md={4} lg={4}>
          <Box display="flex" justifyContent="center">
            <Tooltip
              placement="top"
              title={t(
                `components.patient.pages.examination.${examination.aasm_state}_state`
              )}
            >
              {icon}
            </Tooltip>
          </Box>
          <br />

          <Typography variant="body1" align="center" gutterBottom>
            {t(
              `components.patient.pages.examination.${examination.aasm_state}_state`
            )}
          </Typography>
          <br />
          
          {
            examination.aasm_state === "accepted" 
            ? 
              postProcessDoctorTeleconsultDoctorEmail
              ?
                (
                  <>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={event => setZoomHealthJoinUrl(1)}
                    >
                      <HeadsetMic />
                      &nbsp;
                      {t("components.patient.pages.examination.meeting")}
                    </Button>

                    {/*<Button
                      variant="contained"
                      fullWidth
                      onClick={openExaminationZoomMeeting}
                    >
                      <HeadsetMic />
                      &nbsp;
                      {t("components.patient.pages.examination.meeting")}
                    </Button>*/}

                    <Typography variant="body2">
                      <small className={classes.textkarla}>
                        {t("components.doctor.pages.examination.zoom_health_meeting_direct_link")}
                        <a target="_blank" href="https://zoom.us/download">{t("components.doctor.pages.examination.meeting_direct_link_label")}</a>
                      </small>
                    </Typography>
                    <br />
                    {/*<Typography variant="body2">
                      <large className={classes.textkarla}>
                        {t(
                          "components.patient.pages.examination.meeting_direct_link"
                        )}
                        {<a target="_blank" href={examination.zoom_meeting.join_url}>}
                        <a target="_blank" href={examination.zoom_meeting.join_url}>
                          {t(
                            "components.patient.pages.examination.meeting_direct_link_label"
                          )}
                        </a>
                      </large>
                    </Typography>*/}
                  </>
                ) 
              :
                (
                  <>
                  {
                    stripePreCheck !== "succeeded"
                    ?
                      <Typography align="center">
                        <large className={classes.textkarla}>
                          {t("components.patient.pages.examination.paywithcreditcard")}
                        </large>
                        <Elements stripe={stripePromise}>
                          <CheckoutForm 
                            setStripeAmount={setStripeAmount}
                            paynow={t("components.patient.pages.examination.paynow")} 
                            setStripePreCheck={setStripePreCheck} 
                            examination={examination} 
                            examinationId={examination.id} 
                          />
                        </Elements>
                        <large className={classes.textkarla}>
                            {t("components.patient.pages.examination.amounttopay")}: {examination.medical_service().price}
                        </large>

                        <br /><br />
                        <Divider />
                          <large className={classes.textkarla}>
                            {t("components.patient.pages.examination.payor")}
                          </large>
                        <Divider />
                        <br />

                        <large className={classes.textkarla}>
                          {t("components.patient.pages.examination.paywithpaypal")}
                        </large>
                        <br />
                        {/*<PayPalScriptProvider options={{ "client-id": "test" }}>
                          <PayPalButtons style={{ layout: "horizontal" }} />
                        </PayPalScriptProvider>*/}
                        <PayPalButton
                          style={{ layout: "horizontal", tagline: false }}
                          currency="USD"
                          amount={stripeAmount}
                          /*onSuccess={(details, data) => {
                            alert("Transaction completed by " + details.payer.name.given_name);
                            return fetch("/paypal-transaction-complete", {
                              method: "post",
                              body: JSON.stringify({
                                orderID: data.orderID
                              })
                            });
                          }}*/
                          onSuccess={
                            (details, data) => {
                              setPayPalDetails(details);
                              setPayPalData(data);
                              //console.log(details);
                              //alert(details.purchase_units);
                              //alert(details.purchase_units.amount.value);
                            }
                          }
                          options={{
                            clientId: "ARQ-WKAkFn3g4C111Ud3lLaUAfzagvJ_pmkLKBVMASvv6nyjX3fv3j0gtBdJEDhRPznYP9sLtf9oiJfH",
                            currency: "USD",
                            disableFunding: 'paylater'
                          }}
                        />
                        <large className={classes.textkarla}>
                            {t("components.patient.pages.examination.amounttopay")}: {examination.medical_service().price}
                        </large>
                        <br /><br />
                      </Typography>
                    : null
                  }

                  { stripePreCheck === "succeeded" && (
                    <Typography className={classes.textkarla} variant="body1" align="center" gutterBottom>
                      <large alignItems="center">{t("components.patient.pages.examination.paid")}: {examination.medical_service().price}</large>
                      <br />
                    </Typography> )
                  }

                  { stripePreCheck === "succeeded" && (
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={event => setZoomHealthJoinUrl(1)}
                    >
                      <HeadsetMic />
                      &nbsp;
                      {t("components.patient.pages.examination.meeting")}
                    </Button>

                    /*<Button
                      variant="contained"
                      fullWidth
                      onClick={openExaminationZoomMeeting}
                    >
                      <HeadsetMic />
                      &nbsp;
                      {t("components.patient.pages.examination.meeting")}
                    </Button>*/) 
                  }
                
                  { stripePreCheck === "succeeded" &&
                    <Typography variant="body2">
                      <small className={classes.textkarla}>
                        {t("components.doctor.pages.examination.zoom_health_meeting_direct_link")}
                        <a target="_blank" href="https://zoom.us/download">{t("components.doctor.pages.examination.meeting_direct_link_label")}</a>
                      </small>
                    </Typography>
                    /*<Typography variant="body2">
                      <large className={classes.textkarla}>
                        {t(
                          "components.patient.pages.examination.meeting_direct_link"
                        )}
                        {<a target="_blank" href={examination.zoom_meeting.join_url}>}
                        <a target="_blank" href={examination.zoom_meeting.join_url}>
                          {t(
                            "components.patient.pages.examination.meeting_direct_link_label"
                          )}
                        </a>
                      </large>
                    </Typography>*/
                  }
                  </>
                ) 
            : null
          }

          {
            examination.aasm_state == "accepted"
            ?
              postProcessDoctorTeleconsultDoctorEmail
              ?
                moment(examination.doctor_timetable().end_at).isBefore(moment()) || (
                  <Button
                      variant="contained"
                      fullWidth
                      onClick={onCancel}
                      disabled={
                        examination.aasm_state == "canceled" ||
                        examination.aasm_state == "declined"
                      }
                  >
                      <Close />
                      {t("components.patient.pages.examination.cancel")}
                  </Button>
                )
              :
                stripePreCheck == "succeeded"
                ? ""
                :
                  moment(examination.doctor_timetable().end_at).isBefore(moment()) || (
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={onCancel}
                        disabled={
                          examination.aasm_state == "canceled" ||
                          examination.aasm_state == "declined"
                        }
                    >
                        <Close />
                        {t("components.patient.pages.examination.cancel")}
                    </Button>
                  )
            :
              moment(examination.doctor_timetable().end_at).isBefore(moment()) || (
                <Button
                    variant="contained"
                    fullWidth
                    onClick={onCancel}
                    disabled={
                      examination.aasm_state == "canceled" ||
                      examination.aasm_state == "declined"
                    }
                >
                    <Close />
                    {t("components.patient.pages.examination.cancel")}
                </Button>
              )
          } &nbsp;

          <Button variant="contained" fullWidth href="#/telemedicine">
            <Undo />
            &nbsp;
            {t("components.patient.pages.examination.back")}
          </Button>
        </Grid>
        
        <Grid item sm={12} md={8} lg={8}>
          <Grid>
            <Typography variant="h5" gutterBottom>
              {`${moment(examination.doctor_timetable().start_at).format(
                "DD/MM/YYYY HH:mm"
              )} - ${moment(examination.doctor_timetable().end_at).format(
                "HH:mm"
              )}`}
            </Typography>

            <Typography variant="h4" gutterBottom>
              {examination.medical_service().title}
              <br />
              { 
                postProcessDoctorTeleconsultDoctorEmail
                ? ""
                :<small>{examination.medical_service().price}</small>
              }
            </Typography>

            <Divider />
            <br />
          </Grid>
          <Grid>
            <Typography variant="h5" gutterBottom>
              <strong>
                {`${examination.doctor_timetable().doctor_profile().name} ${examination.doctor_timetable().doctor_profile().surname
                  }`}
              </strong>
            </Typography>
            <List aria-label="contacts">
              {isEmpty(
                examination.doctor_timetable().doctor_profile()
                  .human_specializations
              ) ? null : (
                <ListItem>
                  <ListItemIcon>
                    <LocalHospital />
                  </ListItemIcon>
                  <ListItemText
                    primary={<span className={classes.textkarla}>{
                      examination
                      .doctor_timetable()
                      .doctor_profile()
                      .human_specializations.join(", ")}</span>}
                  />
                </ListItem>
              )}
              {isEmpty(
                examination.doctor_timetable().doctor_profile().phone
              ) ? null : (
                <ListItem>
                  <ListItemIcon>
                    <Phone />
                  </ListItemIcon>
                  <ListItemText
                    primary={<span className={classes.textkarla}>{
                      examination.doctor_timetable().doctor_profile().phone
                    }</span>}
                  />
                </ListItem>
              )}
              {isEmpty(
                examination.doctor_timetable().doctor_profile().mobile_phone
              ) ? null : (
                <ListItem>
                  <ListItemIcon>
                    <PhoneAndroid />
                  </ListItemIcon>
                  <ListItemText
                    primary={<span className={classes.textkarla}>{
                      examination.doctor_timetable().doctor_profile()
                        .mobile_phone
                    }</span>}
                  />
                </ListItem>
              )}
              {isEmpty(
                examination.doctor_timetable().doctor_profile().email_address
              ) ? null : (
                <ListItem>
                  <ListItemIcon>
                    <Email />
                  </ListItemIcon>
                  <ListItemText
                    primary={<span className={classes.textkarla}>{
                      examination.doctor_timetable().doctor_profile()
                        .email_address
                    }</span>}
                  />
                </ListItem>
              )}
            </List>
          </Grid>
          <br />
          {
            examination.aasm_state == "accepted"
            ?
              postProcessDoctorTeleconsultDoctorEmail
              ? 
                <Grid>
                  <Typography variant="h5" gutterBottom>
                    <strong>
                      {t("components.patient.pages.examination.file_uploads_title")}
                    </strong>
                  </Typography>
                  <Divider />
                  <br />
                  <Typography className={classes.textkarla} variant="body2" gutterBottom>
                    {t("components.patient.pages.examination.file_uploads_text")}
                  </Typography>
                  <br />
                  <FileUploader onFileUpload={onFileUpload} />

                  <Tabs
                    value={fileTabValue}
                    onChange={(event, newValue) => setFileTabValue(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab
                      label={`Files (${reject(documents, { mime_type: "application/dicom" }).length
                        })`}
                    />
                    <Tab
                      label={`Dicom (${filter(documents, { mime_type: "application/dicom" }).length
                        })`}
                    />
                  </Tabs>
                  <div hidden={fileTabValue !== 0}>
                    <FileList
                      files={reject(documents, { mime_type: "application/dicom" })}
                      showDelete
                      onFileDelete={onFileDelete}
                    />
                  </div>
                  <div hidden={fileTabValue !== 1}>
                    <FileList
                      files={filter(documents, { mime_type: "application/dicom" })}
                      showDelete
                      onFileDelete={onFileDelete}
                    />
                  </div>
                </Grid> 
              : 
                stripePreCheck == "succeeded" 
                ?
                  <Grid>
                    <Typography variant="h5" gutterBottom>
                      <strong>
                        {t("components.patient.pages.examination.file_uploads_title")}
                      </strong>
                    </Typography>
                    <Divider />
                    <br />
                    <Typography className={classes.textkarla} variant="body2" gutterBottom>
                      {t("components.patient.pages.examination.file_uploads_text")}
                    </Typography>
                    <br />
                    <FileUploader onFileUpload={onFileUpload} />

                    <Tabs
                      value={fileTabValue}
                      onChange={(event, newValue) => setFileTabValue(newValue)}
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab
                        label={`Files (${reject(documents, { mime_type: "application/dicom" }).length
                          })`}
                      />
                      <Tab
                        label={`Dicom (${filter(documents, { mime_type: "application/dicom" }).length
                          })`}
                      />
                    </Tabs>
                    <div hidden={fileTabValue !== 0}>
                      <FileList
                        files={reject(documents, { mime_type: "application/dicom" })}
                        showDelete
                        onFileDelete={onFileDelete}
                      />
                    </div>
                    <div hidden={fileTabValue !== 1}>
                      <FileList
                        files={filter(documents, { mime_type: "application/dicom" })}
                        showDelete
                        onFileDelete={onFileDelete}
                      />
                    </div>
                  </Grid> 
                : null 
            : null
          }
          <br />
          {
            examination.aasm_state == "accepted"
            ?
              postProcessDoctorTeleconsultDoctorEmail
              ?
                moment(examination.doctor_timetable().end_at).isBefore(moment()) || (

                  <Grid>
                    <Typography variant="h5" gutterBottom>
                      <strong>
                        {t("components.patient.pages.examination.talk")}
                      </strong>
                    </Typography>
                    <Divider />
                    <br />
                    {isEmpty(examination) || (
                      <MessageList
                        patientProfileId={examination.patient_profile().id}
                      />
                    )}
                  </Grid>
                )
              :
                stripePreCheck === "succeeded"
                ?
                  moment(examination.doctor_timetable().end_at).isBefore(moment()) || (

                    <Grid>
                      <Typography variant="h5" gutterBottom>
                        <strong>
                          {t("components.patient.pages.examination.talk")}
                        </strong>
                      </Typography>
                      <Divider />
                      <br />
                      {isEmpty(examination) || (
                        <MessageList
                          patientProfileId={examination.patient_profile().id}
                        />
                      )}
                    </Grid>
                  )
                : null
            : null
          }
        </Grid>
      </Grid>

      <ConfirmDialog
        title={t(
          "components.patient.pages.examination.cancel_confimration.title"
        )}
        open={confirmDialog}
        setOpen={setConfirmDialog}
        onConfirm={onCancelConfirmed}
      >
        {t("components.patient.pages.examination.cancel_confimration.text")}
      </ConfirmDialog>
    </>
  );
};

Examination.contextTypes = {
  t: PropTypes.func
};

export default Examination;
