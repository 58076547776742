import { cloneDeep } from "lodash";

import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR
} from "../actions/const";
import {
  GET_STATES_BY_COUNTRY,
  GET_STATES_BY_COUNTRY_SUCCESS,
  GET_STATES_BY_COUNTRY_ERROR
} from "../actions/const";
import {
  GET_CITIES_BY_STATE,
  GET_CITIES_BY_STATE_SUCCESS,
  GET_CITIES_BY_STATE_ERROR
} from "../actions/const";
import {
  GET_PROVINCES,
  GET_PROVINCES_SUCCESS,
  GET_PROVINCES_ERROR
} from "./../actions/const";

const initialState = {
  loadingCountries: false,
  loadingStates: {
    birth: false,
    residential: false
  },
  loadingCities: {
    birth: false,
    residential: false
  },
  loadingProvinces: {
    birth: false,
    residential: false
  },
  countries: null,
  states: {},
  cities: {},
  provinces: {}
};

const reducer = (state = initialState, action) => {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case GET_COUNTRIES: {
      return { ...state, countries: null, loadingCountries: true };
    }
    case GET_COUNTRIES_SUCCESS: {
      return { ...state, countries: action.countries, loadingCountries: false };
    }
    case GET_COUNTRIES_ERROR: {
      return { ...state, countries: [], loadingCountries: false };
    }
    case GET_STATES_BY_COUNTRY: {
      const { key } = action;
      nextState.states[key] = null;
      nextState.loadingStates[key] = true;
      nextState.loadingCities[key] = true;
      nextState.loadingProvinces[key] = true;
      return nextState;
    }
    case GET_STATES_BY_COUNTRY_SUCCESS: {
      const { key } = action;
      nextState.states[key] = action.states;
      nextState.loadingStates[key] = false;
      return nextState;
    }
    case GET_STATES_BY_COUNTRY_ERROR: {
      const { key } = action;
      nextState.states[key] = [];
      nextState.loadingStates[key] = false;
      return nextState;
    }
    case GET_CITIES_BY_STATE: {
      const { key } = action;
      nextState.cities[key] = null;
      nextState.loadingCities[key] = true;
      return nextState;
    }
    case GET_CITIES_BY_STATE_SUCCESS: {
      const { key } = action;
      nextState.cities[key] = action.cities;
      nextState.loadingCities[key] = false;
      return nextState;
    }
    case GET_CITIES_BY_STATE_ERROR: {
      const { key } = action;
      nextState.cities[key] = [];
      nextState.loadingCities[key] = false;
      return nextState;
    }
    case GET_PROVINCES: {
      const { key } = action;
      nextState.provinces[key] = null;
      nextState.loadingProvinces[key] = true;
      return nextState;
    }
    case GET_PROVINCES_SUCCESS: {
      const { key } = action;
      nextState.provinces[key] = action.provinces;
      nextState.loadingProvinces[key] = false;
      return nextState;
    }
    case GET_PROVINCES_ERROR: {
      const { key } = action;
      nextState.provinces[key] = [];
      nextState.loadingProvinces[key] = false;
      return nextState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
