import * as types from "./const";

export const examinationMessageIndex = examinationId => ({
  type: types.EXAMINATION_MESSAGE_INDEX,
  examinationId
});

export const examinationMessageIndexSuccess = messages => ({
  type: types.EXAMINATION_MESSAGE_INDEX_SUCCESS,
  messages
});

export const examinationMessageIndexError = errors => ({
  type: types.EXAMINATION_MESSAGE_INDEX_ERROR,
  errors
});

export const createExaminationMessage = (
  examinationId,
  message,
  successCallback,
  errorCallback
) => ({
  type: types.CREATE_EXAMINATION_MESSAGE,
  examinationId,
  message,
  successCallback,
  errorCallback
});

export const createExaminationMessageSuccess = message => ({
  type: types.CREATE_EXAMINATION_MESSAGE_SUCCESS,
  message
});

export const createExaminationMessageError = errors => ({
  type: types.CREATE_EXAMINATION_MESSAGE_ERROR,
  errors
});
