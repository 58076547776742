import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  TextField,
  Button,
  MenuItem,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { Delete, Description, CloudDownload } from "@material-ui/icons";
import { Formik } from "formik";
import { map, includes, truncate } from "lodash";

import AddDocumentDialog from "./AddDocumentDialog";
import {
  getDoctorClinics,
  editDoctorPatient,
  doctorPatientsDocumentIndex,
  deleteDoctorPatientsDocument
} from "./../../../actions";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "8px",
    height: "100%"
  },
  field: {
    padding: "8px"
  }
}));
const DoctorPatientEdit = (props, context) => {
  const { doctorPatient, doctorId } = props;
  const { t } = context;

  const actionsDispatch = useDispatch();

  const errors = useSelector(state => state.doctorPatient.errors);
  const clinics = useSelector(state => state.scheduledFile.clinics);
  const documents = useSelector(state => state.document.list);

  const [modalOpen, setModalOpen] = useState(false);

  const fetchAPI = () => {
    actionsDispatch(getDoctorClinics(doctorId));
    actionsDispatch(doctorPatientsDocumentIndex(doctorPatient.id));
  };

  const deleteDocument = documentId => {
    actionsDispatch(
      deleteDoctorPatientsDocument(doctorPatient.id, documentId, fetchAPI)
    );
  };

  useEffect(() => {
    if (modalOpen === false) {
      fetchAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item sm={8} xs={12}>
        <Paper className={classes.container}>
          <Formik
            enableReinitialize
            initialValues={doctorPatient}
            onSubmit={(values, actions) =>
              actionsDispatch(editDoctorPatient(doctorPatient.id, values))
            }
            render={({ handleSubmit, handleChange, values }) => (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      id="diagnosticIndication"
                      label={t(
                        "components.doctor.shared.doctor_patient_edit.diagnostic_indication"
                      )}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={8}
                      margin="normal"
                      className={classes.field}
                      value={values.diagnosticIndication}
                      onChange={handleChange("diagnosticIndication")}
                      error={includes(errors, "diagnostic_indication")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="therapeuticIndication"
                      label={t(
                        "components.doctor.shared.doctor_patient_edit.therapeutic_indication"
                      )}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={8}
                      margin="normal"
                      className={classes.field}
                      value={values.therapeuticIndication || ""}
                      onChange={handleChange("therapeuticIndication")}
                      error={includes(errors, "therapeutic_indication")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="urgencyMotivation"
                      label={t(
                        "components.doctor.shared.doctor_patient_edit.urgency_motivation"
                      )}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={8}
                      margin="normal"
                      className={classes.field}
                      value={values.urgencyMotivation || ""}
                      onChange={handleChange("urgencyMotivation")}
                      error={includes(errors, "urgency_motivation")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="clinicId"
                      label={t(
                        "components.doctor.shared.doctor_patient_edit.structure_data"
                      )}
                      variant="outlined"
                      fullWidth
                      select
                      margin="normal"
                      className={classes.field}
                      value={values.clinicId || ""}
                      onChange={handleChange("clinicId")}
                      error={includes(errors, "structure_data")}
                    >
                      {map(clinics, item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <br />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {t("components.doctor.shared.doctor_patient_edit.save")}
                </Button>
              </form>
            )}
          />
        </Paper>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Paper className={classes.container}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setModalOpen(true)}
          >
            {t("components.doctor.shared.doctor_patient_edit.add_file")}
          </Button>

          <List>
            {map(documents, item => (
              <ListItem key={item.id}>
                <ListItemAvatar>
                  <Avatar>
                    <Description />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={truncate(item.description, { 'length': 32, 'separator': ' ' })} secondary={item.documentType}/>
                <ListItemSecondaryAction>
                  <Tooltip
                    title={t(
                      "components.doctor.shared.doctor_patient_edit.download_file"
                    )}
                    placement="top"
                  >
                    <IconButton
                      edge="end"
                      aria-label="download"
                      href={item.url}
                      download
                      component="a"
                      target="_blank"
                    >
                      <CloudDownload />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t(
                      "components.doctor.shared.doctor_patient_edit.delete_file"
                    )}
                    placement="top"
                  >
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteDocument(item.id)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      <AddDocumentDialog
        open={modalOpen}
        closeModal={() => setModalOpen(false)}
        doctorPatientsId={doctorPatient.id}
      />
    </Grid>
  );
};

DoctorPatientEdit.contextTypes = {
  t: PropTypes.func
};

export default DoctorPatientEdit;
