import baseConfig from "./base";

const config = {
  appEnv: "dist",
  historyConfig: {
    basename: "/"
  },
  apiMock: false,
  apiBaseUrl: "https://api.sef.care/api/internal/v1",
  backofficeUrl: "https://admin.sef.care",
  supportMail: "info@sef.care",
  cookieDomain: ".sef.care"
};

export default Object.freeze(Object.assign({}, baseConfig, config));
