import { DRG_INDEX, DRG_INDEX_SUCCESS, DRG_INDEX_ERROR } from "./const";

export function DRGIndex(patientId) {
  return { type: DRG_INDEX, patientId };
}
export function DRGIndexSucces(drgs) {
  return { type: DRG_INDEX_SUCCESS, drgs };
}
export function DRGIndexError(errors) {
  return { type: DRG_INDEX_ERROR, errors };
}
