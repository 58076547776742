import { cloneDeep, reduce } from "lodash";
import moment from "moment";

import { REGISTER, REGISTER_SUCCESS, REGISTER_ERROR } from "../actions/const";
import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR
} from "../actions/const";
import {
  EDIT_PROFILE,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR
} from "../actions/const";
import { SET_MODAL_OPEN } from "../actions/const";
import {
  ASSOCIATE_DOCTOR,
  ASSOCIATE_DOCTOR_SUCCESS,
  ASSOCIATE_DOCTOR_ERROR
} from "../actions/const";
import {
  ASSOCIATED_DOCTORS,
  ASSOCIATED_DOCTORS_SUCCESS,
  ASSOCIATED_DOCTORS_ERROR
} from "../actions/const";
import { RESET_PATIENT_ERRORS } from "./../actions/const";

const initialState = {
  loading: false,
  profile: {},
  currentDoctor: {},
  associatedDoctors: [],
  associateDoctorsModalOpen: false,
  errors: []
};

const reducer = (state = initialState, action) => {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case REGISTER: {
      nextState.loading = true;
      return state;
    }
    case REGISTER_SUCCESS: {
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case REGISTER_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    case GET_PROFILE: {
      nextState.loading = true;
      return nextState;
    }
    case GET_PROFILE_SUCCESS: {
      nextState.profile = action.profile;
      nextState.currentDoctor = (
        reduce(action.profile.doctorPatients, (prev, current) =>
          moment(current.created_at).isBefore(moment(prev.created_at))
            ? current
            : prev
        ) || {}
      ).doctor;
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case GET_PROFILE_ERROR: {
      nextState.profile = {};
      nextState.loading = false;
      return nextState;
    }
    case EDIT_PROFILE: {
      return nextState;
    }
    case EDIT_PROFILE_SUCCESS: {
      nextState.profile = action.profile;
      nextState.errors = [];
      return nextState;
    }
    case EDIT_PROFILE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    case SET_MODAL_OPEN: {
      const { isOpen } = action;
      nextState.associateDoctorsModalOpen = isOpen;
      return nextState;
    }
    case ASSOCIATE_DOCTOR: {
      nextState.loading = true;
      return nextState;
    }
    case ASSOCIATE_DOCTOR_SUCCESS:
    case ASSOCIATE_DOCTOR_ERROR:
      nextState.loading = false;
      return nextState;
    case ASSOCIATED_DOCTORS: {
      nextState.loading = true;
      return nextState;
    }
    case ASSOCIATED_DOCTORS_SUCCESS: {
      const { doctors } = action;
      nextState.associatedDoctors = doctors;
      nextState.loading = false;
      return nextState;
    }
    case ASSOCIATED_DOCTORS_ERROR: {
      nextState.associatedDoctors = {};
      nextState.loading = false;
      return nextState;
    }
    case RESET_PATIENT_ERRORS: {
      nextState.errors = [];
      return nextState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
