import { head, last, nth } from 'lodash'

import ApiMock from './apiMock'
import {
	findUserByToken,
	getDoctorProfileById,
	editDoctorProfile,
	scheduledFileIndex,
	clinics,
	drgRequestsToSchedule,
	addScheduledFile,
	showScheduledFile,
	editScheduledFile,
	confirmScheduledFile,
	cancelScheduledFile,
	indexDrgRequestSchedule,
	showDrgRequestSchedule,
	addDrgRequestSchedule,
	deleteDrgRequestSchedule,
	editDrgRequestSchedule,
	getPatientProfileAssociatedToDoctor
} from './data/MocksData'

//Get Profile
ApiMock.onGet(/^.+\/doctor_profiles\/\w+.json/).reply((config) => {
	const profileId = head(last(config.url.split('/')).split('.'))

	const profile = getDoctorProfileById(profileId)

	const token = config.headers['Authorization']

	const user = findUserByToken(token)

	if (user && profile) {
		return [
			200,
			{
				data: profile
			}
		]
	} else {
		return [
			404,
			{
				errors: [
					{
						status: 404,
						title: 'Record not Found',
						detail: `Couldn't find DoctorProfile with 'id'= ${profileId}`,
						source: { pointer: '/request/url/:id' }
					}
				]
			}
		]
	}
})

//Index scheduled files
ApiMock.onGet(/^.+\/doctor_profiles\/\w+\/scheduled_files.json/).reply((config) => {
	const profileId = nth(config.url.split('/'), -2)

	const profile = getDoctorProfileById(profileId)
	if (profile) return [200, scheduledFileIndex()]
	return [
		404,
		{
			errors: [
				{
					status: 404,
					title: 'Record not Found',
					detail: `Couldn't find DoctorProfile with 'id'= ${profileId}`,
					source: { pointer: '/request/url/:id' }
				}
			]
		}
	]
})

//Add scheduled file
ApiMock.onPost(/^.+\/doctor_profiles\/\w+\/scheduled_files.json/).reply((config) => {
	const profileId = nth(config.url.split('/'), -2)

	const profile = getDoctorProfileById(profileId)

	if (profile) {
		const params = JSON.parse(config.data).scheduled_file

		const newScheduledFile = addScheduledFile(params)

		return [200, newScheduledFile]
	}
	return [
		404,
		{
			errors: [
				{
					status: 404,
					title: 'Record not Found',
					detail: `Couldn't find DoctorProfile with 'id'= ${profileId}`,
					source: { pointer: '/request/url/:id' }
				}
			]
		}
	]
})

//Edit schedule file
ApiMock.onPatch(/^.+\/scheduled_files\/\w+.json/).reply((config) => {
	const scheduledFileId = head(last(config.url.split('/')).split('.'))
	const scheduleFileFields = JSON.parse(config.data).scheduled_file
	const editedScheduledFile = editScheduledFile(scheduledFileId, scheduleFileFields)
	return [200, editedScheduledFile]
})

//Show scheduled file
ApiMock.onGet(/^.+\/scheduled_files\/\w+.json/).reply((config) => {
	const scheduledFileId = Number(head(last(config.url.split('/')).split('.')))
	const scheduledFileShow = showScheduledFile(scheduledFileId)
	return [200, scheduledFileShow]
})

//Confirm scheduled file
ApiMock.onPatch(/^.+\/scheduled_files\/\w+\/confirm/).reply((config) => {
	const scheduledFileId = nth(config.url.split('/'), -2)
	const scheduledFile = confirmScheduledFile(scheduledFileId)
	return [200, scheduledFile]
})

//Cancel scheduled file
ApiMock.onPatch(/^.+\/scheduled_files\/\w+\/cancel/).reply((config) => {
	const scheduledFileId = nth(config.url.split('/'), -2)
	const scheduledFile = cancelScheduledFile(scheduledFileId)
	return [200, scheduledFile]
})

//Get Clinics
ApiMock.onGet(/^.+\/doctor_profiles\/\w+\/clinics.json/).reply((config) => {
	const profileId = nth(config.url.split('/'), -2)

	const profile = getDoctorProfileById(profileId)

	if (profile) return [200, clinics]
	return [
		404,
		{
			errors: [
				{
					status: 404,
					title: 'Record not Found',
					detail: `Couldn't find DoctorProfile with 'id'= ${profileId}`,
					source: { pointer: '/request/url/:id' }
				}
			]
		}
	]
})

//Edit Profile
ApiMock.onPut(/^.+\/doctor_profiles\/\w+.json/).reply((config) => {
	const profile = JSON.parse(config.data).doctor_profile

	const profileId = head(last(config.url.split('/')).split('.'))

	editDoctorProfile(profileId, profile)

	return [
		200,
		{
			data: profile
		}
	]
})

//Get Drg request accepted and not scheduled
ApiMock.onGet(/^.+\/doctor_profiles\/\w+\/drg_requests.json\?\w+/).reply((config) => {
	return [200, drgRequestsToSchedule()]
})

//Index Drg request schedule
ApiMock.onGet(/^.+\/scheduled_files\/\w+\/drg_request_schedules.json/).reply((config) => {
	const scheduledFileId = Number(nth(config.url.split('/'), -2))
	const scheduledFile = indexDrgRequestSchedule(scheduledFileId)
	return [200, scheduledFile]
})

//Get Drg request schedule
ApiMock.onGet(/^.+\/drg_request_schedules\/\w+.json/).reply((config) => {
	const drgRequestScheduleId = head(last(config.url.split('/')).split('.'))
	const drgRequestSchedule = showDrgRequestSchedule(drgRequestScheduleId)
	return [200, drgRequestSchedule]
})

//Add Drg request schedule
ApiMock.onPost(/^.+\/scheduled_files\/\w+\/drg_request_schedules.json/).reply((config) => {
	const scheduledFileId = nth(config.url.split('/'), -2)

	const params = JSON.parse(config.data).drg_request_schedule

	const newDrgRequestSchedule = addDrgRequestSchedule(scheduledFileId, params)

	return [200, newDrgRequestSchedule]
})

//Edit Drg Request schedule
ApiMock.onPut(/^.+\/drg_request_schedules\/\w+.json/).reply((config) => {
	const drgRequestScheduleId = head(last(config.url.split('/')).split('.'))
	const params = JSON.parse(config.data).drg_request_schedule
	const editedDrgRequestSchedule = editDrgRequestSchedule(drgRequestScheduleId, params)
	return [200, editedDrgRequestSchedule]
})

//Delete Drg request schedule
ApiMock.onDelete(/^.+\/drg_request_schedules\/\w+.json/).reply((config) => {
	const drgRequestScheduleId = head(last(config.url.split('/')).split('.'))
	deleteDrgRequestSchedule(drgRequestScheduleId)
	return [200]
})

ApiMock.onGet(/^.+\/doctor_profiles\/\w+\/patient_profiles.json/).reply((config) => {
	const profileId = config.url.split('/')[7]

	const patientProfiles = getPatientProfileAssociatedToDoctor(profileId)

	const token = config.headers['Authorization']

	const user = findUserByToken(token)

	if (user && patientProfiles) {
		return [
			200,
			{
				data: patientProfiles
			}
		]
	} else {
		return [
			404,
			{
				errors: [
					{
						status: 404,
						title: 'Record not Found',
						detail: `Couldn't find DoctorProfile with 'id'= ${profileId}`,
						source: { pointer: '/request/url/:id' }
					}
				]
			}
		]
	}
})

ApiMock.onAny().reply((config) => console.log(config))