import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR } from "./const";
import { LOGOUT, LOGOUT_SUCCESS, LOGOUT_ERROR } from "./const";
import { USER_DATA_CHANGE } from "./const";

export function login(email, password, successCallback, errorCallback) {
  return {
    type: LOGIN,
    email,
    password,
    successCallback,
    errorCallback
  };
}
export function loginSuccess(data, token) {
  return { type: LOGIN_SUCCESS, data, token };
}
export function loginError(errors) {
  return { type: LOGIN_ERROR, errors };
}

export function logout() {
  return { type: LOGOUT };
}
export function logoutSuccess(data) {
  return { type: LOGOUT_SUCCESS, data };
}
export function logoutError(errors) {
  return { type: LOGOUT_ERROR, errors };
}

export function userDataChange(change) {
  return { type: USER_DATA_CHANGE, change };
}
