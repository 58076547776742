import axios from "./AxiosConfig";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const UserSource = {
  login: ({ email, password }) =>
    api(
      axios.post(`${apiBaseUrl}/login.json`, {
        user: { email, password }
      }),
      "login"
    ),
  logout: () => api(axios.delete(`${apiBaseUrl}/logout.json`), "logout")
};

export default UserSource;
