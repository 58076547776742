import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Grid,
  LinearProgress
} from "@material-ui/core";
import { isEmpty } from "lodash";
import moment from "moment";

import { getProfile } from "./../../actions";
import DoctorPatientEdit from "../doctor/shared/DoctorPatientEdit";

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 12,
    paddingBottom: 8
  }
}));

const PatientDetail = (props, context) => {
  const { t } = context;
  const { match } = props;

  const classes = useStyles();

  const actionsDispatch = useDispatch();
  const user = useSelector(state => state.user.current);
  const { loading, profile } = useSelector(state => state.patient);

  useEffect(() => {
    actionsDispatch(getProfile(match.params.id));
  }, [actionsDispatch, match.params.id]);
  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <Card className={classes.card} key={profile.id}>
        <CardHeader
          title={
            <Typography variant="h5">{`${profile.name ||
              profile.emailAddress} ${profile.surname || ""}`}</Typography>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t("components.doctor.pages.associated_patient_list.gender")}
              </Typography>
              <Typography variant="h6" component="h2">
                {(profile.gender && t(`locale.${profile.gender}`)) ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t(
                  "components.doctor.pages.associated_patient_list.fiscal_code"
                )}
              </Typography>
              <Typography variant="h6" component="h2">
                {profile.fiscalCode ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t(
                  "components.doctor.pages.associated_patient_list.membership_asl"
                )}
              </Typography>
              <Typography variant="h6" component="h2">
                {profile.membershipAsl ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t(
                  "components.doctor.pages.associated_patient_list.birth_data"
                )}
              </Typography>
              <Typography variant="h6" component="h2">
                <small>
                  {t(
                    "components.doctor.pages.associated_patient_list.birth_date"
                  )}
                </small>{" "}
                {(profile.birthDate &&
                  moment(profile.birthDate).format(
                    t("locale.display_date_format")
                  )) ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
              <Typography variant="h6" component="h2">
                <small>
                  {t(
                    "components.doctor.pages.associated_patient_list.birth_place"
                  )}
                </small>{" "}
                {`${profile.birthCountryName ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )} ${profile.birthStateName || ""} ${profile.birthCity ||
                  ""}`}
              </Typography>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t(
                  "components.doctor.pages.associated_patient_list.residential_place"
                )}
              </Typography>
              <Typography variant="h6" component="h2">
                {`${profile.residentialCountryName ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )} ${profile.residentialStateName ||
                  ""} ${profile.residentialCity || ""}`}
              </Typography>
              <Typography variant="body2" component="p">
                {profile.residentialAddress} {profile.residentialPostalCode}
              </Typography>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t("components.doctor.pages.associated_patient_list.contacs")}
              </Typography>
              <Typography variant="body2" component="p">
                <small>
                  {t(
                    "components.doctor.pages.associated_patient_list.email_address"
                  )}{" "}
                </small>
                {profile.emailAddress ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
              <Typography variant="body2" component="p">
                <small>
                  {t("components.doctor.pages.associated_patient_list.phone")}{" "}
                </small>
                {profile.phone ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
              <Typography variant="body2" component="p">
                <small>
                  {t(
                    "components.doctor.pages.associated_patient_list.mobile_phone"
                  )}{" "}
                </small>
                {profile.mobilePhone ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {user.doctorId && !isEmpty(profile.doctorPatients) && (
        <DoctorPatientEdit
          doctorPatient={profile.doctorPatients[0]}
          doctorId={user.doctorId}
        />
      )}
    </>
  );
};

PatientDetail.contextTypes = {
  t: PropTypes.func
};
export default PatientDetail;
