import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Grid,
  LinearProgress,
  Divider
} from "@material-ui/core";
import { Undo, LocalHospitalOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorProfile } from "../../../actions";
import { upperCase } from "lodash";
import doctorFallbackImg from "./../../../images/doctor_fallback_img.png";
import { makeStyles } from "@material-ui/core/styles";

import AskMeetDialog from "./telemedicine/AskMeetDialog";

import config from "./../../../config";

const useStyles = makeStyles(theme => ({
  profileImg: {
    display: "block",
    width: "100%",
    padding: "5%",
    border: "1px solid gray",
    borderRadius: "10%"
  },
  presentationText: {
    whiteSpace: "pre-line"
  },
  actionButton: {
    marginTop: "8px"
  }
}));

const DoctorDetail = ({ match }, context) => {
  const classes = useStyles();
  const { t } = context;
  const actionsDispatch = useDispatch();

  const [currentMedicalService, setCurrentMedicalService] = useState();

  const loading = useSelector(state => state.doctor.loading);
  const doctor_profile = useSelector(state => state.doctor.profile);
  const patientId = useSelector(state => state.user.current.patientId);

  useEffect(() => {
    actionsDispatch(getDoctorProfile(match.params.id));
  }, [actionsDispatch]);

  const handleOpenAskMeetDialog = reload => {
    // if (reload) getEvents();
    setCurrentMedicalService(null);
  };

  const [postProcessDoctorTeleconsultDoctorEmail, setPostProcessDoctorTeleconsultDoctorEmail] = useState(0);

  useEffect(() => {
    if(patientId) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/post_process_doctor_teleconsult.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ patientId: patientId })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.status1 && data.status3){
            setPostProcessDoctorTeleconsultDoctorEmail(data.status3)
          } else{
            console.log(data.status)
          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [patientId]);

  /* Sub Components */

  const medicalServices = (
    <>
      <br />
      <Typography variant="body1" gutterBottom>
        {t("components.patient.pages.doctor_detail.medical_services")}
      </Typography>
      <Divider />
      <br />
      <List>
        {(doctor_profile.medical_services || []).map(ms => (
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <LocalHospitalOutlined fontSize="large" />
            </ListItemAvatar>
            <ListItemText
              primary={
                <div>
                  <Typography variant="h6">
                    {ms.title}
                    &nbsp;
                    {
                      !!postProcessDoctorTeleconsultDoctorEmail
                      ? ''
                      : <small>{ms.price}</small>
                    }
                  </Typography>
                </div>
              }
              secondary={
                <>
                  <Typography
                    className={classes.presentationText}
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    <small>{ms.description}</small>
                  </Typography>
                  <div>
                    <Button
                      className={classes.actionButton}
                      variant="contained"
                      color="primary"
                      onClick={() => setCurrentMedicalService(ms)}
                      fullWidth
                    >
                      {t("components.patient.pages.doctor_detail.ask_meeting")}
                    </Button>
                  </div>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );

  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <Grid container spacing={4}>
        <Grid item sm={3} xs={12}>
          <img className={classes.profileImg} src={doctor_profile.avatar_url || doctorFallbackImg} />
          <br />
          {/*
            ((doctorId > 0) && (doctorId > 0)) && (user.doctorId == doctorId)
            ?
              <Button variant="contained" fullWidth href="#/doctor_telemedicine">
                <Undo />
                &nbsp;
                {t("components.patient.pages.doctor_detail.back")}
              </Button>
            :*/
              <Button variant="contained" fullWidth href="#/telemedicine">
                <Undo />
                &nbsp;
                {t("components.patient.pages.doctor_detail.back")}
              </Button>
          }
        </Grid>
        <Grid item sm={9} xs={12}>
          <Typography variant="h5" gutterBottom>
            {`${doctor_profile.name} ${doctor_profile.surname}`}
            <br />
            <small>{doctor_profile.residentialCity}</small>
          </Typography>

          <br />
          <Typography variant="body1" gutterBottom>
            {(doctor_profile.specializations || [])
              .map(s => upperCase(s))
              .join(", ")}
          </Typography>
          <Divider />
          <br />
          <Typography
            className={classes.presentationText}
            variant="body2"
            gutterBottom
          >
            {doctor_profile.presentation}
          </Typography>

          {(doctor_profile.medical_services || []).length == 0
            ? null
            : medicalServices}
        </Grid>
      </Grid>
      <AskMeetDialog
        open={!!currentMedicalService}
        handleModal={handleOpenAskMeetDialog}
        medicalService={currentMedicalService}
        doctorProfileId={doctor_profile.id}
      />
    </>
  );
};

DoctorDetail.contextTypes = {
  t: PropTypes.func
};

export default DoctorDetail;
