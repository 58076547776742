import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { Link } from "react-router-dom";
import {
  Button,
  IconButton,
  CardContent,
  Card,
  CardActions,
  CardActionArea,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  card: {
    textAlign: "center"
  },
  icon: {
    /*backgroundColor: "rgba(30, 100, 230, 1)",*/
    backgroundColor: "rgb(58, 98, 171)",
    color: "white"
  },
  actions: {
    justifyContent: "center"
  }
}));

const HomeButtonsNew = ({ buttons }, { t }) => {
  const classes = useStyles();
  return (
    map(buttons, (buttonObj, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Card className={classes.card}>
          <CardActionArea component={Link} to={buttonObj.link}>
            <CardContent>
              <IconButton className={classes.icon}>
                {buttonObj.icon}
              </IconButton>
            </CardContent>
            <CardActions className={classes.actions}>
              <Button>{t(buttonObj.label)}</Button>
            </CardActions>
          </CardActionArea>
        </Card>
      </Grid>
    ))
  );
};

HomeButtonsNew.contextTypes = {
  t: PropTypes.func
};

export default HomeButtonsNew;
