import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR
} from "./const";
import {
  GET_STATES_BY_COUNTRY,
  GET_STATES_BY_COUNTRY_SUCCESS,
  GET_STATES_BY_COUNTRY_ERROR
} from "./const";
import {
  GET_CITIES_BY_STATE,
  GET_CITIES_BY_STATE_SUCCESS,
  GET_CITIES_BY_STATE_ERROR
} from "./const";
import {
  GET_PROVINCES,
  GET_PROVINCES_SUCCESS,
  GET_PROVINCES_ERROR
} from "./const";

export function getCountries() {
  return { type: GET_COUNTRIES };
}
export function getCountriesSuccess(countries) {
  return { type: GET_COUNTRIES_SUCCESS, countries };
}
export function getCountriesError(error) {
  return { type: GET_COUNTRIES_ERROR, error };
}

export function getStatesByCountry(key, country) {
  return {
    type: GET_STATES_BY_COUNTRY,
    key,
    country
  };
}
export function getStatesByCountrySuccess(key, states) {
  return { type: GET_STATES_BY_COUNTRY_SUCCESS, key, states };
}
export function getStatesByCountryError(key, error) {
  return { type: GET_STATES_BY_COUNTRY_ERROR, key, error };
}

export function getCitiesByState(key, country, state) {
  return {
    type: GET_CITIES_BY_STATE,
    key,
    country,
    state
  };
}
export function getCitiesByStateSuccess(key, cities) {
  return { type: GET_CITIES_BY_STATE_SUCCESS, key, cities };
}
export function getCitiesByStateError(key, error) {
  return { type: GET_CITIES_BY_STATE_ERROR, key, error };
}

export function getProvinces(
  key,
  country,
  state,
  successCallback,
  errorCallback
) {
  return {
    type: GET_PROVINCES,
    key,
    country,
    state,
    successCallback,
    errorCallback
  };
}
export function getProvincesSuccess(key, provinces) {
  return { type: GET_PROVINCES_SUCCESS, key, provinces };
}
export function getProvincesError(key, error) {
  return { type: GET_PROVINCES_ERROR, key, error };
}
