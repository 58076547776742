import React from "react";
import { CardHeader, FormControl, Select, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "redux-i18n";

let itFlag = require("../../images/IT.png");
let enFlag = require("../../images/EN.png");
let logo = require("../../images/sef_logo.png");

const styles = {
  action: {
    alignSelf: "center"
  }
};

const AccessHeader = props => {
  const classes = props.classes;

  const actionsDispatch = useDispatch();

  const currentLang = useSelector(state => state.i18nState.lang);

  return (
    <CardHeader
      classes={{
        action: classes.action
      }}
      //avatar={<img src={logo} alt="" height={40} width={40} />}
      action={
        <FormControl variant="outlined">
          <Select
            value={currentLang}
            onChange={event => actionsDispatch(setLanguage(event.target.value))}
          >
            <MenuItem value="it">
              <img src={itFlag} alt="It" width={24} />
            </MenuItem>
            <MenuItem value="en">
              <img src={enFlag} alt="En" width={24} />
            </MenuItem>
          </Select>
        </FormControl>
      }
    />
  );
};

export default withStyles(styles)(AccessHeader);
