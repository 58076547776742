import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { Formik } from "formik";
import { get } from "lodash";

import ScheduledFileForm from "../../shared/ScheduledFileForm";
import {
  editScheduledFile,
  getDoctorClinics,
  cancelScheduledFile,
  getScheduledFile,
  DRGRequestIndex,
  drgRequestScheduleIndex
} from "../../../../actions";

const useStyles = makeStyles(theme => ({
  editScheduledFileTitle: {
    display: "flex"
  },
  editScheduledFileTypography: {
    flexGrow: 1
  },
  editButton: {
    marginBottom: theme.spacing(1)
  }
}));

const EditScheduledFileDialog = (props, context) => {
  const { t } = context;
  const { open, handleModal, scheduledFileId } = props;

  const classes = useStyles();

  const actionsDispatch = useDispatch();

  const doctorId = useSelector(state => state.user.current.doctorId);

  const { clinics, errors, currentScheduledFile } = useSelector(
    state => state.scheduledFile
  );

  const successCallback = () => {
    actionsDispatch(
      DRGRequestIndex(null, doctorId, {
        q: { accepted: true, not_scheduled: true }
      })
    );
    actionsDispatch(getScheduledFile(scheduledFileId));
    actionsDispatch(drgRequestScheduleIndex(scheduledFileId));
    handleModal(false);
  };

  useEffect(() => {
    actionsDispatch(getDoctorClinics(doctorId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={() => handleModal(false)}>
      <DialogTitle disableTypography className={classes.editScheduledFileTitle}>
        <Typography
          variant="h6"
          className={classes.editScheduledFileTypography}
        >
          {t("components.doctor.edit_scheduled_file_dialog.module_title")}
        </Typography>
        <Tooltip
          title={t("components.doctor.edit_scheduled_file_dialog.module_close")}
          placement="top"
        >
          <IconButton size="small" onClick={() => handleModal(false)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("components.doctor.edit_scheduled_file_dialog.module_text")}
        </DialogContentText>
        <Formik
          onSubmit={(values, actions) => {
            actionsDispatch(
              editScheduledFile(currentScheduledFile.id, values, () =>
                handleModal(false)
              )
            );
          }}
          initialValues={Object.assign(currentScheduledFile, {
            clinicId: get(currentScheduledFile, "clinic.id", "")
          })}
          render={({ handleSubmit, handleChange, values }) => (
            <form onSubmit={handleSubmit}>
              <ScheduledFileForm
                clinics={clinics}
                errors={errors}
                handleChange={handleChange}
                values={values}
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                className={classes.editButton}
              >
                {t(
                  "components.doctor.edit_scheduled_file_dialog.module_continue"
                )}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={() =>
                  // eslint-disable-next-line no-restricted-globals
                  confirm(
                    t("components.doctor.edit_scheduled_file.confirm_cancel")
                  ) &&
                  actionsDispatch(
                    cancelScheduledFile(scheduledFileId, successCallback)
                  )
                }
              >
                {t("components.doctor.edit_scheduled_file.cancel")}
              </Button>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

EditScheduledFileDialog.contextTypes = {
  t: PropTypes.func
};

export default EditScheduledFileDialog;
