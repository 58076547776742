import { map, omit } from "lodash";
import { takeEvery, call, put, all } from "redux-saga/effects";
import DrgSource from "../sources/DRGSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import { DRG_INDEX } from "../actions/const";
import { DRGIndexSucces, DRGIndexError, postError } from "../actions";

const mapDrgs = response => {
  const drgsNavigator = new ApiJsonNavigator(response.data);
  return map(drgsNavigator.data(), item => {
    return {
      ...item.attributes(),
      id: item.id,
      implants: map(item.implants(), impl => ({
        ...omit(impl.attributes(), ["min", "max"]),
        implantId: impl.id,
        placeholders: { min: impl.attributes().min, max: impl.attributes().max }
      }))
    };
  });
};

function* getDrgs({ patientId }) {
  const { response, errors } = yield call(DrgSource.index, patientId);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(DRGIndexError(errors.pointers));
    return;
  }
  const drgs = yield call(mapDrgs, response);
  yield put(DRGIndexSucces(drgs));
}

export const getDrgsFlow = takeEvery(DRG_INDEX, getDrgs);
