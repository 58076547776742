import { map, isEmpty } from "lodash";
import { takeEvery, call, put, all } from "redux-saga/effects";
import DocumentSource from "../sources/DocumentSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import {
  SCHEDULED_FILE_DOCUMENT_INDEX,
  DRG_REQUEST_SCHEDULE_DOCUMENT_INDEX,
  DOCUMENT_ACTION_CREATOR,
  DRG_REQUEST_DOCUMENT_INDEX,
  DOCTOR_PATIENTS_DOCUMENT_INDEX,
  ADD_DOCTOR_PATIENTS_DOCUMENT,
  DELETE_DOCTOR_PATIENTS_DOCUMENT,
  EXAMINATION_DOCUMENT_INDEX,
  ADD_EXAMINATION_DOCUMENT,
  DELETE_EXAMINATION_DOCUMENT
} from "../actions/const";
import {
  scheduledFileDocumentIndex,
  drgRequestScheduleDocumentIndex,
  drgRequestDocumentIndex,
  doctorPatientsDocumentIndex,
  examinationDocumentIndex,
  documentIndexSuccess,
  documentIndexError,
  addDoctorPatientsDocumentSuccess,
  addDoctorPatientsDocumentError,
  deleteDoctorPatientsDocumentError,
  deleteDoctorPatientsDocumentSuccess,
  addExaminationDocumentSuccess,
  addExaminationDocumentError,
  deleteExaminationDocumentError,
  deleteExaminationDocumentSuccess,
  postError,
  postNotice
} from "../actions";

const mapDocuments = response => {
  const documentsApiNavigator = new ApiJsonNavigator(response.data);
  return map(documentsApiNavigator.data(), item => {
    return {
      ...item.attributes(),
      id: item.id,
      documentType: isEmpty(item.document_type())
        ? null
        : item.document_type().attributes().name
    };
  });
};

function* indexScheduledFileDocument({ scheduledFileId }) {
  const { response, errors } = yield call(
    DocumentSource.scheduledFileDocumentIndex,
    scheduledFileId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(documentIndexError(errors.pointers));
    return;
  }
  const documents = yield call(mapDocuments, response);
  yield put(documentIndexSuccess(documents));
}

function* indexDrgRequestScheduleDocument({ drgRequestScheduleId }) {
  const { response, errors } = yield call(
    DocumentSource.drgRequestScheduleDocumentIndex,
    drgRequestScheduleId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(documentIndexError(errors.pointers));
    return;
  }
  const documents = yield call(mapDocuments, response);
  yield put(documentIndexSuccess(documents));
}

function* indexDrgRequestDocument({ drgRequestId }) {
  const { response, errors } = yield call(
    DocumentSource.drgRequestDocumentIndex,
    drgRequestId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(documentIndexError(errors.pointers));
    return;
  }
  const documents = yield call(mapDocuments, response);
  yield put(documentIndexSuccess(documents));
}

function* indexDoctorPatientsDocument({ doctorPatientsId }) {
  const { response, errors } = yield call(
    DocumentSource.doctorPatientsDocumentIndex,
    doctorPatientsId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(documentIndexError(errors.pointers));
    return;
  }
  const documents = yield call(mapDocuments, response);
  yield put(documentIndexSuccess(documents));
}

function* indexExaminationDocument({ examinationId }) {
  const { response, errors } = yield call(
    DocumentSource.examinationDocumentIndex,
    examinationId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(documentIndexError(errors.pointers));
    return;
  }
  const documents = yield call(mapDocuments, response);
  yield put(documentIndexSuccess(documents));
}

function* documentActionCreator({ match: { path, params } }) {
  if ((path.match(/scheduled_files/) || []).length > 0) {
    yield put(scheduledFileDocumentIndex(params.id));
  } else if ((path.match(/drg_request_schedules/) || []).length > 0) {
    yield put(drgRequestScheduleDocumentIndex(params.id));
  } else if ((path.match(/drg_requests/) || []).length > 0) {
    yield put(drgRequestDocumentIndex(params.id));
  } else if ((path.match(/doctor_patients/) || []).length > 0) {
    yield put(doctorPatientsDocumentIndex(params.id));
  } else if ((path.match(/examination/) || []).length > 0) {
    yield put(examinationDocumentIndex(params.id));
  }
}

function* addDoctorPatientsDocument({
  doctorPatientsId,
  description,
  documentType,
  document,
  successCallback
}) {
  const { errors } = yield call(
    DocumentSource.addDoctorPatientsDocument,
    doctorPatientsId,
    description,
    documentType,
    document
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(addDoctorPatientsDocumentError(errors.pointers));
    return;
  }
  yield put(postNotice("doctor_patients_document_add.success"));
  yield put(addDoctorPatientsDocumentSuccess());
  if (successCallback) {
    yield call(successCallback);
  }
}

function* deleteDoctorPatientsDocument({
  doctorPatientsId,
  documentId,
  successCallback
}) {
  const { errors } = yield call(
    DocumentSource.deleteDoctorPatientsDocument,
    doctorPatientsId,
    documentId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(deleteDoctorPatientsDocumentError(errors.pointers));
    return;
  }
  yield put(postNotice("doctor_patients_document_delete.success"));
  yield put(deleteDoctorPatientsDocumentSuccess());
  if (successCallback) yield call(successCallback);
}

function* addExaminationDocument({
  examinationId,
  doctor_profile_id,
  patient_profile_id,
  description,
  documentType,
  document,
  successCallback
}) {
  const { errors } = yield call(
    DocumentSource.addExaminationDocument,
    examinationId,
    doctor_profile_id,
    patient_profile_id,
    description,
    documentType,
    document
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(addExaminationDocumentError(errors.pointers));
    return;
  }
  yield put(postNotice("doctor_patients_document_add.success"));
  yield put(addExaminationDocumentSuccess());
  if (successCallback) {
    yield call(successCallback);
  }
}

function* deleteExaminationDocument({
  examinationId,
  documentId,
  successCallback
}) {
  const { errors } = yield call(
    DocumentSource.deleteExaminationDocument,
    examinationId,
    documentId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(deleteExaminationDocumentError(errors.pointers));
    return;
  }
  yield put(postNotice("doctor_patients_document_delete.success"));
  yield put(deleteExaminationDocumentSuccess());
  if (successCallback) yield call(successCallback);
}

export const scheduledFileDocumentIndexFlow = takeEvery(
  SCHEDULED_FILE_DOCUMENT_INDEX,
  indexScheduledFileDocument
);

export const drgRequestScheduleDocumentIndexFlow = takeEvery(
  DRG_REQUEST_SCHEDULE_DOCUMENT_INDEX,
  indexDrgRequestScheduleDocument
);

export const drgRequestDocumentIndexFlow = takeEvery(
  DRG_REQUEST_DOCUMENT_INDEX,
  indexDrgRequestDocument
);

export const doctorPatientsDocumentIndexFlow = takeEvery(
  DOCTOR_PATIENTS_DOCUMENT_INDEX,
  indexDoctorPatientsDocument
);

export const examinationDocumentIndexFlow = takeEvery(
  EXAMINATION_DOCUMENT_INDEX,
  indexExaminationDocument
);

export const documentActionCreatorFlow = takeEvery(
  DOCUMENT_ACTION_CREATOR,
  documentActionCreator
);

export const addDoctorPatientsDocumentFlow = takeEvery(
  ADD_DOCTOR_PATIENTS_DOCUMENT,
  addDoctorPatientsDocument
);

export const deleteDoctorPatientsDocumentFlow = takeEvery(
  DELETE_DOCTOR_PATIENTS_DOCUMENT,
  deleteDoctorPatientsDocument
);
export const addExaminationDocumentFlow = takeEvery(
  ADD_EXAMINATION_DOCUMENT,
  addExaminationDocument
);

export const deleteExaminationDocumentFlow = takeEvery(
  DELETE_EXAMINATION_DOCUMENT,
  deleteExaminationDocument
);
