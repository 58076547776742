import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  MenuItem,
  Grid
} from "@material-ui/core";
import { Description, CloudDownload } from "@material-ui/icons";
import { map, truncate, isEmpty, find, filter } from "lodash";
import { makeStyles } from "@material-ui/styles";

import {
  associatedDoctors,
  doctorPatientsDocumentIndex
} from "../../../actions";
import AddDocumentDialog from "./add_document/AddDocumentDialog";

const useStyles = makeStyles(theme => ({
  select: {
    width: "100%",
    minWidth: 150
  },
  button: {
    marginTop: 20,
    marginBottom: 20
  }
}));

const AddDocuments = (props, context) => {
  const classes = useStyles();
  const { t } = context;
  const actionsDispatch = useDispatch();

  const documents = useSelector(state => state.document.list);
  const currentUser = useSelector(state => state.user.current);
  const doctors = useSelector(state => state.patient.associatedDoctors);
  const doctorPatients = useSelector(
    state => state.patient.profile.doctorPatients
  );

  const [doctorId, setDoctorId] = useState(null);
  const [doctorPatient, setDoctorPatient] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    actionsDispatch(associatedDoctors(currentUser.patientId));
  }, [actionsDispatch, currentUser.patientId]);

  useEffect(() => {
    if (!modalOpen && !isEmpty(doctorPatient)) {
      actionsDispatch(doctorPatientsDocumentIndex(doctorPatient.id));
    }
  }, [actionsDispatch, doctorPatient, modalOpen]);

  const handleChangeDoctor = event => {
    const docPatient = find(
      doctorPatients,
      item => item.doctor.id === event.target.value
    );
    setDoctorPatient(docPatient);
    setDoctorId(event.target.value);
  };

  const patientDocuments = filter(documents, item => item.patient_visible);

  return (
    <Grid container xs={12} justify="center">
      <Grid item md={8} xs={12}>
        <Typography variant="h5" gutterBottom>
          {t("components.patient.pages.add_documents.title")}
        </Typography>
        <TextField
          id="doctorId"
          label={t("components.patient.pages.add_documents.doctor_label")}
          select
          value={doctorId || ""}
          onChange={handleChangeDoctor}
          margin="normal"
          className={classes.select}
        >
          {map(doctors, item => (
            <MenuItem
              key={item.id}
              value={item.id}
            >{`${item.name} ${item.surname}`}</MenuItem>
          ))}
        </TextField>
        {doctorId && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setModalOpen(true)}
            className={classes.button}
            fullWidth
          >
            {t("components.patient.pages.add_documents.add_file")}
          </Button>
        )}
        {doctorId && isEmpty(patientDocuments) ? (
          <Typography>
            {t("components.patient.pages.add_documents.no_documents")}
          </Typography>
        ) : (
          doctorId && (
            <List>
              <Typography variant="h6" gutterBottom>
                {t("components.patient.pages.add_documents.list_title")}
              </Typography>
              {map(patientDocuments, item => (
                <ListItem key={item.id}>
                  <ListItemAvatar>
                    <Avatar>
                      <Description />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={truncate(item.description, {
                      length: 32,
                      separator: " "
                    })}
                    secondary={item.documentType}
                  />
                  <ListItemSecondaryAction>
                    <Tooltip
                      title={t(
                        "components.patient.pages.add_documents.download_file"
                      )}
                      placement="top"
                    >
                      <IconButton
                        edge="end"
                        aria-label="download"
                        href={item.url}
                        download
                        component="a"
                        target="_blank"
                      >
                        <CloudDownload />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )
        )}
        <AddDocumentDialog
          open={modalOpen}
          closeModal={() => setModalOpen(false)}
          doctorPatientsId={doctorPatient.id}
        />
      </Grid>
    </Grid>
  );
};

AddDocuments.contextTypes = {
  t: PropTypes.func
};

export default AddDocuments;
