import axios from "./AxiosConfig";
import config from "../config";

import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const DocumentSource = {
  scheduledFileDocumentIndex: scheduledFileId =>
    api(
      axios.get(
        `${apiBaseUrl}/scheduled_files/${scheduledFileId}/documents.json`
      ),
      "scheduled_file_document_index"
    ),
  drgRequestScheduleDocumentIndex: drgRequestScheduleId =>
    api(
      axios.get(
        `${apiBaseUrl}/drg_request_schedules/${drgRequestScheduleId}/documents.json`
      ),
      "drg_request_schedule_document_index"
    ),
  drgRequestDocumentIndex: drgRequestId =>
    api(
      axios.get(`${apiBaseUrl}/drg_requests/${drgRequestId}/documents.json`),
      "drg_request_document_index"
    ),
  doctorPatientsDocumentIndex: doctorPatientsid =>
    api(
      axios.get(
        `${apiBaseUrl}/doctor_patients/${doctorPatientsid}/documents.json`,
        "doctor_patients_document_index"
      )
    ),
  examinationDocumentIndex: examinationId =>
    api(
      axios.get(
        `${apiBaseUrl}/examinations/${examinationId}/documents.json`,
        "examination_document_index"
      )
    ),
  addDoctorPatientsDocument: (
    doctorPatientsId,
    description,
    documentType,
    document
  ) => {
    const formData = new FormData();
    formData.append(
      "document",
      JSON.stringify({ description, document_type_id: documentType })
    );
    formData.append("doc_file", document);

    const config = {
      headers: { "content-type": "multipart/form-data" }
    };
    return api(
      axios.post(
        `${apiBaseUrl}/doctor_patients/${doctorPatientsId}/documents.json`,
        formData,
        config
      ),
      "doctor_patients_document_add"
    );
  },
  deleteDoctorPatientsDocument: (doctorPatientsId, documentId) =>
    api(
      axios.delete(
        `${apiBaseUrl}/doctor_patients/${doctorPatientsId}/documents/${documentId}.json`
      ),
      "doctor_patients_document_delete"
    ),
  addExaminationDocument: (
    examinationId,
    doctor_profile_id,
    patient_profile_id,
    description,
    documentType,
    document
  ) => {
    const formData = new FormData();
    formData.append(
      "document",
      JSON.stringify({ doctor_profile_id, patient_profile_id, description, document_type_id: documentType })
    );
    formData.append("doc_file", document);

    const config = {
      headers: { "content-type": "multipart/form-data" }
    };
    return api(
      axios.post(
        `${apiBaseUrl}/examinations/${examinationId}/documents.json`,
        formData,
        config
      ),
      "examination_document_add"
    );
  },
  deleteExaminationDocument: (examinationId, documentId) =>
    api(
      axios.delete(
        `${apiBaseUrl}/examinations/${examinationId}/documents/${documentId}.json`
      ),
      "examination_document_delete"
    )
};

export default DocumentSource;
