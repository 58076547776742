import axios from "./AxiosConfig";
import qs from "qs";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const DRGSource = {
  add: (patientId, description, doctorId, drgId, file) => {
    const formData = new FormData();

    const drgRequest = {
      drg_id: drgId,
      doctor_profile_id: doctorId,
      message: description
    };

    formData.append("drg_request", JSON.stringify(drgRequest));
    formData.append("document", file);

    const config = {
      headers: { "content-type": "multipart/form-data" }
    };

    return api(
      axios.post(
        `${apiBaseUrl}/patient_profiles/${patientId}/drg_requests.json`,
        formData,
        config
      ),
      "drg_request_new"
    );
  },
  index: (patientId, doctorId, query) => {
    const payloadString = qs.stringify(query);

    let requestUrl = `${apiBaseUrl}/patient_profiles/${patientId}/drg_requests.json?${payloadString}`;
    if (doctorId)
      requestUrl = `${apiBaseUrl}/doctor_profiles/${doctorId}/drg_requests.json?${payloadString}`;

    return api(axios.get(requestUrl), "drg_request_index");
  },
  show: drgRequestId =>
    api(
      axios.get(`${apiBaseUrl}/drg_requests/${drgRequestId}.json`),
      "drg_request_show"
    ),
  crossBorderHealthcareAuthorizations: patientId =>
    api(
      axios.get(
        `${apiBaseUrl}/patient_profiles/${patientId}/cross_border_healthcare_authorizations.json`
      ),
      "cbha"
    )
};

export default DRGSource;
