import { takeEvery, call, put, all } from "redux-saga/effects";

import MessageSource from "../sources/MessageSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import * as types from "../actions/const";
import {
  examinationMessageIndexSuccess,
  examinationMessageIndexError,
  createExaminationMessageSuccess,
  createExaminationMessageError,
  postError,
  postNotice
} from "../actions";

/* INDEX */
function* examinationMessageIndex({ examinationId }) {
  const { response, errors } = yield call(MessageSource.index, examinationId);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(examinationMessageIndexError(errors.pointers));
    return;
  }
  const responseNavigator = new ApiJsonNavigator(response.data);
  yield put(examinationMessageIndexSuccess(responseNavigator));
}

export const examinationMessageIndexFlow = takeEvery(
  types.EXAMINATION_MESSAGE_INDEX,
  examinationMessageIndex
);

/* CREATE */
function* createExaminationMessage({
  examinationId,
  message,
  successCallback,
  errorCallback
}) {
  const { text, doctorProfileId, patientProfileId } = message;

  const { response, errors } = yield call(MessageSource.create, examinationId, {
    message: {
      text,
      doctor_profile_id: doctorProfileId,
      patient_profile_id: patientProfileId
    }
  });
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(createExaminationMessageError(errors.pointers));
    if (errorCallback) {
      yield call(errorCallback, errors);
    }
    return;
  }
  const responseNavigator = new ApiJsonNavigator(response.data);
  yield put(postNotice("create_examination_message.success"));
  yield put(createExaminationMessageSuccess(responseNavigator));
  if (successCallback) {
    yield call(successCallback, responseNavigator);
  }
}

export const createExaminationMessageFlow = takeEvery(
  types.CREATE_EXAMINATION_MESSAGE,
  createExaminationMessage
);
