import { takeEvery, call, put, all } from "redux-saga/effects";
import { map } from "lodash";
import moment from "moment";

import DrgRequestScheduleSource from "../sources/DrgRequestScheduleSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import {
  DRG_REQUEST_SCHEDULE_INDEX,
  GET_DRG_REQUEST_SCHEDULE,
  ADD_DRG_REQUEST_SCHEDULE,
  EDIT_DRG_REQUEST_SCHEDULE,
  DELETE_DRG_REQUEST_SCHEDULE
} from "../actions/const";
import {
  drgRequestScheduleIndexSuccess,
  drgRequestScheduleIndexError,
  getDrgRequestScheduleSuccess,
  getDrgRequestScheduleError,
  addDrgRequestScheduleSuccess,
  addDrgRequestScheduleError,
  editDrgRequestScheduleSuccess,
  editDrgRequestScheduleError,
  deleteDrgRequestScheduleSuccess,
  deleteDrgRequestScheduleError,
  postError,
  postNotice
} from "../actions";

const mapIndex = response => {
  const DrgRequestScheduleNavigator = new ApiJsonNavigator(response.data);
  return map(DrgRequestScheduleNavigator.data(), item => {
    const { name, surname } = item
      .drg_request()
      .patient_profile()
      .attributes();
    const { id, start_time, duration } = item.attributes();
    const start = moment(start_time);
    const end = start.clone().add(duration, "minutes");
    return {
      id,
      title: `${name} ${surname}: ${
        item
          .drg_request()
          .drg()
          .attributes().title
      }`,
      start: start.toDate(),
      end: end.toDate()
    };
  });
};

const mapGet = response => {
  const drgRequestScheduleNavigator = new ApiJsonNavigator(response.data);
  const {
    start_time,
    duration,
    hospitalization_days,
    notes,
    id
  } = drgRequestScheduleNavigator.data().attributes();

  return {
    startTime: moment(start_time),
    duration,
    hospitalizationDays: hospitalization_days,
    notes,
    implants: map(
      drgRequestScheduleNavigator.data().drg_request_schedule_implants(),
      drsi => {
        const implant = drsi.implant();
        return {
          ...implant.attributes(),
          ...drsi.attributes(),
          placeholders: {
            min: implant.attributes().min,
            max: implant.attributes().max
          },
          id: drsi.id,
          implantId: implant.id
        };
      }
    ),
    drgRequestId: drgRequestScheduleNavigator
      .data()
      .drg_request()
      .attributes().id,
    drgId: drgRequestScheduleNavigator
      .data()
      .drg_request()
      .drg()
      .attributes().id,
    patient: drgRequestScheduleNavigator
      .data()
      .drg_request()
      .patient_profile()
      .attributes(),
    id
  };
};

function* drgRequestScheduleIndex({ scheduleFileId }) {
  const { response, errors } = yield call(
    DrgRequestScheduleSource.index,
    scheduleFileId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(drgRequestScheduleIndexError(errors.pointers));
    return;
  }
  const drgRequestSchedules = yield call(mapIndex, response);
  yield put(drgRequestScheduleIndexSuccess(drgRequestSchedules));
}

function* getDrgRequestSchedule({ id }) {
  const { response, errors } = yield call(DrgRequestScheduleSource.get, id);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getDrgRequestScheduleError(errors.pointers));
    return;
  }
  const currentRequest = yield call(mapGet, response);
  yield put(getDrgRequestScheduleSuccess(currentRequest));
}

function* addDrgRequestSchedule({
  scheduleFileId,
  drgRequestSchedule,
  successCallback
}) {
  const {
    startTime,
    duration,
    hospitalizationDays,
    notes,
    drgRequestId,
    drgId,
    implants
  } = drgRequestSchedule;
  const newDrgRequestSchedule = {
    drg_request_schedule: {
      start_time: startTime,
      duration,
      hospitalization_days: hospitalizationDays,
      notes,
      drg_request_id: String(drgRequestId),
      drg_request_attributes: {
        drg_id: drgId,
        id: String(drgRequestId)
      },
      drg_request_schedule_implants_attributes: map(implants, item => {
        return { implant_id: item.implantId, min: item.min, max: item.max };
      })
    }
  };
  // eslint-disable-next-line no-unused-vars
  const { response, errors } = yield call(
    DrgRequestScheduleSource.add,
    scheduleFileId,
    newDrgRequestSchedule
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(addDrgRequestScheduleError(errors.pointers));
    return;
  }
  yield put(postNotice("drg_request_schedule_add.success"));
  yield put(addDrgRequestScheduleSuccess());
  yield call(successCallback);
}

function* editDrgRequestSchedule({ id, drgRequestSchedule, successCallback }) {
  const {
    startTime,
    duration,
    hospitalizationDays,
    notes,
    drgId,
    drgRequestId,
    implants
  } = drgRequestSchedule;
  const editedDrgRequestSchedule = {
    drg_request_schedule: {
      start_time: startTime,
      duration,
      hospitalization_days: hospitalizationDays,
      notes,
      drg_request_attributes: {
        id: String(drgRequestId),
        drg_id: drgId
      },
      drg_request_schedule_implants_attributes: map(implants, imp => ({
        id: imp.id,
        implant_id: imp.implantId,
        min: imp.min,
        max: imp.max
      }))
    }
  };
  // eslint-disable-next-line no-unused-vars
  const { response, errors } = yield call(
    DrgRequestScheduleSource.edit,
    id,
    editedDrgRequestSchedule
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(editDrgRequestScheduleError(errors.pointers));
    return;
  }
  yield put(postNotice("drg_request_schedule_edit.success"));
  yield put(editDrgRequestScheduleSuccess());
  yield call(successCallback);
}

function* deleteDrgRequestSchedule({ id, successCallback }) {
  // eslint-disable-next-line no-unused-vars
  const { response, errors } = yield call(DrgRequestScheduleSource.delete, id);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(deleteDrgRequestScheduleError(errors.pointers));
    return;
  }
  yield put(postNotice("drg_request_schedule_delete.success"));
  yield put(deleteDrgRequestScheduleSuccess());
  if (successCallback) yield call(successCallback);
}

export const drgRequestScheduleIndexFlow = takeEvery(
  DRG_REQUEST_SCHEDULE_INDEX,
  drgRequestScheduleIndex
);

export const getDrgRequestScheduleFlow = takeEvery(
  GET_DRG_REQUEST_SCHEDULE,
  getDrgRequestSchedule
);

export const addDrgRequestScheduleFlow = takeEvery(
  ADD_DRG_REQUEST_SCHEDULE,
  addDrgRequestSchedule
);

export const editDrgRequestScheduleFlow = takeEvery(
  EDIT_DRG_REQUEST_SCHEDULE,
  editDrgRequestSchedule
);

export const deleteDrgRequestScheduleFlow = takeEvery(
  DELETE_DRG_REQUEST_SCHEDULE,
  deleteDrgRequestSchedule
);
