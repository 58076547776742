import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { associateDoctor } from "../../../../actions";

const useStyles = makeStyles(theme => ({
  addDoctorModalTitleContainer: {
    display: "flex",
    alignItems: "center"
  },
  addDoctorModalTitle: {
    flexGrow: 1
  }
}));

const AssociateDoctorDialogBody = (props, context) => {
  const classes = useStyles();

  const { t } = context;
  const { closeModal } = props;
  const loading = useSelector(state => state.patient.loading);
  const user = useSelector(state => state.user.current);

  const actionsDispatch = useDispatch();

  const [doctorRef, setDoctorRef] = useState("");

  const handleAssociateDoctor = event => {
    event.preventDefault();
    actionsDispatch(associateDoctor(user.patientId, doctorRef));
    setDoctorRef("");
    closeModal();
  };

  return (
    <form onSubmit={handleAssociateDoctor}>
      <DialogTitle
        disableTypography
        className={classes.addDoctorModalTitleContainer}
      >
        <Typography variant="h6" className={classes.addDoctorModalTitle}>
          {t("components.pages.patient_dashboard.modal_title")}
        </Typography>
        <Tooltip
          title={t("components.pages.patient_dashboard.modal_close")}
          placement="top"
        >
          <IconButton size="small" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("components.pages.patient_dashboard.modal_text")}
        </DialogContentText>
        <TextField
          fullWidth
          label={t("components.pages.patient_dashboard.modal_label")}
          name="doctorRef"
          value={doctorRef}
          onChange={event => setDoctorRef(event.target.value)}
          required
        />
      </DialogContent>
      <br />
      <DialogActions>
        <Button
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {t("components.pages.patient_dashboard.modal_confirm")}
        </Button>
      </DialogActions>
    </form>
  );
};

AssociateDoctorDialogBody.contextTypes = {
  t: PropTypes.func
};
export default AssociateDoctorDialogBody;
