import { mapKeys, result, camelCase, snakeCase } from "lodash";
import { takeEvery, call, put, all } from "redux-saga/effects";
import DoctorPatientSource from "../sources/DoctorPatientSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import { GET_DOCTOR_PATIENT, EDIT_DOCTOR_PATIENT } from "../actions/const";
import {
  getDoctorPatientSuccess,
  getDoctorPatientError,
  editDoctorPatientSuccess,
  editDoctorPatientError,
  postError,
  postNotice
} from "../actions";

const mapDoctorPatient = response => {
  const doctorPatientNavigator = new ApiJsonNavigator(response.data);
  return {
    ...mapKeys(result(doctorPatientNavigator, "data.attributes"), (val, key) =>
      camelCase(key)
    ),
    clinic: mapKeys(
      result(doctorPatientNavigator, "data.clinic.attributes"),
      (val, key) => camelCase(key)
    ),
    doctor: mapKeys(
      result(doctorPatientNavigator, "data.doctor_profile.attributes"),
      (val, key) => camelCase(key)
    )
  };
};

function* getDoctorPatient({ id }) {
  const { response, errors } = yield call(DoctorPatientSource.get, id);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getDoctorPatientError(errors.pointers));
    return;
  }
  const doctorPatient = yield call(mapDoctorPatient, response);
  yield put(getDoctorPatientSuccess(doctorPatient));
}

function* editDoctorPatient({ id, doctorPatient }) {
  const doctorPatientData = mapKeys(doctorPatient, (val, key) =>
    snakeCase(key)
  );
  // eslint-disable-next-line no-unused-vars
  const { response, errors } = yield call(
    DoctorPatientSource.edit,
    id,
    doctorPatientData
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(editDoctorPatientError(errors.pointers));
    return;
  }
  yield put(postNotice("edit_doctor_patient.success"));
  yield put(editDoctorPatientSuccess());
}

export const getDoctorPatientFlow = takeEvery(
  GET_DOCTOR_PATIENT,
  getDoctorPatient
);

export const editDoctorPatientFlow = takeEvery(
  EDIT_DOCTOR_PATIENT,
  editDoctorPatient
);
