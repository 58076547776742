import { map, mapKeys, result, camelCase, intersectionBy } from "lodash";
import { takeEvery, call, put, all } from "redux-saga/effects";
import DrgRequestSource from "../sources/DrgRequestSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import {
  DRG_REQUEST_INDEX,
  DRG_REQUEST_SHOW,
  ADD_DRG_REQUEST,
  CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS
} from "../actions/const";
import {
  DRGRequestIndexSuccess,
  DRGRequestIndexError,
  DRGRequestShowSuccess,
  DRGRequestShowError,
  addDRGRequestSuccess,
  addDRGRequestError,
  crossBorderHealthcareAuthorizationSuccess,
  crossBorderHealthcareAuthorizationError,
  postError,
  postNotice
} from "../actions";

const mapIndex = response => {
  const requestsNavigator = new ApiJsonNavigator(response.data);
  return map(requestsNavigator.data(), item => ({
    ...item.attributes(),
    patientProfile: mapKeys(
      result(item, "patient_profile.attributes"),
      (val, key) => camelCase(key)
    ),
    doctorProfile: mapKeys(
      result(item, "doctor_profile.attributes"),
      (val, key) => camelCase(key)
    ),
    drg: mapKeys(result(item, "drg.attributes"), (val, key) => camelCase(key)),
    drgRequestSchedule: mapKeys(
      result(item, "drg_request_schedule.attributes"),
      (val, key) => camelCase(key)
    ),
    doctorPatientId: result(
      intersectionBy(
        result(item, "doctor_profile.doctor_patients"),
        result(item, "patient_profile.doctor_patients"),
        "id"
      ),
      "[0].id"
    )
  }));
};

const mapDrgRequest = response => {
  const drgRequestNavigator = new ApiJsonNavigator(response.data);
  return {
    data: drgRequestNavigator.data().attributes(),
    doctor: drgRequestNavigator
      .data()
      .doctor_profile()
      .attributes(),
    drg: drgRequestNavigator
      .data()
      .drg()
      .attributes(),
    patient: drgRequestNavigator
      .data()
      .patient_profile()
      .attributes()
  };
};

const mapCBHA = response => {
  const cbhaNavigator = new ApiJsonNavigator(response.data);
  return map(cbhaNavigator.data(), item => item.attributes());
};

function* DRGRequestIndex({ patientId, doctorId, query }) {
  const { response, errors } = yield call(
    DrgRequestSource.index,
    patientId,
    doctorId,
    query
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(DRGRequestIndexError(errors.pointers));
    return;
  }
  const drgRequests = yield call(mapIndex, response);
  yield put(DRGRequestIndexSuccess(drgRequests));
}

function* showDrgRequest({ drgRequestId }) {
  const { response, errors } = yield call(DrgRequestSource.show, drgRequestId);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(DRGRequestShowError(errors.pointers));
    return;
  }
  const drgRequest = yield call(mapDrgRequest, response);
  yield put(DRGRequestShowSuccess(drgRequest));
}

function* addDRGRequest({
  patientId,
  description,
  doctorId,
  drgId,
  file,
  successCallback
}) {
  // eslint-disable-next-line no-unused-vars
  const { response, errors } = yield call(
    DrgRequestSource.add,
    patientId,
    description,
    doctorId,
    drgId,
    file
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(addDRGRequestError(errors.pointers));
    return;
  }
  yield put(postNotice("drg_request_new.success"));
  yield put(addDRGRequestSuccess());
  if (successCallback) {
    yield call(successCallback);
  }
}

function* getCrossBorderHealthcareAuthorizations({ patientId }) {
  const { response, errors } = yield call(
    DrgRequestSource.crossBorderHealthcareAuthorizations,
    patientId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(crossBorderHealthcareAuthorizationError(errors.pointers));
    return;
  }
  const modules = yield call(mapCBHA, response);
  yield put(crossBorderHealthcareAuthorizationSuccess(modules));
}

export const DRGRequestIndexFlow = takeEvery(
  DRG_REQUEST_INDEX,
  DRGRequestIndex
);

export const showDrgRequestFlow = takeEvery(DRG_REQUEST_SHOW, showDrgRequest);

export const addDRGRequestFlow = takeEvery(ADD_DRG_REQUEST, addDRGRequest);

export const getCrossBorderHealthcareAuthorizationsFlow = takeEvery(
  CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS,
  getCrossBorderHealthcareAuthorizations
);
