import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import {
  TextField,
  Button,
  FormGroup,
  Typography,
  MenuItem,
  Grid,
  Paper,
  LinearProgress,
  FormLabel
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { makeStyles } from "@material-ui/styles";
import { map, includes, isEmpty } from "lodash";
import { Formik } from "formik";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import { DRGIndex, addDRGRequest, associatedDoctors } from "../../../actions";
import config from "./../../../config";

const useStyles = makeStyles(theme => ({
  textField: {
    textAlign: "left"
  },
  input: {
    textOverflow: "ellipsis"
  },
  alert: {
    backgroundColor: red["600"],
    color: "white",
    marginBottom: 30,
    padding: 5
  },
  dropzone: {
    paddingTop: 10,
    paddingRight: 10,
    textAlign: "center",
    marginBottom: 20
  },
  label: {
    marginTop: 20
  }
}));

const AddDRGRequest = (props, context) => {
  const { t } = context;

  const classes = useStyles();

  const user = useSelector(state => state.user.current);
  const doctors = useSelector(state => state.patient.associatedDoctors);
  const drgs = useSelector(state => state.drg.list);
  const loadingRequest = useSelector(state => state.drgRequest.loading);
  const errors = useSelector(state => state.drg.errors);
  const loadingDoctors = useSelector(state => state.patient.loading);
  const loadingDRG = useSelector(state => state.drg.loading);

  const actionsDispatch = useDispatch();

  useEffect(() => {
    actionsDispatch(DRGIndex(user.patientId));
    actionsDispatch(associatedDoctors(user.patientId));
  }, [actionsDispatch, user.patientId]);

  const handleAddDRGRequest = values => {
    actionsDispatch(
      addDRGRequest(user.patientId, ...values, () =>
        actionsDispatch(push("/dashboard"))
      )
    );
  };

  const handleDocumentUpload = (handleChange, file) => value => {
    if (value.meta.status === "done") {
      file = value.file;
    } else if (value.meta.status === "removed") {
      file = {};
    }
    handleChange({ target: { name: "file", value: file } });
  };

  return loadingDRG || loadingDoctors ? (
    <LinearProgress />
  ) : (
    <Grid container>
      <Grid item md={2} xs />
      <Grid item md xs={12}>
        <Typography variant="h5" gutterBottom>
          {t("components.pages.add_drg_request.title")}
        </Typography>
        {isEmpty(drgs) && (
          <Paper className={classes.alert}>
            <Typography>
              {t("components.pages.add_drg_request.no_drg_alert")}&nbsp;
              {t("message.contact_support")}&nbsp;
              {config.supportMail}
            </Typography>
          </Paper>
        )}
        <Formik
          onSubmit={handleAddDRGRequest}
          initialValues={{ patientDocuments: [] }}
        >
          {({ handleSubmit, handleChange, values }) => (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <TextField
                  id="description"
                  label={t("components.pages.add_drg_request.description")}
                  placeholder={t(
                    "components.pages.add_drg_request.description_placeholder"
                  )}
                  className={classes.textField}
                  value={values.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  disabled={isEmpty(drgs)}
                  variant="outlined"
                  error={includes(errors, "description")}
                  margin="normal"
                />
                <TextField
                  name="doctor"
                  label={t("components.pages.add_drg_request.doctor")}
                  select
                  required
                  className={classes.textField}
                  inputProps={{
                    className: classes.input
                  }}
                  value={values.doctor}
                  disabled={isEmpty(drgs)}
                  onChange={handleChange}
                  error={includes(errors, "doctor_profile")}
                  margin="normal"
                >
                  {map(doctors, (item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {`${item.name} ${item.surname}`}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="drgId"
                  label={t("components.pages.add_drg_request.selected_drg")}
                  select
                  required
                  className={classes.textField}
                  inputProps={{
                    className: classes.input
                  }}
                  value={values.drgId}
                  disabled={isEmpty(drgs)}
                  onChange={handleChange}
                  error={includes(errors, "drg")}
                  margin="normal"
                >
                  {map(drgs, (item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
                <FormLabel htmlFor="modules" className={classes.label}>
                  <Typography>
                    {t("components.pages.add_drg_request.modules")}
                  </Typography>
                </FormLabel>
                <div className={classes.dropzone} id="modules">
                  <Dropzone
                    accept=".pdf"
                    maxFiles={1}
                    inputContent={t("message.dropzone.text")}
                    styles={{ dropzone: { overflow: "unset" } }}
                    onChangeStatus={handleDocumentUpload(
                      handleChange,
                      values.document
                    )}
                  />
                </div>
              </FormGroup>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={loadingRequest || isEmpty(drgs)}
              >
                {t("components.pages.add_drg_request.add")}
              </Button>
            </form>
          )}
        </Formik>
      </Grid>
      <Grid item md={2} xs />
    </Grid>
  );
};

AddDRGRequest.contextTypes = {
  t: PropTypes.func
};

export default AddDRGRequest;
