import React from "react";
import PropTypes from "prop-types";
import Date from "./Date";

const DateRange = ({ from, to, separator, ...rest }) => (
  <div style={{ display: "flex" }}>
    <Date date={from} />
    &nbsp;{separator}&nbsp;
    <Date date={to} />
  </div>
);

DateRange.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

DateRange.defaultProps = {
  separator: "-"
};

export default DateRange;
