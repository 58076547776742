import { cloneDeep } from 'lodash';

import {
  POST_ERROR,
  POST_NOTICE,
  CONSUME_ERRORS,
  CONSUME_NOTICES,
} from '../actions/const';

const initialState = {
  errors: [],
  notices: [],
};

function reducer(state = initialState, action) {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case POST_ERROR: {
      nextState.errors.push(action.message);
      return nextState;
    }
    case POST_NOTICE: {
      nextState.notices.push(action.message);
      return nextState;
    }
    case CONSUME_ERRORS: {
      nextState.errors.length = 0;
      return nextState;
    }
    case CONSUME_NOTICES: {
      nextState.notices.length = 0;
      return nextState;
    }
    default: {
      return state;
    }
  }
}

export default reducer;
