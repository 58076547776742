import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Grid,
  Button,
  LinearProgress,
  Tooltip
} from "@material-ui/core";
import { RemoveRedEye as EyeIcon } from "@material-ui/icons";
import { map } from "lodash";
import moment from "moment";

import { associatedPatients } from "./../../../actions";

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 12,
    paddingBottom: 8
  }
}));

const EditProfile = (props, context) => {
  const { t } = context;

  const classes = useStyles();

  const actionsDispatch = useDispatch();
  const doctorId = useSelector(state => state.user.current.doctorId);
  const { loading, associatedPatientList } = useSelector(state => state.doctor);

  useEffect(() => {
    actionsDispatch(associatedPatients(doctorId));
  }, [actionsDispatch, doctorId]);
  return loading ? (
    <LinearProgress />
  ) : (
    map(associatedPatientList, item => (
      <Card className={classes.card} key={item.id}>
        <CardHeader
          title={
            <Typography variant="h5">{`${item.name || item.email_address} ${item.surname || ''}`}</Typography>
          }
          action={
            <Tooltip
              title={t(
                "components.doctor.pages.associated_patient_list.view_detail_tooltip"
              )}
              placement="top"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => actionsDispatch(push(`/patients/${item.id}`))}
              >
                <EyeIcon />
              </Button>
            </Tooltip>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t("components.doctor.pages.associated_patient_list.gender") }
              </Typography>
              <Typography variant="h6" component="h2">
                {(item.gender && t(`locale.${item.gender}`)) ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t(
                  "components.doctor.pages.associated_patient_list.fiscal_code"
                )}
              </Typography>
              <Typography variant="h6" component="h2">
                {item.fiscal_code ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t(
                  "components.doctor.pages.associated_patient_list.membership_asl"
                )}
              </Typography>
              <Typography variant="h6" component="h2">
                {item.membership_asl ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t(
                  "components.doctor.pages.associated_patient_list.birth_data"
                )}
              </Typography>
              <Typography variant="h6" component="h2">
                <small>
                  {t(
                    "components.doctor.pages.associated_patient_list.birth_date"
                  )}
                </small>{" "}
                {(item.birth_date &&
                  moment(item.birth_date).format(
                    t("locale.display_date_format")
                  )) ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
              <Typography variant="h6" component="h2">
                <small>
                  {t(
                    "components.doctor.pages.associated_patient_list.birth_place"
                  )}
                </small>{" "}
                {`${item.birth_country_name ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )} ${item.birth_state_name || ""} ${item.birth_city || ""}`}
              </Typography>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t(
                  "components.doctor.pages.associated_patient_list.residential_place"
                )}
              </Typography>
              <Typography variant="h6" component="h2">
                {`${item.residential_country_name ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )} ${item.residential_state_name ||
                  ""} ${item.residential_city || ""}`}
              </Typography>
              <Typography variant="body2" component="p">
                {item.residential_address}
              </Typography>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {t("components.doctor.pages.associated_patient_list.contacs")}
              </Typography>
              <Typography variant="body2" component="p">
                <small>
                  {t(
                    "components.doctor.pages.associated_patient_list.email_address"
                  )}{" "}
                </small>
                {item.email_address ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
              <Typography variant="body2" component="p">
                <small>
                  {t("components.doctor.pages.associated_patient_list.phone")}{" "}
                </small>
                {item.phone ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
              <Typography variant="body2" component="p">
                <small>
                  {t(
                    "components.doctor.pages.associated_patient_list.mobile_phone"
                  )}{" "}
                </small>
                {item.mobile_phone ||
                  t(
                    "components.doctor.pages.associated_patient_list.missing_data"
                  )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
};

EditProfile.contextTypes = {
  t: PropTypes.func
};
export default EditProfile;
