import React from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";

const Date = ({ date, ...rest }) => (
  <Typography {...rest}>
    {date !== "-" ? moment(date).format("DD/MM/YYYY") : "-"}
  </Typography>
);

export default Date;
