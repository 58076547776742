import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormGroup, TextField, MenuItem } from "@material-ui/core";
import { includes, map, find } from "lodash";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import "moment/locale/it";
import "moment/locale/en-gb";
import ImplantTable from "./ImplantTable";

const renderItem = item => (
  <MenuItem key={item.id} value={item.id}>
    {item.title}
  </MenuItem>
);

const DrgRequestScheduleForm = (
  {
    currentScheduledFile,
    values,
    handleChange,
    errors,
    currentLang,
    drgs,
    onLoad,
    editMode
  },
  { t }
) => {
  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrgChange = event => {
    handleChange(event);
    handleChange({
      target: {
        name: "implants",
        value: (find(drgs, { id: event.target.value }) || {}).implants
      }
    });
  };

  return (
    <FormGroup>
      <TextField
        name="drgId"
        label={t("components.doctor.drg_request_schedule_form.drg")}
        select
        value={values.drgId || ""}
        onChange={handleDrgChange}
        disabled={editMode}
        required
        error={includes(errors, "drgId")}
      >
        {map(drgs, renderItem)}
      </TextField>
      <br />
      <TextField
        name="hospitalizationDays"
        label={t(
          "components.doctor.drg_request_schedule_form.hospitalization_days"
        )}
        type="number"
        inputProps={{
          min: 1,
          step: 1
        }}
        value={values.hospitalizationDays || ""}
        onChange={handleChange}
        required
        error={includes(errors, "hospitalization_days")}
      />
      <br />
      <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLang}>
        <KeyboardDateTimePicker
          required
          name="startTime"
          clearable
          disablePast={moment().isBefore(currentScheduledFile.to)}
          minDate={currentScheduledFile.from}
          maxDate={currentScheduledFile.to}
          cancelLabel={t("components.shared.edit_profile.cancel")}
          clearLabel={t("components.shared.edit_profile.clear")}
          openTo="date"
          ampm={false}
          format={t("locale.display_datetime")}
          label={t("components.doctor.drg_request_schedule_form.start_time")}
          views={["year", "month", "date", "hours", "minutes"]}
          value={values.startTime ? values.startTime : null}
          onChange={date =>
            handleChange({ target: { name: "startTime", value: date || null } })
          }
          error={includes(errors, "start_time")}
        />
        <br />
      </MuiPickersUtilsProvider>
      <TextField
        name="duration"
        label={t("components.doctor.drg_request_schedule_form.duration")}
        type="number"
        inputProps={{
          min: 0,
          step: 5
        }}
        value={values.duration || []}
        onChange={handleChange}
        required
        error={includes(errors, "duration")}
      />
      <br />
      <TextField
        id="notes"
        label={t("components.doctor.drg_request_schedule_form.notes_label")}
        placeholder={t(
          "components.doctor.drg_request_schedule_form.notes_placeholder"
        )}
        value={values.notes || ""}
        onChange={handleChange}
        multiline
        rows={4}
        variant="outlined"
        error={includes(errors, "notes")}
      />
      <ImplantTable
        errors={errors}
        implants={values.implants}
        handleChange={handleChange}
      />
      <br />
    </FormGroup>
  );
};

DrgRequestScheduleForm.contextTypes = {
  t: PropTypes.func
};

export default DrgRequestScheduleForm;
