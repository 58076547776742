import { cloneDeep } from "lodash";

import {
  SCHEDULED_FILE_INDEX,
  SCHEDULED_FILE_INDEX_SUCCESS,
  SCHEDULED_FILE_INDEX_ERROR
} from "../actions/const";
import {
  ADD_SCHEDULED_FILE,
  ADD_SCHEDULED_FILE_SUCCESS,
  ADD_SCHEDULED_FILE_ERROR
} from "../actions/const";
import {
  GET_SCHEDULED_FILE,
  GET_SCHEDULED_FILE_SUCCESS,
  GET_SCHEDULED_FILE_ERROR
} from "../actions/const";
import {
  GET_DOCTOR_CLINICS,
  GET_DOCTOR_CLINICS_SUCCESS,
  GET_DOCTOR_CLINICS_ERROR
} from "../actions/const";
import {
  EDIT_SCHEDULED_FILE,
  EDIT_SCHEDULED_FILE_SUCCESS,
  EDIT_SCHEDULED_FILE_ERROR
} from "../actions/const";
import {
  CONFIRM_SCHEDULED_FILE,
  CONFIRM_SCHEDULED_FILE_SUCCESS,
  CONFIRM_SCHEDULED_FILE_ERROR
} from "../actions/const";
import {
  CANCEL_SCHEDULED_FILE,
  CANCEL_SCHEDULED_FILE_SUCCESS,
  CANCEL_SCHEDULED_FILE_ERROR
} from "../actions/const";
import { RESET_SCHEDULED_FILE_ERRORS } from "./../actions/const";

const initialState = {
  loading: false,
  list: [],
  clinics: [],
  currentScheduledFile: {},
  errors: []
};

const reducer = (state = initialState, action) => {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case SCHEDULED_FILE_INDEX: {
      nextState.loading = true;
      nextState.list = [];
      return nextState;
    }
    case SCHEDULED_FILE_INDEX_SUCCESS: {
      const { scheduledFiles } = action;
      nextState.list = scheduledFiles;
      nextState.loading = false;
      return nextState;
    }
    case SCHEDULED_FILE_INDEX_ERROR: {
      nextState.list = [];
      nextState.loading = false;
      return nextState;
    }
    case ADD_SCHEDULED_FILE: {
      nextState.loading = true;
      return nextState;
    }
    case ADD_SCHEDULED_FILE_SUCCESS: {
      const { scheduledFileId } = action;
      nextState.currentScheduledFile.id = scheduledFileId;
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case ADD_SCHEDULED_FILE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.currentScheduledFile = {};
      nextState.loading = false;
      return nextState;
    }
    case GET_SCHEDULED_FILE: {
      nextState.loading = true;
      nextState.currentScheduledFile = {};
      return nextState;
    }
    case GET_SCHEDULED_FILE_SUCCESS: {
      const { scheduledFile } = action;
      nextState.currentScheduledFile = scheduledFile;
      nextState.loading = false;
      nextState.errors = [];
      return nextState;
    }
    case GET_SCHEDULED_FILE_ERROR: {
      nextState.currentScheduledFile = {};
      nextState.loading = false;
      return nextState;
    }
    case EDIT_SCHEDULED_FILE: {
      return nextState;
    }
    case EDIT_SCHEDULED_FILE_SUCCESS: {
      const { data } = action;
      nextState.currentScheduledFile = data;
      return nextState;
    }
    case EDIT_SCHEDULED_FILE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    case GET_DOCTOR_CLINICS:
    case GET_DOCTOR_CLINICS_ERROR: {
      nextState.clinics = [];
      return nextState;
    }
    case GET_DOCTOR_CLINICS_SUCCESS: {
      const { clinics } = action;
      nextState.clinics = clinics;
      return nextState;
    }
    case CONFIRM_SCHEDULED_FILE: {
      return nextState;
    }
    case CONFIRM_SCHEDULED_FILE_SUCCESS: {
      nextState.errors = [];
      return nextState;
    }
    case CONFIRM_SCHEDULED_FILE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    case CANCEL_SCHEDULED_FILE: {
      return nextState;
    }
    case CANCEL_SCHEDULED_FILE_SUCCESS: {
      nextState.errors = [];
      return nextState;
    }
    case CANCEL_SCHEDULED_FILE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    case RESET_SCHEDULED_FILE_ERRORS: {
      nextState.errors = [];
      return nextState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
