import React from "react";
import { useDispatch } from "react-redux";
import { Dialog } from "@material-ui/core";
import { push } from "connected-react-router";

import DownloadModulesDialogBody from "./DownloadModulesDialogBody";

const DownloadModulesHome = (props, context) => {
  const actionsDispatch = useDispatch();
  const closeModal = () => {
    actionsDispatch(push("/"));
  };
  return (
    <Dialog open={true} onClose={closeModal}>
      <DownloadModulesDialogBody closeModal={closeModal} />
    </Dialog>
  );
};

export default DownloadModulesHome;
