import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { Formik } from "formik";

import ScheduledFileForm from "../../shared/ScheduledFileForm";
import { addScheduledFile, getDoctorClinics } from "../../../../actions";

const useStyles = makeStyles(theme => ({
  addScheduledFileTitle: {
    display: "flex"
  },
  addScheduledFileTypography: {
    flexGrow: 1
  }
}));

const AddScheduledFileDialogBody = (props, { t }) => {
  const { closeModal } = props;
  const classes = useStyles();

  const actionsDispatch = useDispatch();

  const doctorId = useSelector(state => state.user.current.doctorId);
  const { clinics, errors, loading } = useSelector(
    state => state.scheduledFile
  );

  useEffect(() => {
    actionsDispatch(getDoctorClinics(doctorId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addSuccessCallback = id => {
    closeModal();
    actionsDispatch(push(`/scheduled_files/${id}`));
  };

  return (
    <>
      <DialogTitle disableTypography className={classes.addScheduledFileTitle}>
        <Typography variant="h6" className={classes.addScheduledFileTypography}>
          {t("components.doctor.shared.add_scheduled_file_dialog.module_title")}
        </Typography>
        <Tooltip
          title={t(
            "components.doctor.shared.add_scheduled_file_dialog.module_close"
          )}
          placement="top"
        >
          <IconButton size="small" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("components.doctor.shared.add_scheduled_file_dialog.module_text")}
        </DialogContentText>
        <Formik
          onSubmit={(values, actions) => {
            actionsDispatch(
              addScheduledFile(doctorId, values, id => addSuccessCallback(id))
            );
          }}
          render={({ handleSubmit, handleChange, values }) => (
            <form onSubmit={handleSubmit}>
              <ScheduledFileForm
                clinics={clinics}
                errors={errors}
                handleChange={handleChange}
                values={values}
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                fullWidth
              >
                {t(
                  "components.doctor.shared.add_scheduled_file_dialog.module_continue"
                )}
              </Button>
            </form>
          )}
        />
      </DialogContent>
    </>
  );
};

AddScheduledFileDialogBody.contextTypes = {
  t: PropTypes.func
};

export default AddScheduledFileDialogBody;
