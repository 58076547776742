import {
  ADD_DRG_REQUEST,
  ADD_DRG_REQUEST_SUCCESS,
  ADD_DRG_REQUEST_ERROR
} from "./const";
import {
  DRG_REQUEST_INDEX,
  DRG_REQUEST_INDEX_SUCCESS,
  DRG_REQUEST_INDEX_ERROR
} from "./const";
import {
  DRG_REQUEST_SHOW,
  DRG_REQUEST_SHOW_SUCCESS,
  DRG_REQUEST_SHOW_ERROR
} from "./const";

import {
  CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS,
  CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_SUCCESS,
  CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_ERROR
} from "./const";

export function addDRGRequest(
  patientId,
  description,
  doctorId,
  drgId,
  file,
  successCallback
) {
  return {
    type: ADD_DRG_REQUEST,
    patientId,
    description,
    doctorId,
    drgId,
    file,
    successCallback
  };
}
export function addDRGRequestSuccess() {
  return { type: ADD_DRG_REQUEST_SUCCESS };
}
export function addDRGRequestError(errors) {
  return { type: ADD_DRG_REQUEST_ERROR, errors };
}

export function DRGRequestIndex(patientId, doctorId, query) {
  return {
    type: DRG_REQUEST_INDEX,
    patientId,
    doctorId,
    query
  };
}
export function DRGRequestIndexSuccess(drgRequests) {
  return { type: DRG_REQUEST_INDEX_SUCCESS, drgRequests };
}
export function DRGRequestIndexError(errors) {
  return { type: DRG_REQUEST_INDEX_ERROR, errors };
}

export function DRGRequestShow(drgRequestId) {
  return {
    type: DRG_REQUEST_SHOW,
    drgRequestId
  };
}
export function DRGRequestShowSuccess(drgRequest) {
  return { type: DRG_REQUEST_SHOW_SUCCESS, drgRequest };
}
export function DRGRequestShowError(errors) {
  return { type: DRG_REQUEST_SHOW_ERROR, errors };
}

export function crossBorderHealthcareAuthorization(patientId) {
  return {
    type: CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS,
    patientId
  };
}
export function crossBorderHealthcareAuthorizationSuccess(modules) {
  return {
    type: CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_SUCCESS,
    modules
  };
}
export function crossBorderHealthcareAuthorizationError(errors) {
  return {
    type: CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_ERROR,
    errors
  };
}
