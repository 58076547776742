import { cloneDeep } from "lodash";
import {
  DRG_REQUEST_INDEX,
  DRG_REQUEST_INDEX_SUCCESS,
  DRG_REQUEST_INDEX_ERROR
} from "../actions/const";
import {
  DRG_REQUEST_SHOW,
  DRG_REQUEST_SHOW_SUCCESS,
  DRG_REQUEST_SHOW_ERROR
} from "../actions/const";
import {
  ADD_DRG_REQUEST,
  ADD_DRG_REQUEST_SUCCESS,
  ADD_DRG_REQUEST_ERROR
} from "../actions/const";
import {
  CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS,
  CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_SUCCESS,
  CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_ERROR
} from "../actions/const";

const initialState = {
  loading: false,
  list: [],
  current: {},
  loadingModules: false,
  modules: []
};

function reducer(state = initialState, action) {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case DRG_REQUEST_INDEX: {
      nextState.list = [];
      nextState.loading = true;
      return nextState;
    }
    case DRG_REQUEST_INDEX_SUCCESS: {
      const { drgRequests } = action;
      nextState.list = drgRequests;
      nextState.loading = false;
      return nextState;
    }
    case DRG_REQUEST_INDEX_ERROR: {
      nextState.list = [];
      nextState.loading = false;
      return nextState;
    }
    case DRG_REQUEST_SHOW: {
      nextState.loading = true;
      nextState.current = {};
      return nextState;
    }
    case DRG_REQUEST_SHOW_SUCCESS: {
      const { drgRequest } = action;
      nextState.current = drgRequest;
      nextState.loading = false;
      return nextState;
    }
    case DRG_REQUEST_SHOW_ERROR: {
      nextState.current = {};
      nextState.loading = false;
      return nextState;
    }
    case ADD_DRG_REQUEST: {
      nextState.loading = true;
      return nextState;
    }
    case ADD_DRG_REQUEST_SUCCESS: {
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case ADD_DRG_REQUEST_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    case CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS: {
      nextState.modules = [];
      nextState.loadingModules = true;
      return nextState;
    }
    case CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_SUCCESS: {
      const { modules } = action;
      nextState.modules = modules;
      nextState.loadingModules = false;
      return nextState;
    }
    case CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_ERROR: {
      nextState.modules = [];
      nextState.loadingModules = false;
      return nextState;
    }
    default: {
      return state;
    }
  }
}

export default reducer;
