import React, {useState, createContext} from "react";
const TeleconsultContext = createContext();

function TeleconsultProvider(props){
  const [teleconsultContextValue, setTeleconsultContextValue] = useState("Initial Value");

  return(
    <TeleconsultContext.Provider value={[teleconsultContextValue, setTeleconsultContextValue]}>
      {props.children}
    </TeleconsultContext.Provider>
  )
}

export {TeleconsultContext, TeleconsultProvider};
