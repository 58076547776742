import axios from "./AxiosConfig";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const PatientSource = {
  register: (user /*userData, profile*/) => {
    //const user = assign(userData, { patient_profile_attributes: profile });
    return api(axios.post(`${apiBaseUrl}/sign_up.json`, { user }), "register");
  },
  getProfile: profileId =>
    api(
      axios.get(`${apiBaseUrl}/patient_profiles/${profileId}.json`),
      "get_profile"
    ),
  editProfile: (profileId, profile) =>
    api(
      axios.put(
        `${apiBaseUrl}/patient_profiles/${profileId}.json`,
        {
          patient_profile: profile
        },
        "edit_profile"
      )
    ),
  associateDoctor: (doctorRef, profileId) =>
    api(
      axios.post(
        `${apiBaseUrl}/patient_profiles/${profileId}/associate_doctor.json`,
        {
          doctor_patient: {
            doctor_profile: {
              search: doctorRef
            }
          }
        }
      ),
      "associate_doctor"
    ),
  associatedDoctors: profileId =>
    api(
      axios.get(
        `${apiBaseUrl}/patient_profiles/${profileId}/doctor_profiles.json`
      ),
      "doctor_profiles"
    )
};

export default PatientSource;
