import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

class ApiJsonDataObj {
    constructor(apiJsonDataObj, navigator) {

        this.apiJsonDataObj = apiJsonDataObj
        this.navigator = navigator

        this.id = apiJsonDataObj.id
        this.type = apiJsonDataObj.type

        /* Setup methods to wrap attributes */
        Object.keys(this.apiJsonDataObj.attributes || []).forEach(attributeName => {
            this[attributeName] = this.apiJsonDataObj.attributes[attributeName]
        })

        /* Setup methods to wrap relationships */
        Object.keys(this.apiJsonDataObj.relationships || []).forEach(relationshipName => {
            this[relationshipName] = (findMap) => {

                const relationshipData = this.apiJsonDataObj.relationships[relationshipName].data

                if(Array.isArray(relationshipData)) {
                    const resolvedRelationships = relationshipData.map((dataObj) => this.navigator.resolveIncluded(dataObj) )

                    if(findMap) {
                        const dataObj = find(resolvedRelationships, findMap)
                        return this.navigator.resolveIncluded(dataObj)
                    }
                    else {
                        return resolvedRelationships
                    }
                }
                else {
                    return this.navigator.resolveIncluded(relationshipData)
                }
            }
        })
    }

    attributes = () => {
        return this.apiJsonDataObj.attributes
    }
}

class ApiJsonNavigator {

    constructor(apiJsonResponse) {
        this.apiJsonResponse = apiJsonResponse
    }

    data = () => {
        if(Array.isArray(this.apiJsonResponse.data)) return this.apiJsonResponse.data.map((dataObj) => new ApiJsonDataObj(dataObj, this) )
        else return new ApiJsonDataObj(this.apiJsonResponse.data, this)
    }

    meta = () => this.apiJsonResponse.meta

    resolveIncluded = (dataObj) => {

        if(isEmpty(dataObj)) return {}

        const foundIncluded = find(this.apiJsonResponse.included, { id: dataObj.id, type: dataObj.type })
        if(foundIncluded) return new ApiJsonDataObj(foundIncluded, this)
        else return new ApiJsonDataObj(dataObj, this)
    }

}

export default ApiJsonNavigator