import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  DialogContent,
  Button,
  Divider,
  LinearProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { Formik } from "formik";
import { isNull, get, find } from "lodash";

import {
  addDrgRequestSchedule,
  drgRequestScheduleIndex,
  DRGRequestIndex,
  DRGIndex
} from "../../../../actions";
import DrgRequestScheduleForm from "./../../shared/DrgRequestScheduleForm";

const useStyles = makeStyles(theme => ({
  addScheduledFileTitle: {
    display: "flex"
  },
  addScheduledFileTypography: {
    flexGrow: 1
  }
}));

const AddDrgRequestScheduleDialog = (props, context) => {
  const { t } = context;
  const user = useSelector(state => state.user.current);
  const actionsDispatch = useDispatch();

  const classes = useStyles();
  const { currentScheduledFile } = useSelector(state => state.scheduledFile);
  const { errors, loadingCurrent } = useSelector(
    state => state.drgRequestSchedule
  );
  const { current } = useSelector(state => state.drgRequest);
  const currentLang = useSelector(state => state.i18nState.lang);
  const drgs = useSelector(state => state.drg.list);

  const { currentDrgRequestId, handleModal } = props;

  const getDrgs = patientId => actionsDispatch(DRGIndex(patientId));

  const successCallback = () => {
    handleModal(null);
    actionsDispatch(drgRequestScheduleIndex(currentScheduledFile.id));
    actionsDispatch(
      DRGRequestIndex(null, user.doctorId, {
        q: { accepted: true, not_scheduled: true }
      })
    );
  };

  return (
    <Dialog
      open={!isNull(currentDrgRequestId)}
      onClose={() => handleModal(null)}
    >
      <DialogTitle disableTypography className={classes.addScheduledFileTitle}>
        <Typography variant="h5" className={classes.addScheduledFileTypography}>
          {t("components.doctor.new_drg_request_schedule_dialog.title")}
        </Typography>
        <Tooltip
          title={t("components.doctor.new_drg_request_schedule_dialog.close")}
          placement="top"
        >
          <IconButton
            size="small"
            className={classes.closeButton}
            onClick={() => handleModal(null)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        {current.patient ? (
          <>
            <Typography variant="h6" gutterBottom>
              {t(
                "components.doctor.new_drg_request_schedule_dialog.patient_data"
              )}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {current.patient.name || current.patient.email_address} {current.patient.surname || ''}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {current.patient.gender && t(`locale.${current.patient.gender}`)}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {current.patient.fiscal_code}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {current.patient.email_address}
            </Typography>
            <Typography variant="subtitle2">{current.patient.phone}</Typography>

            <br />
            <br />
            <Divider />
            <br />
            <Typography variant="body1" color="textSecondary">
              {t("components.doctor.new_drg_request_schedule_dialog.text")}
            </Typography>
            <br />
            <Formik
              onSubmit={(values, actions) => {
                const {
                  startTime,
                  duration,
                  hospitalizationDays,
                  notes,
                  drgId,
                  implants
                } = values;
                const drgRequestSchedule = {
                  startTime: startTime.utc(),
                  duration,
                  drgRequestId: currentDrgRequestId,
                  hospitalizationDays,
                  notes,
                  drgId,
                  implants
                };
                actionsDispatch(
                  addDrgRequestSchedule(
                    currentScheduledFile.id,
                    drgRequestSchedule,
                    successCallback
                  )
                );
              }}
              enableReinitialize
              initialValues={{
                drgId: get(current, "drg.id", ""),
                implants: (find(drgs, { id: get(current, "drg.id", "") }) || {})
                  .implants
              }}
              render={({ handleSubmit, handleChange, values }) => (
                <form onSubmit={handleSubmit}>
                  <DrgRequestScheduleForm
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    currentScheduledFile={currentScheduledFile}
                    currentLang={currentLang}
                    drgs={drgs}
                    onLoad={() => getDrgs(current.patient.id)}
                  />
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={loadingCurrent}
                    fullWidth
                  >
                    {t(
                      "components.doctor.new_drg_request_schedule_dialog.create"
                    )}
                  </Button>
                </form>
              )}
            />
          </>
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
    </Dialog>
  );
};

AddDrgRequestScheduleDialog.contextTypes = {
  t: PropTypes.func
};

export default AddDrgRequestScheduleDialog;
