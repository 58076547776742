import { map } from "lodash";
import { takeEvery, call, put, all } from "redux-saga/effects";
import DocumentTypeSource from "../sources/DocumentTypeSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import { DOCUMENT_TYPES_INDEX } from "../actions/const";
import {
  documentTypesIndexSuccess,
  documentTypesIndexError,
  postError
} from "../actions";

const mapDocumentTypes = response => {
  const documentTypesApiNavigator = new ApiJsonNavigator(response.data);
  return map(documentTypesApiNavigator.data(), item => ({
    ...item.attributes(),
    id: item.id
  }));
};

function* indexDocumentTypes(action) {
  const { response, errors } = yield call(DocumentTypeSource.index, action.query);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(documentTypesIndexError(errors.pointers));
    return;
  }
  const documentTypes = yield call(mapDocumentTypes, response);
  yield put(documentTypesIndexSuccess(documentTypes));
}

export const documentTypesIndexFlow = takeEvery(
  DOCUMENT_TYPES_INDEX,
  indexDocumentTypes
);
