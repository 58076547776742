import { cloneDeep } from "lodash";

import {
  DRG_INDEX,
  DRG_INDEX_SUCCESS,
  DRG_INDEX_ERROR
} from "../actions/const";

const initialState = {
  loading: false,
  list: []
};

function reducer(state = initialState, action) {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case DRG_INDEX: {
      nextState.loading = true;
      nextState.list = [];
      return nextState;
    }
    case DRG_INDEX_SUCCESS: {
      const { drgs } = action;
      nextState.list = drgs;
      nextState.loading = false;
      return nextState;
    }
    case DRG_INDEX_ERROR: {
      nextState.list = [];
      nextState.loading = false;
      return nextState;
    }
    default: {
      return state;
    }
  }
}

export default reducer;
