import { cloneDeep } from "lodash";

import * as types from "../actions/const";

const initialState = {
  loading: false,
  list: [],
  errors: []
};

const reducer = (state = initialState, action) => {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case types.EXAMINATION_MESSAGE_INDEX: {
      nextState.loading = true;
      return nextState;
    }
    case types.EXAMINATION_MESSAGE_INDEX_SUCCESS: {
      const { messages } = action;
      nextState.errors = [];
      nextState.list = messages.data();
      nextState.loading = false;
      return nextState;
    }
    case types.EXAMINATION_MESSAGE_INDEX_ERROR: {
      const { errors } = action;
      nextState.list = [];
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    case types.CREATE_EXAMINATION_MESSAGE: {
      nextState.loading = true;
      return nextState;
    }
    case types.CREATE_EXAMINATION_MESSAGE_SUCCESS: {
      const { message } = action;
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case types.CREATE_EXAMINATION_MESSAGE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
