import { cloneDeep } from "lodash";

import {
  GET_DOCTOR_PROFILES,
  GET_DOCTOR_PROFILES_SUCCESS,
  GET_DOCTOR_PROFILES_ERROR
} from "../actions/const";
import {
  GET_DOCTOR_PROFILE,
  GET_DOCTOR_PROFILE_SUCCESS,
  GET_DOCTOR_PROFILE_ERROR
} from "../actions/const";
import {
  EDIT_DOCTOR_PROFILE,
  EDIT_DOCTOR_PROFILE_SUCCESS,
  EDIT_DOCTOR_PROFILE_ERROR
} from "../actions/const";
import { RESET_DOCTOR_ERRORS } from "./../actions/const";
import {
  ASSOCIATED_PATIENTS,
  ASSOCIATED_PATIENTS_SUCCESS,
  ASSOCIATED_PATIENTS_ERROR
} from "../actions/const";

const initialState = {
  loading: false,
  profile: {},
  profiles: [],
  associatedPatientList: [],
  errors: []
};

const reducer = (state = initialState, action) => {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case GET_DOCTOR_PROFILES: {
      nextState.profiles = [];
      nextState.loading = true;
      return nextState;
    }
    case GET_DOCTOR_PROFILES_SUCCESS: {
      nextState.profiles = action.profiles;
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case GET_DOCTOR_PROFILES_ERROR: {
      nextState.profiles = [];
      nextState.loading = false;
      return nextState;
    }
    case GET_DOCTOR_PROFILE: {
      nextState.profile = {};
      nextState.loading = true;
      return nextState;
    }
    case GET_DOCTOR_PROFILE_SUCCESS: {
      nextState.profile = action.profile;
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case GET_DOCTOR_PROFILE_ERROR: {
      nextState.profile = {};
      nextState.loading = false;
      return nextState;
    }
    case EDIT_DOCTOR_PROFILE: {
      return nextState;
    }
    case EDIT_DOCTOR_PROFILE_SUCCESS: {
      nextState.profile = action.profile;
      nextState.errors = [];
      return nextState;
    }
    case EDIT_DOCTOR_PROFILE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    case RESET_DOCTOR_ERRORS: {
      nextState.errors = [];
      return nextState;
    }
    case ASSOCIATED_PATIENTS: {
      nextState.loading = true;
      return nextState;
    }
    case ASSOCIATED_PATIENTS_SUCCESS: {
      nextState.associatedPatientList = action.associatedPatientList;
      nextState.loading = false;

      return nextState;
    }
    case ASSOCIATED_PATIENTS_ERROR: {
      nextState.loading = false;
      return nextState;
    }
    default: {
      return nextState;
    }
  }
};

export default reducer;
