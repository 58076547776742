import axios from "./AxiosConfig";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const scheduledFileSource = {
  index: doctorId =>
    api(
      axios.get(
        `${apiBaseUrl}/doctor_profiles/${doctorId}/scheduled_files.json`
      ),
      "scheduled_file_index"
    ),
  show: scheduledFileId =>
    api(
      axios.get(`${apiBaseUrl}/scheduled_files/${scheduledFileId}.json`),
      "get_scheduled_file"
    ),
  add: (doctorId, scheduledFile) =>
    api(
      axios.post(
        `${apiBaseUrl}/doctor_profiles/${doctorId}/scheduled_files.json`,
        scheduledFile
      ),
      "scheduled_file_add"
    ),
  edit: (id, scheduledFile) =>
    api(
      axios.patch(`${apiBaseUrl}/scheduled_files/${id}.json`, scheduledFile),
      "scheduled_file_edit"
    ),
  confirm: id =>
    api(
      axios.patch(`${apiBaseUrl}/scheduled_files/${id}/confirm.json`),
      "scheduled_file_confirm"
    ),
  cancel: id =>
    api(
      axios.patch(`${apiBaseUrl}/scheduled_files/${id}/cancel.json`),
      "scheduled_file_cancel"
    ),
  getClinics: doctorId =>
    api(
      axios.get(`${apiBaseUrl}/doctor_profiles/${doctorId}/clinics.json`),
      "clinic"
    )
};

export default scheduledFileSource;
