import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "./../../../actions";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import { PersonAdd, HeadsetMic } from "@material-ui/icons";
import config from "./../../../config";

const PatientMenu = (props, { t }) => {
  const actionDispatch = useDispatch();
  const patientId = useSelector(state => state.user.current.patientId);
  useEffect(() => {
    actionDispatch(getProfile(patientId));
  }, [actionDispatch, patientId]);

  const [postProcessDoctorTeleconsultPatientEmail, setPostProcessDoctorTeleconsultPatientEmail] = useState(0);
  const [postProcessDoctorTeleconsultPatientId, setPostProcessDoctorTeleconsultPatientId] = useState(0);

  useEffect(() => {
    if(patientId) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/post_process_doctor_teleconsult.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ patientId: patientId })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.status1 && data.status3){
            setPostProcessDoctorTeleconsultPatientEmail(data.status4)
            setPostProcessDoctorTeleconsultPatientId(data.status2)
          } else{
            console.log(data.status)
          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [patientId]);

  return (
    <>
      <Divider />
      <List>
        <ListItemText
          inset
          primaryTypographyProps={{
            variant: "overline"
          }}
        >
          {t("components.patient.shared.patient_menu.label")}
        </ListItemText>
        
        { 
          (postProcessDoctorTeleconsultPatientId == patientId)
          ?
            null
          :
          <ListItem button component={Link} to={"/home/associate_doctor"}>
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>

            <ListItemText>
              {t("components.patient.shared.patient_menu.associate_doctor")}
            </ListItemText>
          </ListItem>
        }

        <ListItem button component={Link} to={"/telemedicine"}>
          <ListItemIcon>
            <HeadsetMic />
          </ListItemIcon>
          { 
            (postProcessDoctorTeleconsultPatientId == patientId)
            ?
              <ListItemText>
                {t("components.pages.homepage.doctorconsult.teleconsult")}
              </ListItemText>
            :
              <ListItemText>
                {t("components.patient.shared.patient_menu.telemedicine")}
              </ListItemText>
          }
        </ListItem>
      </List>
    </>
  );
};

PatientMenu.contextTypes = {
  t: PropTypes.func
};

export default PatientMenu;
