import {
  SCHEDULED_FILE_INDEX,
  SCHEDULED_FILE_INDEX_SUCCESS,
  SCHEDULED_FILE_INDEX_ERROR
} from "./const";
import {
  ADD_SCHEDULED_FILE,
  ADD_SCHEDULED_FILE_SUCCESS,
  ADD_SCHEDULED_FILE_ERROR
} from "./const";
import {
  GET_SCHEDULED_FILE,
  GET_SCHEDULED_FILE_SUCCESS,
  GET_SCHEDULED_FILE_ERROR
} from "./const";
import {
  GET_DOCTOR_CLINICS,
  GET_DOCTOR_CLINICS_SUCCESS,
  GET_DOCTOR_CLINICS_ERROR
} from "./const";
import {
  CONFIRM_SCHEDULED_FILE,
  CONFIRM_SCHEDULED_FILE_SUCCESS,
  CONFIRM_SCHEDULED_FILE_ERROR
} from "./const";
import {
  CANCEL_SCHEDULED_FILE,
  CANCEL_SCHEDULED_FILE_SUCCESS,
  CANCEL_SCHEDULED_FILE_ERROR
} from "./const";
import {
  EDIT_SCHEDULED_FILE,
  EDIT_SCHEDULED_FILE_SUCCESS,
  EDIT_SCHEDULED_FILE_ERROR
} from "./const";
import { RESET_SCHEDULED_FILE_ERRORS } from "./const";

export function scheduledFileIndex(doctorId) {
  return {
    type: SCHEDULED_FILE_INDEX,
    doctorId
  };
}
export function scheduledFileIndexSuccess(scheduledFiles) {
  return { type: SCHEDULED_FILE_INDEX_SUCCESS, scheduledFiles };
}
export function scheduledFileIndexError(errors) {
  return { type: SCHEDULED_FILE_INDEX_ERROR, errors };
}

export function addScheduledFile(doctorId, scheduledFile, successCallback) {
  return {
    type: ADD_SCHEDULED_FILE,
    doctorId,
    scheduledFile,
    successCallback
  };
}
export function addScheduledFileSuccess(scheduledFileId) {
  return { type: ADD_SCHEDULED_FILE_SUCCESS, scheduledFileId };
}
export function addScheduledFileError(errors) {
  return { type: ADD_SCHEDULED_FILE_ERROR, errors };
}

export function getScheduledFile(scheduledFileId) {
  return {
    type: GET_SCHEDULED_FILE,
    scheduledFileId
  };
}
export function getScheduledFileSuccess(scheduledFile) {
  return { type: GET_SCHEDULED_FILE_SUCCESS, scheduledFile };
}
export function getScheduledFileError(errors) {
  return { type: GET_SCHEDULED_FILE_ERROR, errors };
}

export function editScheduledFile(id, scheduledFile, successCallback) {
  return {
    type: EDIT_SCHEDULED_FILE,
    id,
    scheduledFile,
    successCallback
  };
}
export function editScheduledFileSuccess(data) {
  return { type: EDIT_SCHEDULED_FILE_SUCCESS, data };
}
export function editScheduledFileError(errors) {
  return { type: EDIT_SCHEDULED_FILE_ERROR, errors };
}

export function getDoctorClinics(doctorId) {
  return { type: GET_DOCTOR_CLINICS, doctorId };
}
export function getDoctorClinicsSuccess(clinics) {
  return { type: GET_DOCTOR_CLINICS_SUCCESS, clinics };
}
export function getDoctorClinicsError(errors) {
  return { type: GET_DOCTOR_CLINICS_ERROR, errors };
}

export function confirmScheduledFile(id, successCallback) {
  return { type: CONFIRM_SCHEDULED_FILE, id, successCallback };
}
export function confirmScheduledFileSuccess() {
  return { type: CONFIRM_SCHEDULED_FILE_SUCCESS };
}
export function confirmScheduledFileError(errors) {
  return { type: CONFIRM_SCHEDULED_FILE_ERROR, errors };
}

export function cancelScheduledFile(id, successCallback) {
  return { type: CANCEL_SCHEDULED_FILE, id, successCallback };
}
export function cancelScheduledFileSuccess() {
  return { type: CANCEL_SCHEDULED_FILE_SUCCESS };
}
export function cancelScheduledFileError(errors) {
  return { type: CANCEL_SCHEDULED_FILE_ERROR, errors };
}

export function resetScheduledFileErrors() {
  return { type: RESET_SCHEDULED_FILE_ERRORS };
}
