import React from "react";
import PropTypes from "prop-types";
import { map, isEmpty } from "lodash";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Typography
} from "@material-ui/core";
import { Delete as DeleteIcon, Description as DescriptionIcon } from "@material-ui/icons";

const FileList = ({ files, showDelete, onFileDelete }, { t }) => {

  return (
    <List dense>
      {isEmpty(files)
        ? t("components.shared.document_list.no_documents")
        : map(files, ({ id, url, filename }) => (
            <ListItem key={id}>
              <ListItemIcon>
                <DescriptionIcon/>
              </ListItemIcon>
              <ListItemText
                primary={
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <Typography variant="body1">
                      <strong>{filename}</strong>
                    </Typography>
                  </a>
                }
              />
              {showDelete && (
                <ListItemSecondaryAction>
                  <IconButton onClick={() => onFileDelete({ fileId: id })}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
    </List>
  );
};

FileList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired
    })
  ),
  onFileDelete: PropTypes.func,
  showDelete: PropTypes.bool
};

FileList.defaultProps = {
  files: [],
  onFileDelete: () => {},
  showDelete: false
};

FileList.contextTypes = {
  t: PropTypes.func
};

export default FileList;
