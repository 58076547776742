import { all, takeEvery, put, call } from "redux-saga/effects";
import { map } from "lodash";
import CountrySource from "../sources/CountrySource";
import {
  GET_COUNTRIES,
  GET_STATES_BY_COUNTRY,
  GET_CITIES_BY_STATE
} from "../actions/const";
import {
  postError,
  getCountriesSuccess,
  getStatesByCountrySuccess,
  getCitiesByStateSuccess,
  getProvincesSuccess
} from "../actions";

const mapResponse = attr => response => ({
  [attr]: map(response.data.data, item => item.attributes)
});

function* getCountries() {
  const { response, errors } = yield call(CountrySource.getCountries);

  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    return;
  }
  const { countries } = yield call(mapResponse("countries"), response);
  yield put(getCountriesSuccess(countries));
}

function* getStatesByCountry({ key, country }) {
  const { response, errors } = yield call(
    CountrySource.getStatesByCountry,
    country
  );

  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    return;
  }
  const { states } = yield call(mapResponse("states"), response);
  yield put(getStatesByCountrySuccess(key, states));
}

function* getCitiesByState({ key, country, state }) {
  const { response, errors } = yield call(
    CountrySource.getCitiesByState,
    country,
    state
  );

  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    return;
  }
  const { cities } = yield call(mapResponse("cities"), response);
  yield put(getCitiesByStateSuccess(key, cities));
}

function* getProvincesByState({ key, country, state }) {
  const { response, errors } = yield call(
    CountrySource.getProvinces,
    country,
    state
  );

  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    return;
  }
  const { provinces } = yield call(mapResponse("provinces"), response);
  yield put(getProvincesSuccess(key, provinces));
}

export const countryFlow = takeEvery(GET_COUNTRIES, getCountries);
export const getStatesByCountryFlow = takeEvery(
  GET_STATES_BY_COUNTRY,
  getStatesByCountry
);
export const getCitiesByStateFlow = takeEvery(
  GET_CITIES_BY_STATE,
  getCitiesByState
);
export const getProvincesByStateFlow = takeEvery(
  GET_CITIES_BY_STATE,
  getProvincesByState
);
