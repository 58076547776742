export const translations = {
  en: {
    "locale.display_date_format": "YYYY-MM-DD",
    "locale.display_datetime": "YYYY-MM-DD HH:mm",
    "locale.display_previous": "Back",
    "locale.display_next": "Next",
    "locale.display_today": "Today",
    "locale.display_month": "Month",
    "locale.display_week": "Week",
    "locale.display_day": "Day",
    "locale.display_agenda": "Agenda",
    "locale.display_date": "Date",
    "locale.display_time": "Time",
    "locale.display_event": "Event",
    "components.notifications.login_success": "Successful login",
    "components.notifications.logout_success": "User logged out",
    "components.notifications.register_success": "Successful registration",
    "components.notifications.edit_profile": "You changed the profile data",
    "components.pages.login.title": "Login",
    "components.pages.login.email": "Email",
    "components.pages.login.password": "Password",
    "components.pages.login.log_in": "Login",
    "components.pages.login.remember_me": "Remember me",
    "components.pages.login.register": "Sign Up",
    "components.pages.login.forgot_password": "Forgot your passowrd?",
    "components.pages.registration.title": "Login details",
    "components.pages.registration.log_in": "Login",
    "components.pages.registration.email": "Registration Email",
    "components.pages.registration.password": "Password",
    "components.pages.registration.confirm_password": "Confirm password",
    "components.pages.registration.accept": "Accept our",
    "components.pages.registration.terms_of_service": "terms of service",
    "components.pages.registration.password_complexity":
      "Use at least: 1 uppercase, 1 lowercase, 1 number and 1 special character",
    "components.pages.patient_dashboard.modal_title": "Associate doctor",
    "components.pages.patient_dashboard.modal_text":
      "Insert email or phone number of the doctor",
    "components.pages.patient_dashboard.modal_label": "Email or phone number",
    "components.pages.patient_dashboard.modal_confirm": "Confirm",
    "components.pages.patient_dashboard.modal_close": "Close",
    "components.drg_request_list.title": "My documents",
    "components.drg_request_list.no_drg_request":
      "No authorization request found for this profile",
    "components.drg_request_list.no_modules_sef":
      "SEF is proceeding with the preparation of your module.",
    "components.drg_request_list.no_modules_mail":
      "You will receive an email as soon as it is ready",
    "components.drg_request_list.drg_request_name": "Request name",
    "components.drg_request_list.drg_request_attachment": "Attachment",
    "components.drg_request_list.drg_request_status": "Status",
    "components.drg_request_list.drg_request_description":
      "Request #{requestId}",
    "components.drg_request_list.drg_request_view": "View",
    "components.drg_request_list.drg_request_doctor": "Doctor",
    "components.drg_request_list.drg_request_sent": "Sent on",
    "components.drg_request_list.drg_request_drg": "Surgery",
    "components.drg_request_list.drg_request_notes": "Notes",
    "components.drg_request_list.drg_request_empty_notes": "No notes",
    "components.drg_request_list.drg_request_download":
      "Download accepted request",
    "components.drg_request_list.drg_request_scheduled":
      "Your operation will be held on",
    "components.drg_request_list.drg_request_add": "Add",
    "components.drg_request_list.module_place": "Place",
    "components.drg_request_list.module_title": "Download authorization",
    "components.drg_request_list.module_text":
      "Your module is ready. Click on 'Download' to download it",
    "components.drg_request_list.module_download": "Download",
    "components.drg_request_list.module_close": "Close",
    "components.drg_request_list.drg_request_documents": "Request documents",
    "components.drg_request_list.drg_request_schedule_documents":
      "Planning documents",
    "components.drg_request_list.request": "Request",
    "components.drg_request_list.schedule": "Schedule",
    "components.drg_request_list.not_scheduled": "Not scheduled",
    "components.shared.edit_profile.title": "Profile data",
    "components.shared.edit_profile.name": "Name",
    "components.shared.edit_profile.surname": "Surname",
    "components.shared.edit_profile.gender": "Gender",
    "components.shared.edit_profile.male": "Male",
    "components.shared.edit_profile.female": "Female",
    "components.shared.edit_profile.membership_asl": "Membership Asl",
    "components.shared.edit_profile.birth_date": "Birth date",
    "components.shared.edit_profile.cancel": "Cancel",
    "components.shared.edit_profile.clear": "Clear",
    "components.shared.edit_profile.birth_country": "Birth country",
    "components.shared.edit_profile.birth_state": "Birth state",
    "components.shared.edit_profile.birth_city": "Birth city",
    "components.shared.edit_profile.fiscal_code": "Fiscal code",
    "components.shared.edit_profile.residential_address": "Residential address",
    "components.shared.edit_profile.residential_postal_code":
      "Residential postal code",
    "components.shared.edit_profile.residential_country": "Residential country",
    "components.shared.edit_profile.residential_state": "Residential state",
    "components.shared.edit_profile.residential_city": "Residential city",
    "components.shared.edit_profile.residential_province":
      "Residential province",
    "components.shared.edit_profile.phone": "Phone number",
    "components.shared.edit_profile.mobile_phone": "Mobile phone number",
    "components.shared.edit_profile.email": "Email",
    "components.shared.edit_profile.specializations": "Specializations",
    "components.shared.edit_profile.presentation": "Brief presentation",
    "components.shared.edit_profile.save": "Save",
    "components.shared.notifications_viewer.close": "Close",
    "components.shared.layouts.base.navbar.home": "Home",
    "components.shared.layouts.base.navbar.dashboard": "Dashboard",
    "components.shared.layouts.base.navbar.dashboard.surgery": "Surgery",
    "components.shared.layouts.base.navbar.edit_profile": "Edit Profile",
    "components.shared.layouts.base.navbar.logout": "Logout",
    "components.shared.layouts.base.navbar.menu": "Menù",
    "components.shared.layouts.base.navbar.italian": "Italian",
    "components.shared.layouts.base.navbar.english": "English",
    "components.shared.document_list.document_type": "Type",
    "components.shared.document_list.description": "Description",
    "components.shared.document_list.file_name": "File name",
    "components.shared.document_list.download": "Download",
    "components.shared.document_list.no_documents":
      "At the moment there is no document available",
    "components.shared.message_list.message_text": "Testo del messaggio",
    "components.pages.drg_request_detail.drg": "Surgery",
    "components.pages.drg_request_detail.doctor": "Doctor",
    "components.pages.drg_request_detail.drg_request": "Authorization Request",
    "components.pages.drg_request_detail.message_label": "Message:",
    "components.pages.drg_request_detail.module_label": "Module:",
    "components.pages.drg_request_detail.drg_title": "Title:",
    "components.pages.drg_request_detail.doctor_name": "Name:",
    "components.pages.drg_request_detail.doctor_surname": "Surname:",
    "components.pages.drg_request_detail.drg_code": "Code:",
    "components.pages.drg_request_detail.drg_label": "Label:",
    "components.pages.drg_request_detail.doctor_email_address":
      "Email address:",
    "components.pages.drg_request_detail.doctor_mobile_phone": "Mobile phone:",
    "components.pages.add_drg_request.title": "Submit Authorization Request",
    "components.pages.add_drg_request.description": "Description",
    "components.pages.add_drg_request.description_placeholder":
      "Insert the description of your authorization (optional)",
    "components.pages.add_drg_request.doctor": "Choose doctor",
    "components.pages.add_drg_request.selected_drg": "Choose surgery",
    "components.pages.add_drg_request.modules": "Upload authorized request",
    "components.pages.add_drg_request.patient_documents":
      "Upload other documents (es. Prescription)",
    "components.pages.add_drg_request.add": "Submit",
    "components.pages.add_drg_request.no_select": "Insert all required data",
    "components.pages.add_drg_request.wrong_file_type": "Insert only pdf files",
    "components.pages.add_drg_request.no_drg_alert":
      "No authorization present for you residential place",
    "components.pages.add_drg_request.doctor_patient_link":
      "To view the form filled out by the doctor click here",
    "components.pages.confirm_registration.title": "You almost did it!",
    "components.pages.confirm_registration.content":
      "An email has just been sent to the address you registered with. Follow the link inside to confirm your email and be able to log in",
    "components.pages.confirm_registration.back_to_login": "Back to login",
    "components.doctor.shared.doctor_menu.label": "Doctor Menu",
    "components.doctor.shared.doctor_menu.associated_patients":
      "Associated patients",
    "components.patient.shared.patient_menu.label": "Patient Menu",
    "components.patient.shared.patient_menu.associate_doctor":
      "Associate doctor",
    "components.patient.shared.patient_menu.telemedicine": "Telemedicine",
    "components.patient.shared.patient_menu.patientbook": "Patient Book",
    "components.patient.shared.patient_menu.medical_history": "Medical history",
    "components.doctor.shared.scheduled_file_list.no_scheduled_file":
      "No file found for this profile",
    "components.doctor.shared.scheduled_file_list.title": "My files",
    "components.doctor.shared.scheduled_file_list.label": "Label",
    "components.doctor.shared.scheduled_file_list.clinic": "Clinic",
    "components.doctor.shared.scheduled_file_list.from": "From",
    "components.doctor.shared.scheduled_file_list.to": "To",
    "components.doctor.shared.add_scheduled_file_dialog.from": "From",
    "components.doctor.shared.add_scheduled_file_dialog.to": "To",
    "components.doctor.shared.scheduled_file_list.status": "Status",
    "components.doctor.shared.scheduled_file_list.edit": "Edit",
    "components.doctor.shared.scheduled_file_list.add": "Add",
    "components.doctor.shared.add_scheduled_file_dialog.module_title":
      "Add file",
    "components.doctor.shared.add_scheduled_file_dialog.module_close": "Close",
    "components.doctor.shared.add_scheduled_file_dialog.module_text":
      "Fill file fields",
    "components.doctor.shared.add_scheduled_file_dialog.module_continue": "Add",
    "components.doctor.shared.add_scheduled_file_dialog.from_date": "From",
    "components.doctor.shared.add_scheduled_file_dialog.to_date": "To",
    "components.doctor.shared.add_scheduled_file_dialog.label": "Label",
    "components.doctor.shared.add_scheduled_file_dialog.clinic_label":
      "Choose a clinic",
    "components.doctor.drg_request_schedule_form.start_time": "Start time",
    "components.doctor.drg_request_schedule_form.duration":
      "Duration (minutes)",
    "components.doctor.drg_request_schedule_form.drg": "Choose a surgery",
    "components.doctor.drg_request_schedule_form.hospitalization_days":
      "Hospitalization days",
    "components.doctor.drg_request_schedule_form.notes_label": "Notes",
    "components.doctor.drg_request_schedule_form.notes_placeholder":
      "Insert notes on this schedule",
    "components.doctor.shared.implant_table.implants": "Implant",
    "components.doctor.shared.implant_table.min": "Min",
    "components.doctor.shared.implant_table.max": "Max",
    "components.doctor.new_drg_request_schedule_dialog.title":
      "Surgical procedures schedule",
    "components.doctor.new_drg_request_schedule_dialog.close": "Close",
    "components.doctor.new_drg_request_schedule_dialog.text":
      "Modify surgery schedule",
    "components.doctor.new_drg_request_schedule_dialog.create": "Schedule",
    "components.doctor.new_drg_request_schedule_dialog.patient_data":
      "Patient Data",
    "components.doctor.edit_drg_request_schedule_dialog.title":
      "Schedule surgery",
    "components.doctor.edit_drg_request_schedule_dialog.close": "Close",
    "components.doctor.edit_drg_request_schedule_dialog.text":
      "Modify surgery schedule",
    "components.doctor.edit_drg_request_schedule_dialog.update": "Schedule",
    "components.doctor.edit_drg_request_schedule_dialog.delete": "Delete",
    "components.doctor.edit_drg_request_schedule_dialog.confirm_delete":
      "Are you sure to delete this schedule file?",
    "components.doctor.edit_scheduled_file.empty_request_list":
      "No requests available",
    "components.doctor.edit_scheduled_file.confirm_confirm":
      "Are you sure to confirm this scheduled file?",
    "components.doctor.edit_scheduled_file.confirm_cancel":
      "Are you sure to cancel this scheduled file?",
    "components.doctor.edit_scheduled_file.confirm": "Confirm",
    "components.doctor.edit_scheduled_file.cancel": "Cancel file",
    "components.doctor.edit_scheduled_file.schedule": "Schedule",
    "components.doctor.edit_scheduled_file.edit": "Edit",
    "components.doctor.edit_scheduled_file.empty_label": "Schedule",
    "components.doctor.edit_scheduled_file.download_documents":
      "Download documents",
    "components.doctor.edit_scheduled_file_dialog.module_title": "Edit file",
    "components.doctor.edit_scheduled_file_dialog.module_close": "Close",
    "components.doctor.edit_scheduled_file_dialog.module_text":
      "Fill file fields",
    "components.doctor.edit_scheduled_file_dialog.module_continue": "Edit",
    "components.doctor.event_view_dialog.start_time": "Start time",
    "components.doctor.event_view_dialog.duration": "Duration (minutes)",
    "components.doctor.event_view_dialog.close": "Close",
    "components.doctor.event_view_dialog.hospitalization_days":
      "Hospitalization days",
    "components.doctor.event_view_dialog.notes": "Notes",
    "components.doctor.pages.doctor_dashboard.scheduled_files": "My files",
    "components.doctor.pages.doctor_dashboard.calendar": "Calendar",
    "components.doctor.pages.associated_patient_list.patient": "Patient",
    "components.doctor.pages.associated_patient_list.fiscal_code":
      "Fiscal code",
    "components.doctor.pages.associated_patient_list.gender": "Gender",
    "components.doctor.pages.associated_patient_list.membership_asl": "ASL",
    "components.doctor.pages.associated_patient_list.birth_data": "Birth data",
    "components.doctor.pages.associated_patient_list.residential_place":
      "Residence",
    "components.doctor.pages.associated_patient_list.contacs": "Contacts",
    "components.doctor.pages.associated_patient_list.email_address": "Email:",
    "components.doctor.pages.associated_patient_list.phone": "Phone:",
    "components.doctor.pages.associated_patient_list.mobile_phone":
      "Mobile phone:",
    "components.doctor.pages.associated_patient_list.birth_date": "Date:",
    "components.doctor.pages.associated_patient_list.birth_place": "Place:",
    "components.doctor.pages.associated_patient_list.view_detail_tooltip":
      "View details",
    "components.doctor.pages.associated_patient_list.missing_data": "N/A",
    "components.pages.homepage.doctor.welcome_message1": "We welcome you to your reserved area on SEF, the European healthcare mobility portal.",
    "components.pages.homepage.doctor.welcome_message2": "From here you can: ",
    "components.pages.homepage.doctor.welcome_message3": "plan, view and manage your cross-border healthcare activities, plan, view and manage your telemedicine activities, view the profile and medical records of your patients at any time.",
    "components.pages.homepage.doctor.welcome_message4": "For assistance or questions: ",
    "components.pages.homepage.doctor.message.title1": "Path of care",
    "components.pages.homepage.doctor.message.message1": "Here you can find the documents and forms",
    "components.pages.homepage.doctor.message.message1.a": "you need to plan your care in Europe",
    "components.pages.homepage.doctor.message.message1.b": "and share information about their health",
    "components.pages.homepage.doctor.message.message1.c": "and care path with your patient",
    "components.pages.homepage.doctor.message.title2": "Teleconsultation",
    "components.pages.homepage.doctor.message.message2": "You can also use the application to compare",
    "components.pages.homepage.doctor.message.message2.a": "yourself with other colleagues",
    "components.pages.homepage.doctor.message.title3": "Telemedicine",
    "components.pages.homepage.doctor.message.message3": "Enter here to manage the calendar of your availability ",
    "components.pages.homepage.doctor.message.message3.a": "and / or to start the scheduled television",
    "components.pages.homepage.dashboard": "Dashboard",
    "components.pages.homepage.patients": "Patient list",
    "components.pages.homepage.add_scheduled_file": "Add scheduled file",
    "components.pages.homepage.associate_doctor": "Associate doctor",
    "components.pages.homepage.cbha_download": "Download authorization",
    "components.pages.homepage.add_drg_request": "Add authorization Request",
    "components.pages.homepage.drg_requests": "My documents",
    "components.pages.homepage.complete_profile":
      "You must complete your profile before continue.",
    "components.pages.homepage.medical_history": "My Medical History",
    "components.pages.homepage.add_documents": "Add documents",
    "components.pages.homepage.telemedicine": "Telemedicine",
    "components.pages.homepage.telemedicine.patient.description1": "Enter to book a tele consultation",
    "components.pages.homepage.telemedicine.doctor.description1": "Enter to book a tele consultation",
    "components.pages.homepage.telemedicine.description2": "with one of our specialists",
    "components.pages.homepage.patientbook": "Patient book",
    "components.patient.pages.patientbook.title": "All Services",
    "components.patient.pages.patientbook.subtitle": "List of all services and related doctors",
    "components.patient.pages.patientbook.files": "Files",
    "components.patient.pages.patientbook.files.subtitle": "Files for each service",
    "components.patient.pages.patientbook.getthefiles": "Get the files",
    "components.patient.pages.patientbook.uploadedbypatient": "Uploaded by patient",
    "components.patient.pages.patientbook.uploadedbydoctor": "Uploaded by doctor",
    "components.patient.pages.patientbook.date": "Date",
    "components.pages.homepage.doctorconsult.teleconsultdashboard": "Teleconsult Dashboard",
    "components.pages.homepage.doctorconsult.teleconsultback": "Doctor Dashboard",
    "components.pages.homepage.doctorconsult.teleconsult": "Teleconsult",
    "components.pages.homepage.doctorconsult.teleconsultbook": "Teleconsult Files",
    "components.pages.homepage.care_path": "Care Path",
    "components.pages.homepage.care_path.description1": "Here you can find the documents and forms",
    "components.pages.homepage.care_path.description2": "you need to access treatment in Europe and",
    "components.pages.homepage.care_path.description3": "share information about your health and",
    "components.pages.homepage.care_path.description4": "care path with your doctor",
    "components.doctor.shared.doctor_patient_edit.diagnostic_indication":
      "Diagnostic indication",
    "components.doctor.shared.doctor_patient_edit.therapeutic_indication":
      "Therapeutic indication",
    "components.doctor.shared.doctor_patient_edit.urgency_motivation":
      "Urgency motivation",
    "components.doctor.shared.doctor_patient_edit.structure_data":
      "Structure data",
    "components.doctor.shared.doctor_patient_edit.save": "Save",
    "components.doctor.shared.doctor_patient_edit.add_file": "Add file",
    "components.doctor.shared.doctor_patient_edit.delete_file": "Delete file",
    "components.doctor.shared.doctor_patient_edit.download_file":
      "Download file",
    "components.doctor.shared.add_document_dialog.title":
      "Add file to medical history",
    "components.doctor.shared.add_document_dialog.text":
      "Insert description, document type and attach the document",
    "components.doctor.shared.add_document_dialog.description": "Description",
    "components.doctor.shared.add_document_dialog.document_type":
      "Document type",
    "components.doctor.shared.add_document_dialog.add_button": "Add",
    "components.homepage.welcome_message":
      "Welcome to SEF, the cross-border health portal that allows you to access treatment in any member state of the European community.",
    "components.homepage.welcome_message1":
      "We welcome you to your reserved area on SEF, the European healthcare mobility portal. From here you can access our telemedicine services and book a consultation with one of our specialists. Or you can access treatment paths in another member state of the European Union.",
    "components.homepage.further_information":
      "Further information on the website ",
    "components.homepage.further_information1":
      "To find out more, visit ",
    "components.patient.pages.doctor_patient_detail.informations":
      "Other informations:",
    "components.patient.pages.doctor_patient_detail.no_information":
      "No other informations",
    "components.patient.pages.doctor_patient_detail.doctor": "Doctor:",
    "components.patient.pages.doctor_patient_detail.missing_data": "N/A",
    "components.patient.pages.doctor_patient_detail.diagnostic_indication":
      "Diagnostic indication:",
    "components.patient.pages.doctor_patient_detail.therapeutic_indication":
      "Therapeutic indication:",
    "components.patient.pages.doctor_patient_detail.urgency_motivation":
      "Urgency motivation:",
    "components.patient.pages.doctor_patient_detail.structure_data":
      "Structure data:",
    "components.patient.pages.doctor_patient_detail.clinic_name": "Name:",
    "components.patient.pages.doctor_patient_detail.clinic_description":
      "Description:",
    "components.patient.pages.add_documents.title":
      "Add documents to your patient detail",
    "components.patient.pages.add_documents.list_title": "Files uploaded",
    "components.patient.pages.add_documents.doctor_label": "Choose the doctor",
    "components.patient.pages.add_documents.add_file": "Add file",
    "components.patient.pages.add_documents.no_documents": "No file present",
    "components.patient.pages.add_documents.download_file": "Download file",
    "components.patient.pages.add_document_dialog.title": "Add document",
    "components.patient.pages.add_document_dialog.text":
      "Insert description and attach the file",
    "components.patient.pages.add_document_dialog.description": "Description",
    "components.patient.pages.add_document_dialog.add_button": "Add",
    "components.patient.pages.telemedicine.title": "Telemedicine",
    "components.patient.pages.telemedicine.text":
      "Find the right doctor or specialist for your needs.",
    "components.patient.pages.telemedicine.prefer_doctor":
      "Prefer doctor",
    "components.patient.pages.telemedicine.preferred_doctor_list":
      "Preferred doctor list",
    "components.patient.pages.telemedicine.my_examinations_title":
      "My Examinations",
    "components.patient.pages.telemedicine.my_examinations_text":
      "A list of your requested, declined or accepted examinations",
    "components.patient.pages.telemedicine.search_by_specialization":
      "Search by specialization",
    "components.patient.pages.telemedicine.my_examinations_empty":
      "There are no examination requests.",
    "components.patient.pages.doctor_detail.back": "Back to list",
    "components.patient.pages.doctor_detail.ask_meeting": "Ask Meeting",
    "components.patient.pages.doctor_detail.medical_services":
      "Medical Services",
    "locale.male": "Male",
    "locale.female": "Female",
    "status.pending": "Pending",
    "status.rejected": "Rejected",
    "status.accepted": "Accepted",
    "status.draft": "Draft",
    "status.canceled": "Canceled",
    "status.confirmed": "Confirmed",
    "login.401": "Wrong email or password",
    "login.success": "Successful login",
    "logout.success": "User logged out",
    "logout.401": "Error! User unauthorized",
    "register.422": "Invalid field",
    "register.success": "Successful registration",
    "get_profile.401": "Wrong param",
    "get_profile.404": "Profile not found",
    "edit_profile.422": "Invalid field",
    "edit_profile.success": "Profile has been modified",
    "associate_doctor.success": "Association completed",
    "associate_doctor.404": "Searched doctor not found",
    "doctor_profiles.404": "Associated doctors not found",
    "drg_index.404": "Surgery not found",
    "drg_request_index.404": "Authorization requests not found",
    "drg_request_new.success": "Authorization request created successfully",
    "drg_request_new.422": "Invalid field",
    "drg_request_show.404": "This authorization request was not found",
    "cbha.404": "CBHA not found",
    "countries.404": "No countries found",
    "states.404": "No states found for this country",
    "cities.404": "No cities found for this state",
    "specializations.general_surgery": "General surgery",
    "specializations.pediatric_surgery": "Pediatric surgery",
    "specializations.plastic_surgey": "Plastic surgery",
    "specializations.reconstructive_surgey": "Reconstructive surgery",
    "specializations.cosmetic_surgey": "Cosmetic surgery",
    "specializations.gynecology": "Gynecology",
    "specializations.obstetrics": "Obstetrics",
    "specializations.neurosurgery": "Neurosurgery",
    "specializations.orthopedics": "Orthopedics",
    "specializations.traumatology": "Traumatology",
    "specializations.urology": "Urology",
    "specializations.maxillo_facial_surgery": "Maxillo-facial surgery",
    "specializations.ophthalmology": "Ophthalmology",
    "specializations.otolaryngology": "Otolaryngology",
    "specializations.cardiac_surgery": "Cardiac surgery",
    "specializations.thoracic_surgery": "Thoracic surgery",
    "specializations.vascular_surgery": "Vascular surgery",
    "edit_doctor_profile.success": "Doctor profile has been modified",
    "get_doctor_profile.404":
      "No doctor profile found associated with this user",
    "edit_doctor_profile.401": "You are not authorized to edit",
    "edit_doctor_profile.422": "Invalid field",
    "scheduled_file_index.404": "Files of a non existent doctor",
    "scheduled_file_add.422": "Invalid field",
    "scheduled_file_add.success": "File created",
    "clinic.404": "Clinics of a non existent doctor",
    "drg_request_schedule_add.success": "Schedule added",
    "drg_request_schedule_add.422": "Invalid field",
    "drg_request_schedule_edit.success": "Scheduled modified",
    "drg_request_schedule_edit.404": "Authorization not found",
    "drg_request_schedule_edit.422": "Invalid field",
    "drg_request_schedule_delete.success": "Authorization request deleted",
    "scheduled_file_confirm.success": "File confirmed",
    "scheduled_file_cancel.success": "File canceled",
    "scheduled_file_edit.success": "File has been modified",
    "scheduled_file_edit.401": "You are not authorized to edit",
    "scheduled_file_edit.422": "Invalid field",
    "associated_patients.404": "Patients not found",
    "edit_doctor_patient.success": "Updated Successfully",
    "get_doctor_patient.404": "Information not found",
    "doctor_patients_document_add.success": "File added",
    "doctor_patients_document_add.422": "Invalid field",
    "doctor_patients_document_delete.success": "Document deleted",
    "document_types_index.404": "Document type not found",
    "message.error": "Generic error",
    "message.success": "Success",
    "message.contact_support": "Contattare il supporto tecnico alla mail",
    "message.file_not_present": "Document not present",
    "message.dropzone.text":
      "Drag the document to upload or click to select a file",
    "message.dropzone.add_files": "Add file",
    "components.doctor.doctor_timetable_details_dialog.module_title":
      "Manage reservation",
    "components.doctor.doctor_timetable_details_dialog.module_text":
      "Accept or decline the patient's reservation:",
    "components.doctor.doctor_timetable_details_dialog.accept_button": "Accept",
    "components.doctor.doctor_timetable_details_dialog.decline_button":
      "Decline",
    "components.doctor.pages.telemedicine.title": "Telemedicine calendar",
    "components.doctor.pages.telemedicine.text":
      "Manage here your remote visits",
    "components.patient.telemedicine.module_title": "Ask meet",
    "components.patient.telemedicine.module_close": "Close",
    "components.patient.telemedicine.module_text":
      "Select one of the visiting time slot available",
    "components.patient.telemedicine.no_timetable_slot_text":
      "There are no available timetable slot for this doctor.",
    "components.patient.telemedicine.doctor_timetable_slot_label":
      "Select time slot",
    "components.patient.telemedicine.ask_meet_button": "Ask meet",
    "components.doctor.doctor_timetable_details_dialog.name": "Name",
    "components.doctor.doctor_timetable_details_dialog.fiscal_code":
      "Fiscal code",
    "components.doctor.doctor_timetable_details_dialog.email_address": "Email",
    "components.doctor.doctor_timetable_details_dialog.phone": "Phone",
    "components.patient.pages.examination.talk": "Chat to doctor",
    "components.patient.pages.examination.cancel": "Cancel reservation",
    "components.patient.pages.examination.back": "Back to list",
    "components.patient.pages.examination.meeting": "Go to meeting...",
    "components.patient.pages.examination.meeting_direct_link":
      "If you have installed the zoom client to use all features, click ",
    "components.patient.pages.examination.meeting_direct_link_label": "here",
    "components.patient.pages.examination.accepted_state":
      "The visit has been accepted",
    "components.patient.pages.examination.requested_state":
      "Wait the doctor to accept or decline",
    "components.patient.pages.examination.declined_state":
      "The examination has been declined",
    "components.patient.pages.examination.canceled_state":
      "The examination has been canceled",
    "components.patient.pages.examination.file_uploads_title":
      "Share Documents or Files",
    "components.patient.pages.examination.file_uploads_text":
      "Share documents or files for the examination to your doctor.",
    "components.patient.pages.examination.cancel_confimration.title":
      "Cancel examination?",
    "components.patient.pages.examination.cancel_confimration.text":
      "Are you sure you want to cancel examination?",
    "components.patient.pages.examination.paidbystripe": "Paid by stripe",
    "components.patient.pages.examination.paidbypaypal": "Paid by Paypal",
    "components.patient.pages.examination.paid": "Paid",
    "components.patient.pages.examination.unpaid": "Unpaid",
    "components.patient.pages.examination.amounttopay": "Amount to pay",
    "components.patient.pages.examination.paynow": "Pay now",
    "components.patient.pages.examination.paywithcreditcard": "Pay with Credit Card",
    "components.patient.pages.examination.payor": "Or",
    "components.patient.pages.examination.paywithpaypal": "Pay with Paypal",
    "components.doctor.pages.examination.talk": "Chat to patient",
    "components.doctor.pages.examination.cancel": "Cancel reservation",
    "components.doctor.pages.examination.back": "Back to planner",
    "components.doctor.pages.examination.meeting": "Go to meeting...",
    "components.doctor.pages.examination.meetingnotready": "Meeting not ready...",
    "components.doctor.pages.examination.meeting_direct_link":
      "If you have installed the zoom client to use all features, click ",
    "components.doctor.pages.examination.meeting_direct_link_label": "here",
    "components.doctor.pages.examination.zoom_health_meeting_direct_link":
      "If you have not yet installed the zoom client to use all features before started meeting, click ",
    "components.doctor.pages.examination.meddream_viewer_chrome_problem1":
      "If Chrome Browser Problem: ",
    "components.doctor.pages.examination.meddream_viewer_chrome_problem2":
      "Open a new tab(ctrl+t), paste this 'chrome://flags' and enter. ",
    "components.doctor.pages.examination.meddream_viewer_chrome_problem3":
      "search for 'same-site-by-default-cookies' and disable it.",
    "components.doctor.pages.examination.accepted_state":
      "The visit has been accepted",
    "components.doctor.pages.examination.requested_state":
      "Accept or decline examination.",
    "components.doctor.pages.examination.declined_state":
      "The examination has been declined",
    "components.doctor.pages.examination.canceled_state":
      "The examination has been canceled",
    "components.doctor.pages.examination.file_uploads_title":
      "Shared Documents or Files",
    "components.doctor.pages.examination.file_uploads_text":
      "Sahred documents or files by patient.",
    "components.doctor.pages.doctor_examination.file_uploads_text":
      "Shared documents or files by doctor to the patient",
    "components.doctor.pages.examination.cancel_confimration.title":
      "Cancel examination?",
    "components.doctor.pages.examination.cancel_confimration.text":
      "Are you sure you want to cancel examination?",
    "components.doctor.pages.examination.select.single.dicom.file1":
      "Please avoid duplicate upload",
    "components.doctor.pages.examination.select.single.dicom.file2":
      "Study should be unique to patient wise",
    "components.doctor.pages.examination.delete.all.dicom.file":
      "Delete all dicom studies",
    "components.doctor.pages.examination.delete.all.dicom.file.description":
      "All studies will be deleted. You wouldn't be able to retrieve the studies",
    "doctor_timetable.success": "Timetable updated.",
    "doctor_timetable_create.422": "Cannot create timetable.",
    "examination_create.422":
      "You can't create reservation. You have other reserver examination or the time slot is no more available.",
    "doctor_timetable_destroy.422": "Cannot delete this reservation",
    "components.doctor.doctor_timetable_details_dialog.medical_service":
      "Medical service",
    "create_examinations.success": "Examination requested successfully.",
    "create_examination_message.success": "Message sent!"
  },
  it: {
    "locale.display_date_format": "DD/MM/YYYY",
    "locale.display_datetime": "DD/MM/YYYY HH:mm",
    "locale.display_previous": "Indietro",
    "locale.display_next": "Avanti",
    "locale.display_today": "Oggi",
    "locale.display_month": "Mese",
    "locale.display_week": "Settimana",
    "locale.display_day": "Giorno",
    "locale.display_agenda": "Agenda",
    "locale.display_date": "Data",
    "locale.display_time": "Ora",
    "locale.display_event": "Evento",
    "components.notifications.login_success": "Login effettuato con successo",
    "components.notifications.logout_success": "L'utente non è più loggato",
    "components.notifications.register_success": "Registrazione effettuata",
    "components.notifications.edit_profile":
      "Hai modificato i dati del profilo",
    "components.pages.login.title": "Effettua l'accesso",
    "components.pages.login.email": "Email",
    "components.pages.login.password": "Password",
    "components.pages.login.log_in": "Accedi",
    "components.pages.login.remember_me": "Resta connesso",
    "components.pages.login.register": "Registrati",
    "components.pages.login.forgot_password": "Hai dimenticato la password?",
    "components.pages.registration.title": "Credenziali di accesso",
    "components.pages.registration.log_in": "Accedi",
    "components.pages.registration.email": "Email di registrazione",
    "components.pages.registration.password": "Password",
    "components.pages.registration.confirm_password": "Conferma password",
    "components.pages.registration.accept": "Accetta i nostri ",
    "components.pages.registration.terms_of_service": "termini di servizio",
    "components.pages.registration.password_complexity":
      "Utilizzare almeno: 1 maiuscola, 1 minuscola, 1 cifra e 1 carattere speciale",
    "components.pages.patient_dashboard.modal_title": "Associa dottore",
    "components.pages.patient_dashboard.modal_text":
      "Inserisci email o numero di telefono del dottore",
    "components.pages.patient_dashboard.modal_label":
      "Email o numero di telefono",
    "components.pages.patient_dashboard.modal_confirm": "Conferma",
    "components.pages.patient_dashboard.modal_close": "Chiudi",
    "components.drg_request_list.title": "Il mio percorso di cura",
    "components.drg_request_list.no_drg_request":
      "Nessuna autorizzazione associata a questo profilo",
    "components.drg_request_list.no_modules_sef":
      "SEF sta procedendo con la compilazione del tuo modulo.",
    "components.drg_request_list.no_modules_mail":
      "Riceverai una mail non appena sarà pronto.",
    "components.drg_request_list.drg_request_name": "Nome richiesta",
    "components.drg_request_list.drg_request_attachment": "Allegato",
    "components.drg_request_list.drg_request_status": "Stato",
    "components.drg_request_list.drg_request_description":
      "Richiesta #{requestId}",
    "components.drg_request_list.drg_request_view": "Dettagli",
    "components.drg_request_list.drg_request_doctor": "Dottore",
    "components.drg_request_list.drg_request_sent": "Inviata il",
    "components.drg_request_list.drg_request_drg": "Tipo intervento",
    "components.drg_request_list.drg_request_notes": "Note",
    "components.drg_request_list.drg_request_empty_notes": "Nessuna nota",
    "components.drg_request_list.drg_request_download":
      "Scarica accettazione richiesta",
    "components.drg_request_list.drg_request_scheduled":
      "Il tuo intervento si terrà il",
    "components.drg_request_list.drg_request_add": "Aggiungi",
    "components.drg_request_list.module_title":
      "Scarica il modulo di richiesta",
    "components.drg_request_list.module_text":
      "Il tuo modulo è pronto. Clicca su 'Download' per scaricarlo",
    "components.drg_request_list.module_download": "Download",
    "components.drg_request_list.module_close": "Chiudi",
    "components.drg_request_list.drg_request_documents":
      "Documenti della richiesta",
    "components.drg_request_list.drg_request_schedule_documents":
      "Allegati e documenti per il viaggio",
    "components.drg_request_list.request": "Richiesta",
    "components.drg_request_list.schedule": "Pianificazione",
    "components.drg_request_list.not_scheduled": "Non pianificata",
    "components.shared.edit_profile.title": "Dati del profilo",
    "components.shared.edit_profile.name": "Nome",
    "components.shared.edit_profile.surname": "Cognome",
    "components.shared.edit_profile.gender": "Sesso",
    "components.shared.edit_profile.male": "Maschio",
    "components.shared.edit_profile.female": "Femmina",
    "components.shared.edit_profile.membership_asl": "Asl di appartenenza",
    "components.shared.edit_profile.birth_date": "Data di nascita",
    "components.shared.edit_profile.cancel": "Annulla",
    "components.shared.edit_profile.clear": "Azzera",
    "components.shared.edit_profile.birth_country": "Nazione di nascita",
    "components.shared.edit_profile.birth_state": "Regione di nascita",
    "components.shared.edit_profile.birth_city": "Comune di nascita",
    "components.shared.edit_profile.fiscal_code": "Codice fiscale",
    "components.shared.edit_profile.residential_address":
      "Indirizzo di residenza",
    "components.shared.edit_profile.residential_postal_code":
      "CAP di residenza",
    "components.shared.edit_profile.residential_country":
      "Nazione di residenza",
    "components.shared.edit_profile.residential_state": "Regione di residenza",
    "components.shared.edit_profile.residential_city": "Città di residenza",
    "components.shared.edit_profile.residential_province":
      "Provincia di residenza",
    "components.shared.edit_profile.phone": "Numero di telefono",
    "components.shared.edit_profile.mobile_phone": "Numero di cellulare",
    "components.shared.edit_profile.email": "Email di contatto",
    "components.shared.edit_profile.specializations": "Specializzazioni",
    "components.shared.edit_profile.presentation": "Breve presentazione",
    "components.shared.edit_profile.save": "Salva",
    "components.shared.notifications_viewer.close": "Chiudi",
    "components.shared.layouts.base.navbar.home": "Home",
    "components.shared.layouts.base.navbar.dashboard": "Dashboard",
    "components.shared.layouts.base.navbar.dashboard.surgery": "Interventi",
    "components.shared.layouts.base.navbar.edit_profile": "Modifica Profilo",
    "components.shared.layouts.base.navbar.logout": "Logout",
    "components.shared.layouts.base.navbar.menu": "Menù",
    "components.shared.layouts.base.navbar.italian": "Italiano",
    "components.shared.layouts.base.navbar.english": "Inglese",
    "components.shared.document_list.document_type": "Tipo",
    "components.shared.document_list.description": "Descrizione",
    "components.shared.document_list.file_name": "Nome del file",
    "components.shared.document_list.download": "Scarica",
    "components.shared.document_list.no_documents":
      "Al momento non c'è nessun documento disponibile",
    "components.shared.message_list.message_text": "Testo del messaggio",
    "components.doctor.pages.examination.talk": "Parla con il paziente",
    "components.pages.drg_request_detail.drg": "Tipo intervento",
    "components.pages.drg_request_detail.doctor": "Dottore",
    "components.pages.drg_request_detail.drg_request":
      "Richiesta autorizzazione",
    "components.pages.drg_request_detail.message_label": "Messaggio:",
    "components.pages.drg_request_detail.module_label": "Modulo:",
    "components.pages.drg_request_detail.drg_title": "Titolo:",
    "components.pages.drg_request_detail.doctor_name": "Nome:",
    "components.pages.drg_request_detail.doctor_surname": "Cognome:",
    "components.pages.drg_request_detail.drg_code": "Codice:",
    "components.pages.drg_request_detail.drg_label": "Etichetta:",
    "components.pages.drg_request_detail.doctor_email_address":
      "Indirizzo email:",
    "components.pages.drg_request_detail.doctor_mobile_phone": "Cellulare:",
    "components.pages.add_drg_request.title": "Invia Richiesta Autorizzazione",
    "components.pages.add_drg_request.description": "Descrizione",
    "components.pages.add_drg_request.description_placeholder":
      "Inserisci una nota alla tua autorizzazione (facoltativo)",
    "components.pages.add_drg_request.doctor": "Scegli il dottore",
    "components.pages.add_drg_request.selected_drg":
      "Scegli la tipologia di intervento",
    "components.pages.add_drg_request.modules":
      "Carica il documento di approvazione",
    "components.pages.add_drg_request.patient_documents":
      "Carica altri documenti (es. Ricetta Medica)",
    "components.pages.add_drg_request.add": "Invia",
    "components.pages.add_drg_request.no_select":
      "Inserire tutti i dati richiesti",
    "components.pages.add_drg_request.wrong_file_type":
      "Inserire solo file pdf",
    "components.pages.add_drg_request.no_drg_alert":
      "Al momento non risulta essere presente nessun modulo di autorizzazione per il tuo luogo di residenza.",
    "components.pages.add_drg_request.doctor_patient_link":
      "Per visualizzare la scheda compilata dal dottore clicca qui",
    "components.pages.confirm_registration.title": "Ce l'hai quasi fatta!",
    "components.pages.confirm_registration.content":
      "Ti è stata appena inviata un'email all'indirizzo con cui ti sei registrato. Segui il link che vi è all'interno per confermare la tua email e poter accedere",
    "components.pages.confirm_registration.back_to_login": "Torna alla login",
    "components.doctor.shared.doctor_menu.label": "Menu Dottore",
    "components.doctor.shared.doctor_menu.associated_patients":
      "Pazienti associati",
    "components.patient.shared.patient_menu.label": "Menu Paziente",
    "components.patient.shared.patient_menu.associate_doctor":
      "Associa dottore",
    "components.patient.shared.patient_menu.telemedicine": "Telemedicina",
    "components.patient.shared.patient_menu.patientbook": "Fascicolo elettronico",
    "components.patient.shared.patient_menu.medical_history": "Anamnesi",
    "components.patient.pages.examination.paidbystripe": "Pagato a strisce",
    "components.patient.pages.examination.paidbypaypal": "Pagato da Paypal",
    "components.patient.pages.examination.paid": "Pagato",
    "components.patient.pages.examination.unpaid": "non pagato",
    "components.patient.pages.examination.amounttopay": "Importo da pagare",
    "components.patient.pages.examination.paynow": "Paga ora",
    "components.patient.pages.examination.paywithcreditcard": "Paga con carta",
    "components.patient.pages.examination.payor": "Oppure",
    "components.patient.pages.examination.paywithpaypal": "Paga con Paypal",
    "components.doctor.shared.scheduled_file_list.no_scheduled_file":
      "Nessuna pianificazione associata a questo profilo",
    "components.doctor.shared.scheduled_file_list.title":
      "Le mie pianificazioni",
    "components.doctor.shared.scheduled_file_list.label": "Etichetta",
    "components.doctor.shared.scheduled_file_list.clinic": "Clinica",
    "components.doctor.shared.scheduled_file_list.from": "Da",
    "components.doctor.shared.scheduled_file_list.to": "A",
    "components.doctor.shared.add_scheduled_file_dialog.from": "Da",
    "components.doctor.shared.add_scheduled_file_dialog.to": "A",
    "components.doctor.shared.scheduled_file_list.status": "Stato",
    "components.doctor.shared.scheduled_file_list.edit": "Modifica",
    "components.doctor.shared.scheduled_file_list.add": "Aggiungi",
    "components.doctor.shared.add_scheduled_file_dialog.module_title":
      "Aggiungi pianificazione",
    "components.doctor.shared.add_scheduled_file_dialog.module_close": "Chiudi",
    "components.doctor.shared.add_scheduled_file_dialog.module_text":
      "Compila i campi della pianificazione",
    "components.doctor.shared.add_scheduled_file_dialog.module_continue":
      "Aggiungi",
    "components.doctor.shared.add_scheduled_file_dialog.clinic_label":
      "Scegli una clinica",
    "components.doctor.shared.add_scheduled_file_dialog.label": "Etichetta",
    "components.doctor.new_drg_request_schedule_dialog.title":
      "Pianificazione degli interventi",
    "components.doctor.new_drg_request_schedule_dialog.close": "Chiudi",
    "components.doctor.new_drg_request_schedule_dialog.text":
      "Riempi i campi per la pianificazione degli interventi",
    "components.doctor.new_drg_request_schedule_dialog.create": "Pianifica",
    "components.doctor.new_drg_request_schedule_dialog.patient_data":
      "Dati del paziente",
    "components.doctor.drg_request_schedule_form.start_time":
      "Inizio operazione",
    "components.doctor.drg_request_schedule_form.duration": "Durata (minuti)",
    "components.doctor.edit_scheduled_file_dialog.module_title":
      "Modifica pianificazione",
    "components.doctor.edit_scheduled_file_dialog.module_close": "Chiudi",
    "components.doctor.edit_scheduled_file_dialog.module_text":
      "Compila i campi della pianificazione",
    "components.doctor.edit_scheduled_file_dialog.module_continue": "Modifica",
    "components.doctor.drg_request_schedule_form.drg":
      "Scegli il tipo di intervento",
    "components.doctor.drg_request_schedule_form.hospitalization_days":
      "Giorni di degenza",
    "components.doctor.drg_request_schedule_form.notes_label": "Annotazioni",
    "components.doctor.drg_request_schedule_form.notes_placeholder":
      "Inserire le annotazioni sulla pianificazione dell'intervento",
    "components.doctor.shared.implant_table.implants": "Protesi",
    "components.doctor.shared.implant_table.min": "Minimo",
    "components.doctor.shared.implant_table.max": "Massimo",
    "components.doctor.edit_drg_request_schedule_dialog.title":
      "Pianificazione intervento",
    "components.doctor.edit_drg_request_schedule_dialog.close": "Chiudi",
    "components.doctor.edit_drg_request_schedule_dialog.text":
      "Modifica i campi per la pianificazione dell'intervento",
    "components.doctor.edit_drg_request_schedule_dialog.update": "Pianifica",
    "components.doctor.edit_drg_request_schedule_dialog.delete": "Elimina",
    "components.doctor.edit_drg_request_schedule_dialog.confirm_delete":
      "Sei sicuro di voler cancellare la pianificazione di questo intervento?",
    "components.doctor.edit_scheduled_file.empty_request_list":
      "Non ci sono pazienti disponibili",
    "components.doctor.edit_scheduled_file.confirm_confirm":
      "Sei sicuro di voler confermare questa pianificazione?",
    "components.doctor.edit_scheduled_file.confirm_cancel":
      "Sei sicuro di voler annullare questa pianificazione?",
    "components.doctor.edit_scheduled_file.confirm": "Conferma",
    "components.doctor.edit_scheduled_file.cancel": "Annulla Pianificazione",
    "components.doctor.edit_scheduled_file.schedule": "Pianifica",
    "components.doctor.edit_scheduled_file.edit": "Modifica",
    "components.doctor.edit_scheduled_file.empty_label": "Pianificazione",
    "components.doctor.edit_scheduled_file.download_documents":
      "Scarica documenti",
    "components.doctor.event_view_dialog.start_time": "Inizio operazione",
    "components.doctor.event_view_dialog.duration": "Durata (minuti)",
    "components.doctor.event_view_dialog.close": "Chiudi",
    "components.doctor.event_view_dialog.hospitalization_days":
      "Giorni di degenza",
    "components.doctor.event_view_dialog.notes": "Annotazioni",
    "components.doctor.pages.doctor_dashboard.scheduled_files":
      "Le mie pianificazioni",
    "components.doctor.pages.doctor_dashboard.calendar": "Calendario",
    "components.doctor.pages.associated_patient_list.patient": "Paziente",
    "components.doctor.pages.associated_patient_list.fiscal_code":
      "Codice fiscale",
    "components.doctor.pages.associated_patient_list.gender": "Genere",
    "components.doctor.pages.associated_patient_list.membership_asl": "ASL",
    "components.doctor.pages.associated_patient_list.birth_data":
      "Dati di nascita",
    "components.doctor.pages.associated_patient_list.residential_place":
      "Residenza",
    "components.doctor.pages.associated_patient_list.contacs": "Contatti",
    "components.doctor.pages.associated_patient_list.email_address": "Email:",
    "components.doctor.pages.associated_patient_list.phone": "Telefono:",
    "components.doctor.pages.associated_patient_list.mobile_phone":
      "Cellulare:",
    "components.doctor.pages.associated_patient_list.birth_date": "Data:",
    "components.doctor.pages.associated_patient_list.birth_place": "Luogo:",
    "components.doctor.pages.associated_patient_list.view_detail_tooltip":
      "Visualizza dettagli",
    "components.doctor.pages.associated_patient_list.missing_data": "N/A",
    "components.pages.homepage.doctor.welcome_message1": "Ti diamo il benvenuto nella tua area riservata su SEF, il portale della mobilità sanitaria europea.",
    "components.pages.homepage.doctor.welcome_message2": "Da qui puoi: ",
    "components.pages.homepage.doctor.welcome_message3": "pianificare, visualizzare e gestire le tue attività di sanità transfrontaliera, pianificare, visualizzare e gestire le tue attività di telemedicina, visualizzare in qualsiasi momento il profilo e la cartella sanitaria dei tuoi pazienti.",
    "components.pages.homepage.doctor.welcome_message4": "Per assistenza o domande: ",
    "components.pages.homepage.doctor.message.title1": "Percorso di cura",
    "components.pages.homepage.doctor.message.message1": "Qui puoi trovare i documenti e moduli necessari",
    "components.pages.homepage.doctor.message.message1.a": "per pianificare le cure in Europa e condividere",
    "components.pages.homepage.doctor.message.message1.b": "con il tuo paziente le informazioni relative alla",
    "components.pages.homepage.doctor.message.message1.c": "sua salute e al suo percorso di cura",
    "components.pages.homepage.doctor.message.title2": "Teleconsulto",
    "components.pages.homepage.doctor.message.message2": "Puoi sfruttare l'applicativo anche per ",
    "components.pages.homepage.doctor.message.message2.a": "confrontarsi con altri colleghi",
    "components.pages.homepage.doctor.message.title3": "Telemedicina",
    "components.pages.homepage.doctor.message.message3": "Entra qui per gestire il calendario delle tue disponibilita",
    "components.pages.homepage.doctor.message.message3.a": "e/o per iniziare la televisita programmata",
    "components.pages.homepage.dashboard": "Dashboard",
    "components.pages.homepage.patients": "Lista pazienti",
    "components.pages.homepage.add_scheduled_file": "Aggiungi pianificazione",
    "components.pages.homepage.associate_doctor": "Associa dottore",
    "components.pages.homepage.cbha_download": "Scarica modulo autorizzazione",
    "components.pages.homepage.add_drg_request": "Aggiungi richiesta approvata",
    "components.pages.homepage.drg_requests": "Il mio percorso di cura",
    "components.pages.homepage.complete_profile":
      "Devi completare il tuo profilo prima di continuare.",
    "components.pages.homepage.medical_history": "La mia Anamnesi",
    "components.pages.homepage.add_documents": "Aggiungi documenti",
    "components.pages.homepage.telemedicine": "Telemedicina",
    "components.pages.homepage.telemedicine.patient.description1": "Entra per prenotare un televisita",
    "components.pages.homepage.telemedicine.doctor.description1": "Entra per prenotare un teleconsulto",
    "components.pages.homepage.telemedicine.description2": "con uno dei nostri specialisti",
    "components.pages.homepage.patientbook": "Fascicolo elettronico",
    "components.patient.pages.patientbook.title": "Tutti i servizi",
    "components.patient.pages.patientbook.subtitle": "Elenco di tutti i servizi e relativi medici",
    "components.patient.pages.patientbook.files": "Files",
    "components.patient.pages.patientbook.files.subtitle": "File per ogni servizio",
    "components.patient.pages.patientbook.getthefiles": "Ottieni i file",
    "components.patient.pages.patientbook.uploadedbypatient": "Caricato dal paziente",
    "components.patient.pages.patientbook.uploadedbydoctor": "Caricato dal dottore",
    "components.patient.pages.patientbook.date": "Data",
    "components.pages.homepage.doctorconsult.teleconsultdashboard": "Teleconsulto Pannello",
    "components.pages.homepage.doctorconsult.teleconsultback": "Dottore Pannello",
    "components.pages.homepage.doctorconsult.teleconsult": "Teleconsulto",
    "components.pages.homepage.doctorconsult.teleconsultbook": "Teleconsulto Fascicolo",
    "components.pages.homepage.care_path": "Percorso di cura",
    "components.pages.homepage.care_path.description1": "Qui puoi trovare i documenti e moduli necessari ",
    "components.pages.homepage.care_path.description2": "per accedere alle cure in Europa e condividere",
    "components.pages.homepage.care_path.description3": "con il tuo medico le informazioni relative alla",
    "components.pages.homepage.care_path.description4": "tua salute e al tuo percorso di cura",
    "components.doctor.shared.doctor_patient_edit.diagnostic_indication":
      "Indicazione diagnosi",
    "components.doctor.shared.doctor_patient_edit.therapeutic_indication":
      "Indicazione terapia",
    "components.doctor.shared.doctor_patient_edit.urgency_motivation":
      "Motivazione urgenza",
    "components.doctor.shared.doctor_patient_edit.structure_data":
      "Dati struttura",
    "components.doctor.shared.doctor_patient_edit.save": "Salva",
    "components.doctor.shared.doctor_patient_edit.add_file": "Aggiungi file",
    "components.doctor.shared.doctor_patient_edit.delete_file": "Elimina file",
    "components.doctor.shared.doctor_patient_edit.download_file":
      "Scarica file",
    "components.doctor.shared.add_document_dialog.title":
      "Aggiungi file alla anamnesi",
    "components.doctor.shared.add_document_dialog.text":
      "Inserisci decrizione, tipo del documento e allega il file",
    "components.doctor.shared.add_document_dialog.description": "Descrizione",
    "components.doctor.shared.add_document_dialog.document_type":
      "Tipo del documento",
    "components.doctor.shared.add_document_dialog.add_button": "Aggiungi",
    "components.homepage.welcome_message":
      "Benvenuti su SEF, il portale della sanità transfrontaliera che ti permette di accedere alle cure in qualsiasi stato membro della comunità europea.",
    "components.homepage.welcome_message1":
      "Ti diamo il benvenuto nella tua area riservata su SEF, il portale della mobilità sanitaria europea. Da qui puoi accedere ai nostri servizi di telemedicina e prenotare un consulto da parte di uno dei nostri specialisti. Oppure puoi accedere ai percorsi di cura in un altro Stato membro dell’Unione europea.",
    "components.homepage.further_information":
      "Ulteriori informazioni sul sito ",
    "components.homepage.further_information1":
      "Per saperne di più, consulta il sito ",
    "components.patient.pages.doctor_patient_detail.informations":
      "Informazioni aggiuntive:",
    "components.patient.pages.doctor_patient_detail.no_information":
      "Nessuna informazione aggiuntiva",
    "components.patient.pages.doctor_patient_detail.doctor": "Dottore:",
    "components.patient.pages.doctor_patient_detail.missing_data": "N/A",
    "components.patient.pages.doctor_patient_detail.diagnostic_indication":
      "Indicazione diagnostica:",
    "components.patient.pages.doctor_patient_detail.therapeutic_indication":
      "Indicazione terapeutica:",
    "components.patient.pages.doctor_patient_detail.urgency_motivation":
      "Motivazione urgenza:",
    "components.patient.pages.doctor_patient_detail.structure_data":
      "Dati struttura:",
    "components.patient.pages.doctor_patient_detail.clinic_name": "Nome:",
    "components.patient.pages.doctor_patient_detail.clinic_description":
      "Descrizione:",
    "components.patient.pages.add_documents.title":
      "Aggiungi documenti alla anamnesi",
    "components.patient.pages.add_documents.list_title": "File caricati",
    "components.patient.pages.add_documents.doctor_label": "Scegli il dottore",
    "components.patient.pages.add_documents.add_file": "Aggiungi file",
    "components.patient.pages.add_documents.no_documents":
      "Nessun file presente",
    "components.patient.pages.add_documents.download_file": "Download file",
    "components.patient.pages.add_document_dialog.title": "Aggiungi documento",
    "components.patient.pages.add_document_dialog.text":
      "Inserisci decrizione e allega il file",
    "components.patient.pages.add_document_dialog.description": "Descrizione",
    "components.patient.pages.add_document_dialog.add_button": "Aggiungi",
    "components.patient.pages.telemedicine.title": "Telemedicina",
    "components.patient.pages.telemedicine.text":
      "Trova il dottore o lo specialista da consultare.",
    "components.patient.pages.telemedicine.prefer_doctor":
      "Preferisco il dottore",
    "components.patient.pages.telemedicine.preferred_doctor_list":
      "Elenco dei medici preferiti",
    "components.patient.pages.telemedicine.my_examinations_title":
      "Le mie visite",
    "components.patient.pages.telemedicine.my_examinations_text":
      "Una lista delle tue visite richieste, accettate o rifiutate.",
    "components.patient.pages.telemedicine.search_by_specialization":
      "Ricerca per specializzazione",
    "components.patient.pages.telemedicine.my_examinations_empty":
      "Non sono presenti appuntamenti per visite.",
    "components.patient.pages.doctor_detail.back": "Torna alla lista",
    "components.patient.pages.doctor_detail.ask_meeting":
      "Richiedi appuntamento",
    "components.patient.pages.doctor_detail.medical_services": "Servizi Medici",
    "locale.male": "Maschio",
    "locale.female": "Femmina",
    "status.pending": "In attesa",
    "status.rejected": "Rifiutato",
    "status.accepted": "Accettato",
    "status.draft": "Bozza",
    "status.canceled": "Annullato",
    "status.confirmed": "Confermato",
    "login.401": "Email o password errate",
    "login.success": "Login effettuato con successo",
    "logout.success": "L'utente non è più loggato",
    "logout.401": "Errore! Utente non autorizzato",
    "register.422": "Campo non valido",
    "register.success": "Registrazione riuscita",
    "get_profile.401": "Parametro errato",
    "get_profile.404": "Nessun profilo trovato",
    "edit_profile.422": "Campo non valido",
    "edit_profile.success": "I dati del profilo sono stati modificati",
    "associate_doctor.success": "Associazione riuscita",
    "associate_doctor.404": "Dottore non trovato",
    "doctor_profiles.404": "Dottori associati non trovati",
    "drg_index.404": "DRG non trovate",
    "drg_request_index.404": "Richieste autorizzazione non trovate",
    "drg_request_new.success": "Autorizzazione alla cura creata correttamente",
    "drg_request_new.422": "Campo non valido",
    "drg_request_show.404":
      "Questa richiesta di autorizzazione non è stata trovata",
    "cbha.404": "CBHA non trovate",
    "countries.404": "Nessuna nazione trovata",
    "states.404": "Nessuno stato trovato per questa nazione",
    "cities.404": "Nessuna città trovata per questo stato",
    "specializations.general_surgery": "Chirurgia generale",
    "specializations.pediatric_surgery": "Chirurgia pediatrica",
    "specializations.plastic_surgey": "Chirurgia plastica",
    "specializations.reconstructive_surgey": "Chirurgia ricostruttiva",
    "specializations.cosmetic_surgey": "Chirurgia estetica",
    "specializations.gynecology": "Ginecologia",
    "specializations.obstetrics": "Ostetricia",
    "specializations.neurosurgery": "Neurochirurgia",
    "specializations.orthopedics": "Otropedia",
    "specializations.traumatology": "Traumatologia",
    "specializations.urology": "Urologia",
    "specializations.maxillo_facial_surgery": "Chirurgia maxillo-facciale",
    "specializations.ophthalmology": "Oftalmologia",
    "specializations.otolaryngology": "Otorinolaringoiatria",
    "specializations.cardiac_surgery": "Chirurgia cardiaca",
    "specializations.thoracic_surgery": "Chirurgia toracica",
    "specializations.vascular_surgery": "Chirurgia vascolare",
    "edit_doctor_profile.success":
      "I dati del profilo dottore sono stati modificati",
    "get_doctor_profile.404":
      "Nessun profilo dottore trovato associato a questo utente",
    "edit_doctor_profile.401": "Non sei autorizzato alla modifica",
    "edit_doctor_profile.422": "Campo non valido",
    "scheduled_file_index.404": "Pianificazioni del dottore non trovate",
    "scheduled_file_add.success": "Pianificazione creata",
    "scheduled_file_add.422": "Campo non valido",
    "scheduled_file_add.422.overlap": "Le date si sovrappongono",
    "clinic.404": "Cliniche del dottore non trovate",
    "drg_request_schedule_add.success": "Intervento pianificato",
    "drg_request_schedule_add.422": "Campo non valido",
    "drg_request_schedule_add.422.overlap": "Le date si sovrappongono",
    "drg_request_schedule_edit.success": "Pianificazione intervento modificata",
    "drg_request_schedule_edit.404": "Autorizzazione non trovata",
    "drg_request_schedule_edit.422": "Campo non valido",
    "drg_request_schedule_edit.422.overlap": "Le date si sovrappongono",
    "drg_request_schedule_delete.success": "Autorizzazione eliminata",
    "scheduled_file_confirm.success": "Pianificazione confermata",
    "scheduled_file_cancel.success": "Pianificazione annullata",
    "scheduled_file_edit.success": "Pianificazione modificata",
    "scheduled_file_edit.401": "Non sei autorizzato alla modifica",
    "scheduled_file_edit.422": "Campo non valido",
    "scheduled_file_edit.422.overlap": "Le date si sovrappongono",
    "associated_patients.404": "Pazienti non trovati",
    "edit_doctor_patient.success": "Aggiornato correttamente",
    "get_doctor_patient.404": "Informazioni non trovate",
    "doctor_patients_document_add.success": "File aggiunto",
    "doctor_patients_document_add.422": "Campo non valido",
    "doctor_patients_document_delete.success": "Documento eliminato",
    "document_types_index.404": "Tipi di documento non trovati",
    "message.error": "Errore generico",
    "message.success": "Successo",
    "message.contact_support": "Contattare il supporto tecnico alla mail",
    "message.file_not_present": "Documento non presente",
    "message.dropzone.text":
      "Trascina il documento da caricare o clicca per selezionare un file",
    "message.dropzone.add_files": "Aggiungi file",
    "components.doctor.doctor_timetable_details_dialog.module_title":
      "Gestisci prenotazione",
    "components.doctor.doctor_timetable_details_dialog.module_text":
      "Conferma o declina la prenotazione del paziente:",
    "components.doctor.doctor_timetable_details_dialog.accept_button":
      "Accetta",
    "components.doctor.doctor_timetable_details_dialog.decline_button":
      "Declina",
    "components.doctor.pages.telemedicine.title": "Calendario telemedicina",
    "components.doctor.pages.telemedicine.text":
      "Gestisci qua le tue visite a distanza",
    "components.patient.telemedicine.module_title": "Richiedi appuntamento",
    "components.patient.telemedicine.module_close": "Chiudi",
    "components.patient.telemedicine.module_text":
      "Selezione uno degli orari di visite messi a disposizione dal dottore",
    "components.patient.telemedicine.no_timetable_slot_text":
      "Non ci sono appuntamenti prenotabili per questo dottore.",
    "components.patient.telemedicine.doctor_timetable_slot_label":
      "Seleziona orario",
    "components.patient.telemedicine.ask_meet_button": "Richiedi appuntamento",
    "components.doctor.doctor_timetable_details_dialog.name": "Nome",
    "components.doctor.doctor_timetable_details_dialog.fiscal_code":
      "Codice fiscale",
    "components.doctor.doctor_timetable_details_dialog.email_address": "Email",
    "components.doctor.doctor_timetable_details_dialog.phone": "Telefono",
    "components.patient.pages.examination.cancel": "Annulla prenotazione",
    "components.patient.pages.examination.back": "Torna alla lista",
    "components.patient.pages.examination.meeting": "Vai al meeting...",
    "components.patient.pages.examination.meeting_direct_link":
      "Se hai installato il client zoom per utilizzare tutte le funzionalità, clicca ",
    "components.patient.pages.examination.meeting_direct_link_label": "qui",
    "components.patient.pages.examination.accepted_state":
      "La visita è stata accettata",
    "components.patient.pages.examination.requested_state":
      "Attendi che il dottore accetti la visita",
    "components.patient.pages.examination.declined_state":
      "La visita è stata rifiutata.",
    "components.patient.pages.examination.canceled_state":
      "La visita è stata annullata.",
    "components.patient.pages.examination.file_uploads_title":
      "Condividi documenti o file",
    "components.patient.pages.examination.file_uploads_text":
      "Condividi documenti o file necessario alla visita con il tuo medico.",
    "components.patient.pages.examination.cancel_confimration.title":
      "Annullare la visita?",
    "components.patient.pages.examination.cancel_confimration.text":
      "Sei sicuro di voler annullare la visita?",
    "components.patient.pages.examination.talk": "Parla con il dottore",
    "components.doctor.pages.examination.cancel": "Annulla prenotazione",
    "components.doctor.pages.examination.back": "Torna al calendario",
    "components.doctor.pages.examination.meeting": "Vai al meeting...",
    "components.doctor.pages.examination.meetingnotready": "Incontro non pronto...",
    "components.doctor.pages.examination.meeting_direct_link":
      "Se hai installato il client zoom per utilizzare tutte le funzionalità, clicca ",
    "components.doctor.pages.examination.meeting_direct_link_label": "qui",
    "components.doctor.pages.examination.zoom_health_meeting_direct_link":
    "Se non hai ancora installato il client zoom per utilizzare tutte le funzionalità prima di iniziare la riunione, clicca ",
    "components.doctor.pages.examination.meddream_viewer_chrome_problem1":
      "Se hai problemi con il browser Chrome: ",
    "components.doctor.pages.examination.meddream_viewer_chrome_problem2":
      "Apri una nuova scheda (ctrl+t), copia ed incolla questo testo nella barra indirizzo: chrome://flags. ",
    "components.doctor.pages.examination.meddream_viewer_chrome_problem3":
      'Cerca la voce "same-site-by-default-cookies" e disabilitali.',
    "components.doctor.pages.examination.accepted_state":
      "La visita è stata accettata",
    "components.doctor.pages.examination.requested_state":
      "Accetta o rifiuta la richiesta di visita",
    "components.doctor.pages.examination.declined_state":
      "La visita è stata rifiutata.",
    "components.doctor.pages.examination.canceled_state":
      "La visita è stata annullata.",
    "components.doctor.pages.examination.file_uploads_title":
      "Documenti o file condivisi",
    "components.doctor.pages.examination.file_uploads_text":
      "Il paziente ha condiviso questi documenti e file.",
    "components.doctor.pages.doctor_examination.file_uploads_text":
      "Documenti o file condivisi dal medico al paziente",
    "components.doctor.pages.examination.cancel_confimration.title":
      "Annullare la visita?",
    "components.doctor.pages.examination.cancel_confimration.text":
      "Sei sicuro di voler annullare la visita?",
    "components.doctor.pages.examination.select.single.dicom.file1":
      "Per favore, evita il caricamento duplicato",
    "components.doctor.pages.examination.select.single.dicom.file2":
      "Lo studio dovrebbe essere unico per il paziente",
    "components.doctor.pages.examination.delete.all.dicom.file":
      "Elimina tutti gli studi dicom",
    "components.doctor.pages.examination.delete.all.dicom.file.description":
      "Tutti gli studi verranno eliminati. Non saresti in grado di recuperare gli studi",
    "doctor_timetable.success": "Pianificazione aggiornata.",
    "doctor_timetable_create.422": "Non è possibile creare l'appuntamento.",
    "examination_create.422":
      "Non puoi richiedere questo appuntamento. Hai già altre prenotazioni oppure l'appuntamento è già stato riservato.",
    "doctor_timetable_destroy.422":
      "Non è possibile eliminare questo appuntamento",
    "components.doctor.doctor_timetable_details_dialog.medical_service":
      "Tipo visita",
    "create_examinations.success": "Visita richiesta con successo.",
    "create_examination_message.success": "Messaggio inviato con successo."
  }
};
