import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import { ListAlt, HeadsetMic } from "@material-ui/icons";

const DoctorMenu = (props, { t }) => {
  const actionDispatch = useDispatch();
  return (
    <>
      {/*<Divider />*/}
      <List>
        {/*<ListItemText
          inset
          primaryTypographyProps={{
            variant: "overline"
          }}
        >
          {t("components.doctor.shared.doctor_menu.label")}
        </ListItemText>*/}
        <ListItem button component={Link} to={"/patients"}>
          <ListItemIcon>
            <ListAlt />
          </ListItemIcon>
          <ListItemText>
            {t("components.doctor.shared.doctor_menu.associated_patients")}
          </ListItemText>
        </ListItem>

        <ListItem button component={Link} to={"/planner_telemedicine"}>
          <ListItemIcon>
            <HeadsetMic />
          </ListItemIcon>

          <ListItemText>
            {t("components.patient.shared.patient_menu.telemedicine")}
          </ListItemText>
        </ListItem>

      </List>
    </>
  );
};

DoctorMenu.contextTypes = {
  t: PropTypes.func
};

export default DoctorMenu;
