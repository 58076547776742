import axios from "./AxiosConfig";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const DoctorPatientSource = {
  get: id =>
    api(
      axios.get(`${apiBaseUrl}/doctor_patients/${id}.json`),
      "get_doctor_patient"
    ),
  edit: (id, doctor_patient) =>
    api(
      axios.put(`${apiBaseUrl}/doctor_patients/${id}.json`, { doctor_patient }),
      "edit_doctor_patient"
    )
};

export default DoctorPatientSource;
