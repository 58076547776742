import { cloneDeep } from "lodash";

import {
  SCHEDULED_FILE_DOCUMENT_INDEX,
  DRG_REQUEST_SCHEDULE_DOCUMENT_INDEX,
  DRG_REQUEST_DOCUMENT_INDEX,
  DOCUMENT_INDEX_SUCCESS,
  DOCUMENT_INDEX_ERROR,
  DOCTOR_PATIENTS_DOCUMENT_INDEX,
  EXAMINATION_DOCUMENT_INDEX,
  ADD_DOCTOR_PATIENTS_DOCUMENT,
  ADD_DOCTOR_PATIENTS_DOCUMENT_SUCCESS,
  ADD_DOCTOR_PATIENTS_DOCUMENT_ERROR,
  DELETE_DOCTOR_PATIENTS_DOCUMENT_SUCCESS,
  DELETE_DOCTOR_PATIENTS_DOCUMENT_ERROR,
  ADD_EXAMINATION_DOCUMENT_SUCCESS,
  ADD_EXAMINATION_DOCUMENT_ERROR,
  DELETE_EXAMINATION_DOCUMENT_SUCCESS,
  DELETE_EXAMINATION_DOCUMENT_ERROR
} from "../actions/const";

const initialState = {
  loading: false,
  list: [],
  errors: []
};

function reducer(state = initialState, action) {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case SCHEDULED_FILE_DOCUMENT_INDEX:
    case DRG_REQUEST_SCHEDULE_DOCUMENT_INDEX:
    case DRG_REQUEST_DOCUMENT_INDEX:
    case DOCTOR_PATIENTS_DOCUMENT_INDEX:
    case EXAMINATION_DOCUMENT_INDEX:
    case ADD_DOCTOR_PATIENTS_DOCUMENT: {
      //nextState.list = [];
      nextState.loading = true;
      return nextState;
    }
    case DOCUMENT_INDEX_SUCCESS: {
      nextState.loading = false;
      nextState.list = action.documents;
      return nextState;
    }
    case DOCUMENT_INDEX_ERROR: {
      nextState.loading = false;
      nextState.list = [];
      return nextState;
    }
    case ADD_DOCTOR_PATIENTS_DOCUMENT_SUCCESS: {
      nextState.loading = false;
      nextState.errors = [];
      return nextState;
    }
    case ADD_DOCTOR_PATIENTS_DOCUMENT_ERROR: {
      nextState.loading = false;
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    case DELETE_DOCTOR_PATIENTS_DOCUMENT_SUCCESS: {
      nextState.errors = [];
      return nextState;
    }
    case DELETE_DOCTOR_PATIENTS_DOCUMENT_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    case ADD_EXAMINATION_DOCUMENT_SUCCESS: {
      nextState.loading = false;
      nextState.errors = [];
      return nextState;
    }
    case ADD_EXAMINATION_DOCUMENT_ERROR: {
      nextState.loading = false;
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    case DELETE_EXAMINATION_DOCUMENT_SUCCESS: {
      nextState.errors = [];
      return nextState;
    }
    case DELETE_EXAMINATION_DOCUMENT_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    default: {
      return state;
    }
  }
}

export default reducer;
