import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  Divider
} from "@material-ui/core";
import {
  ImageAspectRatioOutlined
} from "@material-ui/icons";
import config from "./../../../config";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  green: { backgroundColor: "green", width: 96, height: 96 },
  orange: { backgroundColor: "orange", width: 96, height: 96 },
  red: { backgroundColor: "red", width: 96, height: 96 },
  icon: { fontSize: "4rem" },
  textkarla: { fontFamily: "karla"},
  documentDataItems: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },
  documentItemsWidth: {
    float:'left'
  },
  documentItemsGap: {
    fontFamily: "karla",
    padding: '20px',
    marginTop: '20px',
    border: '1px solid #dddddd',
    borderRound: '5px'
  }
}));

const useEnhancer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  
  return {
    id
  };
};


//FUNCTION COMPONENT START//
const PatientBook = (props, { t }) => {
  const {
    id,
  } = useEnhancer();
  const classes = useStyles();
  const [documentData, setDocumentData] = useState([]);
  const [documentData1, setDocumentData1] = useState('');
  const [documentData2, setDocumentData2] = useState([]);
  const user = useSelector(state => state.user.current);
  
  useEffect(() => {
    console.log("THis is ytesy ");
    window
      .fetch(`${config.apiBaseUrl}/patient_book.json`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ patientId: user.patientId })
      }).then(res => {
        // console.log(res.json());
        return res.json();
      })
      .then(data => {
        setDocumentData(data.status);        
      });
  }, []);

  useEffect(() => {
    if(documentData1 != '') {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/patient_book_file.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ examinationId: documentData1})
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data) {
            setDocumentData2(data.data); 
          }       
        });
    }
  }, [documentData1]);

  const documentDataItems = documentData.map((docData, index) =>
    <li key={index} className={classes.documentItemsGap} alignItems="center">
      <large className={classes.documentItemsWidth} style={{width: '22%'}}>
        {t("components.patient.pages.patientbook.date")}: {docData[3]}
      </large>
      <large className={classes.documentItemsWidth} style={{width: '28%'}}>{docData[1]}</large>
      <large className={classes.documentItemsWidth} style={{width: '28%'}}>{docData[2]}</large>
      <large  alignItems="center" style={{width: '22%'}}>
        <Button
          className={classes.documentItemsWidth}
          alignItems="center"
          onClick={() => (setDocumentData1(docData[0]))}
        >
          <ImageAspectRatioOutlined />
          &nbsp;
          {t("components.patient.pages.patientbook.getthefiles")}
        </Button> 
      </large>
      <div style={{clear:'both'}}></div>
    </li>
  );

  const documentDataItems1 = documentData2.map((docData1, index) => {
    return (
      <li 
        key={index} 
        className={classes.textkarla} 
        alignItems="center" 
        className={classes.documentDataItems}
        style={{marginTop:'20px', paddingBottom: '5px', marginBottom: '5px'}} 
      >
          <a 
            href={docData1['attributes']['url']} 
            download
            style={{textDecoration: 'none'}}
          >
            {docData1['attributes']['file_name']}
          </a><br />

          <large>
            { docData1['attributes']['patient_profile_id'] !== null ? t("components.patient.pages.patientbook.uploadedbypatient") : ""}
          </large>

          <large>
            { docData1['attributes']['doctor_profile_id'] !== null ? t("components.patient.pages.patientbook.uploadedbydoctor") : ""}
          </large>
      </li>
    )
  });

  return (
    <>
      <div>
        <div style={{width: '75%', float: 'left', paddingRight: '1%'}}>
          <Grid>
            <Typography variant="h5" gutterBottom>
              {t("components.patient.pages.patientbook.title")}
            </Typography>
            <large 
              className={classes.textkarla} 
              gutterBottom 
              style={{fontSize: '1rem'}}>
              {t("components.patient.pages.patientbook.subtitle")}
            </large>
            <br />
          </Grid>
          <Divider />

          {
            <ul className={classes.documentDataItems}>{documentDataItems}</ul>
          }
        </div>

        <div style={{width: '24%', float: 'right'}}>
          <Grid>
            <Typography variant="h5" gutterBottom>
              {t("components.patient.pages.patientbook.files")}
            </Typography>
            <large className={classes.textkarla} gutterBottom style={{fontSize: '1rem'}}>
              {t("components.patient.pages.patientbook.files.subtitle")}
            </large>
            <br />
          </Grid>
          <Divider />

          {
            <ul className={classes.documentDataItems}>{documentDataItems1}</ul>
          }
        </div>
        <div style={{clear:'both', paddingBottom: "40px"}}></div>
      </div>
    </>
  );
};

PatientBook.contextTypes = {
  t: PropTypes.func
};

export default PatientBook;
