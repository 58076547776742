import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Grid,
  Chip,
  Divider,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
  Button
} from "@material-ui/core";
import {
  Person,
  LocalHospitalOutlined,
  Check,
  Close,
  Reply,
  Star,
} from "@material-ui/icons";
import { spacing } from '@material-ui/system';
import { makeStyles } from "@material-ui/core/styles";
import Carousel from 'react-material-ui-carousel'
import { useDispatch, useSelector } from "react-redux";
import { getDoctorProfiles, getExaminations } from "../../../actions";
import { upperCase, isEmpty, set } from "lodash";
import ApiJsonNavigator from "../../../sources/libs/ApiJsonNavigator";
import moment from "moment";
import config from "./../../../config";

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.75)
  },
  green: { backgroundColor: "green" },
  orange: { backgroundColor: "orange" },
  red: { backgroundColor: "red" },
  textkarla: { fontFamily: "karla"},
  FormControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop:  theme.spacing(2),
  },
  starPrefer: {
    fontSize: 25,
    color: "#FFA600"
  },
  starNonPrefer: {
    fontSize: 25,
    color: '#9B9B9B'
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Telemedicine = (props, context) => {
  const { t } = context;
  const classes = useStyles();
  const actionsDispatch = useDispatch();

  const doctor_profiles = useSelector(state => state.doctor.profiles);
  const examinations = useSelector(state =>
    state.examination.list instanceof ApiJsonNavigator
      ? state.examination.list.data()
      : []
  );

  useEffect(() => {
    actionsDispatch(getDoctorProfiles());
    actionsDispatch(
      getExaminations({
        "q[sorts]": "deleted_doctor_timetable_start_at_desc"
        //"q[deleted_doctor_timetable_end_at_gt]": moment().toDate()
      })
    );
  }, [actionsDispatch]);


  const patientId = useSelector(state => state.user.current.patientId);
  const [postProcessDoctorTeleconsultDoctorEmail, setPostProcessDoctorTeleconsultDoctorEmail] = useState(0);
  const [postProcessDoctorTeleconsultDoctorId, setPostProcessDoctorTeleconsultDoctorId] = useState(0);

  useEffect(() => {
    if(patientId) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/post_process_doctor_teleconsult.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ patientId: patientId })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.status1 && data.status3){
            setPostProcessDoctorTeleconsultDoctorEmail(data.status3)
            setPostProcessDoctorTeleconsultDoctorId(data.status1)
          } else{
            console.log(data.status)
          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [patientId]);
  
  const [selectAll, setSelectAll] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [starPreferLoadStatus, setStarPreferLoadStatus] = useState([]);
  const [starPreferDoctor, setStarPreferDoctor] = useState(0);
  const [starPreferOtherDoctorId, setStarPreferOtherDoctorId] = useState("");
  const [starPreferSelfDoctorId, setStarPreferSelfDoctorId] = useState("");

  let uniqueSpecializations = []
  doctor_profiles.map(profile => (
    (profile.specializations || []).map((ps, index) => (
      uniqueSpecializations.push(ps)
    ))
  ))
  uniqueSpecializations = [...new Set(uniqueSpecializations)];

  const handleSelectAll = (event) => {
    if(selectAll){
      setSelectAll("")
      setSpecialization("")
      setStarPreferLoadStatus([])
      setStarPreferDoctor(0)
    }else{
      setSelectAll(1)
      setSpecialization("")
      setStarPreferLoadStatus([])
      setStarPreferDoctor(0)
    }
  };

  const handleSpecializationChange = (event) => {
    setSpecialization(event.target.value);
    setSelectAll("");
  };

  const handleStarPreferDoctor = (otherDoctorId, selfDoctorId) => (event) => {
    event.preventDefault()
    setStarPreferDoctor(1);
    setStarPreferOtherDoctorId(otherDoctorId);
    setStarPreferSelfDoctorId(selfDoctorId);
  };

  useEffect(() => {
    console.log("THis is ytesy ");
    window
      .fetch(`${config.apiBaseUrl}/star_prefer_doctor_teleconsult_load_status.json`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
          selfDoctorId: postProcessDoctorTeleconsultDoctorId
        })
      }).then(res => {
        // console.log(res.json());
        return res.json();
      })
      .then(data => {
        if(data.status){
          setStarPreferLoadStatus(data.status);
          setStarPreferDoctor(0);
        } else{
          setStarPreferLoadStatus([])
        }
        //console.log(data.zoomHealthConMsg);
      });
  }, [selectAll, starPreferDoctor, postProcessDoctorTeleconsultDoctorId]);

  useEffect(() => {
    if(starPreferDoctor) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/star_prefer_doctor_teleconsult_set_status.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ 
            otherDoctorId: starPreferOtherDoctorId,
            selfDoctorId: starPreferSelfDoctorId
          })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.status){
            setStarPreferDoctor(0);
          } else{

          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [starPreferDoctor]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Typography variant="h5" gutterBottom>
          {
            postProcessDoctorTeleconsultDoctorEmail 
            ? t("components.pages.homepage.doctorconsult.teleconsult")
            : t("components.patient.pages.telemedicine.title")
          }
        </Typography>
        <Typography className={classes.textkarla} variant="body1" gutterBottom>
          {t("components.patient.pages.telemedicine.text")}
        </Typography>

        <Divider />

        {
          postProcessDoctorTeleconsultDoctorEmail
          ?
            <>
              <Chip
                size="small"
                icon={<LocalHospitalOutlined />}
                label={"Select All"}
                color="primary"
                className={classes.chip}
                onClick={handleSelectAll}
              />


              {specialization &&
                <List>
                  {doctor_profiles
                  .sort((a,b) => (a.surname > b.surname ) ? 1: -1)
                  .map(profile => (
                    (profile.specializations.indexOf(specialization) !== -1) && (
                      postProcessDoctorTeleconsultDoctorId !== profile.id
                      ?
                        <ListItem
                          key={profile.id}
                          button
                          component="a"
                          href={`#/doctor_detail/${profile.id}`}
                        >
                          <ListItemAvatar>
                            <Avatar
                              alt={`${profile.name} ${profile.surname}`}
                              src={profile.avatar_url}
                            >
                              <Person />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="textPrimary"
                                >
                                  <strong>{`${profile.name} ${profile.surname}`}</strong>
                                </Typography>
                                &nbsp;
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {profile.specializations
                                    .map(s => upperCase(s))
                                    .join(", ")}
                                </Typography>
                                {isEmpty(profile.residentialCity) ? null : (
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    &nbsp;-&nbsp;
                                    {profile.residentialCity}
                                  </Typography>
                                )}
                              </>
                            }
                            secondary={
                              <>
                                {(profile.medical_services || []).map((ms, index) => (
                                  <Chip
                                    key={index}
                                    variant="outlined"
                                    size="small"
                                    icon={<LocalHospitalOutlined />}
                                    label={ms.title}
                                    color="primary"
                                    className={classes.chip}
                                  />
                                ))}

                                <Chip
                                  size="small"
                                  icon={//Checking if this prefer doctor and self doctor are found in the array
                                      starPreferLoadStatus.
                                      filter(x => x.prefer_doctor_id == profile.id && 
                                        x.doctor_profile_id == postProcessDoctorTeleconsultDoctorId).
                                      length > 0
                                      ?
                                        <Star className={classes.starPrefer}/>
                                      : <Star className={classes.starNonPrefer}/>
                                
                                  }
                                  label={"Prefer doctor"}
                                  color="primary"
                                  className={classes.chip}
                                  onClick={handleStarPreferDoctor(profile.id, postProcessDoctorTeleconsultDoctorId)}
                                />
                              </>
                            }
                          />
                        </ListItem>
                      : ''
                    )
                  ))}
                </List>
              }

              {selectAll &&
                <List>
                  {doctor_profiles
                  .sort((a,b) => (a.surname > b.surname ) ? 1: -1)
                  .map(profile => (
                    postProcessDoctorTeleconsultDoctorId !== profile.id
                    ?
                      <ListItem
                        key={profile.id}
                        button
                        component="a"
                        href={`#/doctor_detail/${profile.id}`}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt={`${profile.name} ${profile.surname}`}
                            src={profile.avatar_url}
                          >
                            <Person />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                component="span"
                                variant="body1"
                                color="textPrimary"
                              >
                                <strong>{`${profile.name} ${profile.surname}`}</strong>
                              </Typography>
                              &nbsp;
                              <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                              >
                                {profile.specializations
                                  .map(s => upperCase(s))
                                  .join(", ")}
                              </Typography>
                              {isEmpty(profile.residentialCity) ? null : (
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  &nbsp;-&nbsp;
                                  {profile.residentialCity}
                                </Typography>
                              )}
                            </>
                          }
                          secondary={
                            <Typography>
                              {(profile.medical_services || []).map((ms, index) => (
                                <Chip
                                  key={index}
                                  variant="outlined"
                                  size="small"
                                  icon={<LocalHospitalOutlined />}
                                  label={ms.title}
                                  color="primary"
                                  className={classes.chip}
                                />
                              ))}
                                  
                              <Chip
                                size="small"
                                variant="outlined"
                                icon={//Checking if this prefer doctor and self doctor are found in the array
                                    starPreferLoadStatus.
                                    filter(x => x.prefer_doctor_id == profile.id && 
                                      x.doctor_profile_id == postProcessDoctorTeleconsultDoctorId).
                                    length > 0
                                    ?
                                      <Star className={classes.starPrefer}/>
                                    : <Star className={classes.starNonPrefer}/>
                              
                                }
                                label={t("components.patient.pages.telemedicine.prefer_doctor")}
                          
                                className={classes.chip}
                                onClick={handleStarPreferDoctor(profile.id, postProcessDoctorTeleconsultDoctorId)}
                              />
                            </Typography>
                          }
                        />
                      </ListItem>
                    : ''
                  ))}
                </List>
              }
            </>
          :
            <List>
              {doctor_profiles.map(profile => (
                postProcessDoctorTeleconsultDoctorId !== profile.id
                ?
                <ListItem
                  key={profile.id}
                  button
                  component="a"
                  href={`#/doctor_detail/${profile.id}`}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`${profile.name} ${profile.surname}`}
                      src={profile.avatar_url}
                    >
                      <Person />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div>
                        <Typography
                          component="span"
                          variant="body1"
                          color="textPrimary"
                        >
                          <strong>{`${profile.name} ${profile.surname}`}</strong>
                        </Typography>
                        &nbsp;
                        <Typography
                          component="span"
                          variant="body2"
                          color="textSecondary"
                        >
                          {profile.specializations
                            .map(s => upperCase(s))
                            .join(", ")}
                        </Typography>
                        {isEmpty(profile.residentialCity) ? null : (
                          <Typography
                            component="span"
                            variant="body2"
                            color="textSecondary"
                          >
                            &nbsp;-&nbsp;
                            {profile.residentialCity}
                          </Typography>
                        )}
                      </div>
                    }
                    secondary={
                      <>
                        {(profile.medical_services || []).map((ms, index) => (
                          <Chip
                            key={index}
                            variant="outlined"
                            size="small"
                            icon={<LocalHospitalOutlined />}
                            label={ms.title}
                            color="primary"
                            className={classes.chip}
                          />
                        ))}
                      </>
                    }
                  />
                </ListItem>
                : ''
              ))}
            </List>
        }
      </Grid>
      
      <Grid item xs={12} md={4}>
        <Typography variant="h5" gutterBottom>
          {t("components.patient.pages.telemedicine.my_examinations_title")}
        </Typography>

        <Typography className={classes.textkarla} variant="body1" gutterBottom>
          {t("components.patient.pages.telemedicine.my_examinations_text")}
        </Typography>

        <Divider />
        
        {
          postProcessDoctorTeleconsultDoctorEmail
          ?
            <FormControl className={classes.FormControl}>
              <InputLabel id="doctor-specialization-label">
                {t("components.patient.pages.telemedicine.search_by_specialization")}
              </InputLabel>
              <Select
                id="doctor-specialization"
                value={specialization}
                onChange={handleSpecializationChange}
              >
                {
                  /*doctor_profiles.map(profile => (
                    (profile.specializations || []).map((ps, index) => (
                      <MenuItem value={ps}>{ps}</MenuItem>
                    ))
                  ))*/
                  
                  (uniqueSpecializations || []).map((ps, index) => (
                    <MenuItem key={index} value={ps}>{ps}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          : ""
        }
        
        <List>
          {isEmpty(examinations) ? (
            <ListItem>
              <ListItemText
                primary={t(
                  "components.patient.pages.telemedicine.my_examinations_empty"
                )}
              />
            </ListItem>
          ) : (
            examinations
            .sort((a,b) => (a.doctor_timetable().start_at < b.doctor_timetable().start_at ) ? 1: -1)
            .map(e => {
              const { name, surname } = e.doctor_timetable().doctor_profile
                ? e
                    .doctor_timetable()
                    .doctor_profile()
                    .attributes()
                : { name: "", surname: "" };
              const { start_at, end_at } = e.doctor_timetable();
              const medicalServiceTitle = e.medical_service().title || "";

              let icon = (
                <Avatar className={classes.red}>
                  <Close />
                </Avatar>
              );
              switch (e.aasm_state) {
                case "requested":
                  icon = (
                    <Avatar className={classes.orange}>
                      <Reply />
                    </Avatar>
                  );
                  break;
                case "accepted":
                  icon = (
                    <Avatar className={classes.green}>
                      <Check />
                    </Avatar>
                  );
                  break;
                default:
                  icon = (
                    <Avatar className={classes.red}>
                      <Close />
                    </Avatar>
                  );
                  break;
              }

              return (
                <ListItem
                  key={e.id}
                  button
                  component="a"
                  href={`#/patient/examinations/${e.id}`}
                >
                  <ListItemAvatar>{icon}</ListItemAvatar>
                  <ListItemText
                    primary={
                      <strong>{`${moment(start_at).format(
                        "DD/MM/YYYY HH:mm"
                      )} - ${moment(end_at).format("HH:mm")}`}</strong>
                    }
                    secondary={
                      <large className={classes.textkarla}>{`${name} ${surname} - ${medicalServiceTitle}`.trim()}</large>
                    }
                  />
                </ListItem>
              );
            })
          )}
        </List>
      </Grid>

      {
        postProcessDoctorTeleconsultDoctorEmail
        ?
          <Grid 
            item xs={12} 
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Typography variant="h5" gutterBottom>
              {
                t("components.patient.pages.telemedicine.preferred_doctor_list")
              }
              <Divider />
            </Typography>
            
            <List>
              {doctor_profiles
              .sort((a,b) => (a.surname > b.surname ) ? 1: -1)
              .map(profile => (
                postProcessDoctorTeleconsultDoctorId !== profile.id
                ?
                  (starPreferLoadStatus || []).map((spd, index) => (
                    spd.prefer_doctor_id == profile.id &&
                    spd.doctor_profile_id == postProcessDoctorTeleconsultDoctorId
                    ?
                      <ListItem
                        key={index}
                        button
                        component="a"
                        href={`#/doctor_detail/${profile.id}`}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt={`${profile.name} ${profile.surname}`}
                            src={profile.avatar_url}
                          >
                            <Person />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                component="span"
                                variant="body1"
                                color="textPrimary"
                              >
                                <strong>{`${profile.name} ${profile.surname}`}</strong>
                              </Typography>
                              &nbsp;
                              <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                              >
                                {profile.specializations
                                  .map(s => upperCase(s))
                                  .join(", ")}
                              </Typography>
                              {isEmpty(profile.residentialCity) ? null : (
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  &nbsp;-&nbsp;
                                  {profile.residentialCity}
                                </Typography>
                              )}
                            </>
                          }
                          secondary={
                            <Typography>
                              <Chip
                                size="small"
                                variant="outlined"
                                icon={<Star className={classes.starPrefer}/>}
                                label={t("components.patient.pages.telemedicine.prefer_doctor")}
                                className={classes.chip}
                                onClick={handleStarPreferDoctor(profile.id, postProcessDoctorTeleconsultDoctorId)}
                              />
                            </Typography>
                          }
                        />
                      </ListItem>
                    : ''
                  ))
                : ''
              ))}
            </List>
          </Grid>
        : ''
      }
    </Grid>
  );
};

Telemedicine.contextTypes = {
  t: PropTypes.func
};

export default Telemedicine;
