import { map, get, snakeCase, uniqBy, last, split, forEach } from "lodash";

const codeMap = key => {
  switch (key) {
    case "overlaps_with_another_record_for_the_same_doctor_and_clinic":
    case "overlaps_with_another_record":
      return ".overlap";
    default:
      return "";
  }
};

class ErrorHandler {
  constructor(error, key) {
    this.messages = uniqBy(
      map(get(error, "response.data.errors", []), item => ({
        key: `${key}.${item.status}${codeMap(snakeCase(item.detail))}`
      })),
      "key"
    );

    this.errorsPointers = map(get(error, "response.data.errors", []), err =>
      last(split(last(split(get(err, "source.pointer", ""), "/")), "."))
    );
  }

  dispatchErrors = callback => {
    forEach(this.messages, message => callback(message));
  };
}

export default ErrorHandler;
