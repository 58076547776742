import {
  DRG_REQUEST_SCHEDULE_INDEX,
  DRG_REQUEST_SCHEDULE_INDEX_SUCCESS,
  DRG_REQUEST_SCHEDULE_INDEX_ERROR
} from "./const";
import {
  ADD_DRG_REQUEST_SCHEDULE,
  ADD_DRG_REQUEST_SCHEDULE_SUCCESS,
  ADD_DRG_REQUEST_SCHEDULE_ERROR
} from "./const";
import {
  GET_DRG_REQUEST_SCHEDULE,
  GET_DRG_REQUEST_SCHEDULE_SUCCESS,
  GET_DRG_REQUEST_SCHEDULE_ERROR
} from "./const";
import {
  EDIT_DRG_REQUEST_SCHEDULE,
  EDIT_DRG_REQUEST_SCHEDULE_SUCCESS,
  EDIT_DRG_REQUEST_SCHEDULE_ERROR
} from "./const";
import {
  DELETE_DRG_REQUEST_SCHEDULE,
  DELETE_DRG_REQUEST_SCHEDULE_SUCCESS,
  DELETE_DRG_REQUEST_SCHEDULE_ERROR
} from "./const";
import { RESET_DRG_REQUEST_SCHEDULE_ERRORS } from "./const";

export const drgRequestScheduleIndex = scheduleFileId => ({
  type: DRG_REQUEST_SCHEDULE_INDEX,
  scheduleFileId
});
export const drgRequestScheduleIndexSuccess = data => ({
  type: DRG_REQUEST_SCHEDULE_INDEX_SUCCESS,
  data
});
export const drgRequestScheduleIndexError = errors => ({
  type: DRG_REQUEST_SCHEDULE_INDEX_ERROR,
  errors
});

export const addDrgRequestSchedule = (
  scheduleFileId,
  drgRequestSchedule,
  successCallback
) => ({
  type: ADD_DRG_REQUEST_SCHEDULE,
  scheduleFileId,
  drgRequestSchedule,
  successCallback
});
export const addDrgRequestScheduleSuccess = data => ({
  type: ADD_DRG_REQUEST_SCHEDULE_SUCCESS,
  data
});
export const addDrgRequestScheduleError = errors => ({
  type: ADD_DRG_REQUEST_SCHEDULE_ERROR,
  errors
});

export const getDrgRequestSchedule = id => ({
  type: GET_DRG_REQUEST_SCHEDULE,
  id
});
export const getDrgRequestScheduleSuccess = drgRequestSchedule => ({
  type: GET_DRG_REQUEST_SCHEDULE_SUCCESS,
  drgRequestSchedule
});
export const getDrgRequestScheduleError = errors => ({
  type: GET_DRG_REQUEST_SCHEDULE_ERROR,
  errors
});

export const editDrgRequestSchedule = (
  id,
  drgRequestSchedule,
  successCallback
) => ({
  type: EDIT_DRG_REQUEST_SCHEDULE,
  id,
  drgRequestSchedule,
  successCallback
});
export const editDrgRequestScheduleSuccess = data => ({
  type: EDIT_DRG_REQUEST_SCHEDULE_SUCCESS,
  data
});
export const editDrgRequestScheduleError = errors => ({
  type: EDIT_DRG_REQUEST_SCHEDULE_ERROR,
  errors
});

export const deleteDrgRequestSchedule = (id, successCallback) => ({
  type: DELETE_DRG_REQUEST_SCHEDULE,
  id,
  successCallback
});
export const deleteDrgRequestScheduleSuccess = data => ({
  type: DELETE_DRG_REQUEST_SCHEDULE_SUCCESS,
  data
});
export const deleteDrgRequestScheduleError = errors => ({
  type: DELETE_DRG_REQUEST_SCHEDULE_ERROR,
  errors
});

export const resetDrgRequestScheduleErrors = () => ({
  type: RESET_DRG_REQUEST_SCHEDULE_ERRORS
});
