import axios from "./AxiosConfig";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const doctorTimetableSource = {
  index: (doctorId, from, to) =>
    api(
      axios.get(
        `${apiBaseUrl}/doctor_profiles/${doctorId}/doctor_timetables.json`,
        {
          params: {
            'q[start_at_gteq]': from,
            'q[start_at_lteq]': to
          }
        }
      ),
      "doctor_timetable_index"
    ),
  create: (doctorId, doctorTimetable) =>
    api(
      axios.post(
        `${apiBaseUrl}/doctor_profiles/${doctorId}/doctor_timetables.json`,
        doctorTimetable
      ),
      "doctor_timetable_create"
    ),
  destroy: (id) =>
    api(
      axios.delete(
        `${apiBaseUrl}/doctor_timetables/${id}.json`
      ),
      "doctor_timetable_destroy"
    ),
};

export default doctorTimetableSource;
