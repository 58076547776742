import React, { useEffect, useState } from "react";
import { useInterval } from "./../../../hooks/useInterval";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty, filter, reject } from "lodash";
import ApiJsonNavigator from "../../../sources/libs/ApiJsonNavigator";
import {
  Avatar,
  Box,
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Tabs,
  Tab,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  GridListTile,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {
  AttachMoney,
  Check,
  Close,
  Reply,
  Undo,
  Phone,
  Email,
  PhoneAndroid,
  HeadsetMic,
  ImageAspectRatioOutlined,
  Delete,
  AttachFile
} from "@material-ui/icons";
import ListIcon from '@material-ui/icons/List';
import {
  addExaminationDocument,
  cancelExamination,
  deleteExaminationDocument,
  examinationDocumentIndex,
  getExamination
} from "../../../actions";
import FileUploader from "../../shared/FileUploader";
import FileList from "../../shared/FileList";
import ConfirmDialog from "./../../shared/ConfirmDialog";
import MessageList from "../../shared/MessageList";
import store from "./../../../stores";
import config from "./../../../config";

const useStyles = makeStyles(theme => ({
  green: { backgroundColor: "green", width: 96, height: 96 },
  orange: { backgroundColor: "orange", width: 96, height: 96 },
  red: { backgroundColor: "red", width: 96, height: 96 },
  icon: { fontSize: "4rem" },
  textkarla: { fontFamily: "karla"},
  fontSize: { fontSize: "medium"}
}));

const useEnhancer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [fileTabValue, setFileTabValue] = useState(0);

  //MedDreamDicomViewer
  //const [medDreamDicomViewer, setMedDreamDicomViewer] = useState(0);
  const [medDreamDicomViewerDeleteAllStudies, setMedDreamDicomViewerDeleteAllStudies] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(dispatch(getExamination(id)));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(examinationDocumentIndex(id));
  }, [dispatch, id]);

  const examination = useSelector(state => {
    return state.examination.current instanceof ApiJsonNavigator
      ? state.examination.current.data()
      : state.examination.current;
  });

  useInterval(async () => {
    dispatch(getExamination(id));
    dispatch(examinationDocumentIndex(id));
  }, 3000);

  return {
    id,
    loading: useSelector(
      state => state.examination.loading || state.document.loading
    ),
    examination: examination,
    documents: useSelector(state =>
      state.document.list.map(({ id, url, file_name, mime_type }) => ({
        id,
        url,
        filename: file_name,
        mime_type
      }))
    ),
    onCancel: () => {
      setConfirmDialog(true);
    },
    onCancelConfirmed: () => {
      dispatch(cancelExamination(id, () => dispatch(getExamination(id))));
    },
    openExaminationDicom: () => {
      const token = store.getState().user.token;
      document.cookie = `token=${token};domain=${config.cookieDomain};path=/`;
      window.open(`${config.backofficeUrl}/examinations/${id}/dicom`, '_blank');
    },
    openExaminationZoomMeeting: () => {
      const token = store.getState().user.token;
      document.cookie = `token=${token};domain=${config.cookieDomain};path=/`;
      window.open(`${config.backofficeUrl}/examinations/${id}/zoom_meeting`, '_blank');
    },
    onFileUpload: ({ file }) => {
      dispatch(
        addExaminationDocument(
          id,
          examination.doctor_timetable().doctor_profile().id,
          null,
          examination.medical_service().title,
          null,
          file,
          () => dispatch(examinationDocumentIndex(id))
        )
      );
    },
    onFileDelete: ({ fileId }) => {
      dispatch(
        deleteExaminationDocument(id, fileId, () =>
          dispatch(examinationDocumentIndex(id))
        )
      );
    },
    //MedDreamDicomViewer
    openMedDreamDicomViewer: () => {
      //setMedDreamDicomViewer(1);
      const token = store.getState().user.token;
      document.cookie = `token=${token};domain=${config.cookieDomain};path=/`;
      window.open(`${config.backofficeUrl}/examinations/${id}/meddream_dicom`, '_blank');
    },

    openMedDreamDicomViewerAllStudies: () => {
      setOpen(true);
    },

    deleteMedDreamDicomViewerAllStudies: () => {
      setOpen(false);
      setMedDreamDicomViewerDeleteAllStudies(1);
    },

    cancelMedDreamDicomViewerAllStudies: () => {
      setOpen(false);
      setMedDreamDicomViewerDeleteAllStudies(0);
    },

    confirmDialog,
    setConfirmDialog,
    fileTabValue,
    setFileTabValue,
    //medDreamDicomViewer,
    //setMedDreamDicomViewer,
    open,
    setOpen,
    medDreamDicomViewerDeleteAllStudies,
    setMedDreamDicomViewerDeleteAllStudies,
  };
};

const Examination = (_, { t }) => {
  const {
    id,
    examination,
    documents,
    onCancel,
    onCancelConfirmed,
    openMedDreamDicomViewer,
    onFileUpload,
    onFileDelete,
    openExaminationDicom,
    confirmDialog,
    setConfirmDialog,
    fileTabValue,
    setFileTabValue,
    //medDreamDicomViewer,
    //setMedDreamDicomViewer,
    openMedDreamDicomViewerAllStudies,
    deleteMedDreamDicomViewerAllStudies,
    cancelMedDreamDicomViewerAllStudies,
    open, 
    setOpen,
    medDreamDicomViewerDeleteAllStudies,
    setMedDreamDicomViewerDeleteAllStudies,
  } = useEnhancer();
  const classes = useStyles();

  const [stripePreCheck, setStripePreCheck] = useState('');
  const [zoomHealth, setZoomHealth] = useState(0);

  let icon = (
    <Avatar className={classes.red}>
      <Close />
    </Avatar>
  );

  useEffect(() => {
    console.log("THis is ytesy ");
    window
      .fetch(`${config.apiBaseUrl}/payment_intent_before.json`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ examinationId: id })
      }).then(res => {
        // console.log(res.json());
        return res.json();
      })
      .then(data => {
        //console.log(data);
        setStripePreCheck(data.status);
      });
  }, [id]);

  useEffect(() => {
    if(zoomHealth) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/zoom_health.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ examinationId: id })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if((data.status == 'NoUserCreated') || (data.status == 'NoMeetingCreated')){
            setZoomHealth(0)
          }else{
            window.open(`${data.meetingStartUrl}`, '_blank')
            setZoomHealth(0)
          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [zoomHealth]);

  useEffect(() => {
    /*if(medDreamDicomViewer) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/meddream_dicom_viewer.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            examinationId: id,
            patientId: examination.patient_profile().id,
           doctorId: examination.doctor_timetable().doctor_profile().id
          })
        }).then(res => {
          //console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.dicomStoreWithInstancesResponseCode == 200){
            //const token = store.getState().user.token;
            //document.cookie = `token=${token};domain=${config.cookieDomain};path=/`
            //window.open(`${config.backofficeUrl}/examinations/${id}/meddream_dicom`, '_blank')
            //window.open(`${data.meddreamUrl}`, '_blank')
            setMedDreamDicomViewer(0)
          }else{
            setMedDreamDicomViewer(0)
          }
          console.log(data.zoomHealthConMsg);
        });
    }*/

    if(medDreamDicomViewerDeleteAllStudies) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/meddream_dicom_viewer_delete_all_studies.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            examinationId: id,
            patientId: examination.patient_profile().id,
            doctorId: examination.doctor_timetable().doctor_profile().id
          })
        }).then(res => {
          //console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.studiesDeleted){
            console.log("Deleted");
            setMedDreamDicomViewerDeleteAllStudies(0)
          }else{
            console.log("Error!");
            setMedDreamDicomViewerDeleteAllStudies(0)
          }
        });
    }
  }, [/*medDreamDicomViewer, */medDreamDicomViewerDeleteAllStudies]);

  const [postProcessDoctorTeleconsultDoctorEmail, setPostProcessDoctorTeleconsultDoctorEmail] = useState(0);
  const [postProcessDoctorTeleconsultPatientEmail, setPostProcessDoctorTeleconsultPatientEmail] = useState(0);

  useEffect(() => {
    console.log("THis is ytesy ");
    window
      .fetch(`${config.apiBaseUrl}/post_process_doctor_teleconsult.json`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ examinationId: id})
      }).then(res => {
        // console.log(res.json());
        return res.json();
      })
      .then(data => {
        if(data.status3 && data.status4){
          setPostProcessDoctorTeleconsultDoctorEmail(data.status3)
          setPostProcessDoctorTeleconsultPatientEmail(data.status4)
        } else{
          console.log(data.status)
        }
        //console.log(data.zoomHealthConMsg);
      });
  }, [id]);

  
  switch (examination.aasm_state) {
    case "requested":
      icon = (
        <Avatar className={classes.orange}>
          <Reply className={classes.icon} />
        </Avatar>
      );
      break;
    case "accepted":
      icon = (
        postProcessDoctorTeleconsultPatientEmail == examination.patient_profile().email_address
        ?
          <Avatar className={classes.green}>
            <Check className={classes.icon} />
          </Avatar>
        :
          <Avatar 
            className={
              stripePreCheck === 'succeeded' 
              ? classes.green
              : classes.orange
            }
          >
            {
              stripePreCheck === 'succeeded'
              ? <Check className={classes.icon} />
              : <AttachMoney className={classes.icon} />
            }
          </Avatar>
      );
      break;
    default:
      icon = (
        <Avatar className={classes.red}>
          <Close className={classes.icon} />
        </Avatar>
      );
      break;
  }

  if (isEmpty(examination)) return <LinearProgress />;

  //console.log(documents)

  return (
    <>
      <Grid container spacing={4}>
        <Grid item sm={12} md={4} lg={3}>
          <Box display="flex" justifyContent="center">
            <Tooltip
              placement="top"
              title={t(
                `components.doctor.pages.examination.${examination.aasm_state}_state`
              )}
            >
              {icon}
            </Tooltip>
          </Box>
          <br />
          <Typography variant="body1" align="center" gutterBottom>
            {t(
              `components.doctor.pages.examination.${examination.aasm_state}_state`
            )}
          </Typography>
          <br />
          {examination.aasm_state === "accepted" ? (
            <>
              {/*<Button
                variant="contained"
                fullWidth
                onClick={openExaminationZoomMeeting}
              >
                <HeadsetMic />
                &nbsp;
                {t("components.doctor.pages.examination.meeting")}
              </Button>

              <Typography variant="body2">
                <small className={classes.textkarla}>
                  {t("components.doctor.pages.examination.meeting_direct_link")}
                  <a target="_blank" href={examination.zoom_meeting.start_url}>{t("components.doctor.pages.examination.meeting_direct_link_label")}</a>
                </small>
              </Typography>
              */}
              
              {/*zoomHealth*/}
              {/*zoomHealthStartUrl*/}

              <Button
                variant="contained"
                fullWidth
                onClick={event => setZoomHealth(1)}
              >
                <HeadsetMic />
                &nbsp;
                {t("components.doctor.pages.examination.meeting")}
              </Button>

              <Typography variant="body2">
                <small className={classes.textkarla}>
                  {t("components.doctor.pages.examination.zoom_health_meeting_direct_link")}
                  <a target="_blank" href="https://zoom.us/download">{t("components.doctor.pages.examination.meeting_direct_link_label")}</a>
                </small>
              </Typography>
              &nbsp;
            </>
          ) : null}
          {/*<Button
            variant="contained"
            fullWidth
            onClick={openExaminationDicom}
            >
            <ImageAspectRatioOutlined />
            &nbsp;
            Dicom
          </Button>*/}
          
          { `${filter(documents, { mime_type: "application/dicom" }).length}` > 0 && 
            <Button
              variant="contained"
              fullWidth
              onClick={openMedDreamDicomViewer}
              >
              <ImageAspectRatioOutlined />
              &nbsp;
              MedDream Dicom
            </Button>
          }

          { `${filter(documents, { mime_type: "application/dicom" }).length}` > 0 && 
            <Typography variant="body2">
              <small className={classes.textkarla}>
                <strong>{t("components.doctor.pages.examination.meddream_viewer_chrome_problem1")}</strong>
              </small>
              <small className={classes.textkarla}>
                {t("components.doctor.pages.examination.meddream_viewer_chrome_problem2")}
                {t("components.doctor.pages.examination.meddream_viewer_chrome_problem3")}
              </small>
            </Typography>
          }

          &nbsp;
          <Button
            variant="contained"
            fullWidth
            onClick={onCancel}
            disabled={
              examination.aasm_state == "canceled" ||
              examination.aasm_state == "declined"
            }
          >
            <Close />
            &nbsp;
            {t("components.doctor.pages.examination.cancel")}
          </Button>
          &nbsp;
          
          <Button variant="contained" fullWidth href="#/planner_telemedicine">
            <Undo />
            &nbsp;
            {t("components.doctor.pages.examination.back")}
          </Button>
        </Grid>
        <Grid item sm={12} md={8} lg={9}>
          <Grid>
            <Typography variant="h5" gutterBottom>
              {`${moment(examination.doctor_timetable().start_at).format(
                "DD/MM/YYYY HH:mm"
              )} - ${moment(examination.doctor_timetable().end_at).format(
                "HH:mm"
              )}`}
            </Typography>
            <Typography variant="h4" gutterBottom>
              {/*stripePreCheck*/}
              {examination.medical_service().title}
              <br />
              <small>{/*examination.medical_service.price*/}</small>
              {
                (postProcessDoctorTeleconsultPatientEmail !== examination.patient_profile().email_address) && (
                  stripePreCheck === "succeeded"
                  ?
                    <small>
                      {t("components.patient.pages.examination.paid")}: {examination.medical_service().price}
                    </small>
                  :
                    <small>
                      {t("components.patient.pages.examination.unpaid")}: {examination.medical_service().price}
                    </small>
                )
              }
            </Typography>
            <Divider />
            <br />
          </Grid>
          <Grid>
            <Typography variant="h5" gutterBottom>
              <strong>
                {`${examination.patient_profile().name} ${
                  examination.patient_profile().surname
                }`}
              </strong>
            </Typography>
            <List aria-label="contacts">
              {isEmpty(examination.patient_profile().phone) ? null : (
                <ListItem>
                  <ListItemIcon>
                    <Phone />
                  </ListItemIcon>
                  <ListItemText primary={<span className={classes.textkarla}>{examination.patient_profile().phone}</span>} />
                </ListItem>
              )}
              {isEmpty(examination.patient_profile().mobile_phone) ? null : (
                <ListItem>
                  <ListItemIcon>
                    <PhoneAndroid />
                  </ListItemIcon>
                  <ListItemText
                    primary={<span className={classes.textkarla}>{examination.patient_profile().mobile_phone}
                  </span>} />
                </ListItem>
              )}
              {
                postProcessDoctorTeleconsultPatientEmail == examination.patient_profile().email_address 
                ? 
                  <ListItem>
                  <ListItemIcon>
                    <Email />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <span className={classes.textkarla}>
                        {postProcessDoctorTeleconsultDoctorEmail}
                      </span>
                    } 
                  />
                </ListItem>
                :
                  isEmpty(examination.patient_profile().email_address) ? null : (
                    <ListItem>
                      <ListItemIcon>
                        <Email />
                      </ListItemIcon>
                      <ListItemText
                        primary={<span className={classes.textkarla}>{examination.patient_profile().email_address}
                      </span>} />
                    </ListItem>
                  )
              }
            </List>
          </Grid>
          <br />
          <Grid>
            <Typography variant="h5" gutterBottom>
              <strong>
                {t("components.doctor.pages.examination.file_uploads_title")}
              </strong>
            </Typography>
            <Divider />
            {/*
            
            <Typography variant="body2" gutterBottom>
              {t("components.doctor.pages.examination.file_uploads_text")}
            </Typography>
            <br />
            */}
            <br />
            {/*
            <Tabs 
              value={fileTabValue} 
              onChange={(event, newValue) => setFileTabValue(newValue)}
              indicatorColor="primary"
              textColor="primary">
              
              <Tab label={`Files (${reject(documents, {mime_type: "application/dicom"}).length})`} />
              <Tab label={`Dicom (${filter(documents, {mime_type: "application/dicom"}).length})`} />
              
            </Tabs>
            */}
            {/*
            <div hidden={fileTabValue !== 0}>
              <FileList files={reject(documents, {mime_type: "application/dicom"})} />
            </div>
            <div hidden={fileTabValue !== 1}>
              <FileList files={filter(documents, {mime_type: "application/dicom"})} />
            </div>
            */}
          </Grid>



          <Grid>
            {
            /*<Divider />
            <br />
            */}
            <Typography className={classes.textkarla} variant="body2" gutterBottom>
              {t("components.doctor.pages.doctor_examination.file_uploads_text")}
            </Typography>
            <br />
            <FileUploader onFileUpload={onFileUpload} />

            <Tabs
              value={fileTabValue}
              onChange={(event, newValue) => setFileTabValue(newValue)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label={`Files (${reject(documents, { mime_type: "application/dicom" }).length
                  })`}
              />
              <Tab
                label={`Dicom (${filter(documents, { mime_type: "application/dicom" }).length
                  })`}
              />
            </Tabs>

            <div hidden={fileTabValue !== 0}>
              <FileList
                files={reject(documents, { mime_type: "application/dicom" })}
                //setCheckDoctorDoc={setCheckDoctorDoc}
                showDelete
                onFileDelete={onFileDelete}
              />
            </div>
            <div hidden={fileTabValue !== 1}>
              <Grid 
                justify="space-between"
                container 
                spacing={12}
              >
                <Grid item>
                  <Typography className={classes.textkarla} align="left">
                    *{t("components.doctor.pages.examination.select.single.dicom.file1")}<br />
                    *{t("components.doctor.pages.examination.select.single.dicom.file2")}
                  </Typography>
                </Grid>

                <Grid item>
                  {
                    isEmpty(filter(documents, { mime_type: "application/dicom" }))
                    ? ""
                    :
                    <Typography align="right">
                      {/*{t("components.doctor.pages.examination.delete.all.dicom.file")} 
                      <Delete 
                        onClick={deleteMedDreamDicomViewerAllStudies}
                      />*/}

                      <Button variant="outlined" color="primary" onClick={openMedDreamDicomViewerAllStudies}>
                        {t("components.doctor.pages.examination.delete.all.dicom.file")} 
                        <Delete />
                      </Button>
                      <Dialog
                        open={open}
                        onClose={cancelMedDreamDicomViewerAllStudies}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">{t("components.doctor.pages.examination.delete.all.dicom.file")}?</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {t("components.doctor.pages.examination.delete.all.dicom.file.description")}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={cancelMedDreamDicomViewerAllStudies} color="primary">
                            {t("components.shared.edit_profile.cancel")}
                          </Button>
                          <Button onClick={deleteMedDreamDicomViewerAllStudies} color="primary" autoFocus>
                            {t("components.pages.add_drg_request.add")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Typography>
                  }
                </Grid>
              </Grid>
              
              <br />
              <Divider />
              <FileList
                files={filter(documents, { mime_type: "application/dicom" })}
                //setCheckDoctorDoc={setCheckDoctorDoc}
                showDelete
                onFileDelete={onFileDelete}
              />
            </div>
          </Grid> 



          <br />
          <Grid>
            <Typography variant="h5" gutterBottom>
              <strong>{t("components.doctor.pages.examination.talk")}</strong>
            </Typography>
            <Divider />
            <br />
            {isEmpty(examination) || (
              <MessageList
                doctorProfileId={
                  examination.doctor_timetable().doctor_profile().id
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        title={t(
          "components.doctor.pages.examination.cancel_confimration.title"
        )}
        open={confirmDialog}
        setOpen={setConfirmDialog}
        onConfirm={onCancelConfirmed}
      >
        {t("components.doctor.pages.examination.cancel_confimration.text")}
      </ConfirmDialog>
    </>
  );
};

Examination.contextTypes = {
  t: PropTypes.func
};

export default Examination;
