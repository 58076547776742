import React, { useEffect, useState, useContext } from "react";
import { TeleconsultContext } from "../../context/TeleconsultContext";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  ListAlt,
  Dashboard,
  Add,
  PersonAdd,
  VerticalAlignBottom as Download,
  Edit as EditIcon,
  NoteAdd,
  HeadsetMic,
  SettingsBackupRestore
} from "@material-ui/icons";
import { capitalize, isEmpty, upperCase } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { push } from "connected-react-router";

import HomeButtons from "../shared/HomeButtons";
import HomeButtonsNew from "../shared/HomeButtonsNew";
import { associatedDoctors, DRGRequestIndex, getProfile } from "../../actions";
import {
  Button,
  CardContent,
  Card,
  CardActions,
  CardActionArea,
  Grid,
  Paper,
  GridBase,
  SnackbarContent,
  LinearProgress,
  IconButton,
  Typography,
  Divider
} from "@material-ui/core";
import PrivateRoute from "../shared/PrivateRoute";
import AddScheduledFileHome from "../doctor/pages/home/AddScheduledFileHome";
import AssociateDoctorHome from "../patient/pages/home/AssociateDoctorHome";
import DownloadModulesHome from "../patient/pages/home/DownloadModulesHome";
import { login } from "../../actions";
import { telemedicine } from "../../components/patient/pages/Telemedicine";
import config from "./../../config";

const useStyles = makeStyles(theme => ({
  missingProfileWarning: {
    backgroundColor: theme.palette.error.dark,
    marginBottom: "16px"
  },
  welcomeMessage: {
    margin: "32px 0px",
    fontFamily: "karla",
    fontWeight: 300
  },
  telemedicinaDesc: {
    fontFamily: "karla",
    fontWeight: 300
  },
  MuiGridroot: {
    flexGrow: 1
  },
  card: {
    textAlign: "center"
  },
  icon: {
    /*backgroundColor: "rgba(30, 100, 230, 1)",*/
    backgroundColor: "rgb(58, 98, 171)",
    color: "white"
  },
  actions: {
    justifyContent: "center"
  },
  textUpperCaseAndSize: {
    textTransform: "uppercase",
    fontSize: "0.875rem"
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
}));

const buttons = {
  doctor: [
    {
      link: "/dashboard",
      label: "components.shared.layouts.base.navbar.dashboard.surgery",
      icon: <Dashboard />
    },
    {
      link: "/patients",
      label: "components.pages.homepage.patients",
      icon: <ListAlt />
    },
    {
      link: "/home/add_scheduled_file",
      label: "components.pages.homepage.add_scheduled_file",
      icon: <Add />
    }
  ],
  doctorForTelemedicine: [
    {
      link: "/planner_telemedicine",
      label: "components.pages.homepage.telemedicine",
      icon: <HeadsetMic />
    }
  ],
  patient: {
    associateDoctor: {
      link: "/home/associate_doctor",
      label: "components.pages.homepage.associate_doctor",
      icon: <PersonAdd />
    },
    downloadCBHA: {
      link: "/home/download_modules",
      label: "components.pages.homepage.cbha_download",
      icon: <Download />
    },
    addDrgRequest: {
      link: "/drg_requests/new",
      label: "components.pages.homepage.add_drg_request",
      icon: <Add />
    },
    myDrgRequest: {
      link: "/dashboard",
      label: "components.pages.homepage.drg_requests",
      icon: <Dashboard />
    },
    addPatientDocuments: {
      link: "/add_document",
      label: "components.pages.homepage.add_documents",
      icon: <NoteAdd />
    },
    telemedicine: {
      link: "/telemedicine",
      label: "components.pages.homepage.telemedicine",
      icon: <HeadsetMic />
    },
    patientBook: {
      link: "/patient_book",
      label: "components.pages.homepage.patientbook",
      icon: <NoteAdd />
    }
  },
  doctorTeleconsult: {
    teleconsult: {
      link: "/telemedicine",
      label: "components.pages.homepage.doctorconsult.teleconsult",
      icon: <HeadsetMic />
    },
    teleconsultBook: {
      link: "/patient_book",
      label: "components.pages.homepage.doctorconsult.teleconsultbook",
      icon: <NoteAdd />
    },
    teleconsultBack: {
      link: "/patient_book",
      label: "components.pages.homepage.doctorconsult.teleconsultback",
      icon: <SettingsBackupRestore />
    }
  }
};

const getButtons = (currentUser, patient, doctors, drgRequests) => {
  const customButtons = buttons;
  if (currentUser.doctorId) {
    return customButtons.doctor;
  } else if (currentUser.patientId) {
    const patientButtons = [];

    if (isEmpty(doctors)) {
      patientButtons.push(customButtons.patient.associateDoctor);
      return patientButtons;
    } else {
      patientButtons.push(customButtons.patient.downloadCBHA);
      if (patient.preauthorizationTemplateSent) {
        patientButtons.push(customButtons.patient.addDrgRequest);
      }
      if (!isEmpty(drgRequests)) {
        patientButtons.push(customButtons.patient.myDrgRequest);
      }
      patientButtons.push(customButtons.patient.addPatientDocuments);

      return patientButtons;
    }
  }
  return [];
};

const getButtonsForDoctorTelemedicine = (currentUser) => {
  const customButtons = buttons;
  if (currentUser.doctorId) {
    return customButtons.doctorForTelemedicine;
  }
  return [];
};

const Homepage = (props, context) => {
  const {
    currentUser,
    patient,
    doctors,
    drgRequests,
    loadingRequest,
    loadingDoctors,
    match
  } = props;

  const actionsDispatch = useDispatch();
  
  const { t } = context;

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser.patientId) {
      actionsDispatch(associatedDoctors(currentUser.patientId));
      actionsDispatch(DRGRequestIndex(currentUser.patientId));
      actionsDispatch(getProfile(currentUser.patientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonProps = getButtons(currentUser, patient, doctors, drgRequests);
  const buttonPropsForDoctorTelemedicine = getButtonsForDoctorTelemedicine(currentUser);

  const profileMissingWarning = (
    <SnackbarContent
      className={classes.missingProfileWarning}
      message={t("components.pages.homepage.complete_profile")}
      action={[
        <IconButton
          key="goToEditProfile"
          aria-label="goToEditProfile"
          color="inherit"
          onClick={() => {
            dispatch(push("/edit_profile"));
          }}
        >
          <EditIcon />
        </IconButton>
      ]}
    />
  );

  const [doctorTeleconsult, setDoctorTeleconsult] = useState(0);
  const [doctorTeleconsultEmail, setDoctorTeleconsultEmail] = useState(0);
  const [doctorTeleconsultTmpStorage, setDoctorTeleconsultTmpStorage] = useState(0);
  const [doctorTeleconsultPatientId, setDoctorTeleconsultPatientId] = useState(0);
  const [doctorTeleconsultDoctorEmail, setDoctorTeleconsultDoctorEmail] = useState(0);
  const [doctorTeleconsultDoctorTmpStorage, setDoctorTeleconsultDoctorTmpStorage] = useState(0);
  const [doctorTeleconsultBackToDoctor, setDoctorTeleconsultBackToDoctor] = useState(0);
  const doctorId = useSelector(state => state.user.current.doctorId);
  const [teleconsultContextValue, setTeleconsultContextValue] = useContext(TeleconsultContext);

  useEffect(() => {
    if(doctorTeleconsult) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/doctor_teleconsult.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ doctorId: doctorId, doctorTmpStorage: teleconsultContextValue  })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.status1 && data.status2){
            setDoctorTeleconsultEmail(data.status1)
            /*setDoctorTeleconsultTmpStorage(data.status2)*/
            setDoctorTeleconsultTmpStorage(teleconsultContextValue)
            setDoctorTeleconsult(1)
          }else{
            setDoctorTeleconsult(data.status)
            setDoctorTeleconsult(0)
          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [doctorTeleconsult]);

  useEffect(() => {
    if(doctorTeleconsultEmail && doctorTeleconsultTmpStorage) {
      actionsDispatch(login(doctorTeleconsultEmail, doctorTeleconsultTmpStorage));
    }
  }, [doctorTeleconsultEmail, doctorTeleconsultTmpStorage]);

  useEffect(() => {
    if(currentUser.patientId) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/after_doctor_teleconsult.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ patientId: currentUser.patientId, doctorTmpStorage: teleconsultContextValue  })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.status3){
            setDoctorTeleconsultDoctorTmpStorage(data.status5)
            setDoctorTeleconsultDoctorEmail(data.status3)
            setDoctorTeleconsultPatientId(data.status2)
          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [currentUser.patientId]);

  useEffect(() => {
    if(doctorTeleconsultBackToDoctor) {
      actionsDispatch(login(doctorTeleconsultDoctorEmail, doctorTeleconsultDoctorTmpStorage));
    }
  }, [doctorTeleconsultBackToDoctor]);

  /*useEffect(() => {
    if(doctorConsultPatientId == currentUser.patientId){
      actionsDispatch(push('/telemedicine'));
    }
  }, [doctorConsultPatientId]);*/

  return loadingRequest || loadingDoctors ? (
    <LinearProgress />
  ) : (
    <>
      {!currentUser.doctorId && isEmpty(props.patient.name)
        ? profileMissingWarning
        : null}
      {currentUser.doctorId ? (
        <div>
          <Typography
            variant="h6"
            align="center"
            className={classes.welcomeMessage}
          >
            {t("components.pages.homepage.doctor.welcome_message1")}
            <br />
            {t("components.pages.homepage.doctor.welcome_message2")}
            {t("components.pages.homepage.doctor.welcome_message3")}
            <br />
            {t("components.pages.homepage.doctor.welcome_message4")}
            <a
              href="mailto:info@sef.care"
              rel="noopener noreferrer"
            >
              info@sef.care
            </a>
          </Typography>

          <br />

          <Typography style={{ flex: 1 }}>
            {t("components.pages.homepage.doctor.message.title1")}
          </Typography>
          <Typography className={classes.telemedicinaDesc}>
            {t("components.pages.homepage.doctor.message.message1")}<br />
            {t("components.pages.homepage.doctor.message.message1.a")}<br />
            {t("components.pages.homepage.doctor.message.message1.b")}<br />
            {t("components.pages.homepage.doctor.message.message1.c")}
          </Typography>
          <br />

          <HomeButtons buttons={buttonProps} />

          <br /><br />
          <Divider />
          <br />

          <Typography style={{ flex: 1 }}>
            {t("components.pages.homepage.doctor.message.title2")}
          </Typography>
          <Typography className={classes.telemedicinaDesc}>
            {t("components.pages.homepage.doctor.message.message2")}<br />
            {t("components.pages.homepage.doctor.message.message2.a")}
          </Typography>
          <br />

          {/*<HomeButtons buttons={[buttons.doctor1.doctorTelemedicine]}/>*/}
          {/*doctor_profile.name}
          {user.doctorId}
          {doctorId*/}
          <Grid 
            container 
            direction="row"
            spacing={6}
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={3} onClick={() => setDoctorTeleconsult(1)}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardContent>
                    <IconButton className={classes.icon}>
                      <HeadsetMic />
                    </IconButton>
                  </CardContent>
                  <CardActions className={classes.actions}>
                    <Button 
                      className={classes.textUpperCaseAndSize}
                    >
                      {t("components.pages.homepage.doctorconsult.teleconsultdashboard")}
                    </Button>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>

          <br /><br />
          <Divider />
          <br />

          <Typography style={{ flex: 1 }}>
            {t("components.pages.homepage.doctor.message.title3")}
          </Typography>
          <Typography className={classes.telemedicinaDesc}>
            {t("components.pages.homepage.doctor.message.message3")}<br />
            {t("components.pages.homepage.doctor.message.message3.a")}
          </Typography>
          <br />

          <HomeButtons buttons={buttonPropsForDoctorTelemedicine} />
        </div>
      ) : (
        <>
          <Typography variant="h6" align="center" className={classes.welcomeMessage}>
            <strong>
              {t("components.homepage.welcome_message1")}
              <br />
              {t("components.homepage.further_information1")}
              <a href="//www.sef.care" target="_blank" rel="noopener noreferrer">
                www.sef.care
              </a>
            </strong>
          </Typography>
          <br />

          {
            doctorTeleconsultPatientId == currentUser.patientId
            ?
            <Grid 
            container 
            spacing={2} 
            direction="row"
            justify="center"
            alignItems="center"
            >
              <Grid item xs={12} sm={6} md={3}>
                <Typography style={{ flex: 1 }}>
                  {t("components.pages.homepage.doctorconsult.teleconsult")}
                </Typography>
                <Typography className={classes.telemedicinaDesc}>
                  {t("components.pages.homepage.telemedicine.doctor.description1")}<br />
                  {t("components.pages.homepage.telemedicine.description2")}
                </Typography>
              </Grid>
              
              <HomeButtonsNew buttons={[buttons.doctorTeleconsult.teleconsult]}/>
              <HomeButtonsNew buttons={[buttons.doctorTeleconsult.teleconsultBook]}/>

              <Grid item xs={12} sm={6} md={3} onClick={() => setDoctorTeleconsultBackToDoctor(1)}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardContent>
                      <IconButton className={classes.icon}>
                        <SettingsBackupRestore />
                      </IconButton>
                    </CardContent>
                    <CardActions className={classes.actions}>
                      <Button 
                        className={classes.textUpperCaseAndSize}
                      >
                        {t("components.pages.homepage.doctorconsult.teleconsultback")}
                      </Button>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
            :
            <div>
              <Grid 
                container 
                spacing={2} 
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6} md={12}>
                  <Typography style={{ flex: 1 }}>
                    {t("components.pages.homepage.telemedicine")}
                  </Typography>
                  <Typography className={classes.telemedicinaDesc}>
                    {t("components.pages.homepage.telemedicine.patient.description1")}<br />
                    {t("components.pages.homepage.telemedicine.description2")}
                  </Typography>
                </Grid>
              </Grid>
                
              <HomeButtons buttons={[buttons.patient.telemedicine]}/>
              <HomeButtons buttons={[buttons.patient.patientBook]}/>
              <br /><Divider /><br />
              <Grid
                justify="space-between"
                container 
                spacing={3}
              >
                <Grid item md={12}>
                  <Typography style={{ flex: 1 }}>
                    {t("components.pages.homepage.care_path")}
                  </Typography>
                  <Typography className={classes.telemedicinaDesc}>
                    {t("components.pages.homepage.care_path.description1")}<br />
                    {t("components.pages.homepage.care_path.description2")}<br />
                    {t("components.pages.homepage.care_path.description3")}<br />
                    {t("components.pages.homepage.care_path.description4")}
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <HomeButtons buttons={buttonProps} />
                </Grid>
                <Grid item md={3}></Grid>
                </Grid>
            </div>
          }
        </>
      )}
      <PrivateRoute
        path={`${match.path}/add_scheduled_file`}
        component={AddScheduledFileHome}
      />
      <PrivateRoute
        path={`${match.path}/associate_doctor`}
        component={AssociateDoctorHome}
      />
      <PrivateRoute
        path={`${match.path}/download_modules`}
        component={DownloadModulesHome}
      />
    </>
  );
};

Homepage.contextTypes = {
  t: PropTypes.func
};

const mapStatetoProps = state => {
  return {
    currentUser: state.user.current,
    patient: state.patient.profile,
    doctors: state.patient.associatedDoctors,
    drgRequests: state.drgRequest.list,
    loadingRequest: state.drgRequest.loading,
    loadingDoctors: state.patient.loading
  };
};

const enhance = compose(connect(mapStatetoProps), withRouter);

export default enhance(Homepage);
