import { find, filter, each, last, flatten, map, indexOf, includes, findIndex, omit, some } from 'lodash'
import moment from 'moment'

import ApiJsonNavigator from '../../libs/ApiJsonNavigator'

const Users = [
	{
		id: 1,
		email: 'patient@sef.care',
		password: 'password',
		token:
			'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNTQyOTYwNjI1LCJleHAiOjE1NDMwNDcwMjUsImp0aSI6IjY0YThiYmU0LTQxNDAtNDFmMC1iZjA3LTViZWEzZTk3ODIxMSJ9.xjJbrIuxF-ntBk5is_Fj5hLok-9vWN8Fp3zV2L5I3kA',
		roles: ['patient']
	},
	{
		id: 2,
		email: 'doctor@sef.care',
		password: 'password',
		token:
			'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNTQyOTYwNjI1LCJleHAiOjE1NDMwNDcwMjUsImp0aSI6IjY0YThiYmU0LTQxNDAtNDFmMC1iZjA3LTViZWEzZTk3ODIxMSJ9.xjJbrIuxF-ntBk5is_Fj5hLok-9vWN8Fp3zV2L5I3kH',
		roles: ['doctor']
	}
]

const patientProfiles = [
	{
		id: '1',
		type: 'patient_profile',
		attributes: {
			id: 1,
			name: 'Giorgio',
			surname: 'Vanni',
			gender: 'male',
			membership_asl: 'Test',
			birth_date: '1984-07-21',
			birth_country: 'IT',
			birth_country_name: 'Italy',
			birth_state: '52',
			birth_state_name: 'Tuscany',
			birth_city: 'Pisa',
			fiscal_code: 'VNNGRG84L21G702Q',
			residential_address: 'Via in quel posto',
			residential_country: 'IT',
			residential_country_name: 'Italy',
			residential_state: '52',
			residential_state_name: 'Tuscany',
			residential_city: 'Pisa',
			phone: '0529738519',
			mobile_phone: '3451978436',
			email_address: 'giorgioVanni@user.it'
		},
		relationships: {
			user: { data: { id: '1', type: 'user' } },
			doctor_profile: { data: [{ id: '1', type: 'doctor_profile' }] },
			doctor_patients: { data: [{ id: '1', type: 'doctor_patient'}]}
		}
	}
]

const doctorProfiles = [
	{
		id: '1',
		type: 'doctor_profile',
		attributes: {
			id: 1,
			name: 'Giuseppe',
			surname: 'Annini',
			birth_date: '1962-01-14',
			birth_country: 'IT',
			birth_state: '52',
			birth_city: 'Florence',
			fiscal_code: 'NNNGPP62A14D612Z',
			residential_address: 'Via molto importante',
			residential_country: 'IT',
			residential_state: '52',
			residential_city: 'Florence',
			phone: '0578542396',
			mobile_phone: '3325981027',
			email_address: 'docotor@sef.care',
			specializations: ['urology', 'maxillo_facial_surgery'],
			userId: 2
		}
	}
]

const doctorPatients = [
	{
		id: '1',
		type: 'doctor_patient',
		attributes: {
			id: 1,
			diagnostic_indication: 'Lorem ipsum',
			therapeutic_indication: 'Dolor sit amlet',
			urgency_motivation: 'Lorem ipsum'
		},
		relationships: {
			patient_profile: { data: { id: '1', type: 'patient_profile' } },
			doctor_profile: { data: { id: '1', type: 'doctor_profile' } },
			clinic: { data: { id: '1', type: 'clinic' } }
		}
	}
]

export const countries = {
	data: [
		{ id: 'AR', type: 'country', attributes: { id: 'AR', name: 'Argentina' } },
		{ id: 'BR', type: 'country', attributes: { id: 'BR', name: 'Brazil' } },
		{ id: 'CA', type: 'country', attributes: { id: 'CA', name: 'Canada' } },
		{ id: 'IT', type: 'country', attributes: { id: 'IT', name: 'Italy' } },
		{ id: 'RU', type: 'country', attributes: { id: 'RU', name: 'Russia' } },
		{ id: 'US', type: 'country', attributes: { id: 'US', name: 'United States' } }
	]
}

export const states = {
	data: [
		{ id: 21, type: 'state', attributes: { id: 21, name: 'Piedmont' } },
		{ id: 23, type: 'state', attributes: { id: 23, name: 'Aosta Valley' } },
		{ id: 25, type: 'state', attributes: { id: 25, name: 'Lombardy' } },
		{ id: 32, type: 'state', attributes: { id: 32, name: 'Trentino-Alto Adige' } },
		{ id: 34, type: 'state', attributes: { id: 34, name: 'Veneto' } },
		{ id: 36, type: 'state', attributes: { id: 36, name: 'Friuli Venezia Giulia' } },
		{ id: 42, type: 'state', attributes: { id: 42, name: 'Liguria' } },
		{ id: 45, type: 'state', attributes: { id: 45, name: 'Emilia-Romagna' } },
		{ id: 52, type: 'state', attributes: { id: 52, name: 'Tuscany' } },
		{ id: 55, type: 'state', attributes: { id: 55, name: 'Umbria' } },
		{ id: 57, type: 'state', attributes: { id: 57, name: 'The Marches' } },
		{ id: 62, type: 'state', attributes: { id: 62, name: 'Latium' } },
		{ id: 65, type: 'state', attributes: { id: 65, name: 'Abruzzo' } },
		{ id: 67, type: 'state', attributes: { id: 67, name: 'Molise' } },
		{ id: 72, type: 'state', attributes: { id: 72, name: 'Campania' } },
		{ id: 75, type: 'state', attributes: { id: 75, name: 'Apulia' } },
		{ id: 77, type: 'state', attributes: { id: 77, name: 'Basilicate' } },
		{ id: 78, type: 'state', attributes: { id: 78, name: 'Calabria' } },
		{ id: 82, type: 'state', attributes: { id: 82, name: 'Sicily' } },
		{ id: 88, type: 'state', attributes: { id: 88, name: 'Sardinia' } }
	]
}

export const cities = {
	data: [
		{ id: 'Arezzo', type: 'city', attributes: { id: 'Arezzo', name: null } },
		{ id: 'Florence', type: 'city', attributes: { id: 'Florence', name: null } },
		{ id: 'Pisa', type: 'city', attributes: { id: 'Pisa', name: null } },
		{ id: 'Livorno', type: 'city', attributes: { id: 'Livorno', name: null } },
		{ id: 'Grosseto', type: 'city', attributes: { id: 'Grosseto', name: null } },
		{ id: 'Massa', type: 'city', attributes: { id: 'Massa', name: null } },
		{ id: 'Carrara', type: 'city', attributes: { id: 'Carrara', name: null } },
		{ id: 'Prato', type: 'city', attributes: { id: 'Prato', name: null } },
		{ id: 'Pistoia', type: 'city', attributes: { id: 'Pistoia', name: null } }
	]
}

export const drgs = {
	data: [
		{ id: '1', type: 'drg', attributes: { id: 1, code: 'A1', title: 'Prima DRG', label: 'La prima DRG' } },
		{ id: '2', type: 'drg', attributes: { id: 2, code: 'A2', title: 'Seconda DRG', label: 'La seconda DRG' } },
		{ id: '3', type: 'drg', attributes: { id: 3, code: 'A3', title: 'Terza DRG', label: 'La terza DRG' } },
		{ id: '4', type: 'drg', attributes: { id: 4, code: 'A4', title: 'Quarta DRG', label: 'La quarta DRG' } }
	]
}

const drgRequests = {
	data: [
		{
			id: '1',
			type: 'drg_request',
			attributes: {
				id: 1,
				message: 'Richiesta DRG - Rejected',
				backoffice_notes: 'Lorem ipsum',
				document_url: null,
				aasm_state: 'rejected',
				created_at: moment()
					.subtract(7, 'days')
					.utc()
					.format(),
				updated_at: moment()
					.subtract(7, 'days')
					.utc()
					.format()
			},
			relationships: {
				patient_profile: { data: { id: '1', type: 'patient_profile' } },
				doctor_profile: { data: { id: '1', type: 'doctor_profile' } },
				drg: { data: { id: '1', type: 'drg' } }
			}
		},
		{
			id: '2',
			type: 'drg_request',
			attributes: {
				id: 2,
				message: 'Richiesta DRG - Accepted',
				document_url: '/test.pdf',
				backoffice_notes: 'Lorem ipsum',
				aasm_state: 'accepted',
				created_at: moment()
					.subtract(3, 'days')
					.utc()
					.format(),
				updated_at: moment()
					.subtract(3, 'days')
					.utc()
					.format()
			},
			relationships: {
				patient_profile: { data: { id: '1', type: 'patient_profile' } },
				doctor_profile: { data: { id: '1', type: 'doctor_profile' } },
				drg: { data: { id: '3', type: 'drg' } }
			}
		},
		{
			id: '3',
			type: 'drg_request',
			attributes: {
				id: 3,
				message: 'Richiesta DRG - Pending',
				document_url: '/test.pdf',
				backoffice_notes: 'A note',
				aasm_state: 'pending',
				created_at: moment()
					.subtract(1, 'days')
					.utc()
					.format(),
				updated_at: moment()
					.subtract(1, 'days')
					.utc()
					.format()
			},
			relationships: {
				patient_profile: { data: { id: '1', type: 'patient_profile' } },
				doctor_profile: { data: { id: '1', type: 'doctor_profile' } },
				drg: { data: { id: '3', type: 'drg' } }
			}
		},
		{
			id: '4',
			type: 'drg_request',
			attributes: {
				id: 4,
				message: 'Richiesta DRG - Accepted - NotScheduled',
				document_url: '/testIna.pdf',
				backoffice_notes: null,
				aasm_state: 'accepted',
				created_at: moment()
					.utc()
					.format(),
				updated_at: moment()
					.utc()
					.format()
			},
			relationships: {
				patient_profile: { data: { id: '1', type: 'patient_profile' } },
				doctor_profile: { data: { id: '1', type: 'doctor_profile' } },
				drg: { data: { id: '4', type: 'drg' } }
			}
		}
	]
}

export const crossBorderHealthcareAuthorization = {
	data: [
		{
			id: '1',
			type: 'cross...',
			attributes: {
				id: 1,
				country: 'Italy',
				state: 'Tuscany',
				city: 'Florence',
				district: 'USL20',
				url: '/test.pdf',
				document_url: '/download/test.pdf'
			}
		},
		{
			id: '2',
			type: 'cross...',
			attributes: {
				id: 2,
				country: 'Italy',
				state: 'Latium',
				city: 'Rome',
				district: 'USL40',
				url: null,
				document_url: '/download/Ciao.pdf'
			}
		}
	]
}

const scheduledFiles = [
	{
		id: '1',
		type: 'scheduled_file',
		attributes: {
			id: 1,
			label: null,
			from: '2020-08-09',
			to: '2020-08-14',
			aasm_state: 'confirmed',
			created_at: '2019-08-09T14:14:47.120Z',
			updated_at: '2019-08-09T14:35:12.195Z'
		},
		relationships: {
			doctor_profile: { data: { id: '1', type: 'doctor_profile' } },
			clinic: { data: { id: '2', type: 'clinic' } },
			drg_request_schedules: { data: [] },
			drg_requests: { data: [] }
		}
	},
	{
		id: '2',
		type: 'scheduled_file',
		attributes: {
			id: 2,
			label: null,
			from: '2020-08-21',
			to: '2020-09-20',
			aasm_state: 'draft',
			created_at: '2019-08-09T15:35:29.496Z',
			updated_at: '2019-08-09T15:35:29.496Z'
		},
		relationships: {
			doctor_profile: { data: { id: '1', type: 'doctor_profile' } },
			clinic: { data: { id: '2', type: 'clinic' } },
			drg_request_schedules: { data: [{ id: '1', type: 'drg_request_schedule' }] },
			drg_requests: { data: [{ id: '2', type: 'drg_request' }] }
		}
	},
	{
		id: '3',
		type: 'scheduled_file',
		attributes: {
			id: 3,
			label: null,
			from: '2020-10-16',
			to: '2020-11-22',
			aasm_state: 'canceled',
			created_at: '2019-08-09T10:44:25.572Z',
			updated_at: '2019-08-09T10:44:25.587Z'
		},
		relationships: {
			doctor_profile: { data: { id: '1', type: 'doctor_profile' } },
			clinic: { data: { id: '1', type: 'clinic' } },
			drg_request_schedules: { data: [] },
			drg_requests: { data: [] }
		}
	},
	{
		id: '4',
		type: 'scheduled_file',
		attributes: {
			id: 4,
			label: 'Test Label',
			from: '2020-10-17',
			to: '2020-10-31',
			aasm_state: 'draft',
			created_at: '2019-08-26T07:07:50.034Z',
			updated_at: '2019-08-26T07:07:50.034Z'
		},
		relationships: {
			doctor_profile: { data: { id: '1', type: 'doctor_profile' } },
			clinic: { data: { id: '2', type: 'clinic' } },
			drg_request_schedules: { data: [] },
			drg_requests: { data: [] }
		}
	}
]

export const clinics = {
	data: [
		{
			id: '1',
			type: 'clinic',
			attributes: {
				id: 1,
				name: 'Una clinica',
				description: '',
				created_at: '2019-08-09T10:29:39.973Z',
				updated_at: '2019-08-09T10:29:39.973Z'
			}
		},
		{
			id: '2',
			type: 'clinic',
			attributes: {
				id: 2,
				name: 'Seconda clinica',
				description: 'Clinica di grande rilievo',
				created_at: '2019-08-09T14:30:52.032Z',
				updated_at: '2019-08-09T14:30:52.032Z'
			}
		},
		{
			id: '3',
			type: 'clinic',
			attributes: {
				id: 3,
				name: 'Terza clinica',
				description: 'Clinicone',
				created_at: '2019-08-09T14:30:52.032Z',
				updated_at: '2019-08-09T14:30:52.032Z'
			}
		}
	]
}

const drgRequestSchedules = {
	data: [
		{
			id: '1',
			type: 'drg_request_schedule',
			attributes: {
				id: 1,
				start_time: '2020-09-01T15:26:00.000Z',
				duration: 60,
				notes: 'Test delle annotazioni',
				hospitalization_days: 4,
				aasm_state: 'scheduled',
				created_at: '2019-08-26T09:55:17.844Z',
				updated_at: '2019-08-26T09:55:17.844Z'
			},
			relationships: {
				scheduled_file: { data: { id: '2', type: 'scheduled_file' } },
				drg_request: { data: { id: '2', type: 'drg_request' } }
			}
		}
	]
}

export const scheduledFileIndex = () => {
	const returnData = {
		data: scheduledFiles,
		included: []
	}

	map(scheduledFiles, (item) => {
		includedScheduledFile(item, returnData)
	})
	return returnData
}

const includedScheduledFile = (scheduledFile, returnData) => {
	const doctorProfile = find(doctorProfiles, { id: scheduledFile.relationships.doctor_profile.data.id })
	if (
		!find(returnData.included, (el) => {
			return el.type === 'doctor_profile' && el.id === doctorProfile.id
		}) &&
		doctorProfile !== undefined
	)
		returnData.included.push(doctorProfile)

	const clinic = find(clinics.data, { id: scheduledFile.relationships.clinic.data.id })
	if (
		!find(returnData.included, (el) => {
			return el.type === 'clinic' && el.id === String(clinic.id)
		}) &&
		clinic !== undefined
	)
		returnData.included.push(clinic)

	map(scheduledFile.relationships.drg_request_schedules.data, (value) => {
		const drgRequestSchedule = find(drgRequestSchedules.data, { id: value.id })
		if (
			!find(returnData.included, (el) => {
				return el.type === 'drg_request_schedule' && el.id === String(drgRequestSchedule.id)
			}) &&
			drgRequestSchedule !== undefined
		)
			returnData.included.push(drgRequestSchedule)
	})

	map(scheduledFile.relationships.drg_requests.data, (value) => {
		const drgRequest = find(drgRequests.data, { id: value.id })
		if (
			!find(returnData.included, (el) => {
				return el.type === 'drg_request' && el.id === String(drgRequest.id)
			}) &&
			drgRequest !== undefined
		) {
			returnData.included.push(drgRequest)
			includedDrgRequest(drgRequest, returnData)
		}
	})
}

const includedDrgRequest = (drgRequest, returnData) => {
	const drg = find(drgs.data, { id: drgRequest.relationships.drg.data.id })
	if (
		!find(returnData.included, (el) => {
			return el.type === 'drg' && el.id === drg.id
		}) &&
		drg !== undefined
	)
		returnData.included.push(drg)

	const patientProfile = find(patientProfiles, { id: drgRequest.relationships.patient_profile.data.id })
	if (
		!find(returnData.included, (el) => {
			return el.type === 'patient_profile' && el.id === patientProfile.id
		}) &&
		patientProfile !== undefined
	)
		returnData.included.push(patientProfile)
}

export const drgRequestsToSchedule = () => {
	const acceptedDrgRequest = filter(drgRequests.data, (el) => {
		return el.attributes.aasm_state === 'accepted'
	})

	const testIds = []

	map(scheduledFiles, (item) => {
		map(item.relationships.drg_requests.data, (value) => {
			testIds.push(value.id)
		})
	})

	const drgRequestsToSchedule = filter(acceptedDrgRequest, (o) => {
		return !includes(testIds, o.id)
	})

	const returnData = {
		data: drgRequestsToSchedule,
		included: []
	}

	map(drgRequestsToSchedule, (item) => {
		includedDrgRequest(item, returnData)
		const doctorProfile = find(doctorProfiles, { id: item.relationships.doctor_profile.data.id })
		if (
			!find(returnData.included, (el) => {
				return el.type === 'doctor_profile' && el.id === doctorProfile.id
			}) &&
			doctorProfile !== undefined
		)
			returnData.included.push(doctorProfile)
	})
	return returnData
}

export const checkForUser = (email, password) => {
	const user = find(Users, { email })

	if (user && user.password === password) return user
	return null
}

export const findUserByToken = (token) => {
	const user = find(Users, { token })
	if (user) return user
	return null
}

export const getPatientProfileByToken = (token) => {
	const user = findUserByToken(token)

	const profile = find(patientProfiles, (item) => {
		return item.relationships.user.data.id === String(user.id)
	})

	if (profile) return profile
	return null
}

export const getStatesByCountry = (countryName) => {
	const statesByCountry = filter(states, (el) => el.countryName === countryName)
	return statesByCountry
}

export const getCitiesByState = (stateName) => {
	const citiesByCountry = filter(cities, (el) => el.stateName === stateName)
	return citiesByCountry
}

export const addPatientUser = (profile) => {
	let newUser = {}
	each(profile.user, (value, key) => {
		if (key !== 'password_confirmation' && key !== 'patient_profile_attributes')
			Object.assign(newUser, { [key]: value })
	})
	const userId = last(Users).id + 1
	newUser.id = userId
	newUser.token =
		'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNTQyOTYwNjI1LCJleHAiOjE1NDMwNDcwMjUsImp0aSI6IjY0YThiYmU0LTQxNDAtNDFmMC1iZjA3LTViZWEzZTk3ODIxMSJ9.xjJbrIuxF-ntBk5is_'
	newUser.roles = ['patient']
	Users.push(newUser)

	let newProfile = {
		id: String(Number(last(patientProfiles).id) + 1),
		type: 'patient_profile',
		attributes: {},
		relationships: {
			user: { data: { id: String(userId), type: 'user' } },
			doctor_profile: { data: [] }
		}
	}
	each(profile.user.patient_profile_attributes, (value, key) => {
		Object.assign(newProfile.attributes, { [key]: value })
	})

	patientProfiles.push(newProfile)

	return newUser
}

export const editPatientProfile = (profileId, profile) => {
	const myProfile = getPatientProfileById(profileId)

	Object.assign(myProfile.attributes, profile)

	return myProfile
}

export const getPatientProfileById = (profileId) => {
	const myProfile = find(patientProfiles, { id: profileId })
	const returnData = {
		data: myProfile,
		included: flatten([doctorPatients, clinics.data])
	}
	return returnData
}

export const isPatientProfile = (profileId) => {
	return find(patientProfiles, { id: profileId }) !== undefined
}

export const getDoctorProfileById = (profileId) => {
	const myProfile = find(doctorProfiles, { id: profileId })

	return myProfile
}

export const editDoctorProfile = (profileId, profile) => {
	const myProfile = getDoctorProfileById(profileId)

	Object.assign(myProfile.attributes, profile)
}

export const getDoctorProfileByToken = (token) => {
	const user = findUserByToken(token)

	const profile = find(doctorProfiles, (item) => {
		return item.attributes.userId === user.id
	})

	if (profile) return profile
	return null
}

export const addScheduledFile = (scheduledFile) => {
	const newId = Number(last(scheduledFiles).id) + 1
	const returnData = {
		data: {
			id: String(newId),
			type: 'scheduled_file',
			attributes: {
				id: newId,
				from: moment(scheduledFile.from)
					.utc()
					.format('YYYY-MM-DD'),
				to: moment(scheduledFile.to)
					.utc()
					.format('YYYY-MM-DD'),
				label: scheduledFile.label,
				aasm_state: 'draft',
				created_at: moment(),
				updated_at: moment()
			},
			relationships: {
				doctor_profile: { data: { id: scheduledFile.doctor_profile_id, type: 'doctor_profile' } },
				clinic: { data: { id: scheduledFile.clinic_id, type: 'clinic' } },
				drg_request_schedules: { data: [] },
				drg_requests: { data: [] }
			}
		},
		included: []
	}

	scheduledFiles.push(returnData.data)

	includedScheduledFile(returnData.data, returnData)

	return returnData
}

export const showScheduledFile = (scheduledFileId) => {
	const scheduledFileNavigator = new ApiJsonNavigator(scheduledFileIndex())

	const scheduledFile = find(scheduledFileNavigator.data(), { id: scheduledFileId })

	let returnData = {
		data: scheduledFile.apiJsonDataObj,
		included: [
			scheduledFile.clinic().apiJsonDataObj,
			scheduledFile.doctor_profile().apiJsonDataObj,
			scheduledFile.drg_request_schedules(),
			scheduledFile.drg_requests()
		]
	}

	const incl = flatten(returnData.included)

	returnData.included = []

	map(incl, (item) => {
		if (item.type === 'drg_request') {
			returnData.included.push(item.apiJsonDataObj)
			returnData.included.push(item.drg().apiJsonDataObj)
			returnData.included.push(item.patient_profile().apiJsonDataObj)
		} else {
			const newElement = item.apiJsonDataObj || item
			returnData.included.push(newElement)
		}
	})

	return returnData
}

export const editScheduledFile = (scheduledFileId, scheduledFileFields) => {
	const scheduledFile = find(scheduledFiles, { id: scheduledFileId })
	Object.assign(scheduledFile.attributes, scheduledFileFields)
	return showScheduledFile(Number(scheduledFileId))
}

export const confirmScheduledFile = (scheduledFileId) => {
	const scheduledFile = find(scheduledFiles, { id: scheduledFileId })
	if (scheduledFile) scheduledFile.attributes.aasm_state = 'confirmed'
	return scheduledFile
}

export const cancelScheduledFile = (scheduledFileId) => {
	const scheduledFile = find(scheduledFiles, { id: scheduledFileId })
	if (scheduledFile) scheduledFile.attributes.aasm_state = 'canceled'
	return scheduledFile
}

export const indexDrgRequestSchedule = (scheduledFileId) => {
	const scheduledFileNavigator = new ApiJsonNavigator(scheduledFileIndex())
	const scheduledFile = find(scheduledFileNavigator.data(), { id: scheduledFileId })

	let returnData = {
		data: [],
		included: []
	}

	returnData.data = map(scheduledFile.drg_request_schedules(), (item) => {
		returnData.included.push(item.drg_request().apiJsonDataObj)
		returnData.included.push(item.drg_request().drg().apiJsonDataObj)
		returnData.included.push(item.drg_request().patient_profile().apiJsonDataObj)
		return item.apiJsonDataObj
	})

	return returnData
}

export const showDrgRequestSchedule = (drgRequestScheduleId) => {
	const drgRequestSchedule = find(drgRequestSchedules.data, { id: drgRequestScheduleId })

	const returnData = {
		data: drgRequestSchedule,
		included: []
	}

	const drgRequest = find(drgRequests.data, {
		id: drgRequestSchedule.relationships.drg_request.data.id
	})

	returnData.included.push(drgRequest)

	includedDrgRequest(drgRequest, returnData)
	return returnData
}

export const addDrgRequestSchedule = (scheduledFileId, drgRequestSchedule) => {
	const newId = Number(last(drgRequestSchedules.data).id) + 1
	const returnData = {
		data: {
			id: String(newId),
			type: 'drg_request_schedule',
			attributes: {
				id: newId,
				start_time: drgRequestSchedule.start_time,
				duration: drgRequestSchedule.duration,
				hospitalization_days: drgRequestSchedule.hospitalization_days,
				notes: drgRequestSchedule.notes,
				aasm_state: 'scheduled',
				created_at: moment()
					.utc()
					.format(),
				updated_at: moment()
					.utc()
					.format()
			},
			relationships: {
				scheduled_file: { data: { id: scheduledFileId, type: 'scheduled_file' } },
				drg_request: { data: { id: drgRequestSchedule.drg_request_id, type: 'drg_request' } }
			}
		},
		included: []
	}

	drgRequestSchedules.data.push(returnData.data)

	const drgId = drgRequestSchedule.drg_request_attributes.drg_id

	const scheduleFile = find(scheduledFiles, { id: String(scheduledFileId) })
	scheduleFile.relationships.drg_request_schedules.data.push({ id: String(newId), type: 'drg_request_schedule' })
	scheduleFile.relationships.drg_requests.data.push({ id: drgRequestSchedule.drg_request_id, type: 'drg_request' })

	const drgRequest = find(drgRequests.data, { id: drgRequestSchedule.drg_request_id })
	drgRequest.relationships.drg.data.id = String(drgId)

	includedDrgRequest(drgRequest, returnData)

	return returnData
}

export const deleteDrgRequestSchedule = (drgRequestScheduleId) => {
	const drgRequestScheduleIndex = findIndex(drgRequestSchedules.data, { id: drgRequestScheduleId })
	const drgRequestSchedule = drgRequestSchedules.data[drgRequestScheduleIndex]
	drgRequestSchedules.data.splice(drgRequestScheduleIndex, 1)
	const scheduledFile = find(scheduledFiles, { id: drgRequestSchedule.relationships.scheduled_file.data.id })
	const index = findIndex(scheduledFile.relationships.drg_request_schedules.data, { id: drgRequestSchedule.id })
	scheduledFile.relationships.drg_request_schedules.data.splice(index, 1)
	const drgRequestIndex = findIndex(scheduledFile.relationships.drg_requests.data, {
		id: drgRequestSchedule.relationships.drg_request.data.id
	})
	scheduledFile.relationships.drg_requests.data.splice(drgRequestIndex, 1)
}

export const editDrgRequestSchedule = (drgRequestScheduleId, editDrgRequestSchedule) => {
	const drgRequestSchedule = find(drgRequestSchedules.data, { id: drgRequestScheduleId })
	const drgId = editDrgRequestSchedule.drg_request_attributes.drg_id
	const drgRequestScheduleFields = omit(editDrgRequestSchedule, 'drg_request_attributes')
	Object.assign(drgRequestSchedule.attributes, drgRequestScheduleFields)
	const drgRequest = find(drgRequests.data, (item) => {
		return item.id === drgRequestSchedule.relationships.drg_request.data.id
	})
	drgRequest.relationships.drg.data.id = String(drgId)

	return showDrgRequestSchedule(drgRequestSchedule.id)
}

export const associatedDoctors = (patientProfileId) => {
	const profile = getPatientProfileById(patientProfileId)

	const doctorProfilesIds = profile.relationships.doctor_profile.data

	const associatedDocs = []

	map(doctorProfilesIds, (item) => {
		associatedDocs.push(find(doctorProfiles, { id: item.id }))
	})

	return associatedDocs
}

export const indexDrgRequest = () => {
	const returnData = {
		data: drgRequests.data,
		included: []
	}

	const drgRequestIds = []

	map(drgRequestSchedules.data, (item) => {
		drgRequestIds.push(item.relationships.drg_request.data.id)
	})

	map(drgRequests.data, (item) => {
		const doctorProfile = find(doctorProfiles, { id: item.relationships.doctor_profile.data.id })
		if (
			!find(returnData.included, (el) => {
				return el.type === 'doctor_profile' && el.id === doctorProfile.id
			}) &&
			doctorProfile !== undefined
		)
			returnData.included.push(doctorProfile)
		includedDrgRequest(item, returnData)
		const drgRequestIndex = indexOf(drgRequestIds, item.id)
		if (drgRequestIndex >= 0) {
			returnData.included.push(drgRequestSchedules.data[drgRequestIndex])
		}
	})

	return returnData
}

export const showDrgRequest = (drgRequestId) => {
	const drgRequest = find(drgRequests.data, { id: drgRequestId })

	const returnData = {
		data: drgRequest,
		included: []
	}

	const doctorProfile = find(doctorProfiles, { id: drgRequest.relationships.doctor_profile.data.id })
	if (
		!find(returnData.included, (el) => {
			return el.type === 'doctor_profile' && el.id === doctorProfile.id
		}) &&
		doctorProfile !== undefined
	)
		returnData.included.push(doctorProfile)
	includedDrgRequest(drgRequest, returnData)
	return returnData
}

export const addDrgRequest = (patientId, drgRequest, document) => {
	const newId = Number(last(drgRequests.data).id) + 1
	const returnData = {
		data: {
			id: String(newId),
			type: 'drg_request',
			attributes: {
				id: newId,
				message: drgRequest.message,
				document_url: `/${document.name}`,
				aasm_state: 'pending',
				created_at: moment()
					.utc()
					.format(),
				updated_at: moment()
					.utc()
					.format()
			},
			relationships: {
				patient_profile: { data: { id: patientId, type: 'patient_profile' } },
				doctor_profile: { data: { id: String(drgRequest.doctor_profile_id), type: 'doctor_profile' } },
				drg: { data: { id: String(drgRequest.drg_id), type: 'drg' } }
			}
		},
		included: []
	}
	drgRequests.data.push(returnData.data)

	const doctorProfile = find(doctorProfiles, { id: returnData.data.relationships.doctor_profile.data.id })
	if (
		!find(returnData.included, (el) => {
			return el.type === 'doctor_profile' && el.id === doctorProfile.id
		}) &&
		doctorProfile !== undefined
	)
		returnData.included.push(doctorProfile)
	includedDrgRequest(returnData.data, returnData)

	return returnData
}

export const getPatientProfileAssociatedToDoctor = (doctorId) => {
	const selectedDoctorPatients = filter(doctorPatients, {
		relationships: { doctor_profile: { data: { id: doctorId } } }
	})
	const returnData = filter(patientProfiles, (pp) => some(selectedDoctorPatients, { id: pp.id }))

	return returnData
}
