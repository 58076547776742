import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import { map, flatten } from "lodash";
import { Calendar, momentLocalizer } from "react-big-calendar";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/it";
import "moment/locale/en-gb";
import EventViewDialog from "./EventViewDialog";

const localizer = momentLocalizer(moment);

const ScheduledFileCalendar = (props, context) => {
  const { t } = context;
  const currentLang = useSelector(state => state.i18nState.lang);
  const list = useSelector(state => state.scheduledFile.list);
  const events = flatten(map(list, item => item.events));
  const messages = {
    previous: t("locale.display_previous"),
    next: t("locale.display_next"),
    today: t("locale.display_today"),
    month: t("locale.display_month"),
    week: t("locale.display_week"),
    day: t("locale.display_day"),
    agenda: t("locale.display_agenda"),
    date: t("locale.display_date"),
    time: t("locale.display_time"),
    event: t("locale.display_event")
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});

  const handleModal = (isOpen, currentEvent = {}) => {
    setModalOpen(isOpen);
    setCurrentEvent(currentEvent);
  };

  return (
    <>
      <Calendar
        defaultDate={moment().toDate()}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        culture={currentLang}
        messages={messages}
        onSelectEvent={event => handleModal(true, event)}
        eventPropGetter={(event, start, end, isSelected) => ({
          style: {
            backgroundColor: event.backgroundColor,
            color: event.color
          }
        })}
        style={{
          minHeight: 600,
          paddingBottom: 64
        }}
      />
      <EventViewDialog
        open={modalOpen}
        handleModal={handleModal}
        currentEvent={currentEvent}
      />
    </>
  );
};

ScheduledFileCalendar.contextTypes = {
  t: PropTypes.func
};

export default ScheduledFileCalendar;
