import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  addScheduledFileTitle: {
    display: "flex"
  },
  addScheduledFileTypography: {
    flexGrow: 1
  }
}));

const EventViewDialog = (props, context) => {
  const { t } = context;

  const classes = useStyles();

  const { open, handleModal, currentEvent } = props;

  return (
    <Dialog open={open} onClose={() => handleModal(false)}>
      <DialogTitle disableTypography className={classes.addScheduledFileTitle}>
        <Typography variant="h6" className={classes.addScheduledFileTypography}>
          {currentEvent.title}
        </Typography>
        <Tooltip
          title={t("components.doctor.event_view_dialog.close")}
          placement="top"
        >
          <IconButton size="small" onClick={() => handleModal(false)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          <strong>
            {t("components.doctor.event_view_dialog.start_time")}:{" "}
          </strong>
          &nbsp;{" "}
          {moment(currentEvent.start).format(t("locale.display_datetime"))}
        </Typography>
        <br />
        <Typography variant="body2" gutterBottom>
          <strong>{t("components.doctor.event_view_dialog.duration")}: </strong>
          &nbsp;
          {currentEvent.duration}
        </Typography>
        <br />
        <Typography variant="body2" gutterBottom>
          <strong>
            {t("components.doctor.event_view_dialog.hospitalization_days")}:{" "}
          </strong>
          &nbsp;
          {currentEvent.hospitalizationDays}
        </Typography>
        <br />
        {currentEvent.notes ? (
          <Typography variant="body2" gutterBottom>
            <strong>{t("components.doctor.event_view_dialog.notes")}: </strong>
            &nbsp;
            {currentEvent.notes}
          </Typography>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

EventViewDialog.contextTypes = {
  t: PropTypes.func
};

export default EventViewDialog;
