import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import { Send as SendIcon } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TextField from "./formik/TextField";
import {
  createExaminationMessage,
  examinationMessageIndex
} from "../../actions";
import { useInterval } from "../../hooks/useInterval";

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16
  }
}));

const useEnhancer = ({ patientProfileId, doctorProfileId }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(examinationMessageIndex(id));
  }, [dispatch, id]);

  useInterval(async () => {
    dispatch(examinationMessageIndex(id));
  }, 3000);
  return {
    messages: useSelector(state => state.message.list),
    messagesLoading: useSelector(state => state.message.loading),
    onMessageSubmit: (value, { resetForm }) => {
      dispatch(
        createExaminationMessage(id, {
          ...value,
          doctorProfileId,
          patientProfileId
        })
      );
      dispatch(examinationMessageIndex(id));
      resetForm({ text: "" });
    }
  };
};

const MessageList = ({ patientProfileId, doctorProfileId }, { t }) => {
  const { messages, messagesLoading, onMessageSubmit } = useEnhancer({
    patientProfileId,
    doctorProfileId
  });
  const classes = useStyles();
  return (
    <>
      <List dense>
        {messages.map(message => {
          const { id, text } = message;
          const user = patientProfileId
            ? message.doctor_profile()
            : message.patient_profile();
          return isEmpty(user) ? (
            <ListItem key={id}>
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
              <ListItemText 
                primary={<small>{`${moment(message.created_at).format("DD/MM/YYYY HH:mm")}`}</small>}
                secondary={text} />
            </ListItem>
          ) : (
            <ListItem key={id}>
              <ListItemAvatar>
                <Avatar src={user.avatar_url}>
                  {user.name[0]}
                  {user.surname[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<small>{`${moment(message.created_at).format("DD/MM/YYYY HH:mm")}`}</small>}
                secondary={<><strong>{`${user.name} ${user.surname}:`}</strong>&nbsp;{text}</>}
              />
            </ListItem>
          );
        })}
      </List>
      <Formik initialValues={{ text: "" }} onSubmit={onMessageSubmit}>
        <Form className={classes.form}>
          <Field
            component={TextField}
            name="text"
            label={t("components.shared.message_list.message_text")}
            fullWidth
          />
          <IconButton type="submit">
            <SendIcon />
          </IconButton>
        </Form>
      </Formik>
    </>
  );
};

MessageList.contextTypes = {
  t: PropTypes.func
};

export default MessageList;
