import {
  GET_DOCTOR_PATIENT,
  GET_DOCTOR_PATIENT_SUCCESS,
  GET_DOCTOR_PATIENT_ERROR
} from "./const";
import {
  EDIT_DOCTOR_PATIENT,
  EDIT_DOCTOR_PATIENT_SUCCESS,
  EDIT_DOCTOR_PATIENT_ERROR
} from "./const";

export const getDoctorPatient = id => ({
  type: GET_DOCTOR_PATIENT,
  id
});
export const getDoctorPatientSuccess = doctorPatient => ({
  type: GET_DOCTOR_PATIENT_SUCCESS,
  doctorPatient
});
export const getDoctorPatientError = errors => ({
  type: GET_DOCTOR_PATIENT_ERROR,
  errors
});

export const editDoctorPatient = (id, doctorPatient) => ({
  type: EDIT_DOCTOR_PATIENT,
  id,
  doctorPatient
});
export const editDoctorPatientSuccess = () => ({
  type: EDIT_DOCTOR_PATIENT_SUCCESS
});
export const editDoctorPatientError = errors => ({
  type: EDIT_DOCTOR_PATIENT_ERROR,
  errors
});
