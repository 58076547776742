import axios from "./AxiosConfig";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const DoctorSource = {
  getProfiles: () =>
    api(
      axios.get(`${apiBaseUrl}/doctor_profiles.json`),
      "get_doctor_profiles"
    ),
  getProfile: profileId =>
    api(
      axios.get(`${apiBaseUrl}/doctor_profiles/${profileId}.json`),
      "get_doctor_profile"
    ),
  editProfile: (profileId, profile) =>
    api(
      axios.put(`${apiBaseUrl}/doctor_profiles/${profileId}.json`, {
        doctor_profile: profile
      }),
      "edit_doctor_profile"
    ),
  associatedPatients: profileId =>
    api(
      axios.get(
        `${apiBaseUrl}/doctor_profiles/${profileId}/patient_profiles.json`
      )
    )
};

export default DoctorSource;
