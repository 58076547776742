import React from "react";
import {
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import { includes, map } from "lodash";
import PropTypes from "prop-types";
import { FieldArray } from "formik";

const ImplantTable = ({ errors, implants, handleChange }, { t }) => {
  return (
    <FieldArray
      name="implants"
      render={arrayHepers => {
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t("components.doctor.shared.implant_table.implants")}
                </TableCell>
                <TableCell>
                  {t("components.doctor.shared.implant_table.min")}
                </TableCell>
                <TableCell>
                  {t("components.doctor.shared.implant_table.max")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(implants, (item, index) => {
                return (
                  <TableRow key={item.implantId}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <TextField
                        id={`implants.${index}.min`}
                        type="number"
                        value={item.min || ""}
                        inputProps={{
                          min: 0
                        }}
                        onChange={handleChange}
                        required
                        error={includes(errors, `min${item.implantId}`)}
                        placeholder={item.placeholders.min}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        id={`implants.${index}.max`}
                        type="number"
                        value={item.max || ""}
                        inputProps={{
                          min: item.min
                        }}
                        onChange={handleChange}
                        required
                        error={includes(errors, `max${item.implantId}`)}
                        placeholder={item.placeholders.max}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        );
      }}
    />
  );
};

ImplantTable.contextTypes = {
  t: PropTypes.func
};

export default ImplantTable;
