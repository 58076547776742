import { all, takeEvery, put, call } from "redux-saga/effects";
import { LOGIN, LOGOUT } from "../actions/const";
import { loginSuccess, postNotice, postError, logoutSuccess } from "../actions";
import { push } from "connected-react-router";
import UserSource from "../sources/UserSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";

function mapResponse(response) {
  const userNavigator = new ApiJsonNavigator(response.data);
  const patientId = userNavigator.data().patient_profile().id || null;
  const doctorId = userNavigator.data().doctor_profile().id || null;
  const data = {
    ...userNavigator.data().attributes(),
    patientId,
    doctorId
  };

  return {
    token: response.headers.authorization,
    data
  };
}

function* doLogin(loginParameters) {
  const { response, errors } = yield call(UserSource.login, loginParameters);

  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    return;
  }
  const { token, data } = yield call(mapResponse, response);
  yield put(loginSuccess(data, token));
  yield put(postNotice("login.success"));
  yield put(push("/"));
}

function* doLogout() {
  yield put(postNotice("logout.success"));
  yield put(logoutSuccess());
  yield put(push("/login"));
}

export const loginFlow = takeEvery(LOGIN, doLogin);
export const logoutFlow = takeEvery(LOGOUT, doLogout);
