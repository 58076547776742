export const POST_ERROR = "POST_ERROR";
export const POST_NOTICE = "POST_NOTICE";
export const CONSUME_ERRORS = "CONSUME_ERRORS";
export const CONSUME_NOTICES = "CONSUME_NOTICES";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = " GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";
export const GET_STATES_BY_COUNTRY = "GET_STATES_BY_COUNTRY";
export const GET_STATES_BY_COUNTRY_SUCCESS = " GET_STATES_BY_COUNTRY_SUCCESS";
export const GET_STATES_BY_COUNTRY_ERROR = "GET_STATES_BY_COUNTRY_ERROR";
export const GET_CITIES_BY_STATE = "GET_CITIES_BY_STATE";
export const GET_CITIES_BY_STATE_SUCCESS = " GET_CITIES_BY_STATE_SUCCESS";
export const GET_CITIES_BY_STATE_ERROR = "GET_CITIES_BY_STATE_ERROR";
export const GET_PROVINCES = "GET_PROVINCES";
export const GET_PROVINCES_SUCCESS = " GET_PROVINCES_SUCCESS";
export const GET_PROVINCES_ERROR = "GET_PROVINCES_ERROR";
export const USER_DATA_CHANGE = "USER_DATA_CHANGE";
export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";
export const SET_MODAL_OPEN = "SET_MODAL_OPEN";
export const ASSOCIATE_DOCTOR = "ASSOCIATE_DOCTOR";
export const ASSOCIATE_DOCTOR_SUCCESS = "ASSOCIATE_DOCTOR_SUCCESS";
export const ASSOCIATE_DOCTOR_ERROR = "ASSOCIATE_DOCTOR_ERROR";
export const ASSOCIATED_DOCTORS = "ASSOCIATED_DOCTORS";
export const ASSOCIATED_DOCTORS_SUCCESS = "ASSOCIATED_DOCTORS_SUCCESS";
export const ASSOCIATED_DOCTORS_ERROR = "ASSOCIATED_DOCTORS_ERROR";
export const DRG_INDEX = "DRG_INDEX";
export const DRG_INDEX_SUCCESS = "DRG_INDEX_SUCCESS";
export const DRG_INDEX_ERROR = "DRG_INDEX_ERROR";
export const ADD_DRG_REQUEST = "ADD_DRG_REQUEST";
export const ADD_DRG_REQUEST_SUCCESS = "ADD_DRG_REQUEST_SUCCESS";
export const ADD_DRG_REQUEST_ERROR = "ADD_DRG_REQUEST_ERROR";
export const DRG_REQUEST_INDEX = "DRG_REQUEST_INDEX";
export const DRG_REQUEST_INDEX_SUCCESS = "DRG_REQUEST_INDEX_SUCCESS";
export const DRG_REQUEST_INDEX_ERROR = "DRG_REQUEST_INDEX_ERROR";
export const DRG_REQUEST_SHOW = "DRG_REQUEST_SHOW";
export const DRG_REQUEST_SHOW_SUCCESS = "DRG_REQUEST_SHOW_SUCCESS";
export const DRG_REQUEST_SHOW_ERROR = "DRG_REQUEST_SHOW_ERROR";
export const CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS =
  "CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS";
export const CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_SUCCESS =
  "CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_SUCCESS";
export const CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_ERROR =
  "CROSSBORDER_HEALTCHCARE_AUTHORIZATIONS_ERROR";
export const GET_DOCTOR_PROFILES = "GET_DOCTOR_PROFILES";
export const GET_DOCTOR_PROFILES_SUCCESS = "GET_DOCTOR_PROFILES_SUCCESS";
export const GET_DOCTOR_PROFILES_ERROR = "GET_DOCTOR_PROFILES_ERROR";
export const GET_DOCTOR_PROFILE = "GET_DOCTOR_PROFILE";
export const GET_DOCTOR_PROFILE_SUCCESS = "GET_DOCTOR_PROFILE_SUCCESS";
export const GET_DOCTOR_PROFILE_ERROR = "GET_DOCTOR_PROFILE_ERROR";
export const EDIT_DOCTOR_PROFILE = "EDIT_DOCTOR_PROFILE";
export const EDIT_DOCTOR_PROFILE_SUCCESS = "EDIT_DOCTOR_PROFILE_SUCCESS";
export const EDIT_DOCTOR_PROFILE_ERROR = "EDIT_DOCTOR_PROFILE_ERROR";
export const SCHEDULED_FILE_INDEX = "SCHEDULED_FILE_INDEX";
export const SCHEDULED_FILE_INDEX_SUCCESS = "SCHEDULED_FILE_INDEX_SUCCESS";
export const SCHEDULED_FILE_INDEX_ERROR = "SCHEDULED_FILE_INDEX_ERROR";
export const ADD_SCHEDULED_FILE = "ADD_SCHEDULED_FILE";
export const ADD_SCHEDULED_FILE_SUCCESS = "ADD_SCHEDULED_FILE_SUCCESS";
export const ADD_SCHEDULED_FILE_ERROR = "ADD_SCHEDULED_FILE_ERROR";
export const GET_SCHEDULED_FILE = "GET_SCHEDULED_FILE";
export const GET_SCHEDULED_FILE_SUCCESS = "GET_SCHEDULED_FILE_SUCCESS";
export const GET_SCHEDULED_FILE_ERROR = "GET_SCHEDULED_FILE_ERROR";
export const EDIT_SCHEDULED_FILE = "EDIT_SCHEDULED_FILE";
export const EDIT_SCHEDULED_FILE_SUCCESS = "EDIT_SCHEDULED_FILE_SUCCESS";
export const EDIT_SCHEDULED_FILE_ERROR = "EDIT_SCHEDULED_FILE_ERROR";
export const CONFIRM_SCHEDULED_FILE = "CONFIRM_SCHEDULED_FILE";
export const CONFIRM_SCHEDULED_FILE_SUCCESS = "CONFIRM_SCHEDULED_FILE_SUCCESS";
export const CONFIRM_SCHEDULED_FILE_ERROR = "CONFIRM_SCHEDULED_FILE_ERROR";
export const CANCEL_SCHEDULED_FILE = "CANCEL_SCHEDULED_FILE";
export const CANCEL_SCHEDULED_FILE_SUCCESS = "CANCEL_SCHEDULED_FILE_SUCCESS";
export const CANCEL_SCHEDULED_FILE_ERROR = "CANCEL_SCHEDULED_FILE_ERROR";
export const GET_DOCTOR_CLINICS = "GET_DOCTOR_CLINICS";
export const GET_DOCTOR_CLINICS_SUCCESS = "GET_DOCTOR_CLINICS_SUCCESS";
export const GET_DOCTOR_CLINICS_ERROR = "GET_DOCTOR_CLINICS_ERROR";
export const DRG_REQUEST_SCHEDULE_INDEX = "DRG_REQUEST_SCHEDULE_INDEX";
export const DRG_REQUEST_SCHEDULE_INDEX_SUCCESS =
  "DRG_REQUEST_SCHEDULE_INDEX_SUCCESS";
export const DRG_REQUEST_SCHEDULE_INDEX_ERROR =
  "DRG_REQUEST_SCHEDULE_INDEX_ERROR";
export const ADD_DRG_REQUEST_SCHEDULE = "ADD_DRG_REQUEST_SCHEDULE";
export const ADD_DRG_REQUEST_SCHEDULE_SUCCESS =
  "ADD_DRG_REQUEST_SCHEDULE_SUCCESS";
export const ADD_DRG_REQUEST_SCHEDULE_ERROR = "ADD_DRG_REQUEST_SCHEDULE_ERROR";
export const GET_DRG_REQUEST_SCHEDULE = "GET_DRG_REQUEST_SCHEDULE";
export const GET_DRG_REQUEST_SCHEDULE_SUCCESS =
  "GET_DRG_REQUEST_SCHEDULE_SUCCESS";
export const GET_DRG_REQUEST_SCHEDULE_ERROR = "GET_DRG_REQUEST_SCHEDULE_ERROR";
export const EDIT_DRG_REQUEST_SCHEDULE = "EDIT_DRG_REQUEST_SCHEDULE";
export const EDIT_DRG_REQUEST_SCHEDULE_SUCCESS =
  "EDIT_DRG_REQUEST_SCHEDULE_SUCCESS";
export const EDIT_DRG_REQUEST_SCHEDULE_ERROR =
  "EDIT_DRG_REQUEST_SCHEDULE_ERROR";
export const DELETE_DRG_REQUEST_SCHEDULE = "DELETE_DRG_REQUEST_SCHEDULE";
export const DELETE_DRG_REQUEST_SCHEDULE_SUCCESS =
  "DELETE_DRG_REQUEST_SCHEDULE_SUCCESS";
export const DELETE_DRG_REQUEST_SCHEDULE_ERROR =
  "DELETE_DRG_REQUEST_SCHEDULE_ERROR";
export const RESET_DOCTOR_ERRORS = "RESET_DOCTOR_ERRORS";
export const RESET_PATIENT_ERRORS = "RESET_PATIENT_ERRORS";
export const RESET_DRG_REQUEST_SCHEDULE_ERRORS =
  "RESET_DRG_REQUEST_SCHEDULE_ERRORS";
export const RESET_SCHEDULED_FILE_ERRORS = "RESET_SCHEDULED_FILE_ERRORS";
export const ASSOCIATED_PATIENTS = "ASSOCIATED_PATIENTS";
export const ASSOCIATED_PATIENTS_SUCCESS = "ASSOCIATED_PATIENTS_SUCCESS";
export const ASSOCIATED_PATIENTS_ERROR = "ASSOCIATED_PATIENTS_ERROR";
export const GET_DOCTOR_PATIENT = "GET_DOCTOR_PATIENT";
export const GET_DOCTOR_PATIENT_SUCCESS = "GET_DOCTOR_PATIENT_SUCCESS";
export const GET_DOCTOR_PATIENT_ERROR = "GET_DOCTOR_PATIENT_ERROR";
export const EDIT_DOCTOR_PATIENT = "EDIT_DOCTOR_PATIENT";
export const EDIT_DOCTOR_PATIENT_SUCCESS = "EDIT_DOCTOR_PATIENT_SUCCESS";
export const EDIT_DOCTOR_PATIENT_ERROR = "EDIT_DOCTOR_PATIENT_ERROR";
export const SCHEDULED_FILE_DOCUMENT_INDEX = "SCHEDULED_FILE_DOCUMENT_INDEX";
export const DRG_REQUEST_SCHEDULE_DOCUMENT_INDEX =
  "DRG_REQUEST_SCHEDULE_DOCUMENT_INDEX";
export const DRG_REQUEST_DOCUMENT_INDEX = "DRG_REQUEST_DOCUMENT_INDEX";
export const DOCTOR_PATIENTS_DOCUMENT_INDEX = "DOCTOR_PATIENTS_DOCUMENT_INDEX";
export const EXAMINATION_DOCUMENT_INDEX = "EXAMINATION_DOCUMENT_INDEX";
export const DOCUMENT_INDEX_SUCCESS = "DOCUMENT_INDEX_SUCCESS";
export const DOCUMENT_INDEX_ERROR = "DOCUMENT_INDEX_ERROR";
export const DOCUMENT_ACTION_CREATOR = "DOCUMENT_ACTION_CREATOR";
export const ADD_DOCTOR_PATIENTS_DOCUMENT = "ADD_DOCTOR_PATIENTS_DOCUMENT";
export const ADD_DOCTOR_PATIENTS_DOCUMENT_SUCCESS =
  "ADD_DOCTOR_PATIENTS_DOCUMENT_SUCCESS";
export const ADD_DOCTOR_PATIENTS_DOCUMENT_ERROR =
  "ADD_DOCTOR_PATIENTS_DOCUMENT_ERROR";
export const DELETE_DOCTOR_PATIENTS_DOCUMENT =
  "DELETE_DOCTOR_PATIENTS_DOCUMENT";
export const DELETE_DOCTOR_PATIENTS_DOCUMENT_SUCCESS =
  "DELETE_DOCTOR_PATIENTS_DOCUMENT_SUCCESS";
export const DELETE_DOCTOR_PATIENTS_DOCUMENT_ERROR =
  "DELETE_DOCTOR_PATIENTS_DOCUMENT_ERROR";
export const ADD_EXAMINATION_DOCUMENT = "ADD_EXAMINATION_DOCUMENT";
export const ADD_EXAMINATION_DOCUMENT_SUCCESS =
  "ADD_EXAMINATION_DOCUMENT_SUCCESS";
export const ADD_EXAMINATION_DOCUMENT_ERROR = "ADD_EXAMINATION_DOCUMENT_ERROR";
export const DELETE_EXAMINATION_DOCUMENT = "DELETE_EXAMINATION_DOCUMENT";
export const DELETE_EXAMINATION_DOCUMENT_SUCCESS =
  "DELETE_EXAMINATION_DOCUMENT_SUCCESS";
export const DELETE_EXAMINATION_DOCUMENT_ERROR =
  "DELETE_EXAMINATION_DOCUMENT_ERROR";
export const DOCUMENT_TYPES_INDEX = "DOCUMENT_TYPES_INDEX";
export const DOCUMENT_TYPES_INDEX_SUCCESS = "DOCUMENT_TYPES_INDEX_SUCCESS";
export const DOCUMENT_TYPES_INDEX_ERROR = "DOCUMENT_TYPES_INDEX_ERROR";

/* Doctor timetables and telemedicine */
export const GET_DOCTOR_TIMETABLES = "GET_DOCTOR_TIMETABLES";
export const GET_DOCTOR_TIMETABLES_SUCCESS = "GET_DOCTOR_TIMETABLES_SUCCESS";
export const GET_DOCTOR_TIMETABLES_ERROR = "GET_DOCTOR_TIMETABLES_ERROR";
export const CREATE_DOCTOR_TIMETABLE = "CREATE_DOCTOR_TIMETABLE";
export const CREATE_DOCTOR_TIMETABLE_SUCCESS =
  "CREATE_DOCTOR_TIMETABLE_SUCCESS";
export const CREATE_DOCTOR_TIMETABLE_ERROR = "CREATE_DOCTOR_TIMETABLE_ERROR";
export const DESTROY_DOCTOR_TIMETABLE = "DESTROY_DOCTOR_TIMETABLE";
export const DESTROY_DOCTOR_TIMETABLE_SUCCESS =
  "DESTROY_DOCTOR_TIMETABLE_SUCCESS";
export const DESTROY_DOCTOR_TIMETABLE_ERROR = "DESTROY_DOCTOR_TIMETABLE_ERROR";

/* Examinations */
export const CREATE_EXAMINATION = "CREATE_EXAMINATION";
export const CREATE_EXAMINATION_SUCCESS = "CREATE_EXAMINATION_SUCCESS";
export const CREATE_EXAMINATION_ERROR = "CREATE_EXAMINATION_ERROR";

export const GET_EXAMINATIONS = "GET_EXAMINATIONS";
export const GET_EXAMINATIONS_SUCCESS = "GET_EXAMINATIONS_SUCCESS";
export const GET_EXAMINATIONS_ERROR = "GET_EXAMINATIONS_ERROR";

export const GET_EXAMINATION = "GET_EXAMINATION";
export const GET_EXAMINATION_SUCCESS = "GET_EXAMINATION_SUCCESS";
export const GET_EXAMINATION_ERROR = "GET_EXAMINATION_ERROR";

export const ACCEPT_EXAMINATION = "ACCEPT_EXAMINATION";
export const ACCEPT_EXAMINATION_SUCCESS = "ACCEPT_EXAMINATION_SUCCESS";
export const ACCEPT_EXAMINATION_ERROR = "ACCEPT_EXAMINATION_ERROR";

export const DECLINE_EXAMINATION = "DECLINE_EXAMINATION";
export const DECLINE_EXAMINATION_SUCCESS = "DECLINE_EXAMINATION_SUCCESS";
export const DECLINE_EXAMINATION_ERROR = "DECLINE_EXAMINATION_ERROR";

export const CANCEL_EXAMINATION = "CANCEL_EXAMINATION";
export const CANCEL_EXAMINATION_SUCCESS = "CANCEL_EXAMINATION_SUCCESS";
export const CANCEL_EXAMINATION_ERROR = "CANCEL_EXAMINATION_ERROR";

export const GET_EXAMINATION_MEETING_SIGNATURE =
  "GET_EXAMINATION_MEETING_SIGNATURE";
export const GET_EXAMINATION_MEETING_SIGNATURE_SUCCESS =
  "GET_EXAMINATION_MEETING_SIGNATURE_SUCCESS";
export const GET_EXAMINATION_MEETING_SIGNATURE_ERROR =
  "GET_EXAMINATION_MEETING_SIGNATURE_ERROR";

export const EXAMINATION_MESSAGE_INDEX = "EXAMINATION_MESSAGE_INDEX";
export const EXAMINATION_MESSAGE_INDEX_SUCCESS =
  "EXAMINATION_MESSAGE_INDEX_SUCCESS";
export const EXAMINATION_MESSAGE_INDEX_ERROR =
  "EXAMINATION_MESSAGE_INDEX_ERROR";
export const CREATE_EXAMINATION_MESSAGE = "CREATE_EXAMINATION_MESSAGE";
export const CREATE_EXAMINATION_MESSAGE_SUCCESS =
  "CREATE_EXAMINATION_MESSAGE_SUCCESS";
export const CREATE_EXAMINATION_MESSAGE_ERROR =
  "CREATE_EXAMINATION_MESSAGE_ERROR";
