import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Grid,
  LinearProgress,
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@material-ui/core";
import { map, isEmpty } from "lodash";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";

import { DRGRequestIndex } from "../../../../actions";
import ChipStatus from "../../../shared/ChipStatus";

const useStyles = makeStyles(theme => ({
  noData: {
    paddingTop: 40
  },
  flexContainer: {
    display: "flex"
  },
  flexItem: {
    flexGrow: 1
  },
  notesTooltip: {
    maxWidth: "none"
  },
  drgRequestSchedulePaper: {
    padding: 12,
    color: "green",
    backgroundColor: "rgba(0,125,0,0.125)",
    display: "flex"
  }
}));

const DRGRequestList = (props, context) => {
  const classes = useStyles();

  const { t } = context;

  const user = useSelector(state => state.user.current);
  const loading = useSelector(state => state.drgRequest.loading);
  const drgRequests = useSelector(state => state.drgRequest.list);

  const actionsDispatch = useDispatch();

  useEffect(() => {
    actionsDispatch(DRGRequestIndex(user.patientId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.patientId]);

  const noDRGRequest = (
    <Typography variant="body2" className={classes.noData}>
      {t("components.drg_request_list.no_drg_request")}
    </Typography>
  );
  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <Grid xs={12} container item>
        <Typography variant="h5" gutterBottom>
          {t("components.drg_request_list.title")}
        </Typography>
      </Grid>
      {isEmpty(drgRequests) ? (
        noDRGRequest
      ) : (
        <Grid container spacing={6}>
          {map(drgRequests, item => (
            <Grid
              className={classes.drgRequestCard}
              item
              xs={12}
              md={6}
              key={item.id}
            >
              <Card>
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography color="textSecondary">
                            {t("components.drg_request_list.drg_request_drg")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6" component="h2">
                            {item.drg.title}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography color="textSecondary">
                            {t(
                              "components.drg_request_list.drg_request_doctor"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6" component="h2">
                            {`${item.doctorProfile.name} ${item.doctorProfile.surname}`}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography color="textSecondary">
                            {t("components.drg_request_list.drg_request_notes")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            component="p"
                            style={{ overflow: "auto", height: "120px" }}
                          >
                            {item.backoffice_notes ||
                              t(
                                "components.drg_request_list.drg_request_empty_notes"
                              )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <ChipStatus
                            label={
                              <Typography color="textSecondary">
                                {t("components.drg_request_list.request")}
                              </Typography>
                            }
                            status={item.aasm_state}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" component="p">
                            {moment(item.created_at).format(
                              t("locale.display_datetime")
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <ChipStatus
                            label={
                              <Typography color="textSecondary">
                                {t("components.drg_request_list.schedule")}
                              </Typography>
                            }
                            status={item.drgRequestSchedule.aasmState}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" component="p">
                            {isEmpty(item.drgRequestSchedule)
                              ? t("components.drg_request_list.not_scheduled")
                              : moment(
                                  item.drgRequestSchedule.startTime
                                ).format(t("locale.display_datetime"))}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    variant="contained"
                    component={Link}
                    to={`/drg_requests/${item.id}/documents`}
                  >
                    {t("components.drg_request_list.drg_request_documents")}
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    component={Link}
                    disabled={isEmpty(item.drgRequestSchedule)}
                    to={`/drg_request_schedules/${
                      (item.drgRequestSchedule || {}).id
                    }/documents`}
                  >
                    {t(
                      "components.drg_request_list.drg_request_schedule_documents"
                    )}
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={!item.document_url}
                    href={item.document_url || ""}
                    target="_blank"
                  >
                    {t("components.drg_request_list.drg_request_download")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

DRGRequestList.contextTypes = {
  t: PropTypes.func
};

export default DRGRequestList;
