import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  IconButton,
  Dialog
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit as EditIcon } from "@material-ui/icons";
import { map, isEmpty } from "lodash";

import "moment/locale/it";
import "moment/locale/en-gb";

import ChipStatus from "../../../shared/ChipStatus";
import { resetScheduledFileErrors } from "../../../../actions";
import AddScheduledFileDialogBody from "../home/AddScheduledFileDialogBody";
import Date from "../../../shared/Date";

const useStyles = makeStyles(theme => ({
  actionCell: {
    width: 1
  },
  noData: {
    paddingTop: 40
  },
  tableContainer: {
    overflow: "auto"
  },
  titleContainer: {
    display: "flex",
    alignItems: "center"
  }
}));

const ScheduledFileList = (props, context) => {
  const { t } = context;

  const classes = useStyles();

  const list = useSelector(state => state.scheduledFile.list);

  const [modalOpen, setModalOpen] = useState(false);

  const actionsDispatch = useDispatch();

  const edit = id => () => actionsDispatch(push(`/scheduled_files/${id}`));

  const noScheduledFiles = (
    <Typography variant="body2" className={classes.noData}>
      {t("components.doctor.shared.scheduled_file_list.no_scheduled_file")}
    </Typography>
  );

  const handleModal = open => {
    !open && actionsDispatch(resetScheduledFileErrors());
    setModalOpen(open);
  };

  return (
    <>
      <Grid container justify="center">
        <Grid xs={12} item className={classes.tableContainer}>
          {isEmpty(list) ? (
            noScheduledFiles
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">
                    {t("components.doctor.shared.scheduled_file_list.label")}
                  </TableCell>
                  <TableCell width="20%">
                    {t("components.doctor.shared.scheduled_file_list.clinic")}
                  </TableCell>
                  <TableCell width="20%">
                    {t("components.doctor.shared.scheduled_file_list.from")}
                  </TableCell>
                  <TableCell width="20%">
                    {t("components.doctor.shared.scheduled_file_list.to")}
                  </TableCell>
                  <TableCell width="10%">
                    {t("components.doctor.shared.scheduled_file_list.status")}
                  </TableCell>
                  <TableCell width="5%" className={classes.actionCell} />
                </TableRow>
              </TableHead>
              <TableBody>
                {map(list, item => (
                  <TableRow key={item.data.id}>
                    <TableCell>
                      <Typography>{item.data.label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item.clinic.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Date date={item.data.from} />
                    </TableCell>
                    <TableCell>
                      <Date date={item.data.to} />
                    </TableCell>
                    <TableCell>
                      <ChipStatus status={item.data.aasm_state} />
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={t(
                          "components.doctor.shared.scheduled_file_list.edit"
                        )}
                        placement="top"
                      >
                        <IconButton onClick={edit(item.data.id)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
      <Dialog open={modalOpen} onClose={() => handleModal(false)}>
        <AddScheduledFileDialogBody closeModal={() => handleModal(false)} />
      </Dialog>
    </>
  );
};

ScheduledFileList.contextTypes = {
  t: PropTypes.func
};

export default ScheduledFileList;
