import {
  DOCUMENT_TYPES_INDEX,
  DOCUMENT_TYPES_INDEX_SUCCESS,
  DOCUMENT_TYPES_INDEX_ERROR
} from "./const";

export const documentTypesIndex = (query) => ({ type: DOCUMENT_TYPES_INDEX, query });

export const documentTypesIndexSuccess = documentTypes => ({
  type: DOCUMENT_TYPES_INDEX_SUCCESS,
  documentTypes
});

export const documentTypesIndexError = errors => ({
  type: DOCUMENT_TYPES_INDEX_ERROR,
  errors
});
