import axios from "./AxiosConfig";
import config from "../config";

import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const DRGSource = {
  index: patientId =>
    api(
      axios.get(`${apiBaseUrl}/patient_profiles/${patientId}/drgs.json`),
      "drg_index"
    )
};

export default DRGSource;
