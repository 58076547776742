import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Button,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  DialogActions,
  DialogContentText,
  DialogContent,
  TextField,
  MenuItem
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { includes } from "lodash";
import { Formik } from "formik";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import {
  addDoctorPatientsDocument,
  documentTypesIndex,
  postError
} from "../../../actions";

const useStyles = makeStyles(theme => ({
  addDoctorModalTitleContainer: {
    display: "flex",
    alignItems: "center"
  },
  addDoctorModalTitle: {
    flexGrow: 1
  },
  dropzone: { paddingTop: 10, paddingRight: 10, textAlign: "center" }
}));

const AddDocumentDialog = ({ open, closeModal, doctorPatientsId }, context) => {
  const { t } = context;

  const actionsDispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    actionsDispatch(documentTypesIndex({'q[tags_name_in]': 'doctor_edit_doctor_patient_only'}));
  }, [actionsDispatch]);

  const documentTypes = useSelector(state => state.documentType.list);
  const errors = useSelector(state => state.document.errors);
  const loading = useSelector(state => state.document.loading);

  const handleDocumentUpload = (handleChange, document) => value => {
    if (value.meta.status === "done") {
      document = value.file;
    } else if (value.meta.status === "removed") {
      document = {};
    }
    handleChange({ target: { name: "document", value: document } });
  };

  return (
    <Dialog open={open} onClose={closeModal}>
      <Formik
        onSubmit={values => {
          if (values.document !== undefined && values.document !== {}) {
            actionsDispatch(
              addDoctorPatientsDocument(
                doctorPatientsId,
                values.description,
                values.documentType,
                values.document,
                () => closeModal()
              )
            );
          } else {
            actionsDispatch(postError("message.file_not_present"));
          }
        }}
      >
        {({ handleChange, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle
              disableTypography
              className={classes.addDoctorModalTitleContainer}
            >
              <Typography variant="h6" className={classes.addDoctorModalTitle}>
                {t("components.doctor.shared.add_document_dialog.title")}
              </Typography>
              <Tooltip
                title={t("components.pages.patient_dashboard.modal_close")}
                placement="top"
              >
                <IconButton size="small" onClick={closeModal}>
                  <Close />
                </IconButton>
              </Tooltip>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("components.doctor.shared.add_document_dialog.text")}
              </DialogContentText>

              <TextField
                type="text"
                name="description"
                label={t(
                  "components.doctor.shared.add_document_dialog.description"
                )}
                required
                value={values.description || ""}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
              <TextField
                select
                name="documentType"
                label={t(
                  "components.doctor.shared.add_document_dialog.document_type"
                )}
                required
                value={values.documentType || ""}
                onChange={handleChange}
                margin="normal"
                fullWidth
                error={includes(errors, "document_type")}
              >
                {documentTypes.map(item => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField>
              <div className={classes.dropzone}>
                <Dropzone
                  accept=".pdf, .p7m, .jpg, .bmp, .tif, .zip, .rar, .dcm, .doc, .docx, .odt, .ods, .xls, .xlsx"
                  maxFiles={1}
                  inputContent={t("message.dropzone.text")}
                  styles={{ dropzone: { overflow: "unset" } }}
                  onChangeStatus={handleDocumentUpload(
                    handleChange,
                    values.document
                  )}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                fullWidth
              >
                {t("components.doctor.shared.add_document_dialog.add_button")}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

AddDocumentDialog.contextTypes = {
  t: PropTypes.func
};

export default AddDocumentDialog;
