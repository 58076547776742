import { map, mapKeys, snakeCase } from "lodash";
import { all, takeEvery, put, call, takeLatest } from "redux-saga/effects";
import DoctorSource from "../sources/DoctorSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import {
  GET_DOCTOR_PROFILES,
  GET_DOCTOR_PROFILE,
  EDIT_DOCTOR_PROFILE,
  ASSOCIATED_PATIENTS
} from "../actions/const";
import {
  postError,
  getDoctorProfilesSuccess,
  getDoctorProfilesError,
  getDoctorProfileError,
  getDoctorProfileSuccess,
  editDoctorProfileError,
  editDoctorProfileSuccess,
  postNotice,
  associatedPatientsSuccess,
  associatedPatientsError
} from "../actions";

const getLiteMapFromProfile = (profile) => {
  return {
    id: profile.id, 
    name: profile.name,
    surname: profile.surname,
    residentialCity: profile.residential_city,
    specializations: profile.human_specializations,
    medical_services: profile.medical_services,
    avatar_url: profile.avatar_url
  };
};

const getMapFromProfile = (profile) => {
  return {
    id: profile.id, 
    name: profile.name,
    surname: profile.surname,
    fiscalCode: profile.fiscal_code,
    birthDate: profile.birth_date,
    birthCountry: profile.birth_country,
    birthState: profile.birth_state,
    birthCity: profile.birth_city,
    residentialAddress: profile.residential_address,
    residentialPostalCode: profile.residential_postal_code,
    residentialCountry: profile.residential_country,
    residentialState: profile.residential_state,
    residentialCity: profile.residential_city,
    phone: profile.phone,
    mobilePhone: profile.mobile_phone,
    emailAddress: profile.email_address,
    specializations: profile.human_specializations,
    presentation: profile.presentation,
    medical_services: profile.medical_services,
    avatar_url: profile.avatar_url
  };
};

const mapProfiles = response => {
  const { data } = response;

  const profileNavigator = new ApiJsonNavigator(data);
  const profiles = profileNavigator.data();

  const mappedProfiles = profiles.map((p, i) => {
    const profile = getLiteMapFromProfile(p);
    profile['medical_services'] = p.medical_services().map(msn => msn.attributes());
    return profile;
  });

  return { profiles: mappedProfiles };
};

const mapProfile = response => {
  const { data } = response;
  const profileNavigator = new ApiJsonNavigator(data);
  const profile = profileNavigator.data().attributes();
  profile['medical_services'] = profileNavigator.data().medical_services().map(msn => msn.attributes());

  return { profile: getMapFromProfile(profile) };
};

const mapEditProfile = response => {
  const { data } = response;
  const profileNavigator = new ApiJsonNavigator(data);
  const profile = profileNavigator.data().attributes();

  return { profile: getMapFromProfile(profile) };
};

const mapAssociatedPatientsList = response => {
  const patientsNavigator = new ApiJsonNavigator(response.data);
  return {
    associatedPatientsList: map(patientsNavigator.data(), item =>
      item.attributes()
    )
  };
};

function* getProfiles() {
  const { response, errors } = yield call(DoctorSource.getProfiles);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getDoctorProfilesError(errors.pointers));
    return;
  }
  const { profiles } = yield call(mapProfiles, response);
  yield put(getDoctorProfilesSuccess(profiles));
}

function* getProfile({ profileId }) {
  const { response, errors } = yield call(DoctorSource.getProfile, profileId);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getDoctorProfileError(errors.pointers));
    return;
  }
  const { profile } = yield call(mapProfile, response);
  yield put(getDoctorProfileSuccess(profile));
}

function* editProfile({ profileId, profile }) {
  const profileData = mapKeys(profile, (val, key) => snakeCase(key));
  const { response, errors } = yield call(
    DoctorSource.editProfile,
    profileId,
    profileData
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(editDoctorProfileError(errors.pointers));
    return;
  }
  const { profile: editProfile } = yield call(mapEditProfile, response);
  yield put(postNotice("edit_doctor_profile.success"));
  yield put(editDoctorProfileSuccess(editProfile));
}

function* getAssociatedPatients({ profileId }) {
  const { response, errors } = yield call(
    DoctorSource.associatedPatients,
    profileId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(associatedPatientsError(errors.pointers));
    return;
  }
  const { associatedPatientsList } = yield call(
    mapAssociatedPatientsList,
    response
  );
  yield put(associatedPatientsSuccess(associatedPatientsList));
}

export const getDoctorProfilesFlow = takeEvery(GET_DOCTOR_PROFILES, getProfiles);
export const getDoctorProfileFlow = takeEvery(GET_DOCTOR_PROFILE, getProfile);
export const editDoctorProfileFlow = takeEvery(
  EDIT_DOCTOR_PROFILE,
  editProfile
);
export const getAssociatedPatientsFlow = takeLatest(
  ASSOCIATED_PATIENTS,
  getAssociatedPatients
);
