import { cloneDeep } from "lodash";

import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR } from "./../actions/const";
import { LOGOUT, LOGOUT_SUCCESS, LOGOUT_ERROR } from "./../actions/const";

import { USER_DATA_CHANGE } from "./../actions/const";

import UserSource from "./../sources/UserSource";

/* Retrieve user infos from local storage */
let user_token = localStorage.getItem("user_token");

const initialState = {
  loading: false,
  current: {
    patientId: localStorage.getItem("patient_profile_id"),
    doctorId: localStorage.getItem("doctor_profile_id")
  },
  token: user_token
};

function reducer(state = initialState, action) {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case LOGIN: {
      nextState.loading = true;

      nextState.token = null;
      nextState.current = {};

      return nextState;
    }
    case LOGIN_SUCCESS: {
      const { data, token } = action;

      nextState.token = token;
      nextState.current = data;

      nextState.loading = false;
      return nextState;
    }
    case LOGOUT: {
      nextState.loading = true;
      UserSource.logout();
      return nextState;
    }
    case LOGOUT_SUCCESS:
    case LOGIN_ERROR:
    case LOGOUT_ERROR: {
      nextState.current = {};
      nextState.token = null;
      nextState.loading = false;
      return nextState;
    }
    case USER_DATA_CHANGE: {
      const { name, value } = action.change;
      nextState.current[name] = value;
      return nextState;
    }
    default: {
      return state;
    }
  }
}

export default reducer;
