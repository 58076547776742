import axios from "./AxiosConfig";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const CountrySource = {
  getCountries: () =>
    api(axios.get(`${apiBaseUrl}/countries.json`), "countries"),
  getStatesByCountry: country =>
    api(axios.get(`${apiBaseUrl}/countries/${country}/states.json`), "states"),
  getCitiesByState: (country, state) =>
    api(
      axios.get(
        `${apiBaseUrl}/countries/${country}/states/${state}/cities.json`
      ),
      "cities"
    ),
  getProvinces: (country, state) =>
    api(
      axios.get(
        `${apiBaseUrl}/countries/${country}/states/${state}/provinces.json`
      ),
      "cities"
    )
};

export default CountrySource;
