import { cloneDeep } from "lodash";

import {
  GET_DOCTOR_TIMETABLES,
  GET_DOCTOR_TIMETABLES_SUCCESS,
  GET_DOCTOR_TIMETABLES_ERROR,
  CREATE_DOCTOR_TIMETABLE,
  CREATE_DOCTOR_TIMETABLE_SUCCESS,
  CREATE_DOCTOR_TIMETABLE_ERROR,
  DESTROY_DOCTOR_TIMETABLE,
  DESTROY_DOCTOR_TIMETABLE_SUCCESS,
  DESTROY_DOCTOR_TIMETABLE_ERROR
} from "../actions/const";

const initialState = {
  loading: false,
  list: [],
  errors: []
};

const reducer = (state = initialState, action) => {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case GET_DOCTOR_TIMETABLES: {
      nextState.loading = true;
      return nextState;
    }
    case GET_DOCTOR_TIMETABLES_SUCCESS: {
      const { doctorTimetables } = action;
      nextState.list = doctorTimetables;
      nextState.loading = false;
      return nextState;
    }
    case GET_DOCTOR_TIMETABLES_ERROR: {
      nextState.list = [];
      nextState.loading = false;
      return nextState;
    }
    case CREATE_DOCTOR_TIMETABLE: {
      nextState.loading = true;
      return nextState;
    }
    case CREATE_DOCTOR_TIMETABLE_SUCCESS: {
      const { doctorTimetable } = action;
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case CREATE_DOCTOR_TIMETABLE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    case DESTROY_DOCTOR_TIMETABLE: {
      nextState.loading = true;
      return nextState;
    }
    case DESTROY_DOCTOR_TIMETABLE_SUCCESS: {
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case DESTROY_DOCTOR_TIMETABLE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
