import React from "react";
import { useSelector } from "react-redux";

import EditPatientProfile from "../patient/pages/EditPatientProfile";
import EditDoctorProfile from "../doctor/pages/EditDoctorProfile";

const EditProfile = (props, context) => {
  const { doctorId, patientId } = useSelector(state => state.user.current);
  return !!doctorId ? (
    <EditDoctorProfile />
  ) : !!patientId ? (
    <EditPatientProfile />
  ) : null;
};

export default EditProfile;
