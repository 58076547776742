import { cloneDeep } from "lodash";

import * as types from "../actions/const";

const initialState = {
  loading: false,
  list: [],
  current: {},
  meeting_signature: false,
  errors: []
};

const reducer = (state = initialState, action) => {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case types.CREATE_EXAMINATION: {
      nextState.loading = true;
      return nextState;
    }
    case types.CREATE_EXAMINATION_SUCCESS: {
      const { examination } = action;
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case types.CREATE_EXAMINATION_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    case types.GET_EXAMINATIONS: {
      nextState.loading = true;
      return nextState;
    }
    case types.GET_EXAMINATIONS_SUCCESS: {
      const { examinations } = action;
      nextState.list = examinations;
      nextState.loading = false;
      return nextState;
    }
    case types.GET_EXAMINATIONS_ERROR: {
      nextState.list = [];
      nextState.loading = false;
      return nextState;
    }
    case types.GET_EXAMINATION: {
      nextState.loading = true;
      return nextState;
    }
    case types.GET_EXAMINATION_SUCCESS: {
      const { examination } = action;
      nextState.current = examination;
      nextState.loading = false;
      return nextState;
    }
    case types.GET_EXAMINATION_ERROR: {
      nextState.current = {};
      nextState.loading = false;
      return nextState;
    }
    case types.ACCEPT_EXAMINATION: {
      nextState.loading = true;
      return nextState;
    }
    case types.ACCEPT_EXAMINATION_SUCCESS: {
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case types.ACCEPT_EXAMINATION_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    case types.DECLINE_EXAMINATION: {
      nextState.loading = true;
      return nextState;
    }
    case types.DECLINE_EXAMINATION_SUCCESS: {
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case types.DECLINE_EXAMINATION_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    case types.CANCEL_EXAMINATION: {
      nextState.loading = true;
      return nextState;
    }
    case types.CANCEL_EXAMINATION_SUCCESS: {
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case types.CANCEL_EXAMINATION_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    case types.GET_EXAMINATION_MEETING_SIGNATURE: {
      nextState.loading = true;
      return nextState;
    }
    case types.GET_EXAMINATION_MEETING_SIGNATURE_SUCCESS: {
      const { meeting_signature } = action;
      nextState.meeting_signature = meeting_signature;
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case types.GET_EXAMINATION_MEETING_SIGNATURE_ERROR: {
      const { errors } = action;
      nextState.meeting_signature = false;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    /*
    case types.DESTROY_DOCTOR_TIMETABLE: {
      nextState.loading = true;
      return nextState;
    }
    case types.DESTROY_DOCTOR_TIMETABLE_SUCCESS: {
      nextState.errors = [];
      nextState.loading = false;
      return nextState;
    }
    case types.DESTROY_DOCTOR_TIMETABLE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loading = false;
      return nextState;
    }
    */
    default: {
      return state;
    }
  }
};

export default reducer;
