import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { setLanguage } from "redux-i18n";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  ClickAwayListener,
  TextField,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from "@material-ui/core";
import {
  AccountCircle,
  ExitToApp,
  Edit as EditIcon,
  Home,
  Dashboard
} from "@material-ui/icons";

import { logout } from "../../../../actions";

import DoctorMenu from "./../../../doctor/shared/DoctorMenu";
import PatientMenu from "./../../../patient/shared/PatientMenu";
import config from "./../../../../config";

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  sideBar: {
    textAlign: "left",
    minWidth: 240
  },
  logo: {
    width: 72,
    height: 72,
    background: "rgba(255, 255, 255, 1)",
    "-moz-border-radius": 36,
    "-webkit-border-radius": 36,
    "border-radius": 36,
    padding: 16
  }
}));

let itFlag = require("../../../../images/IT.png");
let enFlag = require("../../../../images/EN.png");
let logo = require("../../../../images/sef_logo.png");

const NavBar = (props, context) => {
  const { t } = context;

  const userToken = useSelector(state => state.user.token);

  const { doctorId, patientId } = useSelector(state => state.user.current);

  const currentLang = useSelector(state => state.i18nState.lang);

  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useDispatch();

  const classes = useStyles();

  const [postProcessDoctorTeleconsultPatientEmail, setPostProcessDoctorTeleconsultPatientEmail] = useState(0);
  const [postProcessDoctorTeleconsultPatientId, setPostProcessDoctorTeleconsultPatientId] = useState(0);

  useEffect(() => {
    if(patientId) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/post_process_doctor_teleconsult.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ patientId: patientId })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.status1 && data.status3){
            setPostProcessDoctorTeleconsultPatientEmail(data.status4)
            setPostProcessDoctorTeleconsultPatientId(data.status2)
          } else{
            console.log(data.status)
          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [patientId]);

  return (
    <div>
      <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
        <AppBar position="static">
          <Toolbar>
            {/*<Button color="inherit" onClick={() => dispatch(push("/"))}>*/}
            {/*  <div className={classes.logo}>*/}
            {/*    <img height={40} width={40} alt="" src={logo} />*/}
            {/*  </div>*/}
            {/*</Button>*/}
            <div className={classes.grow} />
            <TextField
              name="language"
              select
              value={currentLang}
              onChange={event => dispatch(setLanguage(event.target.value))}
              InputProps={{
                disableUnderline: true
              }}
            >
              <MenuItem value="it">
                <Tooltip
                  title={t("components.shared.layouts.base.navbar.italian")}
                  placement="top"
                >
                  <>
                  <img src={itFlag} alt="" width={25} />
                  &nbsp;
                  {t("components.shared.layouts.base.navbar.italian")}
                  </>
                </Tooltip>
              </MenuItem>
              <MenuItem value="en">
                <Tooltip
                  title={t("components.shared.layouts.base.navbar.english")}
                  placement="top"
                >
                  <>
                  <img src={enFlag} alt="" width={25} />
                  &nbsp;
                  {t("components.shared.layouts.base.navbar.english")}
                  </>
                </Tooltip>
              </MenuItem>
            </TextField>
            <Tooltip
              title={t("components.shared.layouts.base.navbar.menu")}
              placement="bottom"
            >
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="end"
                onClick={() => setMenuOpen(true)}
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <Drawer
            anchor="right"
            open={menuOpen}
            onClose={() => setMenuOpen(true)}
            className={classes.sideBar}
            classes={{ paper: classes.sideBar }}
          >
            <List>
              <ListItem
                button
                onClick={() => {
                  dispatch(push("/"));
                }}
              >
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText>
                  {t("components.shared.layouts.base.navbar.home")}
                </ListItemText>
              </ListItem>

              {patientId &&
                <ListItem
                  button
                  onClick={() => {
                    dispatch(push("/dashboard"));
                  }}
                >
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText>
                    {t("components.shared.layouts.base.navbar.dashboard")}
                  </ListItemText>
                </ListItem>
              }
            </List>

            {doctorId && 
              <div>
                <Divider />
                <List>
                  <ListItemText
                    inset
                    primaryTypographyProps={{
                      variant: "overline"
                    }}
                  >
                    {t("components.doctor.shared.doctor_menu.label")}
                  </ListItemText>
                </List>
                <List>
                  <ListItem
                    button
                    onClick={() => {
                      dispatch(push("/dashboard"));
                    }}
                  >
                    <ListItemIcon>
                      <Dashboard />
                    </ListItemIcon>
                    <ListItemText>
                      {t("components.shared.layouts.base.navbar.dashboard.surgery")}
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            }

            {!!patientId && <PatientMenu />}
            {!!doctorId && <DoctorMenu />}

            <div className={classes.grow} />
            <List>
              {
                (postProcessDoctorTeleconsultPatientId == patientId)
                ?
                  null
                : 
                  <ListItem
                    button
                    onClick={() => {
                      setMenuOpen(false);
                      dispatch(push("/edit_profile"));
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {t("components.shared.layouts.base.navbar.edit_profile")}
                    </ListItemText>
                  </ListItem>
              }
              {userToken ? (
                <ListItem
                  button
                  onClick={() => {
                    setMenuOpen(false);
                    dispatch(logout());
                  }}
                >
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText>
                    {t("components.shared.layouts.base.navbar.logout")}
                  </ListItemText>
                </ListItem>
              ) : null}
            </List>
          </Drawer>
        </AppBar>
      </ClickAwayListener>
    </div>
  );
};

NavBar.contextTypes = {
  t: PropTypes.func
};

export default NavBar;
