import ApiMock from './apiMock'

import {
	checkForUser,
	findUserByToken,
	getPatientProfileByToken,
	addPatientUser,
	getDoctorProfileByToken
} from './data/MocksData'

ApiMock.onPost(/^.+\/login.json/).reply((config) => {
	const { email, password } = JSON.parse(config.data).user

	const user = checkForUser(email, password)

	/* Re-build response */
	if (user) {
		const headers = {
			authorization: user.token,
			'cache-control': 'max-age=0, private, must-revalidate',
			'content-type': 'application/json; charset=utf-8'
		}

		const patient = getPatientProfileByToken(user.token)

		let patient_profile = {
			data: null
		}
		let doctor_profile = {
			data: null
		}

		if (patient) {
			patient_profile = {
				data: {
					id: patient.id,
					type: 'patient_profile'
				}
			}
		} else {
			const doctor = getDoctorProfileByToken(user.token)
			if (doctor) {
				doctor_profile = {
					data: {
						id: doctor.id,
						type: 'doctor_profile'
					}
				}
			}
		}

		const userData = {
			data: {
				id: user.id,
				type: 'user',
				attributes: {
					email: user.email
				},
				relationships: {
					doctor_profile,
					patient_profile
				}
			}
		}

		return [201, userData, headers]
	} else {
		return [
			401,
			{
				errors: [
					{
						status: 401,
						title: 'Unauthorized',
						detail: 'You need to login to authorize this request.',
						source: { pointer: '/data/attributes/login_or_password' }
					}
				]
			}
		]
	}
})

ApiMock.onDelete(/^.+\/logout.json/).reply((config) => {
	const token = config.headers['Authorization']

	/* Re-build response */
	if (findUserByToken(token)) {
		return [200]
	} else {
		return [
			401,
			{
				errors: [
					{
						status: 401,
						title: 'Unauthorized',
						detail: 'You need to login to authorize this request.',
						source: { pointer: '/data/attributes/login_or_password' }
					}
				]
			}
		]
	}
})

ApiMock.onPost(/^.+\/sign_up.json/).reply((config) => {
	const profile = JSON.parse(config.data)

	const user = addPatientUser(profile)

	return [
		201,
		{
			id: user.id,
			type: 'user',
			attributes: {
				email: user.email
			}
		}
	]
})
