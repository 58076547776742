import config from './../../config'

const mocks = {}

if (config.apiMock) {
    mocks.UserApiMock = require('./UserApiMock')
    mocks.PatientApiMock = require('./PatientApiMock')
    mocks.DoctorApiMock = require('./DoctorApiMock')
}

export default mocks