import React, { useState, useEffect } from "react";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment/locale/it";
import "moment/locale/en-gb";

import { getDoctorTimetables } from "./../../../../actions";

import { createExamination } from "../../../../actions";

const useStyles = makeStyles(theme => ({
  editScheduledFileTitle: {
    display: "flex"
  },
  editScheduledFileTypography: {
    flexGrow: 1
  },
  editButton: {
    marginBottom: theme.spacing(1)
  }
}));

const AskMeetDialog = (props, context) => {
  const { t } = context;
  
  const { open, handleModal, medicalService, doctorProfileId } = props;

  const classes = useStyles();

  const actionsDispatch = useDispatch();

  const [currentDoctorTimetableId, setCurrentDoctorTimetableId] = useState();

  const currentLang = useSelector(state => state.i18nState.lang);
  moment.locale(currentLang);

  const user = useSelector(state => state.user.current);

  const doctorTimetables = useSelector(state => {
    if (isEmpty(state.doctorTimetable.list)) return [];
    return state.doctorTimetable.list.data().map(tt => {
      const { id, start_at, end_at } = tt.attributes();
      const examination = tt.examination();
      const patientProfile = isEmpty(examination)
        ? {}
        : examination.patient_profile();

      return {
        id,
        title: isEmpty(patientProfile)
          ? "-"
          : `${patientProfile.attributes().name} ${
              patientProfile.attributes().surname
            }`,
        dateString: `${moment(start_at).format("dddd DD/MM/YYYY HH:mm")} - ${moment(
          end_at
        ).format("HH:mm")}`,
        examination: isEmpty(examination)
          ? examination
          : examination.attributes(),
        patientProfile: isEmpty(patientProfile)
          ? patientProfile
          : patientProfile.attributes()
      };
    });
  });

  const getDoctorTimetableSlots = () => {
    if (isEmpty(medicalService)) return;

    const filterStart = moment();
    const filterEnd = moment()
      .endOf("month")
      .add(7, "days");
    actionsDispatch(
      getDoctorTimetables(doctorProfileId, filterStart, filterEnd)
    );
  };

  useEffect(getDoctorTimetableSlots, [actionsDispatch, medicalService, open]);
  useEffect(() => {
    setCurrentDoctorTimetableId(null);
  }, [actionsDispatch, medicalService, open]);

  const handleAskMeet = () => {
    const examination = {
      medical_service_id: medicalService.id,
      doctor_timetable_id: currentDoctorTimetableId,
      request: ""
    };
    actionsDispatch(
      createExamination(examination, (examination) => {
        handleModal(true);
        actionsDispatch(push(`/patient/examinations/${examination.data().id}`));
      })
    );
  };

  const noDoctorTimetableDialogContent = (
    <DialogContent>
      <DialogContentText>
        {t("components.patient.telemedicine.no_timetable_slot_text")}
      </DialogContentText>
    </DialogContent>  
  );

  const doctorTimetableDialogContent = (
    <>
      <DialogContent>
        <DialogContentText>
          {t("components.patient.telemedicine.module_text")}
        </DialogContentText>

        <div>
          <FormControl fullWidth>
            <InputLabel id="doctor-timetable-slot-label">
              {t("components.patient.telemedicine.doctor_timetable_slot_label")}
            </InputLabel>
            <Select
              labelId="doctor-timetable-slot-label"
              id="doctor-timetable-slot"
              value={currentDoctorTimetableId}
              onChange={event => {
                setCurrentDoctorTimetableId(event.target.value);
              }}
              fullWidth
            >
              {doctorTimetables.map(dt => {
                return (
                  <MenuItem value={dt.id} key={dt.id}>
                    {dt.dateString}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!currentDoctorTimetableId}
          onClick={handleAskMeet}
        >
          {t("components.patient.telemedicine.ask_meet_button")}
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Dialog open={open} onClose={() => handleModal(false)}>
      <DialogTitle disableTypography className={classes.editScheduledFileTitle}>
        <Typography
          variant="h6"
          className={classes.editScheduledFileTypography}
        >
          {t("components.patient.telemedicine.module_title")}
        </Typography>
        <Tooltip
          title={t("components.patient.telemedicine.module_close")}
          placement="top"
        >
          <IconButton size="small" onClick={() => handleModal(false)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      { isEmpty(doctorTimetables) ? noDoctorTimetableDialogContent : doctorTimetableDialogContent }
    </Dialog>
  );
};

AskMeetDialog.contextTypes = {
  t: PropTypes.func
};

export default AskMeetDialog;
