import React, { useState, useEffect, useContext } from "react";
import {TeleconsultContext} from "../../context/TeleconsultContext";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid
} from "@material-ui/core";
import { Card, CardContent, Link } from "@material-ui/core";
import PropTypes from "prop-types";
import { push } from "connected-react-router";

import { login } from "../../actions";

import config from "./../../config";

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    display: "flex",
    flexDirection: "column"
  },
  loginButton: {},
  signUpButton: {
    marginTop: 8
  },
  forgotPassword: {
    textAlign: "left"
  }
}));

const Login = (props, context) => {
  const { t } = context;

  const classes = useStyles();

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [rembemberMe, setRememberMe] = useState(false);

  const [teleconsultContextValue, setTeleconsultContextValue] = useContext(TeleconsultContext);

  // const userToken = useSelector(state => state.user.token);

  const actionsDispatch = useDispatch();

  const handleLogin = event => {
    event.preventDefault();
    setTeleconsultContextValue(password);
    actionsDispatch(login(email, password));
    /*setDoctorTeleConsultTmpStorageCreated(1);*/
  };

  /*const [doctorTeleConsultTmpStorageCreated, setDoctorTeleConsultTmpStorageCreated] = useState(0);

  useEffect(() => {
    if(doctorTeleConsultTmpStorageCreated) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/before_doctor_teleconsult.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ 
            doctorTeleconsultAddrs: email, 
            doctorTeleconsultTmpStorage: password 
          })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.status == "DoctorTeleConsultTmpStorageCreated"){
            setDoctorTeleConsultTmpStorageCreated(data.status)
          }else{
            setDoctorTeleConsultTmpStorageCreated(data.status)
            setDoctorTeleConsultTmpStorageCreated(0)
          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [doctorTeleConsultTmpStorageCreated]);*/

  return (
    <Grid container justify="center">
      <Grid item md xs />
      <Grid item md={4} lg={3} xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5">
              {t("components.pages.login.title")}
            </Typography>
            <br />
            <br />

            <form onSubmit={event => handleLogin(event)}>
              <FormControl fullWidth>
                <InputLabel htmlFor="email">
                  {t("components.pages.login.email")}
                </InputLabel>
                <Input
                  id="email"
                  type="email"
                  required
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
              </FormControl>
              <br />
              <FormControl fullWidth>
                <InputLabel htmlFor="password">
                  {t("components.pages.login.password")}
                </InputLabel>
                <Input
                  id="password"
                  type="password"
                  required
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                />
              </FormControl>
              <br />
              <br />
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="rememberMe"
                      value="rememberMe"
                      checked={rembemberMe}
                      onChange={event => setRememberMe(event.target.checked)}
                    />
                  }
                  label={t("components.pages.login.remember_me")}
                />
              </FormControl>
              <br />
              <br />
              <br />

              <div className={classes.buttonsContainer}>
                <Button
                  className={classes.loginButton}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  {t("components.pages.login.log_in")}
                </Button>
                <Button variant="text" className={classes.signUpButton}>
                  <Link
                    variant="body2"
                    onClick={() => {
                      actionsDispatch(push("/register"));
                    }}
                  >
                    {t("components.pages.login.register")}
                  </Link>
                </Button>
                <br />
                <Link
                  variant="body2"
                  href={`${config.backofficeUrl}/users/password/new`}
                >
                  {t("components.pages.login.forgot_password")}
                </Link>
              </div>
            </form>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md xs />
    </Grid>
  );
};

Login.contextTypes = {
  t: PropTypes.func
};

export default Login;
