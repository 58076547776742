import {
  GET_DOCTOR_TIMETABLES,
  GET_DOCTOR_TIMETABLES_SUCCESS,
  GET_DOCTOR_TIMETABLES_ERROR,
  CREATE_DOCTOR_TIMETABLE,
  CREATE_DOCTOR_TIMETABLE_SUCCESS,
  CREATE_DOCTOR_TIMETABLE_ERROR,
  DESTROY_DOCTOR_TIMETABLE,
  DESTROY_DOCTOR_TIMETABLE_SUCCESS,
  DESTROY_DOCTOR_TIMETABLE_ERROR
} from "./const";

export function getDoctorTimetables(doctorId, from, to) {
  return {
    type: GET_DOCTOR_TIMETABLES,
    from,
    to,
    doctorId
  };
}
export function getDoctorTimetablesSuccess(doctorTimetables) {
  return { type: GET_DOCTOR_TIMETABLES_SUCCESS, doctorTimetables };
}
export function getDoctorTimetablesError(errors) {
  return { type: GET_DOCTOR_TIMETABLES_ERROR, errors };
}

export function createDoctorTimetable(doctorId, doctorTimetable, successCallback, errorCallback) {
  return {
    type: CREATE_DOCTOR_TIMETABLE,
    doctorId,
    doctorTimetable,
    successCallback,
    errorCallback
  };
}
export function createDoctorTimetableSuccess(doctorTimetable) {
  return { type: CREATE_DOCTOR_TIMETABLE_SUCCESS, doctorTimetable };
}
export function createDoctorTimetableError(errors) {
  return { type: CREATE_DOCTOR_TIMETABLE_ERROR, errors };
}

export function destroyDoctorTimetable(doctorTimetableId, successCallback, errorCallback) {
  return {
    type: DESTROY_DOCTOR_TIMETABLE,
    doctorTimetableId,
    successCallback,
    errorCallback
  };
}
export function destroyDoctorTimetableSuccess() {
  return { type: DESTROY_DOCTOR_TIMETABLE_SUCCESS };
}
export function destroyDoctorTimetableError(errors) {
  return { type: DESTROY_DOCTOR_TIMETABLE_ERROR, errors };
}










