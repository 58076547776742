import axios from "./AxiosConfig";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const messageSource = {
  index: examinationId =>
    api(
      axios.get(`${apiBaseUrl}/examinations/${examinationId}/messages.json`),
      "examination_message_index"
    ),
  create: (examinationId, message) =>
    api(
      axios.post(
        `${apiBaseUrl}/examinations/${examinationId}/messages.json`,
        message
      ),
      "examination_message_create"
    )
};

export default messageSource;
