import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Typography,
  CardHeader,
  CardContent,
  CardActions,
  Button
} from "@material-ui/core";

const logo = require("./../../images/sef_logo.png");

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "center"
  },
  media: {
    height: "auto",
    width: 100,
    marginTop: 20
  },
  actions: {
    justifyContent: "center"
  }
}));

const ConfirmRegistration = (props, context) => {
  const { t } = context;

  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <img alt="sef care" src={logo} className={classes.media} />
      <CardHeader title={t("components.pages.confirm_registration.title")} />
      <CardContent>
        <Typography variant="subtitle1">
          {t("components.pages.confirm_registration.content")}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
        >
          {t("components.pages.confirm_registration.back_to_login")}
        </Button>
      </CardActions>
    </Card>
  );
};

ConfirmRegistration.contextTypes = {
  t: PropTypes.func
};
export default ConfirmRegistration;
