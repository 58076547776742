import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Grid,
  Typography,
  LinearProgress,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Hidden,
  Tooltip,
  Button,
  Chip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Schedule as ScheduleIcon,
  DoneOutlined as DoneIcon
} from "@material-ui/icons";
import { get, map, isEmpty, isEqual } from "lodash";
import { Calendar, momentLocalizer } from "react-big-calendar";

import {
  getScheduledFile,
  DRGRequestIndex,
  drgRequestScheduleIndex,
  confirmScheduledFile,
  DRGRequestShow,
  resetDrgRequestScheduleErrors,
  resetScheduledFileErrors
} from "../../../actions";
import ChipStatus from "../../shared/ChipStatus";
import AddDrgRequestScheduleDialog from "./scheduled_file/AddDrgRequestScheduleDialog";
import EditDrgRequestScheduleDialog from "./scheduled_file/EditDrgRequestScheduleDialog";
import EditScheduledFileDialog from "./scheduled_file/EditScheduledFileDialog";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/it";
import "moment/locale/en-gb";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { isNull } from "util";
import DateRange from "../../shared/DateRange";

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(theme => ({
  chip: {
    marginLeft: 5
  },
  buttonGrid: {
    alignItems: "flex-end"
  }
}));

const EditScheduledFile = ({ match }, context) => {
  const { t } = context;
  const classes = useStyles();
  const user = useSelector(state => state.user.current);
  const currentLang = useSelector(state => state.i18nState.lang);
  const messages = {
    previous: t("locale.display_previous"),
    next: t("locale.display_next"),
    today: t("locale.display_today"),
    month: t("locale.display_month"),
    week: t("locale.display_week"),
    day: t("locale.display_day"),
    agenda: t("locale.display_agenda"),
    date: t("locale.display_date"),
    time: t("locale.display_time"),
    event: t("locale.display_event")
  };

  const [currentDrgRequestId, setCurrentDrgRequestId] = useState(null);
  const [
    currentDrgRequestScheduleId,
    setCurrentDrgRequestScheduleId
  ] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const actionsDispatch = useDispatch();

  const { loading, currentScheduledFile } = useSelector(
    state => state.scheduledFile
  );
  const requestList = useSelector(state => state.drgRequest.list);
  const { list } = useSelector(state => state.drgRequestSchedule);

  useEffect(() => {
    actionsDispatch(
      DRGRequestIndex(null, user.doctorId, {
        q: { accepted: true, not_scheduled: true }
      })
    );
    actionsDispatch(getScheduledFile(match.params.id));
    actionsDispatch(drgRequestScheduleIndex(match.params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenAddDrgRequestScheduleDialog = drgRequestId => {
    setCurrentDrgRequestId(drgRequestId);
    if (!isNull(drgRequestId)) actionsDispatch(DRGRequestShow(drgRequestId));
    else actionsDispatch(resetDrgRequestScheduleErrors());
  };

  const handleOpenEditDrgRequestScheduleDialog = drgRequestScheduleId => {
    setCurrentDrgRequestScheduleId(drgRequestScheduleId);
    if (isNull(drgRequestScheduleId))
      actionsDispatch(resetDrgRequestScheduleErrors());
  };

  const handleOpenEditScheduledFileDialog = open => {
    setModalOpen(open);
    if (open) actionsDispatch(resetScheduledFileErrors());
  };

  const successCallback = () => {
    actionsDispatch(
      DRGRequestIndex(null, user.doctorId, {
        q: { accepted: true, not_scheduled: true }
      })
    );
    actionsDispatch(getScheduledFile(match.params.id));
    actionsDispatch(drgRequestScheduleIndex(match.params.id));
  };

  const currentState = get(currentScheduledFile, "aasm_state", "");
  const editable =
    !isEqual(currentState, "confirmed") && !isEqual(currentState, "canceled");
  const scheduledFileStateActions = (
    <Paper>
      <List>
        <ListItem>
          <ChipStatus status={currentState} />
          {editable && (
            <Chip
              icon={<DoneIcon />}
              label={t("components.doctor.edit_scheduled_file.confirm")}
              onClick={() =>
                // eslint-disable-next-line no-restricted-globals
                confirm(
                  t("components.doctor.edit_scheduled_file.confirm_confirm")
                ) &&
                actionsDispatch(
                  confirmScheduledFile(match.params.id, successCallback)
                )
              }
              color="primary"
              className={classes.chip}
            />
          )}
        </ListItem>
      </List>
    </Paper>
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            <strong>
              {currentScheduledFile.label ||
                `${t("components.doctor.edit_scheduled_file.empty_label")} #${
                  currentScheduledFile.id
                }`}
            </strong>
          </Typography>
          <Typography variant="subtitle1" gutterBottom display="inline">
            <strong>{get(currentScheduledFile, "clinic.name", "-")}</strong>
            <DateRange
              style={{ marginLeft: "5px" }}
              from={get(currentScheduledFile, "from", "-")}
              to={get(currentScheduledFile, "to", "-")}
            />
          </Typography>
        </Grid>
        <Grid
          container
          xs={6}
          spacing={3}
          className={classes.buttonGrid}
          direction="column"
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={`/scheduled_files/${match.params.id}/documents`}
            >
              {t("components.doctor.edit_scheduled_file.download_documents")}
            </Button>
          </Grid>
          {editable && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleOpenEditScheduledFileDialog(true)}
              >
                {t("components.doctor.edit_scheduled_file.edit")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Divider />
      <br />

      <Hidden mdUp>
        {scheduledFileStateActions}
        <br />
      </Hidden>

      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <Grid container jusify="center" spacing={2}>
            <Grid item md={9} xs={12}>
              <Calendar
                defaultDate={moment(currentScheduledFile.from).toDate()}
                localizer={localizer}
                events={list}
                startAccessor="start"
                endAccessor="end"
                culture={currentLang}
                messages={messages}
                dayPropGetter={date => {
                  let backgroundColor = "lightgray";
                  if (
                    moment(date).isBetween(
                      currentScheduledFile.from,
                      currentScheduledFile.to,
                      null,
                      "[]"
                    )
                  )
                    backgroundColor = "white";
                  return { style: { backgroundColor } };
                }}
                style={{
                  minHeight: 600,
                  paddingBottom: 64
                }}
                onSelectEvent={event =>
                  editable && handleOpenEditDrgRequestScheduleDialog(event.id)
                }
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Hidden smDown>
                {scheduledFileStateActions}
                <br />
              </Hidden>
              {editable && (
                <Paper>
                  <List>
                    {isEmpty(requestList) ? (
                      <ListItem>
                        <ListItemText
                          primary={t(
                            "components.doctor.edit_scheduled_file.empty_request_list"
                          )}
                        />
                      </ListItem>
                    ) : (
                      map(requestList, request => (
                        <React.Fragment key={request.id}>
                          <ListItem alignItems="flex-start">
                            <ListItemText
                              primary={`${request.patientProfile.name} ${request.patientProfile.surname} `}
                              secondary={
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {`${request.drg.title}`}
                                </Typography>
                              }
                            />
                            <ListItemSecondaryAction>
                              <Tooltip
                                title={t(
                                  "components.doctor.edit_scheduled_file.schedule"
                                )}
                                placement="top"
                              >
                                <IconButton
                                  edge="end"
                                  aria-label="comments"
                                  onClick={() =>
                                    handleOpenAddDrgRequestScheduleDialog(
                                      request.id
                                    )
                                  }
                                >
                                  <ScheduleIcon />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))
                    )}
                  </List>
                </Paper>
              )}
            </Grid>
          </Grid>
          <AddDrgRequestScheduleDialog
            currentDrgRequestId={currentDrgRequestId}
            handleModal={handleOpenAddDrgRequestScheduleDialog}
          />
          <EditDrgRequestScheduleDialog
            currentDrgRequestScheduleId={currentDrgRequestScheduleId}
            handleModal={handleOpenEditDrgRequestScheduleDialog}
          />
          {editable && (
            <EditScheduledFileDialog
              open={modalOpen}
              handleModal={handleOpenEditScheduledFileDialog}
              scheduledFileId={match.params.id}
            />
          )}
        </>
      )}
    </>
  );
};

EditScheduledFile.contextTypes = {
  t: PropTypes.func
};

export default EditScheduledFile;
