import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  IconButton,
  DialogContentText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  title: {
    display: "flex",
    alignItems: "center"
  },
  typography: {
    flexGrow: 1
  }
}));

const DownloadModulesDialog = (props, context) => {
  const classes = useStyles();
  const { t } = context;
  const { closeModal } = props;

  const patient = useSelector(state => state.patient.profile);

  return (
    <>
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6" className={classes.typography}>
          {t("components.drg_request_list.module_title")}
        </Typography>
        <Tooltip
          title={t("components.drg_request_list.module_close")}
          placement="top"
        >
          <IconButton size="small" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {patient.preauthorizationTemplateSent ? (
            t("components.drg_request_list.module_text")
          ) : (
            <>
              {t("components.drg_request_list.no_modules_sef")}
              <br /> {t("components.drg_request_list.no_modules_mail")}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      {patient.preauthorizationTemplateSent && (
        <DialogActions>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            href={patient.preauthorizationTemplateUrl || ""}
            target="_blank"
            onClick={closeModal}
          >
            {t("components.drg_request_list.module_download")}
          </Button>
        </DialogActions>
      )}
    </>
  );
};

DownloadModulesDialog.contextTypes = {
  t: PropTypes.func
};

export default DownloadModulesDialog;
