import { REGISTER, REGISTER_SUCCESS, REGISTER_ERROR } from "./const";
import { GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_ERROR } from "./const";
import {
  EDIT_PROFILE,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR
} from "./const";
import { SET_MODAL_OPEN } from "./const";
import {
  ASSOCIATE_DOCTOR,
  ASSOCIATE_DOCTOR_SUCCESS,
  ASSOCIATE_DOCTOR_ERROR
} from "./const";
import {
  ASSOCIATED_DOCTORS,
  ASSOCIATED_DOCTORS_SUCCESS,
  ASSOCIATED_DOCTORS_ERROR
} from "./const";
import { RESET_PATIENT_ERRORS } from "./const";

export function register(user, profile) {
  return { type: REGISTER, user, profile };
}
export function registerSuccess() {
  return { type: REGISTER_SUCCESS };
}
export function registerError(errors) {
  return { type: REGISTER_ERROR, errors };
}

export function getProfile(profileId) {
  return { type: GET_PROFILE, profileId };
}
export function getProfileSuccess(profile) {
  return { type: GET_PROFILE_SUCCESS, profile };
}
export function getProfileError(errors) {
  return { type: GET_PROFILE_ERROR, errors };
}

export function editProfile(profileId, profile) {
  return {
    type: EDIT_PROFILE,
    profileId,
    profile
  };
}
export function editProfileSuccess(profile) {
  return { type: EDIT_PROFILE_SUCCESS, profile };
}
export function editProfileError(errors) {
  return { type: EDIT_PROFILE_ERROR, errors };
}

export function setModalOpen(isOpen) {
  return { type: SET_MODAL_OPEN, isOpen };
}

export function associateDoctor(profileId, doctorRef) {
  return {
    type: ASSOCIATE_DOCTOR,
    profileId,
    doctorRef
  };
}
export function associateDoctorSuccess() {
  return { type: ASSOCIATE_DOCTOR_SUCCESS };
}
export function associateDoctorError() {
  return { type: ASSOCIATE_DOCTOR_ERROR };
}

export function associatedDoctors(profileId) {
  return {
    type: ASSOCIATED_DOCTORS,
    profileId
  };
}
export function associatedDoctorsSuccess(doctors) {
  return { type: ASSOCIATED_DOCTORS_SUCCESS, doctors };
}
export function associatedDoctorsError() {
  return { type: ASSOCIATED_DOCTORS_ERROR };
}

export function resetPatientErrors() {
  return { type: RESET_PATIENT_ERRORS };
}
