import axios from "./AxiosConfig";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const drgRequestScheduleSource = {
  index: scheduleFileId =>
    api(
      axios.get(
        `${apiBaseUrl}/scheduled_files/${scheduleFileId}/drg_request_schedules.json`
      ),
      "drg_request_schedule_index"
    ),
  add: (scheduleFileId, drgRequestSchedule) =>
    api(
      axios.post(
        `${apiBaseUrl}/scheduled_files/${scheduleFileId}/drg_request_schedules.json`,
        drgRequestSchedule
      ),
      "drg_request_schedule_add"
    ),
  get: id =>
    api(
      axios.get(`${apiBaseUrl}/drg_request_schedules/${id}.json`),
      "drg_request_schedule_get"
    ),
  edit: (id, drgRequestSchedule, successCallback, errorCallback) =>
    api(
      axios.put(
        `${apiBaseUrl}/drg_request_schedules/${id}.json`,
        drgRequestSchedule
      ),
      "drg_request_schedule_edit"
    ),
  delete: id =>
    api(
      axios.delete(`${apiBaseUrl}/drg_request_schedules/${id}.json`),
      "drg_request_schedule_delete"
    )
};

export default drgRequestScheduleSource;
