import React, { Component } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { SnackbarProvider, withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { isEmpty, isNil } from "lodash";

import { consumeNotices, consumeErrors } from "../../actions";

const NotificationsViewer = (props, context) => {
  const { enqueueSnackbar } = props;

  const { t } = context;

  const { errors, notices } = useSelector(state => state.notifications);

  const actionDispatch = useDispatch();

  const action = key => (
    <Tooltip
      title={t("components.shared.notifications_viewer.close")}
      placement="top"
    >
      <IconButton
        onClick={() => {
          props.closeSnackbar(key);
        }}
        color="inherit"
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );

  errors.forEach(error => {
    enqueueSnackbar(t(isNil(error) ? "message.error" : error), {
      variant: "error",
      action,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right"
      }
    });
  });
  if (!isEmpty(errors)) actionDispatch(consumeErrors());

  notices.forEach(notice => {
    enqueueSnackbar(t(isNil(notice) ? "message.success" : notice), {
      variant: "success",
      action,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right"
      }
    });
  });
  if (!isEmpty(notices)) actionDispatch(consumeNotices());

  return null;
};

NotificationsViewer.contextTypes = {
  t: PropTypes.func
};

const providedSnackbar = PropComponent => {
  return class extends Component {
    render() {
      return (
        <SnackbarProvider hideIconVariant>
          <PropComponent />
        </SnackbarProvider>
      );
    }
  };
};

export default providedSnackbar(withSnackbar(NotificationsViewer));
