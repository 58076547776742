import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../actions";
import DRGRequestList from "../pages/patient_dashboard/DRGRequestList";

const PatientDashboard = (props, { t }) => {
  const actionsDispatch = useDispatch();
  const user = useSelector(state => state.user.current);

  useEffect(() => {
    actionsDispatch(getProfile(user.patientId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DRGRequestList />;
};

PatientDashboard.contextTypes = {
  t: PropTypes.func
};

export default PatientDashboard;
