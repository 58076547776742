import { nth, head, last } from 'lodash'

import ApiMock from './apiMock'

import {
	countries,
	states,
	cities,
	editPatientProfile,
	drgs,
	associatedDoctors,
	indexDrgRequest,
	showDrgRequest,
	addDrgRequest,
	crossBorderHealthcareAuthorization,
	isPatientProfile,
	findUserByToken,
	getPatientProfileById
} from './data/MocksData'

//Get Profile
ApiMock.onGet(/^.+\/patient_profiles\/\w+.json/).reply((config) => {
	const profileId = head(last(config.url.split('/')).split('.'))

	const token = config.headers['Authorization']

	const data = getPatientProfileById(profileId)

	const user = findUserByToken(token)

	if (data && user) {
		return [200, data]
	} else {
		return [
			404,
			{
				errors: [
					{
						status: 404,
						title: 'Record not Found',
						detail: `Couldn't find PatientProfile with 'id'= ${profileId}`,
						source: { pointer: '/request/url/:id' }
					}
				]
			}
		]
	}
})

//Edit Profile
ApiMock.onPut(/^.+\/patient_profiles\/\w+.json/).reply((config) => {
	const profile = JSON.parse(config.data).patient_profile

	const profileId = head(last(config.url.split('/')).split('.'))

	const editedProfile = editPatientProfile(profileId, profile)

	return [
		200,
		{
			data: editedProfile
		}
	]
})

ApiMock.onGet(/^.+\/countries\/\w+\/states\/\w+\/cities.json/).reply((config) => {
	return [200, cities]
})

ApiMock.onGet(/^.+\/countries\/\w+\/states.json/).reply((config) => {
	return [200, states]
})

ApiMock.onGet(/^.+\/countries.json/).reply((config) => {
	return [200, countries]
})

ApiMock.onGet(/^.+\/drgs.json/).reply((config) => {
	return [200, drgs]
})

//Get associated doctors
ApiMock.onGet(/^.+\/patient_profiles\/\w+\/doctor_profiles.json/).reply((config) => {
	const profileId = nth(config.url.split('/'), -2)
	const doctors = associatedDoctors(profileId)
	return [200, { data: doctors }]
})

//Associate doctor
ApiMock.onPost(/^.+\/patient_profiles\/\w+\/associate_doctor.json/).reply((config) => {
	const profileId = nth(config.url.split('/'), -2)
	if (isPatientProfile(profileId)) return [200]
	return [
		404,
		{
			errors: [
				{
					status: 404,
					title: 'Record not Found',
					detail: `Couldn't find PatientProfile with 'id'= ${profileId}`,
					source: { pointer: '/request/url/:id' }
				}
			]
		}
	]
})

ApiMock.onGet(/^.+\/drg_requests\/\w+.json/).reply((config) => {
	const drgRequestId = head(last(config.url.split('/')).split('.'))
	const response = showDrgRequest(drgRequestId)
	return [200, response]
})

ApiMock.onGet(/^.+\/patient_profiles\/\w+\/drg_requests.json/).reply((config) => {
	return [200, indexDrgRequest()]
})

ApiMock.onPost(/^.+\/patient_profiles\/\w+\/drg_requests.json/).reply((config) => {
	const profileId = nth(config.url.split('/'), -2)
	const drgRequest = JSON.parse(config.data.get('drg_request'))
	const document = config.data.get('document')
	const newDrgRequest = addDrgRequest(profileId, drgRequest, document)
	return [200, newDrgRequest]
})

ApiMock.onGet(/^.+\/patient_profiles\/\w+\/cross_border_healthcare_authorizations.json/).reply((config) => {
	return [200, crossBorderHealthcareAuthorization]
})
