import axios from "./AxiosConfig";
import config from "../config";
import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const examinationSource = {
  index: (query) =>
    api(
      axios.get(
        `${apiBaseUrl}/examinations.json`,
        { params: query }
      ),
      "examinations_index"
    ),
  show: (id) =>
    api(
      axios.get(
        `${apiBaseUrl}/examinations/${id}.json`
      ),
      "examinations_show"
    ),
  create: (examination) =>
    api(
      axios.post(
        `${apiBaseUrl}/examinations.json`,
        examination
      ),
      "examination_create"
    ),
  accept: (examinationId) =>
    api(
      axios.patch(
        `${apiBaseUrl}/examinations/${examinationId}/accept.json`
      ),
      "examination_accept"
    ),
  decline: (examinationId) =>
    api(
      axios.patch(
        `${apiBaseUrl}/examinations/${examinationId}/decline.json`
      ),
      "examination_decline"
    ),
  cancel: (examinationId) =>
    api(
      axios.patch(
        `${apiBaseUrl}/examinations/${examinationId}/cancel.json`
      ),
      "examination_cancel"
    ),
  meeting_signature: (examinationId) =>
      api(
        axios.get(
          `${apiBaseUrl}/examinations/${examinationId}/meeting_signature.json`
        ),
        "examination_meeting_signature"
      )

  /*
  destroy: (id) =>
    api(
      axios.delete(
        `${apiBaseUrl}/doctor_timetables/${id}.json`
      ),
      "doctor_timetable_destroy"
    ),
  */
};

export default examinationSource;
