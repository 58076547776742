import React from "react";
import { Grid } from "@material-ui/core";
import AccessHeader from "./../AccessHeader";

let emptyLayout = Component => ({ classes, ...rest }) => (
  <>
    <AccessHeader />
    <Grid container>
      <Grid item md sm xs />
      <Grid item sm={10} md={10} xs={10}>
        <main className="main">
          <Component {...rest} />
        </main>
      </Grid>
      <Grid item md sm xs />
    </Grid>
  </>
);

export default emptyLayout;
