import {
  mapKeys,
  result,
  camelCase,
  snakeCase,
  map,
  isEmpty,
  assign
} from "lodash";
import { push } from "connected-react-router";
import { takeEvery, call, all, put } from "redux-saga/effects";
import PatientSource from "../sources/PatientSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import {
  GET_PROFILE,
  EDIT_PROFILE,
  ASSOCIATE_DOCTOR,
  ASSOCIATED_DOCTORS,
  REGISTER
} from "../actions/const";
import {
  postError,
  getProfileSuccess,
  getProfileError,
  postNotice,
  editProfileSuccess,
  associateDoctorSuccess,
  associateDoctorError,
  associatedDoctorsSuccess,
  associatedDoctorsError,
  setModalOpen,
  registerSuccess,
  registerError
} from "../actions";

const mapProfile = response => {
  const { data } = response;
  const profileNavigator = new ApiJsonNavigator(data);
  const profile = mapKeys(
    result(profileNavigator, "data.attributes"),
    (val, key) => camelCase(key)
  );
  profile.doctorPatients = map(
    result(profileNavigator, "data.doctor_patients"),
    item => ({
      ...mapKeys(result(item, "attributes"), (val, key) => camelCase(key)),
      clinic: mapKeys(result(item, "clinic.attributes"), (val, key) =>
        camelCase(key)
      ),
      clinicId: result(item, "clinic.attributes.id"),
      doctor: mapKeys(result(item, "doctor_profile.attributes"), (val, key) =>
        camelCase(key)
      )
    })
  );
  return { profile };
};

const mapDoctors = response => {
  const doctorsNavigator = new ApiJsonNavigator(response.data);
  const associatedDoctors = map(doctorsNavigator.data(), item =>
    item.attributes()
  );
  return associatedDoctors;
};

function* getProfile({ profileId }) {
  const { errors, response } = yield call(PatientSource.getProfile, profileId);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getProfileError(errors.pointers));
    return;
  }
  const { profile } = yield call(mapProfile, response);
  yield put(getProfileSuccess(profile));
}

function* editProfile({ profileId, profile }) {
  const profileData = mapKeys(profile, (val, key) => snakeCase(key));
  const { errors, response } = yield call(
    PatientSource.editProfile,
    profileId,
    profileData
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getProfileError(errors.pointers));
    return;
  }
  const { profile: editedProfile } = yield call(mapProfile, response);
  yield put(postNotice("edit_profile.success"));
  yield put(editProfileSuccess(editedProfile));
}

function* associateDoctor({ doctorRef, profileId }) {
  const { errors } = yield call(
    PatientSource.associateDoctor,
    doctorRef,
    profileId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(associateDoctorError());
    return;
  }
  yield put(postNotice("associate_doctor.success"));
  yield put(associateDoctorSuccess());
  yield put(push("/"));
}

function* associatedDoctors({ profileId }) {
  const { errors, response } = yield call(
    PatientSource.associatedDoctors,
    profileId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(associatedDoctorsError(errors.pointers));
    return;
  }
  const associatedDoctors = yield call(mapDoctors, response);
  yield put(associatedDoctorsSuccess(associatedDoctors));
  if (isEmpty(associatedDoctors)) yield put(setModalOpen(true));
}

function* register({ user, profile }) {
  const userData = mapKeys(user, (val, key) => snakeCase(key));
  const profileData = mapKeys(profile, (val, key) => snakeCase(key));
  const userProfile = assign(userData, {
    patient_profile_attributes: profileData
  });
  const { errors } = yield call(PatientSource.register, userProfile);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(registerError(errors.pointers));
    return;
  }
  yield put(postNotice("register.success"));
  yield put(registerSuccess());
  yield put(push("/confirm-registration"));
}

export const getPatientProfileFlow = takeEvery(GET_PROFILE, getProfile);
export const editPatientProfileFlow = takeEvery(EDIT_PROFILE, editProfile);
export const associateDoctorFlow = takeEvery(ASSOCIATE_DOCTOR, associateDoctor);
export const associatedDoctorFlow = takeEvery(
  ASSOCIATED_DOCTORS,
  associatedDoctors
);
export const registerFlow = takeEvery(REGISTER, register);
