import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { acceptExamination, declineExamination } from "../../../../actions";
import config from "../../../../config";

const useStyles = makeStyles(theme => ({
  editScheduledFileTitle: {
    display: "flex"
  },
  editScheduledFileTypography: {
    flexGrow: 1
  },
  editButton: {
    marginBottom: theme.spacing(1)
  }
}));

const DoctorTimetableDetailsDialog = (
  { open, handleModal, doctorTimetable },
  { t }
) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleAcceptExamination = () => {
    dispatch(
      acceptExamination(doctorTimetable.examination.id, () => {
        handleModal(true);
      })
    );
  };

  const handleDeclineExamination = () => {
    dispatch(
      declineExamination(doctorTimetable.examination.id, () => {
        handleModal(true);
      })
    );
  };
  const [dialogDoctorTeleconsultInitialize, setDialogDoctorTeleconsultInitialize] = useState(0);
  const [dialogDoctorTeleconsultPatientEmail, setDialogDoctorTeleconsultPatientEmail] = useState(0);
  const [dialogDoctorTeleconsultPatientId, setDialogDoctorTeleconsultPatientId] = useState(0);

  useEffect(() => {
    if(doctorTimetable) {
      console.log("THis is ytesy ");
      window
        .fetch(`${config.apiBaseUrl}/post_process_doctor_teleconsult.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ patientId: doctorTimetable.patientProfile.id })
        }).then(res => {
          // console.log(res.json());
          return res.json();
        })
        .then(data => {
          if(data.status1 && data.status3){
            setDialogDoctorTeleconsultPatientEmail(data.status3)
            setDialogDoctorTeleconsultPatientId(data.status2)
          } else{
            console.log(data.status)
          }
          //console.log(data.zoomHealthConMsg);
        });
    }
  }, [doctorTimetable]);

  return (
    <Dialog open={open} onClose={() => handleModal(false)}>
      <DialogTitle disableTypography className={classes.editScheduledFileTitle}>
        <Typography
          variant="h6"
          className={classes.editScheduledFileTypography}
        >
          {t("components.doctor.doctor_timetable_details_dialog.module_title")}
        </Typography>
        <Tooltip
          title={t(
            "components.doctor.doctor_timetable_details_dialog.module_close"
          )}
          placement="top"
        >
          <IconButton size="small" onClick={() => handleModal(false)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("components.doctor.doctor_timetable_details_dialog.module_text")}
        </DialogContentText>
        {isEmpty(doctorTimetable) ? null : (
          <>
            <Typography variant="body1">
              <small>
                {t("components.doctor.doctor_timetable_details_dialog.name")}
                :&nbsp;
              </small>
              {`${doctorTimetable.patientProfile.name} ${doctorTimetable.patientProfile.surname}`}
            </Typography>
            <Typography variant="body1">
              <small>
                {t(
                  "components.doctor.doctor_timetable_details_dialog.fiscal_code"
                )}
                :&nbsp;
              </small>
              {doctorTimetable.patientProfile.fiscal_code}
            </Typography>
            <Typography variant="body1">
              <small>
                {t(
                  "components.doctor.doctor_timetable_details_dialog.email_address"
                )}
                :&nbsp;
              </small>
              {
                dialogDoctorTeleconsultPatientId == doctorTimetable.patientProfile.id
                ?
                  dialogDoctorTeleconsultPatientEmail
                :
                doctorTimetable.patientProfile.email_address
              }
            </Typography>
            <Typography variant="body1">
              <small>
                {t("components.doctor.doctor_timetable_details_dialog.phone")}:
                :&nbsp;
              </small>
              {doctorTimetable.patientProfile.phone}
            </Typography>
            <Typography variant="body1">
              <small>
                {t(
                  "components.doctor.doctor_timetable_details_dialog.medical_service"
                )}
                :&nbsp;
              </small>
              {doctorTimetable.medicalService.title}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={/*loading*/ false}
          onClick={handleAcceptExamination}
        >
          {t("components.doctor.doctor_timetable_details_dialog.accept_button")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={/*loading*/ false}
          onClick={handleDeclineExamination}
        >
          {t(
            "components.doctor.doctor_timetable_details_dialog.decline_button"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DoctorTimetableDetailsDialog.contextTypes = {
  t: PropTypes.func
};

export default DoctorTimetableDetailsDialog;
