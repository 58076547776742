import { connect } from "react-redux";
import { lifecycle, compose } from "recompose";

import * as documentActionCreator from "../../actions";
import DocumentList from "../shared/DocumentList.jsx";
import { bindActionCreators } from "redux";

const mapStatetoProps = state => {
  return {
    documents: state.document.list,
    loading: state.document.loading
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(documentActionCreator, dispatch)
});
const enhance = compose(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentDidMount() {
      this.props.documentActionCreator(this.props.match);
    }
  })
);

export default enhance(DocumentList);
