import { createStore, applyMiddleware, compose } from "redux";

import history from "./history";
import loggerMiddleware from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";

import reducer from "./../reducers";
import rootSaga from "../sagas/rootSaga";
import { localStorageMiddleware } from "./middlewares";

/* Setup react router */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const routingMiddleware = routerMiddleware(history);

/* Setup Redux Saga */
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routingMiddleware];
if (process.env.NODE_ENV !== "production") {
  middlewares.push(loggerMiddleware);
}

middlewares.push(localStorageMiddleware);

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

export function configureStore() {
  const store = createStore(reducer(history), {}, enhancer);

  /* Run sagas */
  sagaMiddleware.run(rootSaga);

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("../reducers", () => {
        const nextRootReducer = require("../reducers").default;
        store.replaceReducer(nextRootReducer);
      });
    }
  }

  return store;
}

export default configureStore();
