import React, { useState, useEffect, use } from "react";
import { useInterval } from "./../../../hooks/useInterval";
import PropTypes from "prop-types";

import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Chip
} from "@material-ui/core";
import { Person, LocalHospitalOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getDoctorTimetables,
  createDoctorTimetable,
  destroyDoctorTimetable
} from "../../../actions";
import { upperCase, isEmpty } from "lodash";
import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import DoctorTimetableDetailsDialog from "./planner_telemedicine/DoctorTimetableDetailsDialog";
import { push } from "connected-react-router";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/it";
import "moment/locale/en-gb";

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.75)
  }
}));

const localizer = momentLocalizer(moment);

const PlannerTelemedicine = (props, context) => {
  const { t } = context;
  const currentLang = useSelector(state => state.i18nState.lang);

  const [currentDate, setCurrentDate] = useState(moment().toDate());
  const [currentEvent, setCurrentEvent] = useState();

  const classes = useStyles();
  const actionsDispatch = useDispatch();

  const messages = {
    previous: t("locale.display_previous"),
    next: t("locale.display_next"),
    today: t("locale.display_today"),
    month: t("locale.display_month"),
    week: t("locale.display_week"),
    day: t("locale.display_day"),
    agenda: t("locale.display_agenda"),
    date: t("locale.display_date"),
    time: t("locale.display_time"),
    event: t("locale.display_event")
  };

  const user = useSelector(state => state.user.current);
  const doctorTimetables = useSelector(state => {
    if (isEmpty(state.doctorTimetable.list)) return [];
    return state.doctorTimetable.list.data().map(tt => {
      const { id, start_at, end_at } = tt.attributes();
      const examination = tt.examination();
      const patientProfile = isEmpty(examination)
        ? {}
        : examination.patient_profile();
      const medicalService = isEmpty(examination)
        ? {}
        : examination.medical_service();
      return {
        id,
        title: isEmpty(patientProfile)
          ? "-"
          : `${patientProfile.attributes().name} ${
              patientProfile.attributes().surname
            }`,
        start_at: moment(start_at).toDate(),
        end_at: moment(end_at).toDate(),
        examination: isEmpty(examination)
          ? examination
          : examination.attributes(),
        patientProfile: isEmpty(patientProfile)
          ? patientProfile
          : patientProfile.attributes(),
        medicalService: isEmpty(medicalService)
          ? medicalService
          : medicalService.attributes()
      };
    });
  });
  const getEvents = () => {
    const filterStart = moment(currentDate)
      .startOf("month")
      .subtract(7, "days");
    const filterEnd = moment(currentDate)
      .endOf("month")
      .add(7, "days");
    actionsDispatch(getDoctorTimetables(user.doctorId, filterStart, filterEnd));
  };

  useEffect(getEvents, [actionsDispatch, currentDate]);

  const handleSelectSlot = ({ start, end }) => {
    actionsDispatch(
      createDoctorTimetable(
        user.doctorId,
        { start_at: start, end_at: end },
        getEvents
      )
    );
  };

  const hanldeNavigate = (date, view) => {
    setCurrentDate(moment(date));
  };

  const handleSelectEvent = event => {
    if (isEmpty(event.examination)) {
      actionsDispatch(destroyDoctorTimetable(event.id, getEvents));
    } else {
      if (event.examination.aasm_state == "requested") setCurrentEvent(event);
      else
        actionsDispatch(
          push(`/planner_telemedicine/examinations/${event.examination.id}`)
        );
    }
  };

  const handleOpenDoctorTimetableDetailsDialog = reload => {
    if (reload) getEvents();
    setCurrentEvent(null);
  };

  useInterval(async () => {
    getEvents();
  }, 3000);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {t("components.doctor.pages.telemedicine.title")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("components.doctor.pages.telemedicine.text")}
          </Typography>

          <br />

          <Calendar
            selectable
            defaultDate={currentDate}
            localizer={localizer}
            defaultView={Views.WEEK}
            events={doctorTimetables}
            startAccessor="start_at"
            endAccessor="end_at"
            culture={currentLang}
            messages={messages}
            onSelectSlot={handleSelectSlot}
            onNavigate={hanldeNavigate}
            onSelectEvent={handleSelectEvent}
            eventPropGetter={(event, start, end, isSelected) => {
              let bgEventColor = "green";

              if (!isEmpty(event.examination)) {
                if (event.examination.aasm_state == "requested")
                  bgEventColor = "orange";
                else bgEventColor = "red";
              }

              if (moment(event.start_at) < moment()) {
                bgEventColor = "gray";
              }

              return {
                style: {
                  backgroundColor: bgEventColor
                }
              };
            }}
            style={{
              minHeight: 600,
              paddingBottom: 64
            }}
          />
        </Grid>
      </Grid>
      <DoctorTimetableDetailsDialog
        open={!!currentEvent}
        handleModal={handleOpenDoctorTimetableDetailsDialog}
        doctorTimetable={currentEvent}
      />
    </>
  );
};

PlannerTelemedicine.contextTypes = {
  t: PropTypes.func
};

export default PlannerTelemedicine;
