import React, { useState } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormGroup,
  Button,
  TextField,
  Link,
  Typography,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { Formik } from "formik";
import { includes } from "lodash";

import { userDataChange, register } from "../../actions";

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    display: "flex",
    flexDirection: "column"
  },
  loginButton: {
    marginTop: 8
  },
  signUpButton: {}
}));

const Registration = (props, context) => {
  const { t } = context;
  const actionsDispatch = useDispatch();

  const classes = useStyles();

  const user = useSelector(state => state.user.current);

  const { errors, loading } = useSelector(state => state.patient);

  const userToken = useSelector(state => state.user.token);
  if (userToken) {
    actionsDispatch(push("/dashboard"));
  }

  const [terms, setTerms] = useState(false);

  const handleUserChange = event => {
    const target = event.target;
    const change = { name: target.name, value: target.value };
    actionsDispatch(userDataChange(change));
  };

  const handleRegister = values => {
    actionsDispatch(register(user, values));
  };

  return (
    <Grid container justify="center">
      <Grid item md xs />
      <Grid item md={8} lg={6} xs={12}>
        <Card>
          <CardContent>
            <Formik
              enableReinitialize
              onSubmit={(values, actions) => {
                handleRegister(values);
              }}
              render={({ handleSubmit, handleChange, values }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Grid item container justify="center" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {t("components.pages.registration.title")}
                        </Typography>
                        <br />
                        <TextField
                          fullWidth
                          name="email"
                          label={t("components.pages.registration.email")}
                          type="email"
                          margin="normal"
                          value={user.email || ""}
                          onChange={handleUserChange}
                          required
                          error={includes(errors, "email")}
                        />
                        <br />
                        <TextField
                          fullWidth
                          name="password"
                          label={t("components.pages.registration.password")}
                          type="password"
                          margin="normal"
                          value={user.password || ""}
                          onChange={handleUserChange}
                          required
                          autoComplete="new-password"
                          error={includes(errors, "password")}
                          helperText={t(
                            "components.pages.registration.password_complexity"
                          )}
                        />
                        <br />
                        <TextField
                          fullWidth
                          name="confirmPassword"
                          label={t(
                            "components.pages.registration.confirm_password"
                          )}
                          type="password"
                          margin="normal"
                          value={user.confirmPassword || ""}
                          onChange={handleUserChange}
                          required
                          autoComplete="new-password"
                          error={includes(errors, "password_confirmation")}
                        />
                      </Grid>
                    </Grid>
                    <br />
                  </FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={terms}
                        onChange={() => setTerms(!terms)}
                      />
                    }
                    label={
                      <>
                        {t("components.pages.registration.accept")}{" "}
                        <Link
                          href="https://www.sef.care/termini-di-servizio/"
                          target="blank"
                        >
                          {t("components.pages.registration.terms_of_service")}
                        </Link>
                      </>
                    }
                  />

                  <div className={classes.buttonsContainer}>
                    <Button
                      className={classes.signUpButton}
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={loading || !terms}
                    >
                      {t("components.pages.login.register")}
                    </Button>
                    <Button className={classes.loginButton}>
                      <Link
                        variant="body2"
                        onClick={() => actionsDispatch(push("/login"))}
                      >
                        {t("components.pages.registration.log_in")}
                      </Link>
                    </Button>
                  </div>
                  <br />
                </form>
              )}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item md xs />
    </Grid>
  );
};

Registration.contextTypes = {
  t: PropTypes.func
};

export default Registration;
