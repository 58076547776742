/* We can use (need .htaccess to route correctly) */
// import createHistory from 'history/createBrowserHistory';
/* Or (legacy and compatible but use ugly '#' in url) */
import { createHashHistory } from 'history';

import config from './../config';

// Create a and export history of your choosing (using a browser history in this case)
const history = createHashHistory(config.historyConfig);
export default history;
