import { takeEvery, call, put, all } from "redux-saga/effects";

import ExaminationSource from "../sources/ExaminationSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import {
  CREATE_EXAMINATION,
  GET_EXAMINATIONS,
  GET_EXAMINATION,
  ACCEPT_EXAMINATION,
  DECLINE_EXAMINATION,
  CANCEL_EXAMINATION,
  GET_EXAMINATION_MEETING_SIGNATURE
} from "../actions/const";
import {
  createExaminationError,
  createExaminationSuccess,
  getExaminationsError,
  getExaminationsSuccess,
  getExaminationError,
  getExaminationSuccess,
  acceptExaminationError,
  acceptExaminationSuccess,
  declineExaminationError,
  declineExaminationSuccess,
  cancelExaminationError,
  cancelExaminationSuccess,
  getExaminationMeetingSignatureError,
  getExaminationMeetingSignatureSuccess,
  postError,
  postNotice
} from "../actions";

/* CREATE */
function* createExamination({ examination, successCallback, errorCallback }) {
  const { medical_service_id, doctor_timetable_id, request } = examination;
  const examinationData = {
    examination: {
      medical_service_id,
      doctor_timetable_id,
      request
    }
  };
  const { response, errors } = yield call(
    ExaminationSource.create,
    examinationData
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(createExaminationError(errors.pointers));
    if (errorCallback) {
      yield call(errorCallback, errors);
    }
    return;
  }
  const responseNavigator = new ApiJsonNavigator(response.data);
  yield put(postNotice("create_examinations.success"));
  yield put(createExaminationSuccess(responseNavigator));
  if (successCallback) {
    yield call(successCallback, responseNavigator);
  }
}

export const createExaminationFlow = takeEvery(
  CREATE_EXAMINATION,
  createExamination
);

/* INDEX */
function* getExaminations({query}) {
  const { response, errors } = yield call(ExaminationSource.index, query);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getExaminationsError(errors.pointers));
    return;
  }
  // const doctorTimetables = yield call(mapIndex, response);
  const responseNavigator = new ApiJsonNavigator(response.data);
  yield put(getExaminationsSuccess(responseNavigator));
}

export const getExaminationsFlow = takeEvery(
  GET_EXAMINATIONS,
  getExaminations
);

/* INDEX */
function* getExamination({id}) {
  const { response, errors } = yield call(ExaminationSource.show, id);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getExaminationError(errors.pointers));
    return;
  }
  const responseNavigator = new ApiJsonNavigator(response.data);
  yield put(getExaminationSuccess(responseNavigator));
}

export const getExaminationFlow = takeEvery(
  GET_EXAMINATION,
  getExamination
);

/* ACCEPT */
function* acceptExamination({ examinationId, successCallback, errorCallback }) {
  const { response, errors } = yield call(ExaminationSource.accept, examinationId, successCallback, errorCallback);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(acceptExaminationError(errors.pointers));
    return;
  }
  const responseNavigator = new ApiJsonNavigator(response.data);
  yield put(acceptExaminationSuccess(responseNavigator));
  if (successCallback) {
    yield call(successCallback, responseNavigator);
  }
}

export const acceptExaminationFlow = takeEvery(
  ACCEPT_EXAMINATION,
  acceptExamination
);

/* DECLINE */
function* declineExamination({ examinationId, successCallback, errorCallback }) {
  const { response, errors } = yield call(ExaminationSource.decline, examinationId, successCallback, errorCallback);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(declineExaminationError(errors.pointers));
    return;
  }
  const responseNavigator = new ApiJsonNavigator(response.data);
  yield put(declineExaminationSuccess(responseNavigator));
  if (successCallback) {
    yield call(successCallback, responseNavigator);
  }
}

export const declineExaminationFlow = takeEvery(
  DECLINE_EXAMINATION,
  declineExamination
);

/* CANCEL */
function* cancelExamination({ examinationId, successCallback, errorCallback }) {
  const { response, errors } = yield call(ExaminationSource.cancel, examinationId, successCallback, errorCallback);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(cancelExaminationError(errors.pointers));
    return;
  }
  const responseNavigator = new ApiJsonNavigator(response.data);
  yield put(cancelExaminationSuccess(responseNavigator));
  if (successCallback) {
    yield call(successCallback, responseNavigator);
  }
}

export const cancelExaminationFlow = takeEvery(
  CANCEL_EXAMINATION,
  cancelExamination
);

/* GET MEETING SIGNATURE */
function* getExaminationMeetingSignature({ examinationId, successCallback, errorCallback }) {
  const { response, errors } = yield call(ExaminationSource.meeting_signature, examinationId, successCallback, errorCallback);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getExaminationMeetingSignatureError(errors.pointers));
    return;
  }
  const { meeting_signature } = response.data;
  yield put(getExaminationMeetingSignatureSuccess(meeting_signature));
  if (successCallback) {
    yield call(successCallback, meeting_signature);
  }
}

export const getExaminationMeetingSignatureFlow = takeEvery(
  GET_EXAMINATION_MEETING_SIGNATURE,
  getExaminationMeetingSignature
);

/* DESTROY */
/*
function* destroyDoctorTimetable({ doctorTimetableId, successCallback, errorCallback }) {
  const { response, errors } = yield call(
    DoctorTimetableSource.destroy,
    doctorTimetableId,
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(destroyDoctorTimetableError(errors.pointers));
    if (successCallback) {
      yield call(errorCallback, errors);
    }
    return;
  }
  const doctorTimetableNavigator = new ApiJsonNavigator(response.data);
  yield put(postNotice("doctor_timetable.success"));
  yield put(destroyDoctorTimetableSuccess(doctorTimetableNavigator));
  if (successCallback) {
    yield call(successCallback);
  }
}

export const destroyDoctorTimetableFlow = takeEvery(
  DESTROY_DOCTOR_TIMETABLE,
  destroyDoctorTimetable
);
*/