import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid, TextField, MenuItem, FormGroup } from "@material-ui/core";
import { includes, map } from "lodash";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import "moment/locale/it";
import "moment/locale/en-gb";

const ScheduledFileForm = (props, context) => {
  const { t } = context;

  const { values, handleChange, errors, clinics } = props;
  const currentLang = useSelector(state => state.i18nState.lang);

  return (
    <FormGroup>
      <TextField
        name="label"
        label={t("components.doctor.shared.add_scheduled_file_dialog.label")}
        value={values.label || []}
        onChange={handleChange}
        error={includes(errors, "label")}
      />
      <br />
      <TextField
        name="clinicId"
        label={t(
          "components.doctor.shared.add_scheduled_file_dialog.clinic_label"
        )}
        select
        value={values.clinicId || []}
        onChange={handleChange}
        required
        error={includes(errors, "clinic")}
      >
        {map(clinics, (item, index) => {
          return (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </TextField>
      <br />
      <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLang}>
        <Grid container>
          <Grid md={5} xs={12} item>
            <KeyboardDatePicker
              required
              name="from"
              autoOk
              clearable
              disablePast
              cancelLabel={t("components.shared.edit_profile.cancel")}
              clearLabel={t("components.shared.edit_profile.clear")}
              openTo="date"
              format="DD/MM/YYYY"
              label={t(
                "components.doctor.shared.add_scheduled_file_dialog.from"
              )}
              views={["year", "month", "date"]}
              value={values.from || null}
              onChange={date =>
                handleChange({ target: { name: "from", value: date || null } })
              }
              error={includes(errors, "from")}
            />
          </Grid>
          <Grid md xs item />
          <Grid md={5} xs={12} item>
            <KeyboardDatePicker
              required
              name="to"
              autoOk
              clearable
              disablePast
              minDate={values.from || moment()}
              cancelLabel={t("components.shared.edit_profile.cancel")}
              clearLabel={t("components.shared.edit_profile.clear")}
              openTo="date"
              format="DD/MM/YYYY"
              label={t("components.doctor.shared.add_scheduled_file_dialog.to")}
              views={["year", "month", "date"]}
              value={values.to || null}
              onChange={date =>
                handleChange({ target: { name: "to", value: date || null } })
              }
              error={includes(errors, "to")}
            />
          </Grid>
        </Grid>
        <br />
        <br />
      </MuiPickersUtilsProvider>
    </FormGroup>
  );
};

ScheduledFileForm.contextTypes = {
  t: PropTypes.func
};

export default ScheduledFileForm;
