import axios from "axios";
import { push } from "connected-react-router";

import "./mocks";
import store from "./../stores";
import { logout, postError } from "./../actions";
import config from "../config";

/* Setup default headers to use jwt */
const axiosInstance = axios.create({
  baseURL: config.apiBaseUrl
});

axiosInstance.interceptors.request.use(
  config => {
    const token = store.getState().user.token;
    if (token) config.headers["Authorization"] = token; // `Bearer ${token}`
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/* Common response for 401 */

axiosInstance.interceptors.response.use(undefined, error => {
  let res = error.response;
  if (localStorage.getItem("user_token") && res.status === 401) {
    store.dispatch(logout());
    store.dispatch(push("/login"));
    store.dispatch(postError("logout.401"));
  }
  return Promise.reject(error);
});

export default axiosInstance;
