import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ScheduledFileList from "./doctor_dashboard/ScheduledFileList";
import ScheduledFileCalendar from "./doctor_dashboard/ScheduledFileCalendar";

import { scheduledFileIndex } from "./../../../actions";

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: 20
  }
}));

const DoctorDashboard = (props, { t }) => {
  const actionsDispatch = useDispatch();
  const classes = useStyles();

  const doctorId = useSelector(state => state.user.current.doctorId);
  const loading = useSelector(state => state.scheduledFile.loading);

  const [value, setValue] = useState("scheduledFiles");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    actionsDispatch(scheduledFileIndex(doctorId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorId]);
  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <Tabs value={value} onChange={handleChange} className={classes.tabs}>
        <Tab
          label={t("components.doctor.pages.doctor_dashboard.scheduled_files")}
          value="scheduledFiles"
        />
        <Tab
          label={t("components.doctor.pages.doctor_dashboard.calendar")}
          value="calendar"
        />
      </Tabs>
      <div hidden={value !== "scheduledFiles"}>
        <ScheduledFileList />
      </div>
      <div hidden={value !== "calendar"}>
        <ScheduledFileCalendar />
      </div>
    </>
  );
};

DoctorDashboard.contextTypes = {
  t: PropTypes.func
};

export default DoctorDashboard;
