import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Button, LinearProgress, Grid } from "@material-ui/core";
import { Formik } from "formik";

import { getDoctorProfile, editDoctorProfile } from "../../../actions";
import EditProfileFields from "../../shared/EditProfileFields";

const EditProfile = (props, context) => {
  const { t } = context;

  const actionsDispatch = useDispatch();

  const loading = useSelector(state => state.doctor.loading);
  const user = useSelector(state => state.user.current);
  const doctorProfile = useSelector(state => state.doctor.profile);
  const errors = useSelector(state => state.doctor.errors);

  useEffect(() => {
    actionsDispatch(getDoctorProfile(user.doctorId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = profile => {
    actionsDispatch(editDoctorProfile(user.doctorId, profile));
  };

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container justify="center">
          <Grid lg={6} xs={12} item>
            <Formik
              enableReinitialize
              initialValues={doctorProfile}
              onSubmit={(values, actions) => {
                handleSave(values);
              }}
              render={({ handleSubmit, handleChange, values }) => (
                <form onSubmit={handleSubmit}>
                  <EditProfileFields
                    profile={values}
                    handleChangeField={handleChange}
                    errors={errors}
                    doctor
                  />
                  <br />

                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    {t("components.shared.edit_profile.save")}
                  </Button>
                </form>
              )}
            />
          </Grid>
        </Grid>
      )}
      <br />
    </>
  );
};

EditProfile.contextTypes = {
  t: PropTypes.func
};
export default EditProfile;
