import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { i18nState } from "redux-i18n";

import notifications from "./notifications";
import user from "./user";
import country from "./country";
import patient from "./patient";
import drg from "./drg";
import doctor from "./doctor";
import scheduledFile from "./scheduledFile";
import drgRequestSchedule from "./drgRequestSchedule";
import doctorPatient from "./doctorPatient";
import drgRequest from "./drgRequest";
import document from "./document";
import documentType from "./documentType";
import doctorTimetable from "./doctorTimetable";
import examination from "./examination";
import message from "./message";

const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    user,
    country,
    patient,
    drg,
    doctor,
    scheduledFile,
    drgRequestSchedule,
    doctorPatient,
    drgRequest,
    document,
    documentType,
    doctorTimetable,
    examination,
    message,
    i18nState
  });

export default reducers;
