import axios from "./AxiosConfig";
import config from "../config";

import api from "./libs/api";

const apiBaseUrl = config.apiBaseUrl;

const DocumentTypeSource = {
  index: (query = null) =>
    api(axios.get(`${apiBaseUrl}/document_types.json`, {params: query}), "document_types_index")
};

export default DocumentTypeSource;
