import ErrorHandler from "./ErrorHandler";

export default function api(promise, errorHandlerKey) {
  return promise
    .then(response => ({ response }))
    .catch(error => {
      const errorHandler = new ErrorHandler(error, errorHandlerKey);
      return {
        errors: {
          labels: errorHandler.messages.map(e => e.key),
          pointers: errorHandler.errorsPointers
        }
      };
    });
}
