import * as types from "./const";

export function createExamination(examination, successCallback, errorCallback) {
  return {
    type: types.CREATE_EXAMINATION,
    examination,
    successCallback,
    errorCallback
  };
}
export function createExaminationSuccess(examination) {
  return { type: types.CREATE_EXAMINATION_SUCCESS, examination };
}
export function createExaminationError(errors) {
  return { type: types.CREATE_EXAMINATION_ERROR, errors };
}

export function getExaminations(query) {
  return {
    type: types.GET_EXAMINATIONS,
    query
  };
}
export function getExaminationsSuccess(examinations) {
  return { type: types.GET_EXAMINATIONS_SUCCESS, examinations };
}
export function getExaminationsError(errors) {
  return { type: types.GET_EXAMINATIONS_ERROR, errors };
}

export function getExamination(id) {
  return {
    type: types.GET_EXAMINATION,
    id
  };
}
export function getExaminationSuccess(examination) {
  return { type: types.GET_EXAMINATION_SUCCESS, examination };
}
export function getExaminationError(errors) {
  return { type: types.GET_EXAMINATION_ERROR, errors };
}

export function acceptExamination(
  examinationId,
  successCallback,
  errorCallback
) {
  return {
    type: types.ACCEPT_EXAMINATION,
    examinationId,
    successCallback,
    errorCallback
  };
}
export function acceptExaminationSuccess(examination) {
  return { type: types.ACCEPT_EXAMINATION_SUCCESS, examination };
}
export function acceptExaminationError(errors) {
  return { type: types.ACCEPT_EXAMINATION_ERROR, errors };
}

export function declineExamination(
  examinationId,
  successCallback,
  errorCallback
) {
  return {
    type: types.DECLINE_EXAMINATION,
    examinationId,
    successCallback,
    errorCallback
  };
}
export function declineExaminationSuccess(examination) {
  return { type: types.DECLINE_EXAMINATION_SUCCESS, examination };
}
export function declineExaminationError(errors) {
  return { type: types.DECLINE_EXAMINATION_ERROR, errors };
}

export function cancelExamination(
  examinationId,
  successCallback,
  errorCallback
) {
  return {
    type: types.CANCEL_EXAMINATION,
    examinationId,
    successCallback,
    errorCallback
  };
}
export function cancelExaminationSuccess(examination) {
  return { type: types.CANCEL_EXAMINATION_SUCCESS, examination };
}
export function cancelExaminationError(errors) {
  return { type: types.CANCEL_EXAMINATION_ERROR, errors };
}

export function getExaminationMeetingSignature(
  examinationId,
  successCallback,
  errorCallback
) {
  return {
    type: types.GET_EXAMINATION_MEETING_SIGNATURE,
    examinationId,
    successCallback,
    errorCallback
  };
}
export function getExaminationMeetingSignatureSuccess(meeting_signature) {
  return {
    type: types.GET_EXAMINATION_MEETING_SIGNATURE_SUCCESS,
    meeting_signature
  };
}
export function getExaminationMeetingSignatureError(errors) {
  return { type: types.GET_EXAMINATION_MEETING_SIGNATURE_ERROR, errors };
}

/*
export function destroyDoctorTimetable(doctorTimetableId, successCallback, errorCallback) {
  return {
    type: types.DESTROY_DOCTOR_TIMETABLE,
    doctorTimetableId,
    successCallback,
    errorCallback
  };
}
export function destroyDoctorTimetableSuccess() {
  return { type: types.DESTROY_DOCTOR_TIMETABLE_SUCCESS };
}
export function destroyDoctorTimetableError(errors) {
  return { type: types.DESTROY_DOCTOR_TIMETABLE_ERROR, errors };
}
*/
