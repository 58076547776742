import { map } from "lodash";
import { takeEvery, call, put, all } from "redux-saga/effects";
import chroma from "chroma-js";
import moment from "moment";

import DoctorTimetableSource from "../sources/DoctorTimetableSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import {
  GET_DOCTOR_TIMETABLES,
  CREATE_DOCTOR_TIMETABLE,
  DESTROY_DOCTOR_TIMETABLE
} from "../actions/const";
import {
  getDoctorTimetablesSuccess,
  getDoctorTimetablesError,
  createDoctorTimetableSuccess,
  createDoctorTimetableError,
  destroyDoctorTimetableSuccess,
  destroyDoctorTimetableError,
  postError,
  postNotice
} from "../actions";

/* INDEX */
function* getDoctorTimetables({ doctorId, from, to }) {
  const { response, errors } = yield call(DoctorTimetableSource.index, doctorId, from, to);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getDoctorTimetablesError(errors.pointers));
    return;
  }
  // const doctorTimetables = yield call(mapIndex, response);
  const doctorTimetablesNavigator = new ApiJsonNavigator(response.data);
  yield put(getDoctorTimetablesSuccess(doctorTimetablesNavigator));
}

export const getDoctorTimetablesFlow = takeEvery(
  GET_DOCTOR_TIMETABLES,
  getDoctorTimetables
);

/* CREATE */
function* createDoctorTimetable({ doctorId, doctorTimetable, successCallback, errorCallback }) {
  const { start_at, end_at } = doctorTimetable;
  const doctor_timetable = {
    doctor_timetable: {
      start_at,
      end_at
    }
  };
  const { response, errors } = yield call(
    DoctorTimetableSource.create,
    doctorId,
    doctor_timetable
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(createDoctorTimetableError(errors.pointers));
    if (errorCallback) {
      yield call(errorCallback, errors);
    }
    return;
  }
  const doctorTimetableNavigator = new ApiJsonNavigator(response.data);
  yield put(postNotice("doctor_timetable.success"));
  yield put(createDoctorTimetableSuccess(doctorTimetableNavigator));
  if (successCallback) {
    yield call(successCallback, doctorTimetableNavigator);
  }
}

export const createDoctorTimetableFlow = takeEvery(
  CREATE_DOCTOR_TIMETABLE,
  createDoctorTimetable
);

/* DESTROY */
function* destroyDoctorTimetable({ doctorTimetableId, successCallback, errorCallback }) {
  const { response, errors } = yield call(
    DoctorTimetableSource.destroy,
    doctorTimetableId,
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(destroyDoctorTimetableError(errors.pointers));
    if (errorCallback) {
      yield call(errorCallback, errors);
    }
    return;
  }
  const doctorTimetableNavigator = new ApiJsonNavigator(response.data);
  yield put(postNotice("doctor_timetable.success"));
  yield put(destroyDoctorTimetableSuccess(doctorTimetableNavigator));
  if (successCallback) {
    yield call(successCallback);
  }
}

export const destroyDoctorTimetableFlow = takeEvery(
  DESTROY_DOCTOR_TIMETABLE,
  destroyDoctorTimetable
);