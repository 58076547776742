import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@material-ui/core";
import {
  Done as DoneIcon,
  Timelapse as TimelapseIcon,
  HighlightOff
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: "black"
  }
}));

const ChipStatus = ({ status, label }, { t }) => {
  const classes = useStyles();
  switch (status) {
    case "pending":
    case "draft": {
      return (
        <Chip
          label={label || t(`status.${status}`)}
          icon={<TimelapseIcon />}
          style={{ backgroundColor: "#EDE04D" }}
          classes={{ icon: classes.icon }}
        />
      );
    }
    case "rejected":
    case "canceled": {
      return (
        <Chip
          label={label || t(`status.${status}`)}
          icon={<HighlightOff />}
          style={{ backgroundColor: "#4bb1cf" }}
          classes={{ icon: classes.icon }}
        />
      );
    }
    case "accepted":
    case "confirmed": {
      return (
        <Chip
          label={label || t(`status.${status}`)}
          icon={<DoneIcon />}
          style={{ backgroundColor: "#15CD72" }}
          classes={{ icon: classes.icon }}
        />
      );
    }
    default: {
      return <Chip label={label || t(`status.${status}`)} />;
    }
  }
};

ChipStatus.contextTypes = {
  t: PropTypes.func
};

export default ChipStatus;
