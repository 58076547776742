import {
  GET_DOCTOR_PROFILES,
  GET_DOCTOR_PROFILES_SUCCESS,
  GET_DOCTOR_PROFILES_ERROR
} from "./const";
import {
  GET_DOCTOR_PROFILE,
  GET_DOCTOR_PROFILE_SUCCESS,
  GET_DOCTOR_PROFILE_ERROR
} from "./const";
import {
  EDIT_DOCTOR_PROFILE,
  EDIT_DOCTOR_PROFILE_SUCCESS,
  EDIT_DOCTOR_PROFILE_ERROR
} from "./const";
import { RESET_DOCTOR_ERRORS } from "./const";
import {
  ASSOCIATED_PATIENTS,
  ASSOCIATED_PATIENTS_SUCCESS,
  ASSOCIATED_PATIENTS_ERROR
} from "./const";

export function getDoctorProfiles() {
  return {
    type: GET_DOCTOR_PROFILES
  };
}
export function getDoctorProfilesSuccess(profiles) {
  return { type: GET_DOCTOR_PROFILES_SUCCESS, profiles };
}
export function getDoctorProfilesError(errors) {
  return { type: GET_DOCTOR_PROFILES_ERROR, errors };
}

export function getDoctorProfile(profileId) {
  return {
    type: GET_DOCTOR_PROFILE,
    profileId
  };
}
export function getDoctorProfileSuccess(profile) {
  return { type: GET_DOCTOR_PROFILE_SUCCESS, profile };
}
export function getDoctorProfileError(errors) {
  return { type: GET_DOCTOR_PROFILE_ERROR, errors };
}

export function editDoctorProfile(
  profileId,
  profile,
  successCallback,
  errorCallback
) {
  return {
    type: EDIT_DOCTOR_PROFILE,
    profileId,
    profile,
    successCallback,
    errorCallback
  };
}
export function editDoctorProfileSuccess(profile) {
  return { type: EDIT_DOCTOR_PROFILE_SUCCESS, profile };
}

export function editDoctorProfileError(errors) {
  return { type: EDIT_DOCTOR_PROFILE_ERROR, errors };
}

export function resetDoctorErrors() {
  return { type: RESET_DOCTOR_ERRORS };
}

export function associatedPatients(profileId) {
  return {
    type: ASSOCIATED_PATIENTS,
    profileId
  };
}

export function associatedPatientsSuccess(associatedPatientList) {
  return { type: ASSOCIATED_PATIENTS_SUCCESS, associatedPatientList };
}

export function associatedPatientsError(errors) {
  return { type: ASSOCIATED_PATIENTS_ERROR, errors };
}
