import { all } from "redux-saga/effects";
import { loginFlow, logoutFlow } from "./loginSaga";
import {
  countryFlow,
  getStatesByCountryFlow,
  getCitiesByStateFlow,
  getProvincesByStateFlow
} from "./countrySaga";
import {
  getDoctorProfilesFlow,
  getDoctorProfileFlow,
  editDoctorProfileFlow,
  getAssociatedPatientsFlow
} from "./doctorSaga";
import {
  getPatientProfileFlow,
  editPatientProfileFlow,
  associateDoctorFlow,
  associatedDoctorFlow,
  registerFlow
} from "./patientSaga";
import { getDrgsFlow } from "./drgSaga";
import {
  DRGRequestIndexFlow,
  showDrgRequestFlow,
  addDRGRequestFlow,
  getCrossBorderHealthcareAuthorizationsFlow
} from "./drgRequestSaga";
import {
  drgRequestScheduleIndexFlow,
  getDrgRequestScheduleFlow,
  addDrgRequestScheduleFlow,
  editDrgRequestScheduleFlow,
  deleteDrgRequestScheduleFlow
} from "./drgRequestScheduleSaga";
import {
  getDoctorPatientFlow,
  editDoctorPatientFlow
} from "./doctorPatientSaga";
import {
  scheduledFileIndexFlow,
  getScheduledFileFlow,
  addScheduledFileFlow,
  editScheduledFileFlow,
  confirmScheduledFileFlow,
  cancelScheduledFileFlow,
  getDoctorClinicsFlow
} from "./scheduledFileSaga";
import {
  scheduledFileDocumentIndexFlow,
  drgRequestScheduleDocumentIndexFlow,
  drgRequestDocumentIndexFlow,
  doctorPatientsDocumentIndexFlow,
  examinationDocumentIndexFlow,
  documentActionCreatorFlow,
  addDoctorPatientsDocumentFlow,
  deleteDoctorPatientsDocumentFlow,
  addExaminationDocumentFlow,
  deleteExaminationDocumentFlow
} from "./documentSaga";
import { documentTypesIndexFlow } from "./documentTypeSaga";
import {
  getDoctorTimetablesFlow,
  createDoctorTimetableFlow,
  destroyDoctorTimetableFlow
} from "./doctorTimetableSaga";
import {
  createExaminationFlow,
  getExaminationsFlow,
  getExaminationFlow,
  acceptExaminationFlow,
  declineExaminationFlow,
  cancelExaminationFlow,
  getExaminationMeetingSignatureFlow
} from "./examinationSaga";
import {
  examinationMessageIndexFlow,
  createExaminationMessageFlow
} from "./messageSaga";

export default function* rootSaga() {
  yield all([
    loginFlow,
    logoutFlow,
    countryFlow,
    getStatesByCountryFlow,
    getCitiesByStateFlow,
    getProvincesByStateFlow,
    getDoctorProfilesFlow,
    getDoctorProfileFlow,
    editDoctorProfileFlow,
    getAssociatedPatientsFlow,
    getPatientProfileFlow,
    editPatientProfileFlow,
    associateDoctorFlow,
    associatedDoctorFlow,
    registerFlow,
    getDrgsFlow,
    DRGRequestIndexFlow,
    showDrgRequestFlow,
    addDRGRequestFlow,
    getCrossBorderHealthcareAuthorizationsFlow,
    drgRequestScheduleIndexFlow,
    getDrgRequestScheduleFlow,
    addDrgRequestScheduleFlow,
    editDrgRequestScheduleFlow,
    deleteDrgRequestScheduleFlow,
    getDoctorPatientFlow,
    editDoctorPatientFlow,
    scheduledFileIndexFlow,
    getScheduledFileFlow,
    addScheduledFileFlow,
    editScheduledFileFlow,
    confirmScheduledFileFlow,
    cancelScheduledFileFlow,
    getDoctorClinicsFlow,
    scheduledFileDocumentIndexFlow,
    drgRequestScheduleDocumentIndexFlow,
    drgRequestDocumentIndexFlow,
    doctorPatientsDocumentIndexFlow,
    documentActionCreatorFlow,
    addDoctorPatientsDocumentFlow,
    deleteDoctorPatientsDocumentFlow,
    documentTypesIndexFlow,
    getDoctorTimetablesFlow,
    createDoctorTimetableFlow,
    destroyDoctorTimetableFlow,
    createExaminationFlow,
    getExaminationsFlow,
    getExaminationFlow,
    acceptExaminationFlow,
    declineExaminationFlow,
    cancelExaminationFlow,
    examinationDocumentIndexFlow,
    addExaminationDocumentFlow,
    deleteExaminationDocumentFlow,
    getExaminationMeetingSignatureFlow,
    examinationMessageIndexFlow,
    createExaminationMessageFlow
  ]);
}
