import { cloneDeep } from "lodash";
import {
  DRG_REQUEST_SCHEDULE_INDEX,
  DRG_REQUEST_SCHEDULE_INDEX_SUCCESS,
  DRG_REQUEST_SCHEDULE_INDEX_ERROR
} from "../actions/const";
import {
  ADD_DRG_REQUEST_SCHEDULE,
  ADD_DRG_REQUEST_SCHEDULE_SUCCESS,
  ADD_DRG_REQUEST_SCHEDULE_ERROR
} from "../actions/const";
import {
  GET_DRG_REQUEST_SCHEDULE,
  GET_DRG_REQUEST_SCHEDULE_SUCCESS,
  GET_DRG_REQUEST_SCHEDULE_ERROR
} from "../actions/const";
import {
  EDIT_DRG_REQUEST_SCHEDULE,
  EDIT_DRG_REQUEST_SCHEDULE_SUCCESS,
  EDIT_DRG_REQUEST_SCHEDULE_ERROR
} from "../actions/const";
import {
  DELETE_DRG_REQUEST_SCHEDULE,
  DELETE_DRG_REQUEST_SCHEDULE_SUCCESS,
  DELETE_DRG_REQUEST_SCHEDULE_ERROR
} from "../actions/const";
import { RESET_DRG_REQUEST_SCHEDULE_ERRORS } from "./../actions/const";

const initialState = {
  loading: false,
  loadingCurrent: false,
  list: [],
  current: {},
  errors: []
};

const reducer = (state = initialState, action) => {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case DRG_REQUEST_SCHEDULE_INDEX: {
      nextState.loading = true;
      nextState.list = [];
      return nextState;
    }
    case DRG_REQUEST_SCHEDULE_INDEX_SUCCESS: {
      const { data } = action;
      nextState.list = data;
      nextState.loading = false;
      return nextState;
    }
    case DRG_REQUEST_SCHEDULE_INDEX_ERROR: {
      nextState.list = [];
      nextState.loading = false;
      return nextState;
    }
    case ADD_DRG_REQUEST_SCHEDULE: {
      nextState.loadingCurrent = true;
      return nextState;
    }
    case ADD_DRG_REQUEST_SCHEDULE_SUCCESS: {
      nextState.errors = [];
      nextState.loadingCurrent = false;
      return nextState;
    }
    case ADD_DRG_REQUEST_SCHEDULE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      nextState.loadingCurrent = false;
      return nextState;
    }
    case GET_DRG_REQUEST_SCHEDULE: {
      nextState.current = {};
      nextState.loadingCurrent = true;
      return nextState;
    }
    case GET_DRG_REQUEST_SCHEDULE_SUCCESS: {
      const { drgRequestSchedule } = action;
      nextState.current = drgRequestSchedule;
      nextState.loadingCurrent = false;
      nextState.errors = [];
      return nextState;
    }
    case GET_DRG_REQUEST_SCHEDULE_ERROR: {
      const { errors } = action;
      nextState.current = {};
      nextState.loadingCurrent = false;
      nextState.errors = errors;
      return nextState;
    }
    case EDIT_DRG_REQUEST_SCHEDULE: {
      return nextState;
    }
    case EDIT_DRG_REQUEST_SCHEDULE_SUCCESS: {
      nextState.errors = [];
      return nextState;
    }
    case EDIT_DRG_REQUEST_SCHEDULE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    case DELETE_DRG_REQUEST_SCHEDULE: {
      return nextState;
    }
    case DELETE_DRG_REQUEST_SCHEDULE_SUCCESS: {
      nextState.errors = [];
      return nextState;
    }
    case DELETE_DRG_REQUEST_SCHEDULE_ERROR: {
      const { errors } = action;
      nextState.errors = errors;
      return nextState;
    }
    case RESET_DRG_REQUEST_SCHEDULE_ERRORS: {
      nextState.errors = [];
      return nextState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
