import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  DialogContent,
  DialogContentText,
  Button,
  LinearProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { Formik } from "formik";
import { isNull } from "lodash";

import {
  editDrgRequestSchedule,
  getDrgRequestSchedule,
  deleteDrgRequestSchedule,
  drgRequestScheduleIndex,
  DRGRequestIndex,
  DRGIndex
} from "../../../../actions";
import DrgRequestScheduleForm from "../../shared/DrgRequestScheduleForm";

const useStyles = makeStyles(theme => ({
  addScheduledFileTitle: {
    display: "flex"
  },
  addScheduledFileTypography: {
    flexGrow: 1
  }
}));

const EditDrgRequestScheduleDialog = (props, context) => {
  const { t } = context;
  const classes = useStyles();
  const user = useSelector(state => state.user.current);
  const actionsDispatch = useDispatch();
  const { currentScheduledFile } = useSelector(state => state.scheduledFile);
  const { loadingCurrent, current, errors } = useSelector(
    state => state.drgRequestSchedule
  );
  const currentLang = useSelector(state => state.i18nState.lang);
  const drgs = useSelector(state => state.drg.list);

  const { currentDrgRequestScheduleId, handleModal } = props;
  const getDrgs = patientId => actionsDispatch(DRGIndex(patientId));

  useEffect(() => {
    if (!isNull(currentDrgRequestScheduleId))
      actionsDispatch(getDrgRequestSchedule(currentDrgRequestScheduleId));
  }, [actionsDispatch, currentDrgRequestScheduleId]);

  const successCallback = () => {
    handleModal(null);
    actionsDispatch(drgRequestScheduleIndex(currentScheduledFile.id));
    actionsDispatch(
      DRGRequestIndex(null, user.doctorId, {
        q: { accepted: true, not_scheduled: true }
      })
    );
  };

  return (
    <Dialog
      open={!isNull(currentDrgRequestScheduleId)}
      onClose={() => handleModal(null)}
    >
      <DialogTitle disableTypography className={classes.addScheduledFileTitle}>
        <Typography variant="h6" className={classes.addScheduledFileTypography}>
          {t("components.doctor.edit_drg_request_schedule_dialog.title")}
        </Typography>
        <Tooltip
          title={t("components.doctor.edit_drg_request_schedule_dialog.close")}
          placement="top"
        >
          <IconButton size="small" onClick={() => handleModal(null)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      {loadingCurrent ? (
        <LinearProgress />
      ) : (
        <DialogContent>
          <DialogContentText>
            {t("components.doctor.edit_drg_request_schedule_dialog.text")}
          </DialogContentText>
          <Formik
            onSubmit={(values, actions) => {
              const {
                startTime,
                duration,
                hospitalizationDays,
                notes,
                drgId,
                implants
              } = values;
              const drgRequestSchedule = {
                startTime: startTime.utc(),
                duration,
                hospitalizationDays,
                notes,
                drgId,
                drgRequestId: current.drgRequestId,
                implants
              };
              actionsDispatch(
                editDrgRequestSchedule(
                  currentDrgRequestScheduleId,
                  drgRequestSchedule,
                  successCallback
                )
              );
            }}
            initialValues={current}
            render={({ handleSubmit, handleChange, values }) => (
              <form onSubmit={handleSubmit}>
                <DrgRequestScheduleForm
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  currentScheduledFile={currentScheduledFile}
                  currentLang={currentLang}
                  drgs={drgs}
                  onLoad={() => getDrgs(current.patient.id)}
                  editMode
                />
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  fullWidth
                >
                  {t(
                    "components.doctor.edit_drg_request_schedule_dialog.update"
                  )}
                </Button>
                <br />
                <br />
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={() =>
                    // eslint-disable-next-line no-restricted-globals
                    confirm(
                      t(
                        "components.doctor.edit_drg_request_schedule_dialog.confirm_delete"
                      )
                    ) &&
                    actionsDispatch(
                      deleteDrgRequestSchedule(
                        currentDrgRequestScheduleId,
                        successCallback
                      )
                    )
                  }
                >
                  {t(
                    "components.doctor.edit_drg_request_schedule_dialog.delete"
                  )}
                </Button>
              </form>
            )}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};

EditDrgRequestScheduleDialog.contextTypes = {
  t: PropTypes.func
};

export default EditDrgRequestScheduleDialog;
