import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  MenuItem,
  Grid,
  Chip
} from "@material-ui/core";
import { map, isEmpty, isEqual, includes } from "lodash";
import { makeStyles } from "@material-ui/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import "moment/locale/it";
import "moment/locale/en-gb";

import {
  getCountries,
  getStatesByCountry,
  getCitiesByState,
  getProvinces
} from "../../actions";

import { specializations } from "../../sources/libs/const";

const useStyles = makeStyles(theme => ({
  textField: {
    width: "100%",
    marginTop: 15,
    marginBottom: 8,
    textAlign: "left"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  input: {
    textOverflow: "ellipsis"
  }
}));

const EditProfileFields = (props, context) => {
  const { t } = context;

  const { profile, doctor, errors, handleChangeField } = props;

  const actionsDispatch = useDispatch();

  const {
    loadingCountries,
    loadingStates,
    loadingCities,
    loadingProvinces,
    countries,
    states,
    cities,
    provinces
  } = useSelector(state => state.country, isEqual);
  const currentLang = useSelector(state => state.i18nState.lang);

  const classes = useStyles();

  useEffect(() => {
    actionsDispatch(getCountries());
    if (profile.birthCountry)
      actionsDispatch(getStatesByCountry("birth", profile.birthCountry));
    if (profile.birthState)
      actionsDispatch(
        getCitiesByState("birth", profile.birthCountry, profile.birthState)
      );
    if (profile.residentialCountry)
      actionsDispatch(
        getStatesByCountry("residential", profile.residentialCountry)
      );
    if (profile.residentialState)
      actionsDispatch(
        getCitiesByState(
          "residential",
          profile.residentialCountry,
          profile.residentialState
        )
      );
    if (profile.residentialCountry && profile.residentialState)
      actionsDispatch(
        getProvinces(
          "residential",
          profile.residentialCountry,
          profile.residentialState
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCountryChange = (key, value) => {
    actionsDispatch(getStatesByCountry(key, value));
    handleChangeField({ target: { name: `${key}Country`, value } });
    handleChangeField({ target: { name: `${key}State`, value: "" } });
    handleChangeField({ target: { name: `${key}City`, value: "" } });
  };

  const handleStateChange = (key, value) => {
    actionsDispatch(getCitiesByState(key, profile[`${key}Country`], value));
    handleChangeField({ target: { name: `${key}State`, value } });
    handleChangeField({ target: { name: `${key}City`, value: "" } });
    if (isEqual(key, "residential")) {
      actionsDispatch(getProvinces(key, profile.residentialCountry, value));
      handleChangeField({ target: { name: `residentialProvince`, value: "" } });
    }
  };

  return (
    <div>
      <br />
      <Typography variant="h5">
        {t("components.shared.edit_profile.title")}
      </Typography>
      <br />

      <Grid item container justify="center" spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            label={t("components.shared.edit_profile.name")}
            required
            value={profile.name || ""}
            onChange={handleChangeField}
            error={includes(errors, "name")}
          />
          <br />
          <TextField
            fullWidth
            name="surname"
            label={t("components.shared.edit_profile.surname")}
            required
            value={profile.surname || ""}
            onChange={handleChangeField}
            error={includes(errors, "surname")}
          />
          {doctor ? null : (
            <>
              <br />
              <br />
              <FormControl error={includes(errors, "gender")}>
                <FormLabel required>
                  {t("components.shared.edit_profile.gender")}
                </FormLabel>
                <RadioGroup name="gender" onChange={handleChangeField} row>
                  <FormControlLabel
                    value={"male"}
                    checked={isEqual(profile.gender, "male")}
                    control={<Radio required />}
                    label={t("components.shared.edit_profile.male")}
                  />
                  <FormControlLabel
                    value={"female"}
                    checked={isEqual(profile.gender, "female")}
                    control={<Radio />}
                    label={t("components.shared.edit_profile.female")}
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container justify="center" spacing={2}>
        <Grid item md={6} xs={12}>
          {doctor ? null : (
            <>
              <TextField
                name="membershipAsl"
                label={t("components.shared.edit_profile.membership_asl")}
                className={classes.textField}
                inputProps={{
                  className: classes.input
                }}
                value={profile.membershipAsl || ""}
                onChange={handleChangeField}
                error={includes(errors, "membership_asl")}
              />
              <br />
            </>
          )}
          <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLang}>
            <KeyboardDatePicker
              disableFuture
              autoOk
              clearable
              cancelLabel={t("components.shared.edit_profile.cancel")}
              clearLabel={t("components.shared.edit_profile.clear")}
              openTo="year"
              format="DD/MM/YYYY"
              label={t("components.shared.edit_profile.birth_date")}
              views={["year", "month", "date"]}
              className={classes.textField}
              value={profile.birthDate || null}
              onChange={date =>
                handleChangeField({
                  target: { name: "birthDate", value: date || null }
                })
              }
            />
            <br />
          </MuiPickersUtilsProvider>
          <TextField
            name="birthCountry"
            label={t("components.shared.edit_profile.birth_country")}
            select
            disabled={loadingCountries}
            className={classes.textField}
            inputProps={{
              className: classes.input
            }}
            value={profile.birthCountry || ""}
            onChange={event => handleCountryChange("birth", event.target.value)}
            error={includes(errors, "birth_country")}
          >
            {map(countries, (item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
          <br />
          <TextField
            name="birthState"
            label={t("components.shared.edit_profile.birth_state")}
            select
            className={classes.textField}
            inputProps={{
              className: classes.input
            }}
            value={profile.birthState || ""}
            disabled={isEmpty(states.birth) || loadingStates["birth"]}
            onChange={event => handleStateChange("birth", event.target.value)}
            error={includes(errors, "birth_state")}
          >
            {map(states.birth, (item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
          <br />
          <TextField
            name="birthCity"
            label={t("components.shared.edit_profile.birth_city")}
            select
            className={classes.textField}
            inputProps={{
              className: classes.input
            }}
            disabled={isEmpty(cities.birth) || loadingCities["birth"]}
            value={profile.birthCity || ""}
            onChange={handleChangeField}
            error={includes(errors, "birth_city")}
          >
            {map(cities.birth, (item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.id}
                </MenuItem>
              );
            })}
          </TextField>
          <br />
          <TextField
            name="fiscalCode"
            label={t("components.shared.edit_profile.fiscal_code")}
            inputProps={{
              maxLength: 16,
              minLength: 16,
              className: classes.input
            }}
            className={classes.textField}
            value={profile.fiscalCode || ""}
            onChange={handleChangeField}
            error={includes(errors, "fiscal_code")}
          />
          <br />
          {doctor ? (
            <TextField
              multiline={true}
              rows={8}
              name="presentation"
              label={t("components.shared.edit_profile.presentation")}
              inputProps={{
                className: classes.input
              }}
              className={classes.textField}
              value={profile.presentation || ""}
              onChange={handleChangeField}
              error={includes(errors, "presentation")}
            />
            )
            : null
          }
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            name="residentialAddress"
            label={t("components.shared.edit_profile.residential_address")}
            className={classes.textField}
            inputProps={{
              className: classes.input
            }}
            value={profile.residentialAddress || ""}
            onChange={handleChangeField}
            error={includes(errors, "residentialAddress")}
          />
          <br />
          <TextField
            name="residentialPostalCode"
            label={t("components.shared.edit_profile.residential_postal_code")}
            className={classes.textField}
            inputProps={{
              className: classes.input
            }}
            value={profile.residentialPostalCode || ""}
            onChange={handleChangeField}
            error={includes(errors, "residentialPostalCode")}
          />
          <br />
          <TextField
            name="residentialCountry"
            label={t("components.shared.edit_profile.residential_country")}
            select
            disabled={loadingCountries}
            className={classes.textField}
            inputProps={{
              className: classes.input
            }}
            value={profile.residentialCountry || ""}
            onChange={event =>
              handleCountryChange("residential", event.target.value)
            }
            error={includes(errors, "residential_country")}
          >
            <MenuItem key={-1} value={null}>
              -
            </MenuItem>
            {map(countries, (item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
          <br />
          <TextField
            name="residentialState"
            label={t("components.shared.edit_profile.residential_state")}
            select
            className={classes.textField}
            inputProps={{
              className: classes.input
            }}
            disabled={
              isEmpty(states.residential) || loadingStates["residential"]
            }
            value={profile.residentialState || ""}
            onChange={event =>
              handleStateChange("residential", event.target.value)
            }
            error={includes(errors, "residential_state")}
          >
            <MenuItem key={-1} value={null}>
              -
            </MenuItem>
            {map(states.residential, (item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
          <br />
          <TextField
            name="residentialCity"
            label={t("components.shared.edit_profile.residential_city")}
            select
            className={classes.textField}
            inputProps={{
              className: classes.input
            }}
            disabled={
              isEmpty(cities.residential) || loadingCities["residential"]
            }
            value={profile.residentialCity || ""}
            onChange={handleChangeField}
            error={includes(errors, "residential_city")}
          >
            <MenuItem key={-1} value={null}>
              -
            </MenuItem>
            {map(cities.residential, (item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.id}
                </MenuItem>
              );
            })}
          </TextField>
          <br />
          {doctor ? null : (
            <>
              <TextField
                name="residentialProvince"
                label={t("components.shared.edit_profile.residential_province")}
                select
                className={classes.textField}
                inputProps={{
                  className: classes.input
                }}
                disabled={
                  isEmpty(provinces.residential) ||
                  loadingProvinces["residential"]
                }
                value={profile.residentialProvince || ""}
                onChange={handleChangeField}
                error={includes(errors, "residential_province")}
              >
                <MenuItem key={-1} value={null}>
                -
                </MenuItem>
                {map(provinces.residential, item => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.id}
                    </MenuItem>
                  );
                })}
              </TextField>
              <br />
            </>
          )}
          <TextField
            name="phone"
            label={t("components.shared.edit_profile.phone")}
            type="tel"
            required
            inputProps={{
              maxLength: 12,
              minLength: 6,
              className: classes.input
            }}
            className={classes.textField}
            value={profile.phone || ""}
            onChange={handleChangeField}
            error={includes(errors, "phone")}
          />
          <br />
          <TextField
            name="mobilePhone"
            label={t("components.shared.edit_profile.mobile_phone")}
            type="tel"
            inputProps={{
              maxLength: 12,
              minLength: 6,
              className: classes.input
            }}
            className={classes.textField}
            value={profile.mobilePhone || ""}
            onChange={handleChangeField}
            error={includes(errors, "mobile_phone")}
          />
          <br />
          <TextField
            type="email"
            name="emailAddress"
            label={t("components.shared.edit_profile.email")}
            required
            className={classes.textField}
            inputProps={{
              className: classes.input
            }}
            value={profile.emailAddress || ""}
            onChange={handleChangeField}
            error={includes(errors, "email_address")}
          />
          <br />
          <br />
        </Grid>
      </Grid>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12}>
          {doctor ? (
            <TextField
              fullWidth
              name="specializations"
              label={t("components.shared.edit_profile.specializations")}
              select
              SelectProps={{
                multiple: true,
                renderValue: selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        className={classes.chip}
                        key={value}
                        label={t(`specializations.${value}`)}
                      />
                    ))}
                  </div>
                )
              }}
              className={classes.textField}
              value={profile.specializations || []}
              onChange={handleChangeField}
              error={includes(errors, "specializations")}
            >
              {map(specializations, (item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {t(`specializations.${item}`)}
                  </MenuItem>
                );
              })}
            </TextField>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

EditProfileFields.contextTypes = {
  t: PropTypes.func
};

export default EditProfileFields;
