import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import I18n from "redux-i18n";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import store from "./stores";
import history from "./stores/history";
import { translations } from "./translations/translations";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import SefTheme from "./styles/sef_theme.json";

import { TeleconsultProvider} from "./context/TeleconsultContext";

const renderApp = Component =>
  ReactDOM.render(
    <MuiThemeProvider theme={createMuiTheme(SefTheme)}>
      <Provider store={store}>
        <I18n translations={translations} initialLang="it">
          <ConnectedRouter history={history}>
            <TeleconsultProvider>
            <Component />
            </TeleconsultProvider>
          </ConnectedRouter>
        </I18n>
      </Provider>
    </MuiThemeProvider>,
    document.getElementById("root")
  );

renderApp(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    renderApp(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
