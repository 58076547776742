import React from "react";
import './jquery-loader';

import { Route, Redirect } from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";

import PrivateRoute from "./components/shared/PrivateRoute";
import baseLayout from "./components/shared/layouts/Base";
import emptyLayout from "./components/shared/layouts/Empty";
import Dashboard from "./components/pages/Dashboard";
import Login from "./components/pages/Login";
import Registration from "./components/pages/Registration";
import EditProfile from "./components/pages/EditProfile";
import ConfirmRegistration from "./components/pages/ConfirmRegistration";
import AddDRGRequest from "./components/patient/pages/AddDRGRequest";
import NotificationViewer from "./components/shared/NotificationsViewer";
import EditScheduledFile from "./components/doctor/pages/EditScheduledFile";
import AssociatedPatientList from "./components/doctor/pages/AssociatedPatientList";
import PatientDetail from "./components/pages/PatientDetail";
import Homepage from "./components/pages/Homepage";
import DocumentList from "./components/pages/DocumentList";
import AddDocuments from "./components/patient/pages/AddDocuments";
import Telemedicine from "./components/patient/pages/Telemedicine";
import PatientBook from "./components/patient/pages/PatientBook";
import DoctorDetail from "./components/patient/pages/DoctorDetail";
import PlannerTelemedicine from "./components/doctor/pages/PlannerTelemedicine";
import Examination from "./components/patient/pages/Examination";
import DoctorExaminationDetail from "./components/doctor/pages/DoctorExaminationDetail";

import "./App.scss";

const App = () => (
  <>
    <CssBaseline />

    <div className="App">
      <PrivateRoute path="/" exact component={() => <Redirect to="/home" />} />
      <PrivateRoute path="/home" component={baseLayout(Homepage)} />
      <PrivateRoute path="/dashboard" component={baseLayout(Dashboard)} />
      <Route path="/register" component={emptyLayout(Registration)} />
      <Route path="/login" component={emptyLayout(Login)} />
      <PrivateRoute path="/edit_profile" component={baseLayout(EditProfile)} />
      <Route
        path="/confirm-registration"
        component={emptyLayout(ConfirmRegistration)}
      />

      {/* Patient */}
      <PrivateRoute
        path="/drg_requests/new"
        component={baseLayout(AddDRGRequest)}
      />
      <PrivateRoute
        path="/drg_requests/:id/documents"
        component={baseLayout(DocumentList)}
      />
      <PrivateRoute
        path="/scheduled_files/:id/documents"
        component={baseLayout(DocumentList)}
      />
      <PrivateRoute
        exact
        path="/scheduled_files/:id"
        component={baseLayout(EditScheduledFile)}
      />
      <PrivateRoute
        exact
        path="/add_document"
        component={baseLayout(AddDocuments)}
      />
      <PrivateRoute
        exact
        path="/patient/examinations/:id"
        component={baseLayout(Examination)}
      />

      {/* Patient - Telemedicine */}
      <PrivateRoute
        exact
        path="/telemedicine"
        component={baseLayout(Telemedicine)}
      />
      <PrivateRoute
        exact
        path="/patient_book"
        component={baseLayout(PatientBook)}
      />
      <PrivateRoute
        exact
        path="/doctor_detail/:id"
        component={baseLayout(DoctorDetail)}
      />

      {/* Doctor */}
      <PrivateRoute
        exact
        path="/patients/:id"
        component={baseLayout(PatientDetail)}
      />
      <PrivateRoute
        exact
        path="/patients"
        component={baseLayout(AssociatedPatientList)}
      />
      <PrivateRoute
        path="/drg_request_schedules/:id/documents"
        component={baseLayout(DocumentList)}
      />

      {/* Doctor - Telemedicine */}
      <PrivateRoute
        exact
        path="/planner_telemedicine"
        component={baseLayout(PlannerTelemedicine)}
      />
      <PrivateRoute
        exact
        path="/planner_telemedicine/examinations/:id"
        component={baseLayout(DoctorExaminationDetail)}
      />
      <NotificationViewer />
    </div>
  </>
);

export default App;
