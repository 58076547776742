import {
  SCHEDULED_FILE_DOCUMENT_INDEX,
  DRG_REQUEST_SCHEDULE_DOCUMENT_INDEX,
  DRG_REQUEST_DOCUMENT_INDEX,
  DOCUMENT_INDEX_SUCCESS,
  DOCUMENT_INDEX_ERROR,
  DOCUMENT_ACTION_CREATOR,
  DOCTOR_PATIENTS_DOCUMENT_INDEX,
  EXAMINATION_DOCUMENT_INDEX,
  ADD_DOCTOR_PATIENTS_DOCUMENT,
  ADD_DOCTOR_PATIENTS_DOCUMENT_SUCCESS,
  ADD_DOCTOR_PATIENTS_DOCUMENT_ERROR,
  DELETE_DOCTOR_PATIENTS_DOCUMENT,
  DELETE_DOCTOR_PATIENTS_DOCUMENT_SUCCESS,
  DELETE_DOCTOR_PATIENTS_DOCUMENT_ERROR,
  ADD_EXAMINATION_DOCUMENT,
  ADD_EXAMINATION_DOCUMENT_SUCCESS,
  ADD_EXAMINATION_DOCUMENT_ERROR,
  DELETE_EXAMINATION_DOCUMENT,
  DELETE_EXAMINATION_DOCUMENT_SUCCESS,
  DELETE_EXAMINATION_DOCUMENT_ERROR
} from "./const";

export function scheduledFileDocumentIndex(scheduledFileId) {
  return { type: SCHEDULED_FILE_DOCUMENT_INDEX, scheduledFileId };
}

export function drgRequestScheduleDocumentIndex(drgRequestScheduleId) {
  return { type: DRG_REQUEST_SCHEDULE_DOCUMENT_INDEX, drgRequestScheduleId };
}

export function drgRequestDocumentIndex(drgRequestId) {
  return { type: DRG_REQUEST_DOCUMENT_INDEX, drgRequestId };
}

export function doctorPatientsDocumentIndex(doctorPatientsId) {
  return { type: DOCTOR_PATIENTS_DOCUMENT_INDEX, doctorPatientsId };
}
export function examinationDocumentIndex(examinationId) {
  return { type: EXAMINATION_DOCUMENT_INDEX, examinationId };
}

export function documentIndexSuccess(documents, key) {
  return { type: DOCUMENT_INDEX_SUCCESS, documents };
}
export function documentIndexError(errors) {
  return { type: DOCUMENT_INDEX_ERROR, errors };
}

export function documentActionCreator(match) {
  return { type: DOCUMENT_ACTION_CREATOR, match };
}

export const addDoctorPatientsDocument = (
  doctorPatientsId,
  description,
  documentType,
  document,
  successCallback
) => ({
  type: ADD_DOCTOR_PATIENTS_DOCUMENT,
  doctorPatientsId,
  description,
  documentType,
  document,
  successCallback
});
export const addDoctorPatientsDocumentSuccess = () => ({
  type: ADD_DOCTOR_PATIENTS_DOCUMENT_SUCCESS
});
export const addDoctorPatientsDocumentError = errors => ({
  type: ADD_DOCTOR_PATIENTS_DOCUMENT_ERROR,
  errors
});

export const deleteDoctorPatientsDocument = (
  doctorPatientsId,
  documentId,
  successCallback
) => ({
  type: DELETE_DOCTOR_PATIENTS_DOCUMENT,
  doctorPatientsId,
  documentId,
  successCallback
});
export const deleteDoctorPatientsDocumentSuccess = () => ({
  type: DELETE_DOCTOR_PATIENTS_DOCUMENT_SUCCESS
});
export const deleteDoctorPatientsDocumentError = errors => ({
  type: DELETE_DOCTOR_PATIENTS_DOCUMENT_ERROR,
  errors
});

export const addExaminationDocument = (
  examinationId,
  doctor_profile_id,
  patient_profile_id,
  description,
  documentType,
  document,
  successCallback
) => ({
  type: ADD_EXAMINATION_DOCUMENT,
  examinationId,
  doctor_profile_id,
  patient_profile_id,
  description,
  documentType,
  document,
  successCallback
});
export const addExaminationDocumentSuccess = () => ({
  type: ADD_EXAMINATION_DOCUMENT_SUCCESS
});
export const addExaminationDocumentError = errors => ({
  type: ADD_EXAMINATION_DOCUMENT_ERROR,
  errors
});

export const deleteExaminationDocument = (
  examinationId,
  documentId,
  successCallback
) => ({
  type: DELETE_EXAMINATION_DOCUMENT,
  examinationId,
  documentId,
  successCallback
});
export const deleteExaminationDocumentSuccess = () => ({
  type: DELETE_EXAMINATION_DOCUMENT_SUCCESS
});
export const deleteExaminationDocumentError = errors => ({
  type: DELETE_EXAMINATION_DOCUMENT_ERROR,
  errors
});
