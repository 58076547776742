import { map } from "lodash";
import { takeEvery, call, put, all } from "redux-saga/effects";
import chroma from "chroma-js";
import moment from "moment";

import ScheduledFileSource from "../sources/ScheduledFileSource";
import ApiJsonNavigator from "../sources/libs/ApiJsonNavigator";
import {
  SCHEDULED_FILE_INDEX,
  GET_SCHEDULED_FILE,
  ADD_SCHEDULED_FILE,
  EDIT_SCHEDULED_FILE,
  CONFIRM_SCHEDULED_FILE,
  CANCEL_SCHEDULED_FILE,
  GET_DOCTOR_CLINICS
} from "../actions/const";
import {
  scheduledFileIndexSuccess,
  scheduledFileIndexError,
  getScheduledFileSuccess,
  getScheduledFileError,
  addScheduledFileSuccess,
  addScheduledFileError,
  editScheduledFileSuccess,
  editScheduledFileError,
  confirmScheduledFileSuccess,
  confirmScheduledFileError,
  cancelScheduledFileSuccess,
  cancelScheduledFileError,
  getDoctorClinicsSuccess,
  getDoctorClinicsError,
  postError,
  postNotice
} from "../actions";

const mapIndex = response => {
  const scheduledFileNavigator = new ApiJsonNavigator(response.data);
  const palette = chroma
    .cubehelix()
    .lightness([0.4, 0.7])
    .scale()
    .colors(scheduledFileNavigator.data().length);
  return map(scheduledFileNavigator.data(), (item, index) => {
    const backgroundColor = palette[index];
    const color = chroma(backgroundColor).get("lab.l") < 70 ? "white" : "black";
    return {
      data: item.attributes(),
      clinic: item.clinic().attributes(),
      events: map(item.drg_request_schedules(), drs => {
        const { name, surname } = drs
          .drg_request()
          .patient_profile()
          .attributes();
        const {
          id,
          start_time,
          duration,
          hospitalization_days,
          notes
        } = drs.attributes();
        const start = moment(start_time);
        const end = start.clone().add(duration, "minutes");
        return {
          id,
          title: `${name} ${surname}: ${
            drs
              .drg_request()
              .drg()
              .attributes().title
          }`,
          start: start.toDate(),
          end: end.toDate(),
          hospitalizationDays: hospitalization_days,
          notes,
          duration,
          backgroundColor,
          color
        };
      })
    };
  });
};

const mapGet = response => {
  const scheduledFileNavigator = new ApiJsonNavigator(response.data);
  return {
    ...scheduledFileNavigator.data().attributes(),
    clinic: scheduledFileNavigator
      .data()
      .clinic()
      .attributes()
  };
};

const mapScheduledFileId = response => {
  const scheduledFileNavigator = new ApiJsonNavigator(response.data);
  return { scheduledFileId: scheduledFileNavigator.data().id };
};

const mapClinics = response => {
  const clinicNavigator = new ApiJsonNavigator(response.data);
  return map(clinicNavigator.data(), item => item.attributes());
};

function* scheduledFileIndex({ doctorId }) {
  const { response, errors } = yield call(ScheduledFileSource.index, doctorId);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(scheduledFileIndexError(errors.pointers));
    return;
  }
  const scheduledFiles = yield call(mapIndex, response);
  yield put(scheduledFileIndexSuccess(scheduledFiles));
}

function* getScheduledFile({ scheduledFileId }) {
  const { response, errors } = yield call(
    ScheduledFileSource.show,
    scheduledFileId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getScheduledFileError(errors.pointers));
    return;
  }
  const scheduledFile = yield call(mapGet, response);
  yield put(getScheduledFileSuccess(scheduledFile));
}

function* addScheduledFile({ doctorId, scheduledFile, successCallback }) {
  const { label, from, to, clinicId } = scheduledFile;
  const scheduled_file = {
    scheduled_file: {
      label,
      from,
      to,
      clinic_id: String(clinicId),
      doctor_profile_id: doctorId
    }
  };
  const { response, errors } = yield call(
    ScheduledFileSource.add,
    doctorId,
    scheduled_file
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(addScheduledFileError(errors.pointers));
    return;
  }
  const { scheduledFileId } = yield call(mapScheduledFileId, response);
  yield put(postNotice("scheduled_file_add.success"));
  yield put(addScheduledFileSuccess(scheduledFileId));
  if (successCallback) {
    yield call(successCallback, scheduledFileId);
  }
}

function* editScheduledFile({ id, scheduledFile, successCallback }) {
  const { response, errors } = yield call(
    ScheduledFileSource.edit,
    id,
    scheduledFile,
    successCallback
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(editScheduledFileError(errors.pointers));
    return;
  }
  const editedScheduledFile = yield call(mapGet, response);
  yield put(postNotice("scheduled_file_edit.success"));
  yield put(editScheduledFileSuccess(editedScheduledFile));
  if (successCallback) {
    yield call(successCallback);
  }
}

function* confirmScheduledFile({ id, successCallback }) {
  const { response, errors } = yield call(ScheduledFileSource.confirm, id);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(confirmScheduledFileError(errors.pointers));
    return;
  }
  const { scheduledFileId } = yield call(mapScheduledFileId, response);
  yield put(postNotice("scheduled_file_confirm.success"));
  yield put(confirmScheduledFileSuccess());
  if (successCallback) {
    yield call(successCallback, scheduledFileId);
  }
}

function* cancelScheduledFile({ id, successCallback }) {
  const { response, errors } = yield call(ScheduledFileSource.cancel, id);
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(cancelScheduledFileError(errors.pointers));
    return;
  }
  const { scheduledFileId } = yield call(mapScheduledFileId, response);
  yield put(postNotice("scheduled_file_cancel.success"));
  yield put(cancelScheduledFileSuccess());
  if (successCallback) {
    yield call(successCallback, scheduledFileId);
  }
}

function* getDoctorClinics({ doctorId }) {
  const { response, errors } = yield call(
    ScheduledFileSource.getClinics,
    doctorId
  );
  if (errors) {
    yield all(errors.labels.map(error => put(postError(error))));
    yield put(getDoctorClinicsError(errors.pointers));
    return;
  }
  const clinics = yield call(mapClinics, response);
  yield put(getDoctorClinicsSuccess(clinics));
}

export const scheduledFileIndexFlow = takeEvery(
  SCHEDULED_FILE_INDEX,
  scheduledFileIndex
);

export const getScheduledFileFlow = takeEvery(
  GET_SCHEDULED_FILE,
  getScheduledFile
);

export const addScheduledFileFlow = takeEvery(
  ADD_SCHEDULED_FILE,
  addScheduledFile
);

export const editScheduledFileFlow = takeEvery(
  EDIT_SCHEDULED_FILE,
  editScheduledFile
);

export const confirmScheduledFileFlow = takeEvery(
  CONFIRM_SCHEDULED_FILE,
  confirmScheduledFile
);

export const cancelScheduledFileFlow = takeEvery(
  CANCEL_SCHEDULED_FILE,
  cancelScheduledFile
);

export const getDoctorClinicsFlow = takeEvery(
  GET_DOCTOR_CLINICS,
  getDoctorClinics
);
