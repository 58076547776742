import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  Tooltip,
  LinearProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { map, split, last, head, isEmpty, sortBy, filter } from "lodash";

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: "fixed"
  },
  tooltip: {
    maxWidth: 500
  }
}));

const DocumentList = ({ documents, loading, match }, { t }) => {
  const classes = useStyles();

  const visibleDocuments = filter(documents, item => {
    if ((match.path.match(/scheduled_files/) || []).length > 0) {
      return item.doctor_visible;
    } else if ((match.path.match(/drg_request_schedules/) || []).length > 0) {
      return item.patient_visible;
    } else if ((match.path.match(/drg_requests/) || []).length > 0) {
      return item.patient_visible;
    } else if ((match.path.match(/doctor_patients/) || []).length > 0) {
      return item.doctor_visible;
    }
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (isEmpty(visibleDocuments)) {
    return (
      <Typography variant="h5" align="center">
        {t("components.shared.document_list.no_documents")}
      </Typography>
    );
  }

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell width="20%">
            {t("components.shared.document_list.document_type")}
          </TableCell>
          <TableCell width="50%">
            {t("components.shared.document_list.description")}
          </TableCell>
          <TableCell width="20%">
            {t("components.shared.document_list.file_name")}
          </TableCell>
          <TableCell width="10%" />
        </TableRow>
      </TableHead>
      <TableBody>
        {map(sortBy(visibleDocuments, "documentType"), item => (
          <TableRow key={item.url}>
            <TableCell width="20%">
              <Typography>{item.documentType}</Typography>
            </TableCell>
            <TableCell width="50%">
              <Tooltip
                placement="bottom-start"
                title={item.description}
                classes={{ tooltip: classes.tooltip }}
                interactive
              >
                <Typography noWrap>{item.description}</Typography>
              </Tooltip>
            </TableCell>
            <TableCell width="20%">
              <Typography>
                {unescape(head(split(last(split(item.url, "/")), "?")))}
              </Typography>
            </TableCell>
            <TableCell width="10%">
              <Button
                variant="contained"
                color="primary"
                href={item.url}
                target="_blank"
              >
                {t("components.shared.document_list.download")}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

DocumentList.contextTypes = {
  t: PropTypes.func
};

export default DocumentList;
